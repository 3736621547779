<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="100px" viewBox="0 0 107 34">
    <path d="M0 0c-2.131 2.201-3.2 5.643-3.2 10.324v13.178c0 1.865-.429 4.051-1.275 6.537-.868 2.49-2.384 4.586-4.588 6.295-2.198 1.707-4.761 2.834-7.685 3.393-1.61.328-3.876.509-6.713.566V17.17c0-2.522 2.063-4.586 4.58-4.586h3.602c2.524 0 4.589-2.061 4.589-4.584V4.107c0-2.523-2.065-4.584-4.589-4.584h-31.698a4.598 4.598 0 0 0-4.586 4.584V8a4.598 4.598 0 0 0 4.586 4.584h4.345a4.601 4.601 0 0 1 4.586 4.586v23.143h-.611v-.012c-2.975-.053-5.317-.237-6.967-.574-2.901-.559-5.448-1.686-7.589-3.393-2.361-1.867-4.029-4.393-5.013-7.57-.551-1.838-.822-3.59-.822-5.262V10.324c0-4.654-1.17-8.115-3.507-10.373-1.302-1.31-3.547-2.506-6.722-3.584v-5.951c2.816-.789 4.974-1.854 6.489-3.197 2.488-2.194 3.74-5.36 3.74-9.49v-15.387c0-5.115 1.756-9.002 5.259-11.676 3.521-2.668 7.614-4.066 12.292-4.207l2.707-.072v23.121a4.597 4.597 0 0 1-4.587 4.586h-3.6a4.599 4.599 0 0 0-4.586 4.586v3.894a4.599 4.599 0 0 0 4.586 4.586h31.698a4.6 4.6 0 0 0 4.589-4.586v-3.894a4.6 4.6 0 0 0-4.589-4.586h-4.346c-2.518 0-4.58-2.061-4.58-4.586v-23.141h.509v.014l2.853.078c4.865.141 9.016 1.566 12.474 4.309C-4.925-46.496-3.2-42.641-3.2-37.658v15.387c0 4.195 1.295 7.412 3.884 9.64 1.443 1.244 3.551 2.258 6.336 3.047v5.951C3.65-2.525 1.307-1.307 0 0" style="fill:#a2a4a7;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 25.326 14.344)" />
    <path d="M0 0c-2.133 2.201-3.199 5.643-3.199 10.324v13.178c0 1.865-.435 4.051-1.276 6.537-.87 2.49-2.386 4.586-4.588 6.295-2.198 1.707-4.762 2.834-7.689 3.393-1.605.328-3.874.509-6.714.566V17.17a4.602 4.602 0 0 1 4.585-4.586h3.601A4.597 4.597 0 0 0-10.692 8V4.107A4.597 4.597 0 0 0-15.28-.477h-31.7a4.596 4.596 0 0 0-4.583 4.584V8a4.596 4.596 0 0 0 4.583 4.584h4.346a4.6 4.6 0 0 1 4.586 4.586v23.143h-.611v-.012c-2.977-.053-5.318-.237-6.967-.574-2.901-.559-5.448-1.686-7.591-3.393-2.359-1.867-4.025-4.393-5.013-7.57-.549-1.838-.82-3.59-.82-5.262V10.324c0-4.654-1.17-8.115-3.505-10.373-1.302-1.31-3.551-2.506-6.724-3.584v-5.951c2.819-.789 4.975-1.854 6.489-3.197 2.488-2.194 3.74-5.36 3.74-9.49v-15.387c0-5.115 1.756-9.002 5.26-11.676 3.522-2.668 7.613-4.066 12.291-4.207l2.709-.072v23.121a4.6 4.6 0 0 1-4.589 4.586h-3.601a4.597 4.597 0 0 0-4.583 4.586v3.894a4.597 4.597 0 0 0 4.583 4.586h31.7a4.598 4.598 0 0 0 4.588-4.586v-3.894a4.598 4.598 0 0 0-4.588-4.586h-4.345a4.6 4.6 0 0 1-4.585-4.586v-23.141h.512v.014l2.853.078c4.867.141 9.016 1.566 12.477 4.309 3.441 2.736 5.169 6.591 5.169 11.574v15.387c0 4.195 1.292 7.412 3.878 9.64 1.444 1.244 3.556 2.258 6.339 3.047v5.951C3.648-2.525 1.303-1.307 0 0" style="fill:#d61b3c;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 24.536 14.344)" />
    <path d="M0 0h3.72v22.158h-5.86v-4.676h-4.849v9.268h27.185v-9.268h-4.854v4.676H9.443V0h3.759v-4.279H0Z" style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 36.018 20.657)" />
    <path d="M0 0c3.017 0 4.983 1.797 4.983 5.117 0 2.012-.744 3.582-2.443 4.279-.88.397-2.06.571-3.501.571h-4.195V0Zm-14.595-12.191h3.717v22.42h-3.717v4.33H-.917c2.274 0 4.151-.133 5.9-.661 3.453-1.132 5.726-3.931 5.726-8.3 0-4.327-2.273-7.387-5.859-8.61v-.09s.921-.478 1.535-1.615l3.581-6.469c.436-.787 1.05-1.005 2.096-1.005h1.005v-4.28H9.441c-2.581 0-3.368.52-4.324 2.313l-4.419 8c-.654 1.176-1.223 1.57-3.016 1.57h-2.838v-7.603h3.586v-4.28h-13.025z" style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 52.557 16.356)" />
    <path d="m0 0-2.932 8.787c-.48 1.527-.569 2.709-.569 2.709h-.129s-.129-1.182-.565-2.709L-7.168 0Zm-18.967-10.27h2.839l9.304 26.75h6.512l9.313-26.75h2.839v-4.279H.17v4.279h3.061L1.309-4.457h-9.793l-1.918-5.813h3.057v-4.279h-11.622z" style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 67.648 17.034)" />
    <path d="M0 0v-2.314c0-2.01 2.531-3.19 5.463-3.19 3.145 0 5.375 1.358 5.375 3.85 0 3.14-3.542 4.144-7.123 5.547-4.156 1.57-8.219 3.453-8.219 9 0 6.252 5.067 8.66 10.402 8.66 4.895 0 9.834-1.883 9.834-5.465v-4.067H10.53v2.096c0 1.574-2.313 2.406-4.632 2.406-2.536 0-4.63-1.048-4.63-3.406 0-2.844 2.882-3.849 6.118-5.072 4.632-1.66 9.262-3.498 9.262-9.309 0-6.336-5.33-9.263-11.185-9.263-5.162 0-10.669 2.314-10.669 6.99V0Z" style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 77.666 18.638)" />
    <path d="M0 0h3.716v22.42H0v4.33h13.197v-4.33H9.438V0h3.759v-4.279H0Z" style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 88.114 20.657)" />
    <path d="M0 0h2.754l7.822 11.449L2.973 22.42H.218v4.33h11.715v-4.33H8.871l4.285-6.424c.57-.699.872-1.312.872-1.312h.089c0-.045.302.568.832 1.312L19.1 22.42h-2.972v4.33h11.274v-4.33h-2.709l-7.871-11.408L24.56 0h2.753v-4.279H15.559V0h3.057l-4.325 6.514c-.565.738-.877 1.353-.877 1.353h-.085c-.089 0-.351-.615-.828-1.353L8.347 0h3.016v-4.279H0Z" style="fill:currentColor;fill-opacity:1;fill-rule:nonzero;stroke:none" transform="matrix(.35278 0 0 -.35278 97.132 20.657)" />
  </svg>
</template>
