import colorwayMerch from './colorwayMerch'
import type { IWhiteboardTemplate } from './IWhiteboardTemplate'
import standard from './standard'
import visualLineBuilder from './visualLineBuilder'

// TODO: Might need to load them dynamically

const templates: Record<number, IWhiteboardTemplate> = {}
templates[standard.id] = standard
templates[visualLineBuilder.id] = visualLineBuilder
templates[colorwayMerch.id] = colorwayMerch

export default templates
