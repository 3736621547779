import type { Directive } from 'vue'
import DOMPurify from 'dompurify'

const sanitizeHtml: Directive = {
  beforeMount(el, binding) {
    el.innerHTML = DOMPurify.sanitize(binding.value)
  },
  updated(el, binding) {
    el.innerHTML = DOMPurify.sanitize(binding.value)
  },
}

export default sanitizeHtml
