<template>
  <tx-dialog
    v-model="isVisible" :title="title" show-ok-cancel :loading="confirming" width="30%"
    @cancel="cancel" @ok="confirm()"
  >
    <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
    <div class="text-xl">
      {{ message }}
    </div>
    <div v-if="confirmContent.length" class="mt-4">
      <div
        v-for="(content, index) in confirmContent"
        :key="index"
        v-sanitize-html="typeof content === 'object' && content.header ? content.header : content"
        class="mb-2 text-l"
      />
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import useErrorMessage from '../composables/errorMessage'
import TxDialog from './TxDialog.vue'
import TxAlert from './TxAlert.vue'

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()

const isVisible = ref(false)
const title = ref('')
const message = ref('')
const confirmContent = ref<(string | { header: string })[]>([])
const confirming = ref(false)
let resolvePromise: (value: boolean) => void
let action: (() => Promise<void>) | undefined

function show(confirmTitle: string, confirmMessage: string, confirmContentArray: any[], asyncAction?: () => Promise<void>) {
  title.value = confirmTitle
  message.value = confirmMessage
  confirmContent.value = confirmContentArray || []
  isVisible.value = true
  confirming.value = false
  errorMessage.value = ''
  action = asyncAction
  return new Promise<boolean>((resolve) => {
    resolvePromise = resolve
  })
}

async function confirm() {
  if (action) {
    confirming.value = true
    try {
      await action()
      resolvePromise(true)
      isVisible.value = false
    }
    catch (error) {
      // Handle error if needed
      console.error('Action failed:', error)
      errorMessage.value = t('general.unexpectedError')
      resolvePromise(false)
    }
    finally {
      confirming.value = false
    }
  }
  else {
    isVisible.value = false
    resolvePromise(false)
  }
}

function cancel() {
  isVisible.value = false
  resolvePromise(false)
}

defineExpose({
  show,
})
</script>
