<template>
  <tx-dialog
    v-model="visible" :title="utils.isValidStringValue(existingFolderId) ? t('manageFolderDialog.editTitle') : t('manageFolderDialog.newTitle')"
    width="450px" show-ok-cancel :ok-state="v$.$invalid ? 'disabled' : 'enabled'" :loading="loading"
    confirm-text="manageFolderDialog.saveFolder" @ok="doSave()" @cancel="doCancel()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div class="w-full h-full">
        <div class="w-full my-2">
          <tx-select v-model="form.parentFolder" :label="t('manageFolderDialog.fields.parentFolder')" :data="parentFolders" />
        </div>
        <div class="w-full my-2">
          <tx-input v-model.trim="form.name" :label="t('manageFolderDialog.fields.folderName')" required />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { computed, reactive, ref } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'

const props = defineProps<{
  folderPathSeparator: string
}>()

const emit = defineEmits<{
  (e: 'save', name: string, parentFolder: string, existingFolderId?: string): void
}>()

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const form = reactive({
  name: '',
  parentFolder: '',
})
const parentFolders = ref<string[]>([])
const existingFolderId = ref<string>()

const rules = computed(() => {
  const result: Record<string, any> = {}
  if (visible.value) {
    result.name = {}
    result.name.required = helpers.withMessage(t('validations.required', { property: t('manageFolderDialog.fields.folderName') }), required)
    result.name.minLength = withI18nMessage(minLength(1))
    result.name.maxLength = withI18nMessage(maxLength(250))
    if (utils.isValidStringValue(form.name)) {
      const isFolderNameExist = () => {
        for (const folderName of parentFolders.value) {
          const nameList = folderName.split(props.folderPathSeparator).map(namePath => namePath.trim())
          if (nameList.includes(form.name.trim())) {
            return false
          }
        }
        return true
      }
      // eslint-disable-next-line dot-notation
      result.name['isFolderNameExist'] = helpers.withMessage(t('manageFolderDialog.validations.duplicateFolderName'), isFolderNameExist)
    }
  }
  return result
})
const v$ = useVuelidate(rules, form)

function showDialog(folders: string[], folder: { folderId: string, folderName: string, parentFolder: string } | undefined = undefined) {
  parentFolders.value = folders
  reset()
  existingFolderId.value = folder?.folderId
  if (folder) {
    form.name = folder.folderName
    form.parentFolder = folder.parentFolder
  }
}

function closeDialog() {
  visible.value = false
}

async function doSave() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
    return
  }
  loading.value = true
  emit('save', form.name, form.parentFolder, existingFolderId.value)
}

function doCancel() {
  visible.value = false
}

function reset() {
  loading.value = false
  visible.value = true
  form.name = ''
  form.parentFolder = ''
}

defineExpose({
  showDialog,
  closeDialog,
  errorMessage,
})
</script>
