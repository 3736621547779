<template>
  <div
    v-if="modelValue" class="absolute flex flex-col h-full px-1 py-2 overflow-hidden rounded right-2 w-96 bg-white shadow-toolbar top-20"
    style="height: calc(100% - 100px);"
  >
    <div class="flex w-full p-4">
      <div
        class="pt-1 text-xl font-medium leading-6 text-center grow text-dark-75"
        v-text="t('whiteboard.discussions.title', discussionsList.length)"
      />
      <tx-button type="icon" faicon="fa-light fa-xmark" @click="doClose" />
    </div>
    <div class="flex h-10 p-2">
      <span class="w-10 my-auto text-sm">show</span>
      <tx-dropdown
        v-model="selectedFilterAction" :items="filterItems" value-prop="key"
        display-prop="label" @select="onFilterChange"
      >
        <template #button>
          <icon-button type="inline" faicon="fa-light fa-angle-down" :label="filterLabel" :height="20" :icon-size="20" :width="200" :right-icon="true" :label-bold="true" />
        </template>
      </tx-dropdown>
    </div>
    <div class="flex flex-col w-full h-full p-2 overflow-auto gap-y-2">
      <div v-for="discussion in discussionsList" :key="discussion.id" class="w-full border rounded-lg border-grey bg-slate-50" item-key="id">
        <div class="w-11/12 h-4 mt-2 ml-2 font-bold text-center truncate text-md">
          {{ discussion.title }}
        </div>
        <div
          class="flex flex-col items-start p-2 cursor-pointer hover:bg-gray-200"
          :class="{ 'bg-gray-300': discussion.selected }" @click="onDiscussionSelected(discussion)"
        >
          <!-- <div class="pl-2 text-base truncate"> -->
          <div class="flex items-center w-full z-[1000]">
            <div v-tooltip="discussion.comments[0].username" class="w-6 h-6 mr-1 text-sm text-center text-white border-2 rounded-full bg-grey-dark">
              {{ discussion.comments[0].firstName.substring(0, 1) }}
            </div>
            <div class="flex-1 text-base font-semibold">
              {{ discussion.comments[0].username }}
            </div>
            <div class="text-xxs">
              <use-time-ago v-slot="{ timeAgo }" :time="new Date(discussion.comments[0].date)">
                {{ timeAgo }}
              </use-time-ago>
            </div>
          </div>
          <div v-sanitize-html="discussion.comments[0].content" class="pt-1 ml-8 text-md" />
          <!-- </div> -->
        </div>
        <div v-if="discussion.comments.length > 1" class="p-2 cursor-pointer">
          <div v-if="discussion.showComments">
            <span class="ml-8 text-center text-md text-slate-400 hover:text-gray-800" @click="changeCommentsStatus(discussion, false)">{{ t('whiteboard.discussions.hideComments') }}</span>
            <div v-for="(comment, index1) in discussion.comments" :key="comment.id" item-key="id">
              <div v-if="index1 !== 0" class="pb-3 hover:bg-gray-200" :class="{ 'bg-gray-300': discussion.commentSelectedId === comment.id }" @click="onDiscussionsCommentSelected(discussion, comment)">
                <div class="flex items-center w-full p-1">
                  <div v-tooltip="comment.username" class="w-6 h-6 mr-1 text-sm text-center text-white border-2 rounded-full bg-grey-dark">
                    <span>{{ comment.firstName.substring(0, 1) }}</span>
                  </div>
                  <div class="flex-1 text-base font-semibold">
                    {{ comment.username }}
                  </div>
                  <div class="text-xxs">
                    <use-time-ago v-slot="{ timeAgo }" :time="new Date(comment.date)">
                      {{ timeAgo }}
                    </use-time-ago>
                  </div>
                </div>
                <div v-sanitize-html="comment.content" class="pt-1 ml-8 text-md" />
              </div>
            </div>
          </div>
          <div v-if="!discussion.showComments" @click="changeCommentsStatus(discussion, true)">
            <span class="ml-8 text-center text-md text-slate-400 hover:text-gray-800">{{ t('whiteboard.discussions.showComments', { remainingCommentsLength: discussion.comments.length - 1 }) }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref, watch } from 'vue'
import { UseTimeAgo } from '@vueuse/components'
import type Whiteboard from '../services/whiteboard'
import type WbDiscussionIcon from '../services/discussionIcon'
import TxButton from '@/shared/components/TxButton.vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import IconButton from '@/shared/components/IconButton.vue'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'

interface ListItem {
  id: string
  title: string
  selected: boolean
  comments: IDiscussionComment[]
  discussionObject: WbDiscussionIcon
  showComments: boolean
  commentSelectedId: string
}

interface IProps {
  modelValue?: boolean
  whiteboard?: Whiteboard
}

const props = withDefaults(defineProps<IProps>(), { modelValue: false })

const emit = defineEmits<{
  (e: 'update:modelValue', val: boolean): void
  (e: 'toggleFullscreen')
}>()

const { t } = useI18n()

const discussionsList = ref<ListItem[]>([])
const filterItems: { key: 'allComments' | 'mentions' | 'showResolved', label: string }[] = [
  { key: 'allComments', label: t('whiteboard.discussions.allComments') },
  { key: 'mentions', label: t('whiteboard.discussions.mentions') },
  { key: 'showResolved', label: t('whiteboard.discussions.showResolved') },
]
const selectedFilterAction = ref<string | null>(null)

const userStore = useUserStore()

const filterLabel = computed<string>(() => {
  let result = filterItems[0].label
  if (utils.isValidStringValue(selectedFilterAction.value)) {
    const data = filterItems.find(item => item.key === selectedFilterAction.value)
    if (data) {
      result = data.label
    }
  }
  return result
})

watch(() => props.modelValue, () => {
  setDiscussionsList()
})
watch(() => [props.whiteboard, props.whiteboard?.discussions], () => {
  setDiscussionsList()
})

function setDiscussionsList() {
  const filterValue = selectedFilterAction.value ? selectedFilterAction.value : 'allComments'
  if (props.whiteboard) {
    if (filterValue !== 'mentions') {
      const result = props.whiteboard?.discussions.value.filter(value => (filterValue === 'showResolved' && value.resolved) || (filterValue === 'allComments'))
        .map(discussionObject => ({
          id: discussionObject.id,
          title: discussionObject.title || '',
          selected: false,
          comments: discussionObject.comments,
          discussionObject,
        } as ListItem))
      discussionsList.value = result
    }
    else {
      discussionsList.value = []
      props.whiteboard?.discussions.value.forEach((discussionObject) => {
        const validComments: IDiscussionComment[] = []
        discussionObject.comments.forEach((comment) => {
          if (comment.mentioned.length) {
            const commentWithMentioned = comment.mentioned.find(mention => mention.type === 'user' && mention.value === userStore.userProfile.Id)
            if (commentWithMentioned != null) {
              validComments.push(comment)
            }
          }
        })
        if (validComments.length) {
          discussionsList.value.push({
            id: discussionObject.id,
            title: discussionObject.title || '',
            selected: false,
            comments: validComments,
            discussionObject,
          } as ListItem)
        }
      })
    }
  }
}
function changeCommentsStatus(discussion: ListItem, status: boolean) {
  discussion.showComments = status
}
function onDiscussionSelected(item: ListItem) {
  discussionsList.value.forEach((data) => {
    data.commentSelectedId = ''
    if (data.id === item.id) {
      data.selected = true
    }
    else {
      data.selected = false
    }
  })
  props.whiteboard?.moveToView(item.discussionObject)
}
function onDiscussionsCommentSelected(discussion: ListItem, comment: IDiscussionComment) {
  discussionsList.value.forEach((data) => {
    data.selected = false
    if (data.id === discussion.id) {
      data.commentSelectedId = comment.id
    }
    else {
      data.commentSelectedId = ''
    }
  })
  props.whiteboard?.moveToView(discussion.discussionObject)
}
function onFilterChange() {
  if (selectedFilterAction.value) {
    setDiscussionsList()
  }
}
function doClose() {
  emit('update:modelValue', false)
}
</script>
