import { requestConstants } from './constants'
import type { IAttachmentModel, ImpactedCatalogModel } from '@/api/t1/model/requestModel'
import utils from '@/services/utils'

export default class RequestModel {
  CatalogCode: number
  Id: number
  Content: Record<string, any>
  InitialContent: Record<string, any> | null
  RequestVersion: number
  StateId: number | null
  StateName: string
  RequestNumber: string
  SourceModelId: number | null
  SourceModelNumber: string
  SourceArticleId: number | null
  SourceArticleNumber: string
  RequestModelNumber: string
  RequestArticleNumber: string
  ArticleNumber: string
  ObjectId: string
  IsCreateArticleRequest: number
  RequestSource: number
  RequestType: string
  State: number
  Reason: string
  Comment: string
  UpdatedByUserName: string
  CreatedByUserName: string
  AttachmentCount: number
  CatalogName: string
  ParticipantCount: number
  ParticipantIds: number[]
  HasAttachment: number
  RequestAttributes: Record<string, string[]> | null
  Status: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date
  ImpactedCatalogs: ImpactedCatalogModel[]

  get RequestTypeTranslator() {
    return requestConstants.requestTypes[this.RequestType]
      ? requestConstants.requestTypes[this.RequestType].label
      : utils.isValidStringValue(this.RequestType) ? this.RequestType : requestConstants.requestTypes.AddColorway.label
  }

  constructor(request: any) {
    this.CatalogCode = request.CatalogCode
    this.Id = request.Id
    this.Content = typeof request.Content === 'string' ? utils.tryParse(request.Content) : request.Content
    this.InitialContent = typeof request.InitialContent === 'string' ? utils.tryParse(request.InitialContent) : request.InitialContent
    this.RequestVersion = request.RequestVersion
    this.StateId = request.StateId
    this.StateName = request.StateName
    this.RequestNumber = request.RequestNumber
    this.SourceModelId = request.SourceModelId
    this.SourceModelNumber = request.SourceModelNumber
    this.SourceArticleId = request.SourceArticleId
    this.SourceArticleNumber = request.SourceArticleNumber
    this.RequestModelNumber = request.RequestModelNumber
    this.RequestArticleNumber = request.RequestArticleNumber
    this.ArticleNumber = request.ArticleNumber
    this.ObjectId = request.ObjectId
    this.IsCreateArticleRequest = request.IsCreateArticleRequest ? 1 : 0
    this.RequestSource = request.RequestSource
    this.RequestType = request.RequestType
    this.State = request.State
    this.Reason = request.Reason
    this.Comment = request.Comment
    this.UpdatedByUserName = request.UpdatedByUserName
    this.CreatedByUserName = request.CreatedByUserName
    this.AttachmentCount = request.AttachmentCount
    this.CatalogName = request.CatalogName
    this.ParticipantCount = request.ParticipantCount
    this.ParticipantIds = request.ParticipantIds
    this.HasAttachment = request.HasAttachment
    this.RequestAttributes = request.RequestAttributes
    this.Status = request.Status
    this.CreatedBy = request.CreatedBy
    this.UpdatedBy = request.Updated_By
    this.CreatedDate = new Date(request.CreatedDate)
    this.UpdatedDate = new Date(request.UpdatedDate)
    this.ImpactedCatalogs = request.ImpactedCatalogs
  }
}
export interface Participants {
  CreatedDate: Date
  Id: number
  ParticipantAccountName: string
  ParticipantUserName: string
  RequestAttributes: Record<string, any>
  Status: number
  UpdatedBy: number
  UpdatedByUserName: string
  UpdatedDate: Date
  UserId: number
}
export interface RequestDetailsModel extends RequestModel {
  Attachments: IAttachmentModel[]
  Participants: Participants[]
}
