<template>
  <div>
    <tx-dropdown ref="refSettingsDropdown" faicon="fa-light fa-square-sliders" :tooltip="t('general.settings')" :max-height="90">
      <div class="w-40 h-[90px] flex flex-col overflow-hidden rounded">
        <div class="flex p-2 mb-2 bg-primary text-on-primary">
          <div class="flex-1 text-sm uppercase">
            {{ t('general.settings') }}
          </div>
          <font-awesome-icon class="w-4 h-4 cursor-pointer" icon="fa-light fa-xmark" @click="refSettingsDropdown?.closeDropdown" />
        </div>
        <div class="flex p-2 flex-col overflow-y-auto grow">
          <tx-switch v-model="form.showRequests" type="inline" :label="t('general.showRequests')" @change="onChange" />
        </div>
      </div>
    </tx-dropdown>
  </div>
</template>

<script lang="ts" setup>
import { useI18n } from 'vue-i18n'
import { onMounted, onUnmounted, reactive, ref, toRaw, watch } from 'vue'
import { useUserStore } from '@/store/userData'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import appConfig from '@/services/appConfig'
import { createContainer, getMyContainers, updateContainer } from '@/api/t1/container'
import utils from '@/services/utils'
import { containerTypeConstants } from '@/models/constants'

export interface ISettingItem {
  showRequests: boolean
}

const emit = defineEmits<{
  (e: 'change', form: ISettingItem): void
}>()

const { t } = useI18n()
const userStore = useUserStore()

const refSettingsDropdown = ref<InstanceType<typeof TxDropdown>>()

let isDirty = false
const form = reactive<ISettingItem>({
  showRequests: true,
})

function onChange() {
  if (userStore.activeCatalog) {
    const settings: Record<number, ISettingItem> = {}
    settings[userStore.activeCatalog.CatalogCode] = toRaw(form)
    userStore.userPreferences.modelDetails.settings = settings
    appConfig.DB!.userPreferences.update(userStore.userPreferences.userId, { modelDetails: { settings } })
      .catch(e => console.error('Unable to update userPreferences', e))
  }
  notifyChange()
}

function notifyChange() {
  emit('change', form)
}

async function setModelDetailSettingsContainer(catalogCode: number, settings: ISettingItem) {
  const modelDetailSettingsContainerName = `${containerTypeConstants.modelDetailSettings}-${userStore.userProfile.Id}`
  const containersList = await utils.tryAsync(getMyContainers(catalogCode, containerTypeConstants.modelDetailSettings))
  if (containersList.success) {
    const container = containersList.result.data.find(f => f.ContainerName === modelDetailSettingsContainerName)
    if (!container) {
      await utils.tryAsync(createContainer(userStore.userProfile.Id, modelDetailSettingsContainerName, JSON.stringify(settings), catalogCode, containerTypeConstants.modelDetailSettings))
    }
    else {
      await utils.tryAsync(updateContainer(userStore.userProfile.Id, container.Id, modelDetailSettingsContainerName, JSON.stringify(settings), catalogCode))
    }
  }
}

onMounted(() => {
  if (userStore.activeCatalog && userStore.userPreferences?.modelDetails?.settings[userStore.activeCatalog.CatalogCode]) {
    form.showRequests = userStore.userPreferences.modelDetails.settings[userStore.activeCatalog.CatalogCode].showRequests
    notifyChange()
  }
})

onUnmounted(() => {
  if (userStore.activeCatalog && userStore.userPreferences?.modelDetails?.settings && isDirty) {
    setModelDetailSettingsContainer(userStore.activeCatalog.CatalogCode, userStore.userPreferences.modelDetails.settings[userStore.activeCatalog.CatalogCode])
  }
})

watch(() => userStore.userPreferences?.modelDetails?.settings, () => {
  isDirty = true
})
</script>
