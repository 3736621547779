<template>
  <div :class="props.class" tabindex="0">
    <div v-if="type === 'default' && label.length > 0">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase" v-text="label" />
      <label v-if="subLabel.length > 0" class="text-xs tracking-wide uppercase" v-text="subLabel" />
    </div>
    <div class="flex w-full h-6">
      <label class="flex">
        <input
          :id="id || internalId"
          ref="refSwitch"
          type="checkbox"
          v-bind="$attrs"
          class="w-0 h-0 opacity-0"
          :checked="checked"
          :disabled="disabled"
          @change="onChange"
        >
        <span
          class="slider block relative cursor-pointer w-11 h-0.5 mt-2.5 mr-2 transition-all delay-500"
          :class="{ 'bg-gray-200': disabled, 'bg-grey': !disabled }"
        />
      </label>
      <span v-if="type === 'inline'" class="z-0 block" v-text="label" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { uniqueId } from 'lodash-es'
import { onMounted, ref, watch } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), { type: 'default', label: '', error: '', required: false, disabled: false, class: '', subLabel: '' })

const emit = defineEmits<{
  (e: 'update:modelValue', val: TxBooleanish): void
  (e: 'change', val: TxBooleanish): void
}>()

interface IProps {
  modelValue?: TxBooleanish
  type?: 'default' | 'inline'
  label?: string
  error?: string
  id?: string
  required?: boolean
  disabled?: boolean
  class?: string
  subLabel?: string
}
const internalId = ref(uniqueId('txswitch'))
const checked = ref(false)
const refSwitch = ref<HTMLInputElement>()

function onChange(event: Event) {
  const checkbox = event?.target as HTMLInputElement
  const val: TxBooleanish = typeof props.modelValue === 'boolean' ? checkbox.checked : (checkbox.checked ? 1 : 0)
  checked.value = !!val
  emit('update:modelValue', val)
  emit('change', val)
}
watch(() => props.modelValue, () => {
  checked.value = !!props.modelValue
  refSwitch.value!.checked = !!props.modelValue
})
onMounted(() => {
  if (props.modelValue != null) {
    if (typeof props.modelValue === 'string') {
      checked.value = props.modelValue.toLowerCase() === 'true'
    }
    else if (typeof props.modelValue === 'number') {
      checked.value = props.modelValue === 1
    }
    else {
      checked.value = props.modelValue
    }
  }
  else {
    checked.value = false
  }
})
</script>

<style lang="scss" scoped>
.slider {
  &:before {
    position: absolute;
    content: "";
    z-index: 1;
    height: 18px;
    width: 18px;
    left: 0;
    bottom: 4px;
    top: -8px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.25);
  }
}

input:checked + .slider:before {
  -webkit-transform: translateX(30px);
  -ms-transform: translateX(30px);
  transform: translateX(30px);
  background-color: #26a2f8;
}
</style>
