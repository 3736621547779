<template>
  <div class="container relative flex flex-col">
    <div>
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div v-if="comments.length" class="flex">
        <tx-switch v-model="resolveProp" class="w-full pt-1" type="inline" :label="t('general.resolve')" :disabled="isReadOnly" @change="onResolveChange" />
        <tx-button
          v-if="!showEditTitleView" v-tooltip="t('general.edit')" type="icon" faicon="fa-light fa-pencil"
          :disabled="resolveProp || isReadOnly" @click="onEditTitle"
        />
        <tx-button
          v-tooltip="t('general.delete')" type="icon" faicon="fa-light fa-trash-can" :disabled="resolveProp || isReadOnly"
          @click="onDelete"
        />
      </div>
      <div class="flex justify-between py-1">
        <template v-if="showEditTitleView">
          <div class="flex-1 pr-1">
            <tx-input v-model="titleProp" :placeholder="t('general.title')" />
          </div>
          <div>
            <template v-if="discussion.title">
              <tx-button
                v-tooltip="t('general.cancel')" type="icon" faicon="fa-light fa-xmark" :disabled="isReadOnly"
                @click="onCancelEditTitle"
              />
              <tx-button
                v-tooltip="t('general.apply')" type="icon" faicon="fa-light fa-check" :disabled="!titleProp.length || isReadOnly"
                @click="onUpdateTitle"
              />
            </template>
            <tx-button
              v-else v-tooltip="t('general.delete')" type="icon" faicon="fa-light fa-trash-can" :disabled="isReadOnly"
              @click="onDelete"
            />
          </div>
        </template>
        <template v-else>
          <span class="w-full text-base font-semibold truncate">{{ titleProp }}</span>
        </template>
      </div>
    </div>
    <div v-if="comments.length" class="flex-grow overflow-y-auto max-h-36">
      <div v-for="comment in comments" :key="comment.id" class="flex pb-3">
        <div class="w-6 h-6 pt-px mr-1 text-sm text-center text-white border-2 rounded-full bg-grey-dark">
          {{ comment.firstName.substring(0, 1) + comment.lastName.substring(0, 1) }}
        </div>
        <div class="flex flex-col flex-1 pr-1">
          <div class="flex text-xxs">
            <div class="flex-1">
              {{ comment.firstName }} {{ comment.lastName }}
            </div>
            <div>
              <use-time-ago v-slot="{ timeAgo }" :time="new Date(comment.date)">
                {{ timeAgo }}
              </use-time-ago>
            </div>
          </div>
          <div v-sanitize-html="comment.content" class="mr-2 text-sm" />
        </div>
      </div>
    </div>
    <div class="flex justify-between py-1">
      <div class="flex flex-1 pr-1">
        <mentionable-input
          ref="refMentionableInput" v-model="commentContent" :placeholder="t('general.comment')"
          clearable :disabled="resolveProp || sending || isReadOnly" insert-space :map-insert="(item) => item.label"
          :items="mentionableUsersAndGroups"
        >
          <template #item="{ item }">
            <div class="p-1 border rounded-lg border-grey bg-slate-50 hover:bg-primary hover:text-on-primary">
              <div class="flex">
                <div
                  v-if="item.type"
                  class="w-5 h-5 pt-px mr-1 text-xs text-center text-white border-2 rounded-full bg-grey-dark"
                >
                  {{ item.type.substring(0, 1).toUpperCase() }}
                </div>
                <div class="flex-1 text-sm truncate">
                  {{ item.label }}
                </div>
              </div>
              <div class="ml-1 text-sm truncate">
                {{ item.subTitle }}
              </div>
            </div>
          </template>
        </mentionable-input>
      </div>
      <div class="flex flex-col justify-end">
        <tx-button
          v-tooltip="t('general.submit')" type="icon"
          faicon="fa-light fa-paper-plane-top" :disabled="!titleProp.length || !commentContent.length || resolveProp || isReadOnly" :loading="sending"
          @click="onSend"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { UseTimeAgo } from '@vueuse/components'
import { useI18n } from 'vue-i18n'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import type WbDiscussionIcon from '../services/discussionIcon'
import type Whiteboard from '../services/whiteboard'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxSwitch from '@/shared/components/TxSwitch.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxInput from '@/shared/components/TxInput.vue'
import MentionableInput from '@/shared/components/MentionableInput.vue'
import { sendMentionNotifications } from '@/api/t1/whiteboard'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import { whiteboardConstants } from '@/models/constants'

const props = defineProps<{
  discussion: WbDiscussionIcon
  whiteboardId: number
  whiteboard?: Whiteboard
  permission: WhiteboardUserPermissionType
}>()

const emit = defineEmits<{
  (e: 'delete'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const refMentionableInput = ref<InstanceType<typeof MentionableInput>>()
const sending = ref(false)
const showEditTitleView = ref(false)
const resolveProp = ref(props.discussion.resolved)
const titleProp = ref('')
const comments = ref<IDiscussionComment[]>([])
const commentContent = ref('')

const isReadOnly = computed(() => props.permission === whiteboardConstants.userPermissions.read)

const mentionableUsersAndGroups = computed<IMentionItem[]>(() => {
  let result: IMentionItem[] = []
  if (props.whiteboard) {
    result = props.whiteboard.mentionableUsersAndGroups.value
  }
  return result
})

function onResolveChange() {
  props.discussion.setProp('resolved', { resolved: resolveProp.value })
}

function onDelete() {
  emit('delete')
}

function onEditTitle() {
  showEditTitleView.value = true
}

function onCancelEditTitle() {
  showEditTitleView.value = false
  const title = props.discussion.getProp('title')
  titleProp.value = title.title || ''
}

function onUpdateTitle() {
  if (titleProp.value.length) {
    props.discussion.setProp('title', { title: titleProp.value })
    showEditTitleView.value = false
  }
}

async function onSend() {
  if (titleProp.value.length) {
    props.discussion.setProp('title', { title: titleProp.value })
    showEditTitleView.value = false
  }
  if (commentContent.value.length && refMentionableInput.value) {
    const mentionedItems = refMentionableInput.value.getMentionedItems()
    if (mentionedItems.length && userStore.activeCatalog) {
      sending.value = true
      const userIds = new Set<number>()
      const accountGroupIds = new Set<number>()
      mentionedItems.forEach((item) => {
        if (item.type === 'user') {
          userIds.add(item.value)
        }
        else if (item.type === 'group') {
          accountGroupIds.add(item.value)
        }
      })
      try {
        await sendMentionNotifications(userStore.activeCatalog.CatalogCode, props.whiteboardId, { Users: [...userIds], AccountGroups: [...accountGroupIds], Url: window.location.href, Content: commentContent.value })
      }
      catch (e) {
        console.error(e)
      }
      sending.value = false
    }
    props.discussion.addComment(commentContent.value, mentionedItems, userStore.userProfile)
    refMentionableInput.value.clear()
  }
}

function load() {
  showEditTitleView.value = false
  titleProp.value = props.discussion.title || ''
  resolveProp.value = props.discussion.resolved || false
  comments.value = props.discussion.comments
  if (!titleProp.value) {
    showEditTitleView.value = true
  }
}

load()

watch(() => props.discussion, load)

function handleObjectModified(e) {
  if (e.target?.id === props.discussion.id) {
    titleProp.value = e.target.title || ''
    resolveProp.value = e.target.resolved || false
    comments.value = e.target.comments
  }
}

onMounted(() => {
  props.whiteboard?.on('object-modified-server', handleObjectModified)
})

onUnmounted(() => {
  props.whiteboard?.off('object-modified-server', handleObjectModified)
})
</script>
