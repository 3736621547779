<template>
  <div class="text-default">
    <div v-if="label.length > 0" class="flex">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase label" :class="{ required }" v-text="label" />
    </div>
    <div class="relative" :class="props.class">
      <input
        ref="refInput"
        type="color"
        v-bind="$attrs"
        :value="rgbaToHex(props.modelValue)"
        class="w-10 h-10 cursor-pointer border bg-card border-form shadow-input"
        :disabled="disabled"
        :required="required"
        @input="handleInput"
      >
      <div v-if="hasError">
        <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500 overflow-auto">
          {{ error.$message }}
        </p>
      </div>
      <div v-else-if="externalMessage && externalMessage.length !== 0" class="text-primary-500">
        <p>{{ externalMessage }}</p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from 'vue'
import type { ErrorObject } from '@vuelidate/core'

defineOptions({
  inheritAttrs: false,
})
const props = withDefaults(defineProps<IProps>(), {
  modelValue: '#ffffff',
  label: '',
  errors: () => [] as ErrorObject[],
  clearable: false,
  disabled: false,
  required: false,
  class: '',
  colorType: 'hex',
})

const emit = defineEmits<{
  (e: 'update:modelValue', val: string | null): void
  (e: 'change', val: string | null): void
}>()

interface IProps {
  modelValue?: string | null
  label?: string
  errors?: ErrorObject[]
  clearable?: boolean
  disabled?: boolean
  required?: boolean
  class?: string
  externalMessage?: string
  colorType?: 'hex' | 'rgb'
}

const refInput = ref<HTMLInputElement>()

function rgbaToHex(rgba: string | null) {
  if (rgba) {
    if (rgba.startsWith('rgb')) {
      const rgb = rgba.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/)
      if (!rgb) { return '#ffffff' }
      return `#${((1 << 24) + (+rgb[1] << 16) + (+rgb[2] << 8) + +rgb[3]).toString(16).slice(1).toUpperCase()}`
    }
    else {
      return rgba
    }
  }
  else {
    return '#ffffff'
  }
}

function hexToRgb(hex: string): string {
  let trimmedHex = hex.trim().replace(/^#/, '')
  if (trimmedHex.length === 3) {
    trimmedHex = trimmedHex.split('').map(char => char + char).join('')
  }
  const num = Number.parseInt(trimmedHex, 16)
  return `rgb(${(num >> 16) & 255}, ${(num >> 8) & 255}, ${num & 255})`
}

const hasError = computed(() => props.errors.length)

function handleInput(e: Event) {
  const inputValue = (e?.target as HTMLInputElement).value
  let colorValue = inputValue

  if (props.colorType === 'rgb') {
    colorValue = hexToRgb(inputValue)
  }

  emit('update:modelValue', colorValue)
  emit('change', colorValue)
}
</script>

<style scoped>
.border-form {
  border: 1px solid #d1d5db;
}
.bg-card {
  background-color: #ffffff;
}
.shadow-input {
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
</style>
