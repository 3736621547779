<template>
  <!-- Card View -->
  <div
    v-if="type === 'card'" class="flex flex-col w-44 h-36 cursor-pointer m-4 shrink-0 bg-card shadow-card rounded-md"
    @click="doClick(item)"
    @click.right.stop="doContext($event, item)"
  >
    <div class="flex-grow overflow-hidden">
      <whiteboard-placeholder />
    </div>
    <div class="w-full p-1 flex">
      <div class="flex-grow overflow-hidden">
        <div class="px-1 pt-2 text-sm font-medium truncate">
          <font-awesome-icon icon="fa-light fa-chalkboard" />
          <span class="ml-1">{{ item.Name }}</span>
        </div>
        <div class="px-1 pt-0.5 text-sm font-light truncate">
          <font-awesome-icon icon="fa-light fa-folder" />
          <span class="ml-1">{{ utils.isValidStringValue(item.FolderName) ? item.FolderName : t('general.blankValue') }}</span>
        </div>
        <div class="px-1 pt-0.5 text-sm text-grey truncate" v-text="item.CreatedByName" />
      </div>
      <div class="m-auto" @click.stop>
        <tx-button type="icon" faicon="fa-light fa-ellipsis-vertical" @click="doContext($event, item)" />
      </div>
    </div>
  </div>

  <!-- Row View -->
  <tr
    v-if="type === 'row'"
    class="cursor-pointer border-b last:border-0 hover:bg-gray-100"
    @click="doClick(item)"
    @click.right.stop="doContext($event, item)"
  >
    <td class="px-4 py-2 flex items-center space-x-2">
      <span>{{ item.Name }}</span>
    </td>
    <td class="px-4 py-2">
      {{ item.FolderName }}
    </td>
    <td class="px-4 py-2">
      {{ item.CreatedByName }}
    </td>
    <td class="px-4 py-2">
      {{ item.CreatedByEmail }}
    </td>
    <td class="px-4 py-2">
      <div v-tooltip="{ text: utils.formatDateTime(item.CreatedDate), theme: { placement: 'left' } }">
        <use-time-ago v-slot="{ timeAgo }" :time="new Date(item.CreatedDate)">
          {{ timeAgo }}
        </use-time-ago>
      </div>
    </td>
    <td class="px-4 py-2">
      <div v-tooltip="{ text: utils.formatDateTime(item.UpdatedDate), theme: { placement: 'left' } }">
        <use-time-ago v-slot="{ timeAgo }" :time="new Date(item.UpdatedDate)">
          {{ timeAgo }}
        </use-time-ago>
      </div>
    </td>
    <td class="px-4 py-2 m-auto" @click.stop>
      <tx-button type="icon" faicon="fa-light fa-ellipsis-vertical" @click="doContext($event, item)" />
    </td>
  </tr>
</template>

<script setup lang="ts">
import { UseTimeAgo } from '@vueuse/components'
import { useI18n } from 'vue-i18n'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'
import WhiteboardPlaceholder from '@/shared/components/svg/WhiteboardPlaceholder.vue'
import type Whiteboard from '@/models/whiteboard'

defineProps<{
  type: 'card' | 'row'
  item: Whiteboard
}>()

const emits = defineEmits<{
  (e: 'click', item: Whiteboard): void
  (e: 'context', evt: MouseEvent, item: Whiteboard): void
}>()

const { t } = useI18n()

function doClick(item: Whiteboard) {
  emits('click', item)
}

function doContext(evt: MouseEvent, item: Whiteboard) {
  emits('context', evt, item)
}
</script>
