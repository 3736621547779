<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 43 32">
    <defs>
      <linearGradient id="Logotype_svg__a" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop stop-opacity=".151" offset="0%" />
        <stop stop-opacity=".506" offset="100%" />
      </linearGradient>
      <linearGradient id="Logotype_svg__b" x1="50%" y1="0%" x2="50%" y2="50%">
        <stop stop-color="#FFF" stop-opacity=".251" offset="0%" />
        <stop stop-color="#FFF" stop-opacity="0" offset="100%" />
      </linearGradient>
      <linearGradient id="Logotype_svg__c" x1="66.576%" y1="42.337%" x2="72.919%" y2="100%">
        <stop stop-opacity="0" offset="0%" />
        <stop offset="100%" />
      </linearGradient>
      <linearGradient id="Logotype_svg__d" x1="86.163%" y1="42.549%" x2="100%" y2="98.614%">
        <stop stop-opacity="0" offset="0%" />
        <stop offset="100%" />
      </linearGradient>
    </defs>
    <g fill="none" fill-rule="evenodd">
      <rect fill="#26A2F8" x="12.17" y=".8" width="20.04" height="30.4" rx="1.5" />
      <rect fill="url(#Logotype_svg__a)" x="12.17" y=".8" width="20.04" height="30.4" rx="1.5" />
      <path d="M30.953 0c.267 0 .518.07.736.193a1 1 0 0 1 .557.226l10.167 8.383a1 1 0 0 1 .12 1.426l-4.917 5.668a1.5 1.5 0 0 1-2.087.175l-3.077-2.537V30.5a1.5 1.5 0 0 1-1.5 1.5h-7.424V0h7.425zm-10.67 32h-7.425a1.5 1.5 0 0 1-1.5-1.5V11.2H1.5A1.5 1.5 0 0 1 0 9.7V1.5A1.5 1.5 0 0 1 1.5 0h18.783v32z" fill="#26A2F8" />
      <path d="M20.283 0v32h-7.708a1.209 1.209 0 0 1-1.217-1.2V1.2c0-.663.545-1.2 1.217-1.2h7.708zm3.245 0h7.708c.672 0 1.217.537 1.217 1.2v29.6c0 .663-.545 1.2-1.217 1.2h-7.708V0z" fill="url(#Logotype_svg__b)" />
      <path d="m32.453.64 9.956 8.162a1 1 0 0 1 .122 1.429L37.44 16.1c-.434.5-1.194.562-1.706.14l-3.281-2.705V.639z" fill="url(#Logotype_svg__c)" opacity=".402" />
      <path d="M11.358 0v11.2H1.217A1.209 1.209 0 0 1 0 10V1.2C0 .537.545 0 1.217 0h10.141z" fill="url(#Logotype_svg__d)" opacity=".402" />
    </g>
  </svg>
</template>
