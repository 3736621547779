<template>
  <div class="flex w-full flex-row items-center justify-center">
    <div
      v-for="(tab, index) in availableTabs" :key="tab.key" class="h-full w-1/3 flex justify-center items-center cursor-pointer py-0.5 text-center border text-s" :class="{
        'border-primary-500 text-primary-500': tab.key === modelValue,
        'rounded-l-full': index === 0,
        'rounded-r-full': index === availableTabs.length - 1,
        'text-default': tab.key !== modelValue,
      }" @click="onClick(tab)"
    >
      <span v-if="tabInfo && tabInfo[tab.key] && tabInfo[tab.key] !== 0" class="absolute border flex justify-center items-center rounded-xl w-[18px] h-[18px] text-xs ml-[93px] -mt-10 border-primary-500 bg-primary text-white">
        {{ tabInfo[tab.key] }}
      </span>
      <span class="text-center">{{ t(tab.label) }}</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'

const props = defineProps<{
  tabs: ITabItem[]
  modelValue?: string | null
  tabInfo?: Record<string, number>
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', val: string): void
}>()

const { t } = useI18n()

const availableTabs = computed(() => props.tabs.filter(t => t.visible))

function onClick(tab: IKeyLabel) {
  emit('update:modelValue', tab.key)
}
</script>
