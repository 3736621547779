<template>
  <div class="flex h-full overflow-hidden" @click.right.stop="showArticleContextMenu">
    <!-- NO ITEM ICON -->
    <div v-show="!loading && totalVisibleArts === 0" class="self-center h-64 m-auto">
      <div v-if="noCriteria" class="h-3 mb-10 text-xs text-center text-dark-25">
        {{ t('articlesList.noArticles') }}
      </div>
      <no-items-image style="width: 106px; height: 150px;" class="m-auto" />
    </div>

    <div
      v-show="loading || totalVisibleArts > 0" id="scrollView" ref="scrollViewList"
      class="w-full h-auto overflow-auto pb-13"
    >
      <!-- Skeleton cards when loading -->
      <table v-show="loading" class="w-full h-full border-separate" :class="`t-${thumbnailSize}`">
        <tbody>
          <tr class="align-top">
            <td>
              <div v-for="artIndex in 50" :key="artIndex" class="float-left thumbnail" data-id="skele-1">
                <article-thumbnail-skeleton :size="thumbnailSize" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <table v-show="!loading" id="articlesListTable" class="w-full h-full border-separate" :class="`t-${thumbnailSize}`">
        <!-- COLUMNS (IF USER PIVOT AND SELECT A COLUMN) -->
        <thead v-if="columnAttribute && columnAttribute.length > 0">
          <tr>
            <!-- COLUMN HEADER -->
            <th
              v-for="(col, colIndex) in dataTable.cols" :key="colIndex"
              class="sticky top-0 h-9 bg-default z-table-head-col" :style="{ width: `${columnsSize[col]}px` }"
            >
              <div class="mx-1 mt-2 text-sm leading-9 bg-white rounded-sm shadow-box h-9">
                <div class="sticky left-0 inline-block px-2 truncate h-9" :style="{ width: `${columnsSize[col] - 10}px` }">
                  <input v-if="allowSelection" v-model="selectedColumns[col]" class="mb-1 mr-1" type="checkbox" @change="onColumnCheckboxChanged(col)">
                  <span class="pr-2 select-text" v-text="col" />
                  <span
                    v-show="!browseByStore.isBrowseByModel" class="pr-2 text-xs font-normal uppercase text-ellipsis"
                    v-text="t('articlesList.nArticles', dataTable.totals[`c-${col}`]?.arts)"
                  />
                  <span
                    class="text-xs font-normal uppercase text-ellipsis"
                    v-text="t('articlesList.nModels', dataTable.totals[`c-${col}`]?.models)"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-for="row in visibleRows" :key="row.rowLabel">
            <!-- ROW HEADER (IF USER PIVOT AND SELECT A ROW) -->
            <tr v-if="rowAttribute && rowAttribute.length > 0">
              <td
                class="sticky z-table-head-row bg-default h-9" :colspan="dataTable.cols.length"
                :style="{ top: columnAttribute && columnAttribute.length > 0 ? '45px' : '0' }"
              >
                <div class="mx-1 mt-2 text-sm font-bold leading-9 bg-white rounded-sm shadow-box">
                  <div class="sticky left-0 inline-block px-2">
                    <input v-if="allowSelection" v-model="selectedRows[row.rowLabel]" class="mb-1 mr-1" type="checkbox" @change="onRowCheckboxChanged(row.rowLabel)">
                    <span class="pr-2 select-text" v-text="row.rowLabel" />
                    <span
                      v-show="!browseByStore.isBrowseByModel" class="pr-2 text-xs font-normal uppercase text-ellipsis"
                      v-text="t('articlesList.nArticles', dataTable.totals[`r-${row.rowLabel}`]?.arts)"
                    />
                    <span
                      class="text-xs font-normal uppercase text-ellipsis"
                      v-text="t('articlesList.nModels', dataTable.totals[`r-${row.rowLabel}`]?.models)"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <!-- THUMBNAILS -->
            <tr class="divide-x divide-gray-400 divide-dashed">
              <td v-for="col in dataTable.cols" :key="col" class="align-top">
                <div
                  v-for="art in dataTable.arts[`${col}//${row.rowLabel}`]?.slice(0, row.maxCount)" :key="art.CatalogArticleId"
                  class="float-left thumbnail" :data-id="art.CatalogArticleId"
                  @click.right.stop="showArticleContextMenu($event, art)"
                >
                  <article-thumbnail
                    v-model:selected="selectedItems[art.CatalogArticleId]" :article="art"
                    :selection-mode="selectedArticleIds.length > 0" :size="thumbnailSize" :allow-selection="props.allowSelection"
                    :show-watermark="art.Status === 2" :watermark-text="art.Status === 2 ? t('general.notAssorted') : '' "
                    @selection-changed="selectionChanged" @click="onArticleClick(art)"
                  />
                </div>
                <div
                  v-show="dataTable.arts[`${col}//${row.rowLabel}`]?.length > row.maxCount"
                  v-on-visible="{ target: scrollViewList, fct: onLoadMore }"
                  class="float-left w-full p-3 text-center" v-text="t('general.loading')"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <tx-menu
      ref="menuRef"
      :options="menuOptions"
      @click="onContextMenuOptionClick"
      @close="onContextMenuOptionClose"
    />
  </div>
</template>

<script lang="ts" setup>
import { useElementSize } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { computed, nextTick, reactive, ref, watch } from 'vue'
import { useLiveArticlesCriteria } from '../composables/liveArticles'
import usePriceGroupsLabel from '../composables/priceGroupsLabel'
import ArticleThumbnail from './ArticleThumbnail.vue'
import ArticleThumbnailSkeleton from './ArticleThumbnailSkeleton.vue'
import NoItemsImage from './svg/NoItemsImage.vue'
import TxMenu from '@/shared/components/TxMenu.vue'
import type MyArticle from '@/models/myArticle'
import type { FilterCriteria } from '@/models/filterCriteria'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'
import { appConstants, privileges } from '@/models/constants'
import { useBrowseByStore } from '@/store/browseBy'
import { customSortComparer, getArticlePropertyInfo, getArticlePropertyValue, getArticleSeasonValue, getPivotSortInfo, getSeasonsInfo, sortArticles } from '@/services/catalogFactory'
import { AttributeType } from '@/models/catalogAttribute'
import appConfig from '@/services/appConfig'

interface IProps {
  browseByDetailsCriteria?: FilterCriteria[]
  categoryCriteria?: FilterCriteria[]
  filterCriteria?: FilterCriteria[]
  allowSelection?: boolean
  columnAttribute?: string
  rowAttribute?: string
  thumbnailSize?: number
  clickToSelect?: boolean
  articles?: MyArticle[]
}

const props = withDefaults(defineProps<IProps>(), {
  browseByDetailsCriteria: () => [] as FilterCriteria[],
  categoryCriteria: () => [] as FilterCriteria[],
  filterCriteria: () => [] as FilterCriteria[],
  allowSelection: true,
  columnAttribute: '',
  rowAttribute: '',
  thumbnailSize: 3,
  clickToSelect: false,
})

const emit = defineEmits<{
  (e: 'articleClick', article: MyArticle): void
  (e: 'selectionChanged', articles: MyArticle[]): void
  (e: 'dataChanged', articles: MyArticle[], articleIdsIndex: { [id: number]: number }, cols: string[], rows: string[]): void
  (e: 'contextMenuItemClick', menuItem: IContextMenuItem, article: MyArticle): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const browseByStore = useBrowseByStore()
const { priceGroupsLabel } = usePriceGroupsLabel()

const scrollViewList = ref<HTMLElement>()
const scrollViewSize = useElementSize(scrollViewList)
const selectedItems = ref({} as { [param: string]: boolean })
const selectedColumns = reactive<Record<string, boolean>>({})
const selectedRows = reactive<Record<string, boolean>>({})
const loading = ref(false)
const visibleRows = ref<{ rowLabel: string, maxCount: number }[]>([])
const totalVisibleArts = ref(0)
const availableArticlesToLoad = ref(30)
const noCriteria = ref(true)
const menuRef = ref<InstanceType<typeof TxMenu>>()
const contextMenuArticle = ref<MyArticle>()
let groupByStartTime: number | null = null

const isBrowseByAttribute = computed(() => browseByStore.isBrowseByAttribute)

const allCriteria = computed(() => {
  let allCriteria = props.categoryCriteria.concat(props.filterCriteria)
  if (isBrowseByAttribute.value) {
    allCriteria = allCriteria.concat(props.browseByDetailsCriteria)
  }
  return allCriteria
})

function useLocalArticles(articles: MyArticle[]) {
  const refArticles = ref(articles)
  const myDataIndex = computed(() => refArticles.value.reduce((acc, curr, index) => {
    acc[curr.CatalogArticleId] = index
    return acc
  }, {} as Record<string, number>))

  return {
    myData: refArticles,
    myDataIndex,
    scrollPosition: ref<{ top: number, left: number }>(),
  }
}

const { myData, myDataIndex: myDataIndexById, scrollPosition } = utils.isDefined(props.articles)
  ? useLocalArticles(props.articles)
  : useLiveArticlesCriteria(allCriteria, scrollViewList, onArticlesLoading, onArticlesLoaded, doLoadMore, totalVisibleArts, availableArticlesToLoad)

// COMPUTED
const contextMenuDisabledBasicCheckFailed = computed(() => {
  const selectedArticles = Object.entries(selectedItems.value).reduce((acu: Array<string>, cur) => {
    if (cur[1]) {
      acu.push(cur[0])
    }
    return acu
  }, [])
  return !contextMenuArticle.value || selectedArticles.length > 1 || selectedArticles.some(catalogArticleId => contextMenuArticle.value?.CatalogArticleId !== catalogArticleId)
})

const menuOptions = computed<Array<IContextMenuItem>>(() => {
  return (
    [
      {
        key: 'copyModel',
        label: t('modelCreate.title.copyModel'),
        icon: 'fa-light fa-plus',
        disabled: contextMenuDisabledBasicCheckFailed.value,
        visible: userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
        && !userStore.currentCustomer && !contextMenuArticle.value?._IsRequestArticle
        && userStore.userProfile.isValidPrivilege(privileges.article.carryOverArticles)
        && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles)
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId),
      },
      {
        key: 'childModel',
        label: t('modelCreate.title.childModel'),
        icon: 'fa-light fa-plus',
        disabled: contextMenuDisabledBasicCheckFailed.value,
        visible: userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
        && !userStore.currentCustomer && !contextMenuArticle.value?._IsRequestArticle
        && userStore.userProfile.isValidPrivilege(privileges.article.carryOverArticles)
        && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles)
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId),
      },
      {
        key: 'similarModelRequest',
        label: t('modelCreate.title.similarModelRequest'),
        icon: 'fa-light fa-plus',
        disabled: contextMenuDisabledBasicCheckFailed.value,
        visible: contextMenuArticle.value?.CatalogCode === userStore.activeCatalog?.CatalogCode
        && userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
        && !userStore.currentCustomer && !contextMenuArticle.value?._IsRequestArticle
        && userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticlesRequest),
      },
      {
        key: 'articleRequest',
        label: t('modelCreate.title.articleRequest'),
        icon: 'fa-light fa-plus',
        disabled: contextMenuDisabledBasicCheckFailed.value,
        visible: contextMenuArticle.value?.CatalogCode === userStore.activeCatalog?.CatalogCode
        && userStore.activeCatalog?.DataSourceTypeId !== appConstants.catalogTypes.inherited
        && !userStore.currentCustomer && !contextMenuArticle.value?._IsRequestArticle
        && userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticlesRequest),
      },
      {
        key: 'assignColor',
        label: t('assignColor.title'),
        icon: 'fa-light fa-plus',
        disabled: contextMenuDisabledBasicCheckFailed.value,
        visible: userStore.userProfile.isValidPrivilege(privileges.article.update) && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId)
        && !contextMenuArticle.value?._IsRequestArticle
        && (contextMenuArticle.value?.ColorId == null || contextMenuArticle.value?.ColorId === 0) && userStore.sellerDetails?.AutomaticNumbering === 1 && contextMenuArticle.value?.Status === 1,
      },
      {
        key: 'assortArticles',
        label: t('assortArticles.title'),
        icon: 'fa-light fa-plus',
        disabled: contextMenuDisabledBasicCheckFailed.value,
        visible: userStore.activeCatalog!.DataSourceTypeId === appConstants.catalogTypes.inherited
        && contextMenuArticle.value?.Status === 2 && !contextMenuArticle.value?._IsRequestArticle
        && userStore.userProfile.isValidPrivilege(privileges.article.updateStatus) && userStore.userProfile.isValidPrivilege(privileges.article.createModelWithArticles)
        && (userStore.userProfile.AccountDetails.AccountTypeId === 1 || userStore.userProfile.AccountDetails.AccountId === userStore.activeCatalog!.AccountId),
      },
    ]
  )
})

const selectedArticleIds = computed(() => {
  return Object.entries(selectedItems.value).reduce((acc, curr) => {
    if (curr[1]) { acc.push(curr[0]) }
    return acc
  }, [] as string[])
})

const dataTable = computed((): IDataTable => {
  const res: IDataTable = {
    cols: [] as string[], // (sorted) unique column values if pivot by column applied
    rows: [] as string[], // (sorted) unique row values if pivot by row applied
    arts: {} as { [pram: string]: MyArticle[] }, // list of articles per column per row, if column or row attribute is not selected to pivot on for each of them the value "_all" will be considered as key
    totals: {}, // total number of thumbnails (not unique articles) in each column, row, row and column combined and in total
    maxPerRow: {}, // maximum number of articles identified per each row
  }
  const blankValue = '[Blank]'
  const indexedSeasonsInfo = getSeasonsInfo(userStore.activeCatalog!, userStore.linkedCatalogDetails, blankValue)

  const modelCount = {} as { [param: string]: string[] }

  const articlesSortPropertiesConfig = userStore.activeCatalog!.Config.ArticlesSortProperties
  const groupByAttributesConfig = userStore.activeCatalog!.Config.GroupByAttributes

  const columnAttributeInfo = props.columnAttribute != null && props.columnAttribute.toString().trim().length
    ? getArticlePropertyInfo(props.columnAttribute, userStore.myAttributes!, priceGroupsLabel.value, t)
    : null
  const columAttributeType = columnAttributeInfo != null && columnAttributeInfo.type ? columnAttributeInfo.type : AttributeType.Nvarchar
  const columnSortInfo = getPivotSortInfo(props.columnAttribute, columAttributeType, userStore.sortBy, userStore.customSortArticlePropertyAndValues, articlesSortPropertiesConfig, groupByAttributesConfig)
  const columnAttributeSortDirection = columnSortInfo.sortDirection
  const columnAttributeCustomSort = columnSortInfo.customSortValueList

  const rowAttributeInfo = props.rowAttribute != null && props.rowAttribute.toString().trim().length
    ? getArticlePropertyInfo(props.rowAttribute, userStore.myAttributes!, priceGroupsLabel.value, t)
    : null
  const rowAttributeType = rowAttributeInfo != null && rowAttributeInfo.type ? rowAttributeInfo.type : AttributeType.Nvarchar
  const rowSortInfo = getPivotSortInfo(props.rowAttribute, rowAttributeType, userStore.sortBy, userStore.customSortArticlePropertyAndValues, articlesSortPropertiesConfig, groupByAttributesConfig)
  const rowAttributeSortDirection = rowSortInfo.sortDirection
  const rowAttributeCustomSort = rowSortInfo.customSortValueList

  res.totals.all = { arts: 0, models: 0 }
  modelCount.all = []

  // SORT DATA
  const sortedArticles = [...myData.value]
  sortArticles(sortedArticles, userStore.sortBy, userStore.customSortArticlePropertyAndValues, userStore.myAttributes!, userStore.activeCatalog!)

  sortedArticles.forEach((art) => {
    const currentArticleSeasonValue = getArticleSeasonValue(art, userStore.activeCatalog!, userStore.linkedCatalogDetails)
    // populate column value(s)
    const colVals: string[] = []
    // if user select a column divider to pivot on
    if (utils.isDefined(props.columnAttribute) && props.columnAttribute !== '') {
      const propertyValuesInfo = getArticlePropertyValue(art, props.columnAttribute, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
      propertyValuesInfo.forEach((propertyValueInfo) => {
        colVals.push(propertyValueInfo.displayValue)
      })
    }
    else { // if user does not select a column divider to pivot on
      colVals.push('_all')
    }

    // populate row value(s)
    const rowVals: string[] = []
    // if user select a row divider to pivot on
    if (utils.isDefined(props.rowAttribute) && props.rowAttribute !== '') {
      const propertyValuesInfo = getArticlePropertyValue(art, props.rowAttribute, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
      propertyValuesInfo.forEach((propertyValueInfo) => {
        rowVals.push(propertyValueInfo.displayValue)
      })
    }
    else { // if user does not select a row divider to pivot on
      rowVals.push('_all')
    }

    // insert unique column values into res.cols sorted(if applicable)
    colVals.forEach((colVal) => {
      if (res.cols.findIndex(itm => itm.toLowerCase() === colVal.toLowerCase()) === -1) {
        const sortDirectionMultiple = columnAttributeSortDirection === 'descending' ? -1 : 1
        utils.insertSorted(colVal, res.cols, (a, b) => customSortComparer(a, b, columAttributeType, sortDirectionMultiple, columnAttributeCustomSort))
      }
    })

    // insert unique row values into res.rows sorted(if applicable)
    rowVals.forEach((rowVal) => {
      if (res.rows.findIndex(itm => itm.toLowerCase() === rowVal.toLowerCase()) === -1) {
        const sortDirectionMultiple = rowAttributeSortDirection === 'descending' ? -1 : 1
        utils.insertSorted(rowVal, res.rows, (a, b) => customSortComparer(a, b, rowAttributeType, sortDirectionMultiple, rowAttributeCustomSort))
      }
    })

    colVals.forEach((colVal) => {
      rowVals.forEach((rowVal) => {
        const artKey = `${colVal}//${rowVal}`
        // init articles list per column per row if not already initialized
        if (!res.arts.hasOwnProperty(artKey)) {
          res.arts[artKey] = []
        }

        // init row level total counts for article and model thumbnails
        if (!res.totals.hasOwnProperty(`r-${rowVal}`)) {
          res.totals[`r-${rowVal}`] = { arts: 0, models: 0 }
        }

        // init column level total counts for article and model thumbnails
        if (!res.totals.hasOwnProperty(`c-${colVal}`)) {
          res.totals[`c-${colVal}`] = { arts: 0, models: 0 }
        }

        // init row & column level total counts for article and model thumbnails
        if (!res.totals.hasOwnProperty(`r-${rowVal}//c-${colVal}`)) {
          res.totals[`r-${rowVal}//c-${colVal}`] = { arts: 0, models: 0 }
        }

        // init row level model number tracker
        if (!modelCount.hasOwnProperty(`r-${rowVal}`)) {
          modelCount[`r-${rowVal}`] = []
        }
        // set total model thumbnail count value for the row value
        if (!modelCount[`r-${rowVal}`].includes(art.ModelNumber)) {
          modelCount[`r-${rowVal}`].push(art.ModelNumber)
          res.totals[`r-${rowVal}`].models = modelCount[`r-${rowVal}`].length
        }

        // init column level model number tracker
        if (!modelCount.hasOwnProperty(`c-${colVal}`)) {
          modelCount[`c-${colVal}`] = []
        }
        // set total model thumbnail count for the column value
        if (!modelCount[`c-${colVal}`].includes(art.ModelNumber)) {
          modelCount[`c-${colVal}`].push(art.ModelNumber)
          res.totals[`c-${colVal}`].models = modelCount[`c-${colVal}`].length
        }

        // init row and column level model number tracker
        if (!modelCount.hasOwnProperty(`r-${rowVal}//c-${colVal}`)) {
          modelCount[`r-${rowVal}//c-${colVal}`] = []
        }

        // set total model thumbnail count for the row and column combined
        if (!modelCount[`r-${rowVal}//c-${colVal}`].includes(art.ModelNumber)) {
          modelCount[`r-${rowVal}//c-${colVal}`].push(art.ModelNumber)
          res.totals[`r-${rowVal}//c-${colVal}`].models = modelCount[`c-${colVal}`].length
        }

        // set total model thumbnail count
        if (!modelCount.all.includes(art.ModelNumber)) {
          modelCount.all.push(art.ModelNumber)
          res.totals.all.models = modelCount.all.length
        }

        // set total articles count per row
        res.totals[`r-${rowVal}`].arts++
        // set total articles count per column, same article can be repeated in one column and multiple rows (when row attribute is multivalue)
        res.totals[`c-${colVal}`].arts++
        // set total count for articles per column per row
        res.totals[`r-${rowVal}//c-${colVal}`].arts++
        // set total articles count
        res.totals.all.arts++

        // init maxPerRow for row value if not exist
        if (!res.maxPerRow.hasOwnProperty(rowVal)) {
          res.maxPerRow[rowVal] = 0
        }

        // set maxPerRow (find maximum number of articles for each row)
        if (res.totals[`r-${rowVal}//c-${colVal}`].arts > res.maxPerRow[rowVal]) {
          res.maxPerRow[rowVal] = res.totals[`r-${rowVal}//c-${colVal}`].arts
        }

        // TODO: Test if using index of art instead of art will enhance performance
        res.arts[artKey].push(art)
      })
    })
  })

  console.log('Data table recalculated', res)
  return res
})

const columnsSize = computed((): { [att: string]: number } => {
  console.log('recalculating size')
  const availableWidth = scrollViewSize.width.value
  const minWidth = [309, 290, 350][props.thumbnailSize - 1]
  const minPc = minWidth * 100 / availableWidth
  const totalCols = dataTable.value.cols.length
  const haveExtraSpace = availableWidth > minWidth * (totalCols + 1)
  const res = {} as { [att: string]: number }
  const totalArts = dataTable.value.totals.all.arts

  if (haveExtraSpace) {
    dataTable.value.cols.forEach(col => res[col] = dataTable.value.totals[`c-${col}`].arts * 100 / totalArts)
    // We have more space to allocate
    dataTable.value.cols.forEach((col) => {
      const totalColArts = dataTable.value.totals[`c-${col}`].arts
      const colPc = totalColArts * 100 / totalArts
      const short = minPc - colPc

      // If percent is not smaller than minimim then we are good
      if (short < 0) { return }

      // Otherwise, we need to steal a % from another column
      let stealFromCol = dataTable.value.cols.filter((col) => {
        return res[col] - minPc >= short
      }).slice(-1)

      if (!stealFromCol) {
        stealFromCol = [...dataTable.value.cols].sort((a, b) => res[a] - res[b]).slice(-1)
      }

      res[stealFromCol[0]] -= short
      res[col] = minPc
    })
    dataTable.value.cols.forEach((col) => {
      res[col] = Math.floor(res[col] * availableWidth / 100) - 1
    })
  }
  else {
    dataTable.value.cols.forEach(col => res[col] = minWidth)
  }

  return res
})
// COMPUTED - END

function onArticleClick(art: MyArticle) {
  if (props.clickToSelect) {
    selectedItems.value[art.CatalogArticleId] = !selectedItems.value[art.CatalogArticleId]
    selectionChanged()
  }
  else {
    emit('articleClick', art)
  }
}

function onContextMenuOptionClick(option: IContextMenuItem, targetItem: MyArticle) {
  emit('contextMenuItemClick', option, targetItem)
}

function onContextMenuOptionClose() {
  contextMenuArticle.value = undefined
}

function showArticleContextMenu(event: MouseEvent, article?: MyArticle) {
  event.preventDefault()
  if (!article) {
    contextMenuArticle.value = undefined
    menuRef.value?.doClose()
    return
  }
  contextMenuArticle.value = article
  menuRef.value?.openMenu(event, article)
}

function onColumnCheckboxChanged(col: string) {
  dataTable.value.rows.forEach((row) => {
    const articles = dataTable.value.arts[`${col}//${row}`]
    if (utils.isDefined(articles) && articles.length) {
      articles.forEach(art => selectedItems.value[art.CatalogArticleId] = selectedColumns[col])
      selectionChanged()
    }
  })
}

function onRowCheckboxChanged(row: string) {
  dataTable.value.cols.forEach((col) => {
    const articles = dataTable.value.arts[`${col}//${row}`]
    if (utils.isDefined(articles) && articles.length) {
      articles.forEach(art => selectedItems.value[art.CatalogArticleId] = selectedRows[row])
      selectionChanged()
    }
  })
}

function selectionChanged() {
  emit('selectionChanged', selectedArticleIds.value.map(itm => myData.value[myDataIndexById.value[itm]]))
}

function deselectAll() {
  Object.entries(selectedItems.value).forEach(itm => selectedItems.value[itm[0]] = false)
  dataTable.value.cols.forEach(col => selectedColumns[col] = false)
  visibleRows.value.forEach(row => selectedRows[row.rowLabel] = false)
  selectionChanged()
}

function selectAll() {
  Object.entries(selectedItems.value).forEach(itm => selectedItems.value[itm[0]] = true)
  dataTable.value.cols.forEach(col => selectedColumns[col] = true)
  visibleRows.value.forEach(row => selectedRows[row.rowLabel] = true)
  selectionChanged()
}

function doLoadMore(count: number, increaseTotalVisible: boolean) {
  console.log('Do load more')
  let artsToLoad = count
  let availableArts = 0
  for (let i = Math.max(visibleRows.value.length - 1, 0); i < dataTable.value.rows.length; i++) {
    if (visibleRows.value.length - 1 < i) {
      visibleRows.value.push({ rowLabel: dataTable.value.rows[i], maxCount: 0 })
    }
    const canAdd = Math.min(dataTable.value.maxPerRow[dataTable.value.rows[i]] - visibleRows.value[i].maxCount, artsToLoad)
    artsToLoad -= canAdd
    visibleRows.value[i].maxCount += canAdd
    availableArts += canAdd
    availableArticlesToLoad.value = canAdd
    if (artsToLoad === 0) {
      break
    }
  }
  if (increaseTotalVisible) { // increase only the number of articles avaiable to load
    totalVisibleArts.value += availableArts
  }
}

function onLoadMore(visible: boolean) {
  if (visible) {
    doLoadMore(80, true)
  }
}

function resetVisibleItems() {
  visibleRows.value = []
  totalVisibleArts.value = 0
}

/**
 * @description when fetching new data from database (when there is a change in filter criteria or switch to/from browse by model)
 * it is being called from reloadArticles in useLiveArticlesCriteria compossible
 * @param {boolean} fullReload
 */
function onArticlesLoading(fullReload: boolean) {
  if (fullReload) {
    loading.value = true
  }
}

/**
 * @description: index data (myDataIndexById), reset variables (selectedItems, visibleRows, totalVisibleArts) and set the scroll top position to 0
 * and call doLoadMore if there are data
 * emits data-changed and selection-changed
 * will be executed after fetching data database(when there is a change in filter criteria or switch to/from browse by model)
 * it is being called from reloadArticles in useLiveArticlesCriteria compossible
 * @param {boolean} fullReload
 */
function onArticlesLoaded(fullReload: boolean) {
  loading.value = false

  if (fullReload) {
    if (myData.value.length === 0) {
      noCriteria.value = true
    }
    emit('dataChanged', myData.value, myDataIndexById.value, dataTable.value.cols, dataTable.value.rows)
    selectedItems.value = {}
    myData.value.forEach(art => selectedItems.value[art.CatalogArticleId] = false)
    selectionChanged()
    if (scrollPosition.value && scrollViewList.value) {
      nextTick(() => {
        scrollViewList.value!.scroll({ top: scrollPosition.value!.top, left: scrollPosition.value!.left, behavior: 'smooth' })
        scrollPosition.value = undefined
      })
    }
    else {
      scrollViewList.value?.scrollTo({ top: 0 })
      resetVisibleItems()
      if (myData.value.length > 0) {
        doLoadMore(80, true)
      }
    }
  }
}

function setLoading(val: boolean) {
  loading.value = val
}

watch([() => props.columnAttribute, () => props.rowAttribute, () => userStore.customSortArticlePropertyAndValues], () => {
  if (groupByStartTime === null) {
    groupByStartTime = performance.now()
    resetVisibleItems()
    doLoadMore(80, true)
    if (scrollViewList.value) {
      scrollViewList.value.scrollTop = 0
    }
  }

  if (groupByStartTime !== null) {
    const loadTime = performance.now() - groupByStartTime
    if (userStore.activeCatalog?.CatalogCode) {
      appConfig.setApplicationInsights('Apply Group By', loadTime, userStore.activeCatalog.CatalogCode, userStore.userProfile.UserName, [])
    }
    groupByStartTime = null
  }
})

watch(() => userStore.sortBy, (val) => {
  if (val !== '_customSort') {
    resetVisibleItems()
    doLoadMore(80, true)
    if (scrollViewList.value) {
      scrollViewList.value.scrollTop = 0
    }
  }
})

watch(() => props.articles, (articles) => {
  if (articles) {
    myData.value = articles
  }
  else {
    myData.value = []
  }
  onArticlesLoaded(true)
})

defineExpose({
  selectAll,
  deselectAll,
  setLoading,
  articles: myData,
})
</script>

<style lang="scss" scoped>
.t-1 {
  td {
    min-width: 220px;
  }

  .thumbnail {
    margin: 4px;
  }
}

.t-2 {
  td {
    min-width: 330px;
  }

  .thumbnail {
    margin: 10px;
  }
}

.t-3 {
  td {
    min-width: 350px;
  }

  .thumbnail {
    margin: 10px;
  }
}

.highlight-article {
  outline: 1px solid rgb(33 150 243);
  transition: outline 0.3s ease-in-out;
}
</style>
