<template>
  <div class="flex h-full overflow-hidden" @click.right.stop="showBucketContextMenu">
    <!-- NO ITEM ICON -->
    <div v-show="!loading && totalVisibleArts === 0" class="self-center h-64 m-auto">
      <div class="h-3 mb-10 text-xs text-center text-dark-25">
        {{ t('articlesList.noArticles') }}
      </div>
      <no-items-image style="width: 106px; height: 150px;" class="m-auto" />
    </div>

    <div
      v-show="loading || totalVisibleArts > 0" id="scrollView" ref="scrollViewList"
      class="w-full h-auto overflow-auto pb-13"
    >
      <!-- Skeleton cards when loading -->
      <table v-show="loading" class="w-full h-full border-separate" :class="`t-${thumbnailSize}`">
        <tbody>
          <tr class="align-top">
            <td>
              <div v-for="artIndex in 50" :key="artIndex" class="float-left thumbnail" data-id="skele-1">
                <article-thumbnail-skeleton :size="thumbnailSize" :is-bucket-list-view="true" />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <table v-show="!loading" id="articlesListTable" class="w-full h-full border-separate" :class="`t-${thumbnailSize}`">
        <!-- COLUMNS (IF USER PIVOT AND SELECT A COLUMN) -->
        <thead v-if="columnAttribute && columnAttribute.length > 0">
          <tr>
            <!-- COLUMN HEADER -->
            <th
              v-for="(col, colIndex) in browseByDataTable.cols" :key="colIndex"
              class="sticky top-0 h-9 bg-default z-table-head-col" :style="{ width: `${columnsSize[col]}px` }"
            >
              <div class="mx-1 mt-2 text-sm leading-9 bg-white rounded-sm shadow-box h-9">
                <div class="sticky left-0 inline-block px-2 truncate h-9" :style="{ width: `${columnsSize[col] - 10}px` }">
                  <input v-model="selectedColumns[col]" class="mb-1 mr-1" type="checkbox" @change="onColumnCheckboxChanged(col)">
                  <span class="pr-2 select-text" v-text="col" />
                  <span
                    v-show="!browseByStore.isBrowseByModel" class="pr-2 text-xs font-normal uppercase text-ellipsis"
                    v-text="t('articlesList.nArticles', browseByDataTable.totals[`c-${col}`]?.articleCount)"
                  />
                  <span
                    class="text-xs font-normal uppercase text-ellipsis"
                    v-text="t('articlesList.nModels', browseByDataTable.totals[`c-${col}`]?.modelCount)"
                  />
                </div>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          <template v-for="row in visibleRows" :key="row.rowLabel">
            <!-- ROW HEADER (IF USER PIVOT AND SELECT A ROW) -->
            <tr v-if="rowAttribute && rowAttribute.length > 0">
              <td
                class="sticky z-table-head-row bg-default h-9" :colspan="browseByDataTable.cols.length"
                :style="{ top: columnAttribute && columnAttribute.length > 0 ? '45px' : '0' }"
              >
                <div class="mx-1 mt-2 text-sm font-bold leading-9 bg-white rounded-sm shadow-box">
                  <div class="sticky left-0 inline-block px-2">
                    <input v-model="selectedRows[row.rowLabel]" class="mb-1 mr-1" type="checkbox" @change="onRowCheckboxChanged(row.rowLabel)">
                    <span class="pr-2 select-text" v-text="row.rowLabel" />
                    <span
                      v-show="!browseByStore.isBrowseByModel" class="pr-2 text-xs font-normal uppercase text-ellipsis"
                      v-text="t('articlesList.nArticles', browseByDataTable.totals[`r-${row.rowLabel}`]?.articleCount)"
                    />
                    <span
                      class="text-xs font-normal uppercase text-ellipsis"
                      v-text="t('articlesList.nModels', browseByDataTable.totals[`r-${row.rowLabel}`]?.modelCount)"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <!-- THUMBNAILS -->
            <tr class="divide-x divide-gray-400 divide-dashed">
              <td v-for="col in browseByDataTable.cols" :key="col" class="align-top">
                <div
                  v-for="bucket in browseByDataTable.buckets[`${col}//${row.rowLabel}`]?.slice(0, row.maxCount)"
                  :key="bucket.key" class="float-left thumbnail"
                  @click.right.stop="showBucketContextMenu($event, bucket)"
                >
                  <bucket-thumbnail
                    v-model:selected="selectedItems[bucket.key]" :bucket="bucket" :size="thumbnailSize"
                    :articles="myData" :articles-indexes="myDataIndexById" :selection-mode="selectedArticleIds.length > 0"
                    :visible-bucket-attributes="visibleBucketAttributes" :visible-calc-type-bucket-attributes="visibleCalcTypeBucketAttributes"
                    @click="onBucketClick(bucket)" @selection-changed="selectionChanged"
                  />
                </div>
                <div
                  v-show="browseByDataTable.buckets[`${col}//${row.rowLabel}`]?.length > row.maxCount"
                  v-on-visible="{ target: scrollViewList, fct: onLoadMore }"
                  class="float-left w-full p-3 text-center" v-text="t('general.loading')"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>

    <tx-menu
      ref="bucketsMenuRef"
      :options="menuOptions"
      @click="onContextMenuOptionClick"
      @close="onContextMenuOptionClose"
    />
  </div>
</template>

<script lang="ts" setup>
import isEmpty from 'lodash-es/isEmpty'
import { computed, onUnmounted, reactive, ref, watch } from 'vue'
import { useElementSize } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import ArticleThumbnailSkeleton from '../ArticleThumbnailSkeleton.vue'
import NoItemsImage from '../svg/NoItemsImage.vue'
import BucketThumbnail from './BucketThumbnail.vue'
import type MyArticle from '@/models/myArticle'
import TxMenu from '@/shared/components/TxMenu.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import type { FilterCriteria } from '@/models/filterCriteria'
import type { ICatalogBucketAttributeParsed } from '@/models/catalogBucketAttribute'
import { customSortComparer, getArticlePropertyInfo, getArticlePropertyValue, getArticleSeasonValue, getPivotSortInfo, getSeasonsInfo, sortArticleComparer, sortArticles } from '@/services/catalogFactory'
import { useBrowseByStore } from '@/store/browseBy'
import { useLiveArticlesCriteria } from '@/shared/composables/liveArticles'
import { useUserStore } from '@/store/userData'
import usePriceGroupsLabel from '@/shared/composables/priceGroupsLabel'

interface IProps {
  categoryCriteria?: FilterCriteria[]
  filterCriteria?: FilterCriteria[]
  allowSelection?: boolean
  columnAttribute?: string
  rowAttribute?: string
  thumbnailSize?: number
  clickToSelect?: boolean
}

const props = withDefaults(defineProps<IProps>(), {
  categoryCriteria: () => [] as FilterCriteria[],
  filterCriteria: () => [] as FilterCriteria[],
  allowSelection: true,
  columnAttribute: '',
  rowAttribute: '',
  thumbnailSize: 3,
  clickToSelect: false,
})

const emit = defineEmits<{
  (e: 'bucketClick', bucket: IAttributeBucket): void
  (e: 'selectionChanged', articles: MyArticle[], selectedBuckets: Array<IAttributeBucket>): void
  (e: 'dataChanged', articles: MyArticle[], bucketCount: number, browseByDataTable: IBrowseByDataTable): void
  (e: 'onContextItemUpdated', bucket?: IAttributeBucket): void
  (e: 'contextMenuItemClick', menuItem: IContextMenuItem, bucket: IAttributeBucket): void
}>()

const loading = ref(false)
const allCriteria = computed(() => props.categoryCriteria.concat(props.filterCriteria))

const userStore = useUserStore()
const { priceGroupsLabel } = usePriceGroupsLabel()
const browseByStore = useBrowseByStore()
const { t } = useI18n()

const scrollViewList = ref<HTMLElement>()
const scrollViewSize = useElementSize(scrollViewList)
const selectedItems = ref({} as { [bucketKey: string]: boolean })
const selectedColumns = reactive<Record<string, boolean>>({})
const selectedRows = reactive<Record<string, boolean>>({})
const visibleRows = ref<{ rowLabel: string, maxCount: number }[]>([])
const totalVisibleArts = ref(0)
const bucketsMenuRef = ref<InstanceType<typeof TxMenu>>()
const contextMenuBucket = ref<IAttributeBucket>()
const availableArticlesToLoad = ref(30)

const { myData, myDataIndex: myDataIndexById } = useLiveArticlesCriteria(allCriteria, scrollViewList, onArticlesLoading, onArticlesLoaded, doLoadMore, totalVisibleArts, availableArticlesToLoad)

// COMPUTED
const isBrowseByAttribute = computed(() => browseByStore.isBrowseByAttribute)

const browseBy = computed(() => browseByStore.browseBy)

const browseByDataTable = computed(() => {
  const browseByDataTable: IBrowseByDataTable = {
    cols: [] as Array<string>, // (sorted) unique column values if pivot by column applied
    rows: [] as Array<string>, // (sorted) unique row values if pivot by row applied
    buckets: {} as { [pram: string]: IAttributeBucket[] }, // list of buckets per column per row, if column or row attribute is not selected to pivot on for each of them the value "_all" will be considered as key
    totals: {}, // total number of thumbnails (not unique articles) in each column, row, row and column combined and in total
    maxPerRow: {}, // maximum number of articles identified per each row
  }
  const blankValue = '[Blank]'
  const indexedSeasonsInfo = getSeasonsInfo(userStore.activeCatalog!, userStore.linkedCatalogDetails, blankValue)

  if (isBrowseByAttribute.value) {
    browseByDataTable.totals.all = { bucketCount: 0, articleCount: 0, modelCount: 0 }
    const modelTracker = {} as { [param: string]: Array<string> } // for model count take combination of catalogCode in filter Criteria (allCriteria) and modelNumber
    modelTracker.all = []
    const bucketTracker = {} // track index of buckets in each row and column section
    const bucketArticleList: { [bucketKey: string]: Array<MyArticle> } = {}

    const articlesSortPropertiesConfig = userStore.activeCatalog!.Config.ArticlesSortProperties
    const groupByAttributesConfig = userStore.activeCatalog!.Config.GroupByAttributes

    const columnProperty = userStore.myAttributes![props.columnAttribute]
    const columnAttributeInfo = props.columnAttribute != null && props.columnAttribute.toString().trim().length
      ? getArticlePropertyInfo(props.columnAttribute, userStore.myAttributes!, priceGroupsLabel.value, t)
      : null
    const columAttributeType = columnAttributeInfo != null && columnAttributeInfo.type ? columnAttributeInfo.type : AttributeType.Nvarchar
    const columnSortInfo = getPivotSortInfo(props.columnAttribute, columAttributeType, userStore.sortBy, userStore.customSortArticlePropertyAndValues, articlesSortPropertiesConfig, groupByAttributesConfig)
    const columnAttributeSortDirection = columnSortInfo.sortDirection
    const columnAttributeCustomSort = columnSortInfo.customSortValueList

    const rowProperty = userStore.myAttributes![props.rowAttribute]
    const rowAttributeInfo = props.rowAttribute != null && props.rowAttribute.toString().trim().length
      ? getArticlePropertyInfo(props.rowAttribute, userStore.myAttributes!, priceGroupsLabel.value, t)
      : null
    const rowAttributeType = rowAttributeInfo != null && rowAttributeInfo.type ? rowAttributeInfo.type : AttributeType.Nvarchar
    const rowSortInfo = getPivotSortInfo(props.rowAttribute, rowAttributeType, userStore.sortBy, userStore.customSortArticlePropertyAndValues, articlesSortPropertiesConfig, groupByAttributesConfig)
    const rowAttributeSortDirection = rowSortInfo.sortDirection
    const rowAttributeCustomSort = rowSortInfo.customSortValueList

    const sortedArticles = [...myData.value]
    // sort articles
    sortArticles(sortedArticles, userStore.sortBy, userStore.customSortArticlePropertyAndValues, userStore.myAttributes!, userStore.activeCatalog!)

    sortedArticles.forEach((article) => {
      const currentArticleSeasonValue = getArticleSeasonValue(article, userStore.activeCatalog!, userStore.linkedCatalogDetails)

      const articleValueInfoForBucketAttribute = getArticlePropertyValue(article, browseBy.value!.key, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
      const currentArticleBucketValues = articleValueInfoForBucketAttribute.map(articleValueInfo => articleValueInfo.displayValue)
      // populate column value(s)
      const articleValueInfoForColumnDivider = getArticlePropertyValue(article, props.columnAttribute, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
      const columnValueList: Array<string> = []
      // if user select a column divider to pivot on
      if (utils.isDefined(props.columnAttribute) && props.columnAttribute !== '') {
        articleValueInfoForColumnDivider.forEach((articleValueInfo) => {
          columnValueList.push(articleValueInfo.displayValue)
        })
      }
      else { // if user does not select a column divider to pivot on
        columnValueList.push('_all')
      }

      // populate row value(s)
      const articleValueInfoForRowDivider = getArticlePropertyValue(article, props.rowAttribute, userStore.myAttributes!, userStore.activeCatalog!, userStore.linkedCatalogDetails, t, blankValue, currentArticleSeasonValue, indexedSeasonsInfo)
      const rowValueList: Array<string> = []
      // if user select a row divider to pivot on
      if (utils.isDefined(props.rowAttribute) && props.rowAttribute !== '') {
        articleValueInfoForRowDivider.forEach((articleValueInfo) => {
          rowValueList.push(articleValueInfo.displayValue)
        })
      }
      else { // if user does not select a row divider to pivot on
        rowValueList.push('_all')
      }

      // insert unique column values into browseByDataTable.cols sorted(if applicable)
      columnValueList.forEach((columnValue) => {
        // TODO: cant cols be a dictionary? if not may be a temporary tracker dictionary
        if (browseByDataTable.cols.findIndex(itm => itm.toLowerCase() === columnValue.toLowerCase()) === -1) {
          if (columnAttributeSortDirection || columnAttributeCustomSort.length) {
            const sortDirectionMultiple = columnAttributeSortDirection === 'descending' ? -1 : 1
            utils.insertSorted(columnValue, browseByDataTable.cols, (a, b) => customSortComparer(a, b, columAttributeType, sortDirectionMultiple, columnAttributeCustomSort))
          }
          else {
            browseByDataTable.cols.push(columnValue)
          }
        }
      })

      // insert unique row values into browseByDataTable.rows sorted(if applicable)
      rowValueList.forEach((rowValue) => {
        // TODO: cant rows be a dictionary? if not may be a temporary tracker dictionary
        if (browseByDataTable.rows.findIndex(itm => itm.toLowerCase() === rowValue.toLowerCase()) === -1) {
          if (rowAttributeSortDirection || rowAttributeCustomSort.length) {
            const sortDirectionMultiple = rowAttributeSortDirection === 'descending' ? -1 : 1
            utils.insertSorted(rowValue, browseByDataTable.rows, (a, b) => customSortComparer(a, b, rowAttributeType, sortDirectionMultiple, rowAttributeCustomSort))
          }
          else {
            browseByDataTable.rows.push(rowValue)
          }
        }
      })

      columnValueList.forEach((currentColumnValue, currentColumnValueIndex) => {
        rowValueList.forEach((currentRowValue, currentRowValueIndex) => {
          // init row level total counts for article and model thumbnails
          if (!browseByDataTable.totals.hasOwnProperty(`r-${currentRowValue}`)) {
            browseByDataTable.totals[`r-${currentRowValue}`] = { bucketCount: 0, articleCount: 0, modelCount: 0 }
          }

          // init column level total counts for article and model thumbnails
          if (!browseByDataTable.totals.hasOwnProperty(`c-${currentColumnValue}`)) {
            browseByDataTable.totals[`c-${currentColumnValue}`] = { bucketCount: 0, articleCount: 0, modelCount: 0 }
          }

          // init row & column level total counts for article and model thumbnails
          if (!browseByDataTable.totals.hasOwnProperty(`r-${currentRowValue}//c-${currentColumnValue}`)) {
            browseByDataTable.totals[`r-${currentRowValue}//c-${currentColumnValue}`] = { bucketCount: 0, articleCount: 0, modelCount: 0 }
          }

          // init row level model number tracker
          if (!modelTracker.hasOwnProperty(`r-${currentRowValue}`)) {
            modelTracker[`r-${currentRowValue}`] = []
          }

          // init column level model number tracker
          if (!modelTracker.hasOwnProperty(`c-${currentColumnValue}`)) {
            modelTracker[`c-${currentColumnValue}`] = []
          }

          // init row and column level model number tracker
          if (!modelTracker.hasOwnProperty(`r-${currentRowValue}//c-${currentColumnValue}`)) {
            modelTracker[`r-${currentRowValue}//c-${currentColumnValue}`] = []
          }

          // set total model thumbnail count value for the row value
          if (!modelTracker[`r-${currentRowValue}`].includes(`${article.CatalogCode}//${article.ModelNumber}`)) {
            modelTracker[`r-${currentRowValue}`].push(`${article.CatalogCode}//${article.ModelNumber}`)
            browseByDataTable.totals[`r-${currentRowValue}`].modelCount = modelTracker[`r-${currentRowValue}`].length
          }
          // set total model thumbnail count for the column value
          if (!modelTracker[`c-${currentColumnValue}`].includes(`${article.CatalogCode}//${article.ModelNumber}`)) {
            modelTracker[`c-${currentColumnValue}`].push(`${article.CatalogCode}//${article.ModelNumber}`)
            browseByDataTable.totals[`c-${currentColumnValue}`].modelCount = modelTracker[`c-${currentColumnValue}`].length
          }

          // set total model thumbnail count for the row and column combined
          if (!modelTracker[`r-${currentRowValue}//c-${currentColumnValue}`].includes(`${article.CatalogCode}//${article.ModelNumber}`)) {
            modelTracker[`r-${currentRowValue}//c-${currentColumnValue}`].push(`${article.CatalogCode}//${article.ModelNumber}`)
            browseByDataTable.totals[`r-${currentRowValue}//c-${currentColumnValue}`].modelCount = modelTracker[`c-${currentColumnValue}`].length
          }

          // set total model thumbnail count
          if (!modelTracker.all.includes(`${article.CatalogCode}//${article.ModelNumber}`)) {
            modelTracker.all.push(`${article.CatalogCode}//${article.ModelNumber}`)
            browseByDataTable.totals.all.modelCount = modelTracker.all.length
          }

          // set total articles count per row
          browseByDataTable.totals[`r-${currentRowValue}`].articleCount++
          // set total articles count per column, same article can be repeated in one column and multiple rows (when row attribute is multivalue)
          browseByDataTable.totals[`c-${currentColumnValue}`].articleCount++
          // set total count for articles per column per row
          browseByDataTable.totals[`r-${currentRowValue}//c-${currentColumnValue}`].articleCount++
          // set total articles count
          browseByDataTable.totals.all.articleCount++

          // init maxPerRow for row value if not exist
          if (!browseByDataTable.maxPerRow.hasOwnProperty(currentRowValue)) {
            browseByDataTable.maxPerRow[currentRowValue] = 0
          }

          // LOOP THROUGH BUCKET VALUES - Add buckets and article related info to buckets
          currentArticleBucketValues.forEach((currentArticleBucketValue, currentBucketValueIndex) => {
            const rowColumnKey = `${currentColumnValue}//${currentRowValue}`
            const bucketKey = `${currentColumnValue}//${currentRowValue}//${currentArticleBucketValue}`

            if (!bucketArticleList.hasOwnProperty(bucketKey)) {
              bucketArticleList[bucketKey] = []
            }

            // init bucket level total counts for article and model thumbnails
            if (!browseByDataTable.totals.hasOwnProperty(bucketKey)) {
              browseByDataTable.totals[bucketKey] = { bucketCount: 0, articleCount: 0, modelCount: 0 }
            }
            // init row and column level model number tracker
            if (!modelTracker.hasOwnProperty(bucketKey)) {
              modelTracker[bucketKey] = []
            }

            if (!bucketTracker.hasOwnProperty(bucketKey)) { // NEW BUCKET FOUND
              // set total buckets count per row
              browseByDataTable.totals[`r-${currentRowValue}`].bucketCount++
              // set total buckets count per column, same article can be repeated in one column and multiple rows (when row attribute is multivalue)
              browseByDataTable.totals[`c-${currentColumnValue}`].bucketCount++
              // increase total count for buckets per column per row
              browseByDataTable.totals[`r-${currentRowValue}//c-${currentColumnValue}`].bucketCount++
              // increase total count for buckets per bucket :)
              browseByDataTable.totals[bucketKey].bucketCount++
              // increase total buckets count
              browseByDataTable.totals.all.bucketCount++

              // new bucket in row column section found, init bucket list per column per row if not already initialized
              if (!browseByDataTable.buckets.hasOwnProperty(rowColumnKey)) {
                browseByDataTable.buckets[rowColumnKey] = []
              }

              const bucket = {
                key: bucketKey,
                propertySystemName: browseBy.value!.key,
                propertyDisplayValue: browseBy.value!.label,
                value: currentArticleBucketValue,
                rawValueLower: articleValueInfoForBucketAttribute[currentBucketValueIndex].lowerStringOrNull,
                bucketFilterValue: articleValueInfoForBucketAttribute[currentBucketValueIndex].filterValue,
                modelsCount: browseByDataTable.totals[`r-${currentRowValue}//c-${currentColumnValue}`].modelCount,
                catalogArticleIdList: [],
                thumbnailDetails: undefined,
                rowProperty,
                rowPropertyRawValueLower: utils.isDefined(props.rowAttribute) && props.rowAttribute !== '' ? articleValueInfoForRowDivider[currentRowValueIndex].lowerStringOrNull : null,
                rowPropertyFilterValue: utils.isDefined(props.rowAttribute) && props.rowAttribute !== '' ? articleValueInfoForRowDivider[currentRowValueIndex].filterValue : null,
                columnProperty,
                columnPropertyRawValueLower: utils.isDefined(props.columnAttribute) && props.columnAttribute !== '' ? articleValueInfoForColumnDivider[currentColumnValueIndex].lowerStringOrNull : null,
                columnPropertyFilterValue: utils.isDefined(props.columnAttribute) && props.columnAttribute !== '' ? articleValueInfoForColumnDivider[currentColumnValueIndex].filterValue : null,
              }
              bucketTracker[bucketKey] = browseByDataTable.buckets[rowColumnKey].length
              browseByDataTable.buckets[rowColumnKey].push(bucket)
            }

            // increase total article thumbnail count for the bucket
            browseByDataTable.totals[bucketKey].articleCount++
            // set total model thumbnail count for the bucket
            if (!modelTracker[bucketKey].includes(`${article.CatalogCode}//${article.ModelNumber}`)) {
              modelTracker[bucketKey].push(`${article.CatalogCode}//${article.ModelNumber}`)
              browseByDataTable.totals[bucketKey].modelCount = modelTracker[bucketKey].length
            }

            const index = bucketTracker[bucketKey]
            browseByDataTable.buckets[rowColumnKey][index].modelsCount = browseByDataTable.totals[bucketKey].modelCount // update model count with updated value
            browseByDataTable.buckets[rowColumnKey][index].catalogArticleIdList.push(article.CatalogArticleId)
            bucketArticleList[bucketKey].push(article)
          })

          // set maxPerRow (find maximum number of articles for each row)
          if (browseByDataTable.totals[`r-${currentRowValue}//c-${currentColumnValue}`].bucketCount > browseByDataTable.maxPerRow[currentRowValue]) {
            browseByDataTable.maxPerRow[currentRowValue] = browseByDataTable.totals[`r-${currentRowValue}//c-${currentColumnValue}`].bucketCount
          }
        })
      })
    })

    let BrowseByImageSortConfig: { sortBy: string, desc?: boolean } | null = null
    if (!isEmpty(userStore.activeCatalog!.Config.BrowseByImageSort) && userStore.activeCatalog!.Config.BrowseByImageSort.hasOwnProperty(browseBy.value!.key)) {
      BrowseByImageSortConfig = userStore.activeCatalog!.Config.BrowseByImageSort[browseBy.value!.key]
    }

    // sort buckets in each section and assign bucket image if configured
    for (const section in browseByDataTable.buckets) {
      // browseByDataTable.buckets[section].sort((a, b) => sortArticleComparer(a.value, b.value, browseBy.value!.type, browseBy.value!.sortDirectionMultiple, browseBy.value!.predefinedCustomSortValueListLower))
      const sectionBuckets: Array<IAttributeBucket> = browseByDataTable.buckets[section]
      browseByDataTable.buckets[section] = []

      sectionBuckets.forEach((sectionBucket) => {
        if (BrowseByImageSortConfig != null) {
          const bucketArticles = bucketArticleList[sectionBucket.key]
          if (bucketArticles.length) {
            utils.sort(bucketArticles, BrowseByImageSortConfig.sortBy, !BrowseByImageSortConfig.hasOwnProperty('desc') || BrowseByImageSortConfig.desc !== true)
            sectionBucket.thumbnailDetails = { articleNumber: bucketArticles[0].ArticleNumber, catalogCode: bucketArticles[0].CatalogCode }
          }
        }
        utils.insertSorted(sectionBucket, browseByDataTable.buckets[section], (a, b) => sortArticleComparer(a.value, b.value, browseBy.value!.type!, browseBy.value!.sortDirectionMultiple, browseBy.value!.predefinedCustomSortValueListLower))
      })
    }
  }
  return browseByDataTable
})

const indexedBuckets = computed(() => {
  // can be used to identify the bucket from browseByDataTable.value.buckets
  const indexedBuckets: Record<string, { rowColumnKey: string, index: number }> = {}
  for (const rowColumnKey in browseByDataTable.value.buckets) {
    const currentRowColumnBuckets: Array<IAttributeBucket> = browseByDataTable.value.buckets[rowColumnKey]
    currentRowColumnBuckets.forEach((bucket, index) => {
      indexedBuckets[bucket.key] = {
        rowColumnKey,
        index,
      }
    })
  }
  return indexedBuckets
})

const selectedArticleIds = computed(() => {
  const selectedArticleIds: Array<string> = []
  for (const bucketKey in selectedItems.value) {
    if (selectedItems.value[bucketKey] === true) { // for selected buckets find list of catalogArticleId list
      const indexInfo = indexedBuckets.value[bucketKey]
      const bucket: IAttributeBucket = browseByDataTable.value.buckets[indexInfo.rowColumnKey][indexInfo.index]
      bucket.catalogArticleIdList.forEach((catalogArticleId) => {
        if (!selectedArticleIds.includes(catalogArticleId)) {
          selectedArticleIds.push(catalogArticleId)
        }
      })
    }
  }
  return selectedArticleIds
})

const contextMenuDisabledBasicCheckFailed = computed(() => {
  const selectedBuckets = getSelectedBuckets()
  return selectedBuckets.length > 1 || !contextMenuBucket.value || (selectedBuckets.length && contextMenuBucket.value.key !== selectedBuckets[0].key)
})

const menuOptions = computed<Array<IContextMenuItem>>(() => {
  return (
    [
      {
        key: 'updateBucketAttributes',
        label: t('contextMenu.updateBucketAttributes'),
        icon: 'fa-light fa-edit',
        disabled: contextMenuDisabledBasicCheckFailed.value || !(userStore.activeCatalog!.BucketAttributeList.filter(attribute => attribute.Status)).length || !props.filterCriteria.length,
        visible: browseByStore.isShowingBucketsListView,
      } as IContextMenuItem,
    ]
  )
})

// const customSortArticlePropertyAndValues = computed(() => userStore.customSortArticlePropertyAndValues)

const columnsSize = computed((): { [att: string]: number } => {
  const availableWidth = scrollViewSize.width.value
  const minWidth = [309, 290, 350][props.thumbnailSize - 1]
  const minPc = minWidth * 100 / availableWidth
  const totalCols = browseByDataTable.value.cols.length
  const haveExtraSpace = availableWidth > minWidth * (totalCols + 1)
  const res = {} as { [att: string]: number }
  const totalArts = browseByDataTable.value.totals.all.bucketCount

  if (haveExtraSpace) {
    browseByDataTable.value.cols.forEach(col => res[col] = browseByDataTable.value.totals[`c-${col}`].bucketCount * 100 / totalArts)
    // We have more space to allocate
    browseByDataTable.value.cols.forEach((col) => {
      const totalColArts = browseByDataTable.value.totals[`c-${col}`].bucketCount
      const colPc = totalColArts * 100 / totalArts
      const short = minPc - colPc

      // If percent is not smaller than minimim then we are good
      if (short < 0) { return }

      // Otherwise, we need to steal a % from another column
      let stealFromCol = browseByDataTable.value.cols.filter((col) => {
        return res[col] - minPc >= short
      }).slice(-1)

      if (!stealFromCol) {
        stealFromCol = [...browseByDataTable.value.cols].sort((a, b) => res[a] - res[b]).slice(-1)
      }

      res[stealFromCol[0]] -= short
      res[col] = minPc
    })
    browseByDataTable.value.cols.forEach((col) => {
      res[col] = Math.floor(res[col] * availableWidth / 100) - 1
    })
  }
  else {
    browseByDataTable.value.cols.forEach(col => res[col] = minWidth)
  }

  return res
})

const visibleBucketAttributes = computed(() => {
  const visibleBucketAttributes: Array<ICatalogBucketAttributeParsed> = []
  const bucketAttributeListClone = [...userStore.activeCatalog!.BucketAttributeList]

  /**
   * to resolve the issue with dependency of calc type bucket attributes where one calc type attributes with calculatedFormula dependant to other calc type bucket attributes that are based on aggregateBucketArticlesAttributeSystemName
   * always evaluate calc type attributes that have calculated formula at the end, as agreed this will only resolve dependance issue when calc with calculatedFormula dependant to other calc based on aggregateBucketArticlesAttributeSystemName
   * this will not resolve dependency chain
   */
  bucketAttributeListClone.sort((a, _b) => {
    if (!utils.isValidStringValue(a.ValidationExpression) || !a.ValidationExpression.toString().toLowerCase().includes('calculatedformula')) {
      return -1
    }
    else {
      return 1
    }
  })

  bucketAttributeListClone.forEach((bucketAttribute) => {
    if (bucketAttribute.Status && bucketAttribute.Visible) {
      const bucketAttributeClone = { ...bucketAttribute, parsedValidationExpressionObject: undefined, evaluateFunction: undefined, aggregateBucketArticlesAttributeSystemName: undefined, includeInactiveArticles: undefined } as ICatalogBucketAttributeParsed
      // if cacl type init parsedValidationExpressionObject
      if (bucketAttribute.AttributeTypeId === AttributeType.Calc) {
        try {
          bucketAttributeClone.parsedValidationExpressionObject = JSON.parse(bucketAttributeClone.ValidationExpression)
          if (utils.isDefined(bucketAttributeClone.parsedValidationExpressionObject) && utils.isDefined(bucketAttributeClone.parsedValidationExpressionObject.calculatedRegexExpression) && utils.isDefined(bucketAttributeClone.parsedValidationExpressionObject.calculatedRegexExpression.calculatedFormula)) {
            // eslint-disable-next-line no-new-func
            bucketAttributeClone.evaluateFunction = Function('data', `'use strict';return (${bucketAttributeClone.parsedValidationExpressionObject.calculatedRegexExpression.calculatedFormula})`)
          }
          if (utils.isDefined(bucketAttributeClone.parsedValidationExpressionObject) && utils.isDefined(bucketAttributeClone.parsedValidationExpressionObject.aggregateBucketArticlesAttributeSystemName)) {
            bucketAttributeClone.aggregateBucketArticlesAttributeSystemName = bucketAttributeClone.parsedValidationExpressionObject.aggregateBucketArticlesAttributeSystemName
          }
          if (utils.isDefined(bucketAttributeClone.parsedValidationExpressionObject)) {
            bucketAttributeClone.includeInactiveArticles = utils.isDefined(bucketAttributeClone.parsedValidationExpressionObject.includeInactiveArticles) ? !!bucketAttributeClone.parsedValidationExpressionObject.includeInactiveArticles : true
          }
        }
        catch (error) {
          console.warn(`unable to parse calc validation expression for bucket attribute ${bucketAttributeClone.AttributeDisplayName}`)
        }
        visibleBucketAttributes.push(bucketAttributeClone)
      }
      else {
        visibleBucketAttributes.push(bucketAttributeClone)
      }
    }
  })
  return visibleBucketAttributes
})

const visibleCalcTypeBucketAttributes = computed(() => visibleBucketAttributes.value.filter(visibleBucketAttribute => visibleBucketAttribute.AttributeTypeId === AttributeType.Calc
  && (utils.isDefined(visibleBucketAttribute.evaluateFunction) || utils.isDefined(visibleBucketAttribute.aggregateBucketArticlesAttributeSystemName))),
)
// COMPUTED - END

// FUNCTIONS
/**
 * @description when fetching new data from database (when there is a change in filter criteria or switch to/from browse by model)
 * it is being called from reloadArticles in useLiveArticlesCriteria compossible
 * @param {boolean} fullReload
 */
function onArticlesLoading(fullReload: boolean) {
  if (fullReload) {
    loading.value = true
  }
}

/**
 * @description: index data (myDataIndexById), reset variables (selectedItems, visibleRows, totalVisibleArts) and set the scroll top position to 0
 * and call doLoadMore if there are data
 * emits data-changed and selection-changed
 * will be executed after fetching data database(when there is a change in filter criteria or switch to/from browse by model)
 * it is being called from reloadArticles in useLiveArticlesCriteria compossible
 * @param {boolean} fullReload
 */
function onArticlesLoaded(fullReload: boolean) {
  loading.value = false

  if (fullReload) {
    totalVisibleArts.value = 0
    scrollViewList.value?.scrollTo({ top: 0 })
    resetVisibleItems()
    emit('dataChanged', myData.value, Object.keys(indexedBuckets.value).length, browseByDataTable.value)
    if (myData.value.length > 0) {
      doLoadMore(80, true)
    }
  }
}

function doLoadMore(count: number, increaseTotalVisible: boolean) {
  let bucketsToLoad = count
  let availableArts = 0
  for (let i = Math.max(visibleRows.value.length - 1, 0); i < browseByDataTable.value.rows.length; i++) {
    if (visibleRows.value.length - 1 < i) {
      visibleRows.value.push({ rowLabel: browseByDataTable.value.rows[i], maxCount: 0 })
    }
    const canAdd = Math.min(browseByDataTable.value.maxPerRow[browseByDataTable.value.rows[i]] - visibleRows.value[i].maxCount, bucketsToLoad)
    bucketsToLoad -= canAdd
    visibleRows.value[i].maxCount += canAdd
    availableArts += canAdd
    availableArticlesToLoad.value = canAdd
    if (bucketsToLoad === 0) {
      break
    }
  }
  if (increaseTotalVisible) {
    totalVisibleArts.value += availableArts
  }
}

function onLoadMore(visible: boolean) {
  if (visible) {
    doLoadMore(80, true)
  }
}

function onBucketClick(bucket: IAttributeBucket) {
  if (props.clickToSelect) {
    selectedItems.value[bucket.key] = !selectedItems.value[bucket.key]
    selectionChanged()
  }
  else {
    emit('bucketClick', bucket)
  }
}

function onContextMenuOptionClick(option: IContextMenuItem, targetItem: IAttributeBucket) {
  emit('contextMenuItemClick', option, targetItem)
}

function onContextMenuOptionClose() {
  emit('onContextItemUpdated', contextMenuBucket.value)
}

function showBucketContextMenu(event: MouseEvent, bucket?: IAttributeBucket) {
  event.preventDefault()
  if (!bucket) {
    contextMenuBucket.value = undefined
    bucketsMenuRef.value?.doClose()
    return
  }
  contextMenuBucket.value = bucket
  bucketsMenuRef.value?.openMenu(event, bucket)
  emit('onContextItemUpdated', contextMenuBucket.value)
}

function onColumnCheckboxChanged(columnValue: string) {
  browseByDataTable.value.rows.forEach((rowValue) => {
    const buckets: Array<IAttributeBucket> = browseByDataTable.value.buckets[`${columnValue}//${rowValue}`]
    if (utils.isDefined(buckets) && buckets.length) {
      buckets.forEach((bucket) => {
        selectedItems.value[bucket.key] = selectedColumns[columnValue]
        selectionChanged()
      })
    }
  })
}

function onRowCheckboxChanged(rowValue: string) {
  browseByDataTable.value.cols.forEach((columnValue) => {
    const buckets: Array<IAttributeBucket> = browseByDataTable.value.buckets[`${columnValue}//${rowValue}`]
    if (utils.isDefined(buckets) && buckets.length) {
      buckets.forEach((bucket) => {
        selectedItems.value[bucket.key] = selectedRows[rowValue]
      })
      selectionChanged()
    }
  })
}

function deselectAll() {
  for (const bucketKey in selectedItems.value) {
    selectedItems.value[bucketKey] = false
  }
  browseByDataTable.value.cols.forEach((columnValue) => {
    selectedColumns[columnValue] = false
  })
  visibleRows.value.forEach((visibleRowInfo) => {
    selectedRows[visibleRowInfo.rowLabel] = false
  })
  selectionChanged()
}

function selectAll() {
  for (const bucketKey in selectedItems.value) {
    selectedItems.value[bucketKey] = true
  }
  browseByDataTable.value.cols.forEach((columnValue) => {
    selectedColumns[columnValue] = true
  })
  visibleRows.value.forEach((visibleRowInfo) => {
    selectedRows[visibleRowInfo.rowLabel] = true
  })
  selectionChanged()
}

function selectionChanged() {
  const selectedBuckets = getSelectedBuckets()
  emit('selectionChanged', selectedArticleIds.value.map(catalogArticleId => myData.value[myDataIndexById.value[catalogArticleId]]), selectedBuckets)
}

function getSelectedBuckets() {
  const selectedBuckets: Array<IAttributeBucket> = []
  for (const bucketKey in selectedItems.value) {
    if (selectedItems.value[bucketKey] === true) { // for selected buckets find list of catalogArticleId list
      const indexInfo = indexedBuckets.value[bucketKey]
      const bucket: IAttributeBucket = browseByDataTable.value.buckets[indexInfo.rowColumnKey][indexInfo.index]
      selectedBuckets.push(bucket)
    }
  }
  return selectedBuckets
}

function resetVisibleItems() {
  visibleRows.value = []
  totalVisibleArts.value = 0
  resetSelectedItems()
}

function resetSelectedItems() {
  selectedItems.value = {}
  for (const bucketKey in indexedBuckets.value) {
    selectedItems.value[bucketKey] = false
  }
  selectionChanged()
}

function setLoading(val: boolean) {
  loading.value = val
}

watch(() => browseByDataTable.value, () => {
  // reset selected items when new browseByDataTable is created
  resetSelectedItems()
})

watch([() => props.columnAttribute, () => props.rowAttribute, () => userStore.customSortArticlePropertyAndValues], () => {
  resetVisibleItems()
  doLoadMore(80, true)
  if (scrollViewList.value) {
    scrollViewList.value.scrollTop = 0
  }
})

watch(() => userStore.sortBy, (val) => {
  if (val !== '_customSort') {
    resetVisibleItems()
    doLoadMore(80, true)
    if (scrollViewList.value) {
      scrollViewList.value.scrollTop = 0
    }
  }
})

onUnmounted(() => {
  resetSelectedItems()
})

defineExpose({
  selectAll,
  deselectAll,
  setLoading,
  articles: myData,
})
</script>

<style lang="scss" scoped>
.t-1 {
  td {
    min-width: 320px;
  }

  .thumbnail {
    margin: 4px;
  }
}

.t-2 {
  td {
    min-width: 330px;
  }

  .thumbnail {
    margin: 10px;
  }
}

.t-3 {
  td {
    min-width: 350px;
  }

  .thumbnail {
    margin: 10px;
  }
}
</style>
