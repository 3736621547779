<template>
  <div ref="container" class="w-full h-full overflow-auto mt-4 container">
    <div v-if="!items.length" class="text-lg">
      <p>{{ t('general.emptyList') }}</p>
    </div>
    <tx-collapse v-else v-model="activeItems" class="px-4" @change="onCollapseChange(activeItems)">
      <tx-collapse-item
        v-for="(item) in items" :key="item.Id"
        :value="item.Id"
        :data-item-id="item.Id"
        :title-class="`tracking-[1px] font-semibold text-lg ${(detailsCache[item.Id] ? detailsCache[item.Id].Status === 0 : item.Status === 0 ? 'opacity-50' : '')}`"
        :title="`${item.IsCreateArticleRequest ? t(item.RequestTypeTranslator) : getEditRequestTitle(item)}: ${item.IsCreateArticleRequest ? item.Content.ArticleNumber : item.SourceArticleId && props.articleIdArticleNumberMap && props.articleIdArticleNumberMap[item.SourceArticleId] ? props.articleIdArticleNumberMap[item.SourceArticleId] : item.SourceArticleNumber}`"
      >
        <div class="border rounded shadow relative overflow-x-hidden">
          <div v-if="loadingStates[item.Id]" class="p-2 h-[560px] overflow-y-auto">
            <loader />
          </div>
          <div
            v-else
            class="p-2 h-[560px] overflow-y-auto"
            :class="{
              'cursor-pointer hover:border hover:border-primary hover:rounded': item.IsCreateArticleRequest, // show pointer only if clickable
              'border border-primary rounded': item.ObjectId === selectedObjectId,
              'opacity-50': detailsCache[item.Id] ? detailsCache[item.Id].Status === 0 : item.Status === 0,
            }"
            @click="item.IsCreateArticleRequest ? onRequestArticleSelected(item) : ''"
          >
            <div class="flex font-semibold justify-between border-b pb-2">
              <div class="flex items-center">
                <div class="mr-4">
                  <div v-tooltip="{ text: utils.formatDateTime(item.CreatedDate), theme: { placement: 'right' } }">
                    <use-time-ago v-slot="{ timeAgo }" :time="new Date(item.CreatedDate)">
                      {{ timeAgo }}
                    </use-time-ago>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-center justify-center flex-grow">
                <tx-chip :text="requestConstants.requestStatesReverse[detailsCache[item.Id] ? detailsCache[item.Id].State : item.State]" :type="getChipType(detailsCache[item.Id] ? detailsCache[item.Id] : item)" />
              </div>
              <div class="flex flex-col justify-end items-start">
                <div class="flex items-left">
                  <div class="whitespace-nowrap" style="max-width: 500px;">
                    <div class="overflow-hidden overflow-ellipsis">
                      {{ t('requests.createdBy') }}: {{ item.CreatedByUserName }}
                    </div>
                  </div>
                </div>
                <div v-if="detailsCache[item.Id] ? detailsCache[item.Id].State === requestConstants.requestStates.approve || detailsCache[item.Id].State === requestConstants.requestStates.reject || detailsCache[item.Id].State === requestConstants.requestStates.confirm : item.State === requestConstants.requestStates.approve || item.State === requestConstants.requestStates.reject || item.State === requestConstants.requestStates.confirm" class="flex items-start">
                  <div class="whitespace-nowrap" style="max-width: 500px;">
                    <div class="overflow-hidden overflow-ellipsis">
                      {{ t('requests.updatedBy') }}: {{ detailsCache[item.Id] ? detailsCache[item.Id].UpdatedByUserName : item.UpdatedByUserName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div v-if="item.Content" class="pt-2 border-b">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('articleDetails.modelOrArticleAttributes') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <template v-for="(value, key) in item.Content" :key="key">
                  <div v-if="userStore.myAttributes && userStore.myAttributes[key]" class="mb-5 last-of-type:mb-4">
                    <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                      {{ userStore.myAttributes[key].DisplayName }}
                    </div>
                    <div class="pl-1 font-semibold leading-3 w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                      {{ utils.getAttributeTypeSpecificValue(userStore.myAttributes[key], value, userStore.priceGroups, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated) }}
                    </div>
                  </div>
                </template>
              </div>
            </div> -->
            <div v-if="item.RequestType !== requestConstants.requestTypes.EditPrices.key && !item.IsCreateArticleRequest" class="pt-4">
              <div class="mb-5">
                <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                  {{ getSubtitle(detailsCache[item.Id] ? detailsCache[item.Id] : item) }}
                </div>
                <div v-if="item.ImpactedCatalogs && item.ImpactedCatalogs.length" class="grid w-full grid-cols-3 gap-x-6 pt-2">
                  <div class="mb-5 last-of-type:mb-4">
                    <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                      {{ t('requestsTable.fields.impactedSeasons') }} :
                    </div>
                    <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                      {{ [...new Set(item.ImpactedCatalogs.map(season => season.Season))].join(',') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-else-if="!item.IsCreateArticleRequest" class="pt-4">
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div v-for="price in getPriceRequest(detailsCache[item.Id] ? detailsCache[item.Id] : item)" :key="price.Id" class="mb-5 last-of-type:mb-4">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ price.Name }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ price.InitialPrice }} -> {{ price.RequestedPrice }}
                  </div>
                </div>
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div class="mb-5 last-of-type:mb-4">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ t('requestsTable.fields.impactedSeasons') }} :
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ item.ImpactedCatalogs && item.ImpactedCatalogs.length ? [...new Set(item.ImpactedCatalogs.map(season => season.Season))].join(',') : '' }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="detailsCache && detailsCache[item.Id] && detailsCache[item.Id].Attachments && detailsCache[item.Id].Attachments.length > 0" class="pt-4">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('requests.title.attachments') }}
              </div>
              <div class="w-full">
                <ul>
                  <li
                    v-for="attachment in detailsCache[item.Id].Attachments.filter(a => a.Status)"
                    :key="attachment.Id"
                    class="cursor-pointer hover:bg-gray-200 hover:text-blue-600 px-2 py-1 rounded transition-all"
                    @click="viewAttachment(attachment, item)"
                  >
                    {{ attachment.FileName }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="pt-2">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('articleDetails.requestAttributes') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div v-for="attribute in getAvailableRequestAttributes(detailsCache[item.Id] ? detailsCache[item.Id].RequestSource : item.RequestSource)" :key="attribute.AttributeSystemName" class="mb-5 last-of-type:mb-4">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ attribute.AttributeDisplayName }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ detailsCache[item.Id] && detailsCache[item.Id].Participants && detailsCache[item.Id].Participants.length !== 0 && utils.isDefined(detailsCache[item.Id].Participants[0].RequestAttributes)
                      ? detailsCache[item.Id].Participants[0].RequestAttributes[attribute.AttributeSystemName] ? detailsCache[item.Id].Participants[0].RequestAttributes[attribute.AttributeSystemName] : '-'
                      : item.RequestAttributes && item.RequestAttributes[attribute.AttributeSystemName] && item.RequestAttributes[attribute.AttributeSystemName].length ? item.RequestAttributes[attribute.AttributeSystemName][0] : '-' }}
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2">
              <div class="font-semibold text-lg uppercase label w-full whitespace-nowrap overflow-hidden inline-block">
                {{ t('requests.title.approvalRejectionReason') }}
              </div>
              <div class="grid w-full grid-cols-3 gap-x-6">
                <div class="mb-5">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ t('requestsTable.fields.reason') }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ detailsCache[item.Id] ? detailsCache[item.Id].Reason : item.Reason }}
                  </div>
                </div>
                <div class="mb-5">
                  <div class="text-xs leading-3 tracking-wide uppercase label w-full whitespace-nowrap text-ellipsis overflow-hidden inline-block">
                    {{ t('requestsTable.fields.comment') }}
                  </div>
                  <div class="pl-1 font-semibold leading-3 w-full break-words whitespace-normal inline-block">
                    {{ detailsCache[item.Id] ? detailsCache[item.Id].Comment : item.Comment }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Do not allow any actions if the request is not created from current catalog -->
          <div class="flex space-x-2 justify-end border-t p-1">
            <template v-if="item.CreatedBy === userStore.userProfile.Id && (detailsCache[item.Id] ? (detailsCache[item.Id].State === requestConstants.requestStates.draft || detailsCache[item.Id].State === requestConstants.requestStates.new) : (item.State === requestConstants.requestStates.draft || item.State === requestConstants.requestStates.new))">
              <!-- <tx-button
                v-if="item.Status === 0" faicon="fa-light fa-rotate-right"
                :text="t('activeOrDeactiveArticle.activate')" @click="onActivateOrDeActivateRequest(item, 1)"
              /> -->
              <tx-button
                v-if="hasUpdateStatusRequestPrivilege && item.CatalogCode === userStore.activeCatalog?.CatalogCode && (detailsCache[item.Id] ? detailsCache[item.Id].Status === 1 : item.Status === 1)"
                width="120px" faicon="fa-light fa-trash-can" :disabled="loadingStates[item.Id]"
                type="danger" :is-request="true" :text="t('general.delete')" @click="onActivateOrDeActivateRequest(item, 0)"
              />
              <tx-button
                v-if="hasAddAttachmentPrivilege && item.CatalogCode === userStore.activeCatalog?.CatalogCode && (detailsCache[item.Id] ? detailsCache[item.Id].Status === 1 : item.Status === 1)"
                width="120px" faicon="fa-light fa-plus" :disabled="loadingStates[item.Id]"
                :is-request="true" :text="t('requests.attachments')" @click="onAddAttachments(item)"
              />
            </template>
            <tx-button
              v-if="item.CatalogCode === userStore.activeCatalog?.CatalogCode && (detailsCache[item.Id] ? detailsCache[item.Id].State === requestConstants.requestStates.draft || detailsCache[item.Id].State === requestConstants.requestStates.new : item.State === requestConstants.requestStates.draft || item.State === requestConstants.requestStates.new) && hasEditRequestPrivilege && (item.CreatedBy === userStore.userProfile.Id || hasEditRequestSpecialPrivilege)" faicon="fa-light fa-edit"
              :is-request="true" :text="t('general.edit')" :disabled="loadingStates[item.Id]" @click="showEditRequest(item)"
            />
            <tx-button
              v-if="item.CatalogCode === userStore.activeCatalog?.CatalogCode && (detailsCache[item.Id] ? detailsCache[item.Id].Status === 1 : item.Status === 1) && (detailsCache[item.Id] ? detailsCache[item.Id].State === requestConstants.requestStates.new || detailsCache[item.Id].State === requestConstants.requestStates.approve : item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.approve) && (item.RequestType === requestConstants.requestTypes.EditPrices.key ? hasApproveRejectPriceRequestsPrivilege : hasRejectRequestPrivilege)"
              width="120px" type="cancel" faicon="fa-light fa-thumbs-down" :is-request="true" :disabled="disableButton || loadingStates[item.Id]" :text="t('articleDetails.actions.reject')" @click="openApproveRejectDialog(item, requestConstants.requestStates.reject)"
            />
            <tx-button
              v-if="item.CatalogCode === userStore.activeCatalog?.CatalogCode && (detailsCache[item.Id] ? detailsCache[item.Id].Status === 1 : item.Status === 1) && (detailsCache[item.Id] ? detailsCache[item.Id].State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.reject : item.State === requestConstants.requestStates.new || item.State === requestConstants.requestStates.reject) && (item.RequestType === requestConstants.requestTypes.EditPrices.key ? hasApproveRejectPriceRequestsPrivilege : hasApproveRequestPrivilege)"
              width="120px" :disabled="disableButton || loadingStates[item.Id]"
              faicon="fa-light fa-thumbs-up" :is-request="true" :text="t('articleDetails.actions.approve')" @click="openApproveRejectDialog(item, requestConstants.requestStates.approve)"
            />
            <tx-button
              v-if="item.CatalogCode === userStore.activeCatalog?.CatalogCode && (detailsCache[item.Id] ? detailsCache[item.Id].Status === 1 : item.Status === 1) && (detailsCache[item.Id] ? detailsCache[item.Id].State === requestConstants.requestStates.approve : item.State === requestConstants.requestStates.approve) && hasConfirmRequestPrivilege && isAllowedConfirmRequests[item.Id]"
              width="120px" :is-request="true" :disabled="disableButton || loadingStates[item.Id]" :text="t('articleDetails.actions.confirm')" @click="onConfirmRequest(item)"
            />
          </div>
        </div>
      </tx-collapse-item>
    </tx-collapse>
    <approve-reject-request-dialog ref="showApproveRejectDialog" @approve-reject="onApproveReject" />
    <add-request-attachments-dialog ref="showAddRequestAttachmentsDialog" @upload="onAttachmentUpload" />
    <view-request-attachment-dialog ref="showRequestAttachmentDialog" @delete="onDeleteAttachment" />
    <edit-request-dialog ref="showEditRequestDialog" @edit-request="onEditRequest" />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onUnmounted, onUpdated, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { UseTimeAgo } from '@vueuse/components'
import EditRequestDialog from './EditRequestDialog.vue'
import { useUserStore } from '@/store/userData'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import { useNotificationStore } from '@/store/notification'
import TxCollapse from '@/shared/components/txCollapse/txCollapse.vue'
import TxCollapseItem from '@/shared/components/txCollapse/txCollapseItem.vue'
import TxChip from '@/shared/components/TxChip.vue'
import TxButton from '@/shared/components/TxButton.vue'
import type RequestModel from '@/models/request'
import { appConstants, editRequestKeys, privileges, requestConstants } from '@/models/constants'
import utils from '@/services/utils'
import ApproveRejectRequestDialog from '@/shared/components/requests/ApproveRejectRequestDialog.vue'
import type CatalogRequestAttribute from '@/models/catalogRequestAttribute'
import { getRequestDetails, updateRequestStatuses } from '@/api/t1/request'
import appConfig from '@/services/appConfig'
import { AttributeType } from '@/models/catalogAttribute'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import type MyArticle from '@/models/myArticle'
import type { IAttachmentModel } from '@/api/t1/model/requestModel'
import AddRequestAttachmentsDialog from '@/modules/articleDetails/components/AddRequestAttachmentsDialog.vue'
import ViewRequestAttachmentDialog from '@/modules/articleDetails/components/ViewRequestAttachmentDialog.vue'
import Loader from '@/shared/components/Loader.vue'
import type { RequestDetailsModel } from '@/models/request'

const props = defineProps<{
  items: RequestModel[]
  selectedRequestId?: number
  selectedObjectId?: string
  articleIdArticleNumberMap?: Record<number, string>
  disableRequestActions?: boolean
  isConfirmedFinished?: boolean
}>()

const emit = defineEmits<{
  (e: 'itemSelected', requestId: number, objectId: string): void
  (e: 'confirm', item: RequestModel): void
  (e: 'refreshRequestDetails'): void
  (e: 'approveReject', requestIds: number[], propertiesToUpdate: { state: number, comment: string, reason: string, updatedByUserName: string }): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const confirmDialog = useConfirmDialog()
const notificationsStore = useNotificationStore()
const { skipRestrictingPropertyUpdateBasedOnArticleState, getArticlesLocked, getArticlesMaxStateDetails, getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank, getIndexedRestrictedAttributesBasedOnArticlesStateRank } = useArticleFormHelper()

const showApproveRejectDialog = ref<InstanceType<typeof ApproveRejectRequestDialog>>()
const isAllowedConfirmRequests = ref<{ [key: string]: boolean }>({})
const showAddRequestAttachmentsDialog = ref<InstanceType<typeof AddRequestAttachmentsDialog>>()
const showRequestAttachmentDialog = ref<InstanceType<typeof ViewRequestAttachmentDialog>>()
const loadingStates = ref<Record<number, boolean>>({})
const detailsCache = ref<Record<number, RequestDetailsModel>>({})
const container = ref<HTMLElement | null>(null)
const showEditRequestDialog = ref<InstanceType<typeof EditRequestDialog>>()
const currentConfirmRequestId = ref<number | null>(null)
const activeItems = ref<Array<number>>([])
if (props.selectedRequestId) {
  activeItems.value.push(props.selectedRequestId)
  scrollToActiveItem(props.selectedRequestId)
  onCollapseChange(activeItems.value)
}

const hasUpdateStatusRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.updateStatus))
const hasRejectRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.rejectRequest))
const hasApproveRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.approveRequest))
const hasApproveRejectPriceRequestsPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.approveRejectPriceRequests))
const hasConfirmRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.confirmRequest))
const hasAddAttachmentPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.addAttachments))
const disableButton = computed(() => !!props.disableRequestActions)
const hasEditRequestPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.editRequest))
const hasEditRequestSpecialPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.specialPermissionToEditRequest))

watch(() => props.selectedRequestId, (requestId) => {
  activeItems.value = []
  if (requestId) {
    activeItems.value.push(requestId)
    scrollToActiveItem(requestId)
    onCollapseChange(activeItems.value)
  }
})

watch(() => props.items, async (newItems) => {
  for (const item of newItems) {
    isAllowedConfirmRequests.value[item.Id] = await isAllowedConfirm(item)
  }
}, { immediate: true })

watch(() => props.isConfirmedFinished, (value) => {
  if (value && currentConfirmRequestId.value) {
    refreshRequestDetails(userStore.activeCatalog!.CatalogCode, currentConfirmRequestId.value)
  }
})

function getAvailableRequestAttributes(requestSource: number) {
  let requestAttributes: CatalogRequestAttribute[] = []
  if (userStore.activeCatalog) {
    requestAttributes = userStore.activeCatalog.RequestAttributeList.filter(a => a.Status > 0)
    if (requestSource === requestConstants.requestSources.new || requestSource === requestConstants.requestSources.carryover) {
      if (userStore.activeCatalog.Config.RequestForm.carryoverModel?.requestAttributesForm
        && userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.length > 0) {
        const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.carryoverModel.requestAttributesForm.map(x => x.attribute))
        for (let i = requestAttributes.length - 1; i >= 0; i--) {
          if (!configRequestAttributes.has(requestAttributes[i].AttributeSystemName)) {
            requestAttributes.splice(i, 1)
          }
        }
      }
    }
    else if (requestSource === requestConstants.requestSources.similarStyle) {
      if (userStore.activeCatalog.Config.RequestForm.copyModel?.requestAttributesForm
        && userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.length > 0) {
        const configRequestAttributes = new Set(userStore.activeCatalog.Config.RequestForm.copyModel.requestAttributesForm.map(x => x.attribute))
        for (let i = requestAttributes.length - 1; i >= 0; i--) {
          if (!configRequestAttributes.has(requestAttributes[i].AttributeSystemName)) {
            requestAttributes.splice(i, 1)
          }
        }
      }
    }
  }
  return requestAttributes
}

function getPriceRequest(request: (RequestModel | RequestDetailsModel)) {
  let contentPrices = request.Content.Prices
  let initialPrices = request.InitialContent ? request.InitialContent.Prices : ''
  if (typeof request.InitialContent === 'string') {
    initialPrices = utils.tryParse(request.InitialContent).Prices
  }
  if (typeof request.Content === 'string') {
    contentPrices = utils.tryParse(request.Content).Prices
  }
  if (!Array.isArray(contentPrices)) {
    return []
  }

  const requestedPrices = contentPrices.map((contentPrice) => {
    const initialPrice = initialPrices.find(initPrice => initPrice.Id === contentPrice.Id)
    return {
      Id: contentPrice.Id,
      Name: contentPrice.Name,
      InitialPrice: initialPrice?.Price || 0,
      RequestedPrice: contentPrice.Price || 0,
    }
  })

  return requestedPrices
}

// check if user is allowed confirm the request,
// should have specific privilege, attribute should be editable,
// should not be restricted at state and role level(role level attributes comes as non editable)
async function isAllowedConfirm(request: RequestModel) {
  if (request.IsCreateArticleRequest) {
    return true
  }
  let isAllowedConfirmRequest = false
  const { userProfile, activeCatalog } = userStore
  const priceGroups = userStore.activeCatalog!.CatalogPriceGroupList
  const { AccountDetails } = userProfile
  const isAccountValid = AccountDetails.AccountTypeId === 1 || AccountDetails.AccountId === activeCatalog?.AccountId
  const articleObject: MyArticle | undefined = await appConfig.DB!.getArticle(userStore.activeCatalog!, request.SourceArticleId!)
  if (articleObject) {
    const key = Object.keys(request.Content)[0]
    const maxArticleStateRankDetails = getArticlesMaxStateDetails([articleObject], (key === editRequestKeys.prices ? !!userStore.activeCatalog!.IsPriceByStyleApply : key === editRequestKeys.sizeScale ? !!userStore.sellerDetails?.IsSizeScaleByStyle : key === editRequestKeys.modelName))
    if (request.RequestType === requestConstants.requestTypes.EditAttribute.key) {
      const attribute = userStore.myAttributes![key]
      const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
      if (hasPrivilege && isAccountValid && attribute.Editable) {
        const indexedRestrictedAttributesBasedOnArticlesMaxSateRank = getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank([articleObject])
        const indexedRestrictedAttributesBasedOnArticlesStateRank = getIndexedRestrictedAttributesBasedOnArticlesStateRank([articleObject])
        if (!attribute.ReadOnly && ((attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesMaxSateRank.hasOwnProperty(attribute.SystemName)) || (!attribute.IsModelLevel && !indexedRestrictedAttributesBasedOnArticlesStateRank.hasOwnProperty(attribute.SystemName)))) {
          isAllowedConfirmRequest = true
        }
      }
    }
    else {
      // static properties
      if (key === editRequestKeys.stateName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.AssignArticleStateToArticles)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.modelName) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
          if (utils.isDefined(maxArticleStateRankDetails)) {
            // if user has special permission assigned to his role then skip restriction
            if (!skipRestrictingPropertyUpdateBasedOnArticleState) {
              isAllowedConfirmRequest = maxArticleStateRankDetails.IsModelNameEditable === 1
            }
          }
        }
      }
      else if (key === editRequestKeys.prices) {
        const hasPrivilege = userProfile.isValidPrivilege(privileges.article.UpdateCatalogModelPrices)
          || userProfile.isValidPrivilege(privileges.article.UpdateCatalogArticleBulkPrices)
        if (hasPrivilege && isAccountValid) {
          const isAnyArticleLocked = await getArticlesLocked([articleObject], !!activeCatalog?.IsPriceByStyleApply)

          if (!isAnyArticleLocked || userProfile.isValidPrivilege(privileges.request.ignoreArticlePriceLockInConfirmPriceRequest)) {
            let allEditableAndNotRestricted = true

            for (const price of request.Content.Prices) {
              const priceGroup = priceGroups.filter(pg => pg.Id === price.Id)[0]
              if (priceGroup && maxArticleStateRankDetails) {
                const priceGroupStateRank = priceGroup.StateRank || 0
                const lockBasedOnArticlesState = !skipRestrictingPropertyUpdateBasedOnArticleState
                  && priceGroup.StateRank != null && maxArticleStateRankDetails.StateRank >= priceGroupStateRank
                if (!priceGroup.IsEditable || lockBasedOnArticlesState) {
                  allEditableAndNotRestricted = false
                  break
                }
              }
              else {
                allEditableAndNotRestricted = false
                break
              }
            }

            if (allEditableAndNotRestricted) {
              isAllowedConfirmRequest = true
            }
          }
        }
      }
      else if (key === editRequestKeys.status) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateStatus)
        if (hasPrivilege && isAccountValid) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.colorId) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.update)
        if (hasPrivilege && isAccountValid && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited
          && userStore.sellerDetails?.AutomaticNumbering === 1 && (articleObject.ColorId === null || articleObject.ColorId === 0)) {
          isAllowedConfirmRequest = true
        }
      }
      else if (key === editRequestKeys.sizeScale) {
        const hasPrivilege = userStore.userProfile.isValidPrivilege(privileges.article.updateSizeScale)
        const lockedBasedOnArticleState = (maxArticleStateRankDetails == null || !maxArticleStateRankDetails.IsSizeScaleEditable) && !skipRestrictingPropertyUpdateBasedOnArticleState
        if (hasPrivilege && isAccountValid && userStore.activeCatalog!.DataSourceTypeId !== appConstants.catalogTypes.inherited && userStore.sellerDetails?.IsSizeScaleByStyle && !lockedBasedOnArticleState) {
          isAllowedConfirmRequest = true
        }
      }
    }
  }
  return isAllowedConfirmRequest
}

function getChipType(item: (RequestModel | RequestDetailsModel)) {
  return item.State === requestConstants.requestStates.approve || item.State === requestConstants.requestStates.confirm
    ? 'success'
    : item.State === requestConstants.requestStates.reject
      ? 'error'
      : 'primary'
}

async function onActivateOrDeActivateRequest(request: RequestModel, status: ZeroOrOneType) {
  if (userStore.activeCatalog) {
    const title = t('general.alert')
    const activateOrDeactivateAction = status === 0 ? 'delete' : 'activate'
    const message = t('activeOrDeactiveArticle.activateOrDeactivateAlert', { action: activateOrDeactivateAction, number: request.Content.ArticleNumber })

    await confirmDialog(title, message, [], async () => {
      await updateRequestStatuses(userStore.activeCatalog!.CatalogCode, [{ Id: request.Id, Status: status }])
      await appConfig.DB!.requests.update([userStore.activeCatalog!.CatalogCode, request.Id], { Status: status })
      await userStore.doLoadData(['Requests'])
      notificationsStore.addNotification({ message: t(status === 0 ? 'activeOrDeactiveArticle.deactivateRequestSuccessfully' : 'activeOrDeactiveArticle.activateRequestSuccessfully', 1), type: 'Success' })
      emit('refreshRequestDetails')
    })
  }
}

function openApproveRejectDialog(request: RequestModel, actionType: number) {
  showApproveRejectDialog.value?.showDialog([request], actionType)
}

async function onConfirmRequest(request: RequestModel) {
  currentConfirmRequestId.value = request.Id
  emit('confirm', request)
}

function onRequestArticleSelected(request: RequestModel) {
  emit('itemSelected', request.Id, request.ObjectId)
}

function onApproveReject(requestIds: number[], propertiesToUpdate: { state: number, comment: string, reason: string, updatedByUserName: string }) {
  refreshRequestDetails(userStore.activeCatalog!.CatalogCode, requestIds[0])
  emit('approveReject', requestIds, propertiesToUpdate)
}

function getEditRequestTitle(item) {
  const key = Object.keys(item.Content)[0]
  if (item.RequestType === requestConstants.requestTypes.EditAttribute.key) {
    const displayName = userStore.myAttributes![key] ? userStore.myAttributes![key].DisplayName : key
    return `Edit ${displayName} Request`
  }
  else {
    if (requestConstants.requestTypes[item.RequestType]) {
      return t(`${requestConstants.requestTypes[item.RequestType].label}`)
    }
    else {
      return t(item.RequestTypeTranslator)
    }
  }
}

function getSubtitle(item: (RequestModel | RequestDetailsModel)) {
  let content: Record<string, any> = item.Content
  let initialContent: Record<string, any> | null = null
  if (typeof item.InitialContent === 'string') {
    initialContent = utils.tryParse(item.InitialContent)
  }
  else {
    initialContent = item.InitialContent
  }
  if (typeof item.Content === 'string') {
    content = utils.tryParse(item.Content)
  }

  const key = Object.keys(content)[0]
  const initialValue = utils.isDefined(initialContent?.[key]) && initialContent?.[key] !== '' && initialContent?.[key].toString().length ? utils.getAttributeTypeSpecificValueWithValue(userStore.myAttributes![key], initialContent?.[key]) : t('general.blank')
  const newValue = content[key]
  const attributesList = Object.values(userStore.myAttributes!)
  const colorPaletteSystemNames = attributesList
    .filter(attribute => attribute.AttributeType === AttributeType.ColorPalette && attribute.SystemName !== editRequestKeys.colorId)
    .map(attribute => attribute.SystemName)

  if (item.RequestType === requestConstants.requestTypes.EditAttribute.key && !colorPaletteSystemNames.includes(key)) {
    let value = newValue
    if (userStore.myAttributes![key]) {
      value = utils.getAttributeTypeSpecificValueWithValue(userStore.myAttributes![key], newValue)
    }
    return `${initialValue} -> ${value}`
  }
  else {
    if (key === editRequestKeys.stateName) {
      const state = userStore.articleStateList.find(state => state.StateId === newValue)
      if (state) {
        return `${initialValue} -> ${state.StateName}`
      }
      return `${initialValue} -> ${newValue}`
    }
    else if (key === editRequestKeys.colorId || colorPaletteSystemNames.includes(key)) {
      let parsedInitialValue = initialValue
      const values = Object.values(newValue) as string[]
      const parsedNewValue = values.length > 0 ? values[0] : newValue

      if (colorPaletteSystemNames.includes(key) && initialValue !== null) {
        let parsedInitial = ''

        if (typeof initialValue === 'string') {
          parsedInitial = utils.tryParse(initialValue)
        }
        else if (typeof initialValue === 'object') {
          parsedInitial = initialValue
        }

        if (parsedInitial) {
          const initialValues = Object.values(parsedInitial) as string[]
          parsedInitialValue = initialValues.length > 0 ? initialValues[0] : parsedInitial
        }
      }

      return `${parsedInitialValue} -> ${parsedNewValue}`
    }
    else if (key === editRequestKeys.status) {
      const newStatus = newValue === 1 ? 'Active' : 'Inactive'
      return `${initialValue} -> ${newStatus}`
    }
    else if (key === editRequestKeys.sizeScale) {
      const sizeScaleObj = userStore.sizeScales[newValue]
      if (sizeScaleObj) {
        return `${initialValue} -> ${sizeScaleObj.SizeScale}`
      }
      return `${initialValue} -> ${newValue}`
    }
    else {
      return `${initialValue} -> ${newValue}`
    }
  }
}

function showEditRequest(item: RequestModel) {
  showEditRequestDialog.value?.showDialog(item, props.articleIdArticleNumberMap)
}

function onEditRequest(item: RequestModel) {
  refreshRequestDetails(userStore.activeCatalog!.CatalogCode, item.Id)
  emit('refreshRequestDetails')
}

async function onCollapseChange(expandedItems) {
  if (!expandedItems || expandedItems.length === 0) { return }

  const expandedRequestId = expandedItems.find(id => !detailsCache.value[id])
  if (expandedRequestId) {
    loadingStates.value[expandedRequestId] = true
    try {
      if (userStore.userProfile.isValidPrivilege(privileges.request.requestDetails)) {
        const response = await getRequestDetails(userStore.activeCatalog!.CatalogCode, expandedRequestId)

        detailsCache.value[expandedRequestId] = response.data as unknown as RequestDetailsModel
        if (detailsCache.value[expandedRequestId].Participants && detailsCache.value[expandedRequestId].Participants.length && detailsCache.value[expandedRequestId].Participants[0].RequestAttributes) {
          if (userStore.activeCatalog && userStore.myAttributes && userStore.activeCatalog.RequestAttributeList && userStore.activeCatalog.RequestAttributeList.length) {
            for (const requestAttribute of userStore.activeCatalog.RequestAttributeList) {
              const attribute = userStore.myAttributes[requestAttribute.AttributeSystemName]
              if (attribute && attribute.AttributeType === AttributeType.Calc && attribute._calcFunction) {
                let content: Record<string, any> = {}
                const detailsContent = detailsCache.value[expandedRequestId].Content
                if (typeof detailsContent === 'string') {
                  content = utils.tryParse(detailsContent)
                }
                else {
                  content = detailsContent
                }
                const value = await utils.getCalcRequestAttributeValue(userStore.activeCatalog, attribute, detailsCache.value[expandedRequestId], content, appConfig.DB, detailsCache.value[expandedRequestId].Participants[0].RequestAttributes, userStore.priceGroups)
                if (utils.isDefined(value)) {
                  detailsCache.value[expandedRequestId].Participants[0].RequestAttributes[attribute.SystemName] = value
                }
              }
            }
          }
        }
      }
    }
    catch (error) {
      console.error('Failed to fetch details:', error)
    }
    finally {
      loadingStates.value[expandedRequestId] = false
    }
  }
}

async function viewAttachment(attachment: IAttachmentModel, request: RequestModel) {
  // atatchment
  if (attachment) {
    showRequestAttachmentDialog.value?.showDialog(attachment, request)
  }
}

async function onDeleteAttachment(request: RequestModel) {
  refreshRequestDetails(userStore.activeCatalog!.CatalogCode, request.Id)
}

function onAddAttachments(request: RequestModel) {
  showAddRequestAttachmentsDialog.value?.showDialog(request)
}

async function onAttachmentUpload(request: RequestModel) {
  refreshRequestDetails(userStore.activeCatalog!.CatalogCode, request.Id)
}

async function refreshRequestDetails(catalogCode: number, requestId: number) {
  loadingStates.value[requestId] = true
  const response = await getRequestDetails(catalogCode, requestId)
  detailsCache.value[requestId] = response.data as unknown as RequestDetailsModel
  if (detailsCache.value[requestId].Participants && detailsCache.value[requestId].Participants.length && detailsCache.value[requestId].Participants[0].RequestAttributes) {
    if (userStore.activeCatalog && userStore.myAttributes && userStore.activeCatalog.RequestAttributeList && userStore.activeCatalog.RequestAttributeList.length) {
      for (const requestAttribute of userStore.activeCatalog.RequestAttributeList) {
        const attribute = userStore.myAttributes[requestAttribute.AttributeSystemName]
        if (attribute && attribute.AttributeType === AttributeType.Calc && attribute._calcFunction) {
          let content: Record<string, any> = {}
          const detailsContent = detailsCache.value[requestId].Content
          if (typeof detailsContent === 'string') {
            content = utils.tryParse(detailsContent)
          }
          else {
            content = detailsContent
          }
          const value = await utils.getCalcRequestAttributeValue(userStore.activeCatalog, attribute, detailsCache.value[requestId], content, appConfig.DB, detailsCache.value[requestId].Participants[0].RequestAttributes, userStore.priceGroups)
          if (utils.isDefined(value)) {
            detailsCache.value[requestId].Participants[0].RequestAttributes[attribute.SystemName] = value
          }
        }
      }
    }
  }
  loadingStates.value[requestId] = false
}

function scrollToActiveItem(activeItemId: number) {
  nextTick(() => {
    const containerEl = container.value
    if (containerEl) {
      const activeItem = containerEl.querySelector(`[data-item-id="${activeItemId}"]`)
      if (activeItem) {
        activeItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
  })
}

onUnmounted(() => {
  detailsCache.value = {}
})
onUpdated(() => {
  if (props.selectedRequestId) {
    scrollToActiveItem(props.selectedRequestId)
  }
})
</script>
