<template>
  <div class="w-full">
    <div v-if="label.length > 0" class="flex">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase label" :class="{ required }" v-text="label" />
    </div>
    <div class="relative" :class="props.class">
      <div class="flex" :class="{ 'border-red-300 bg-red-50 placeholder-red-200 text-red-900': hasError }">
        <input
          ref="refFromInput"
          type="number"
          v-bind="$attrs"
          :value="from"
          class="pr-6 text-base bg-transparent h-9"
          :class="{ 'bg-gray-200 rounded': disabled }"
          :placeholder="fromPlaceholder.length > 0 ? fromPlaceholder : t('general.from')"
          :disabled="disabled"
          :required="required"
          @input="doFromInput"
        >
        <span class="p-2 text-base"> - </span>
        <input
          ref="refToInput"
          type="number"
          v-bind="$attrs"
          :value="to"
          class="pr-6 text-base bg-transparent h-9"
          :class="{ 'bg-gray-200 rounded': disabled }"
          :placeholder="toPlaceholder.length > 0 ? toPlaceholder : t('general.to')"
          :disabled="disabled"
          :required="required"
          @input="doToInput"
        >
        <font-awesome-icon
          v-if="clearable" class="absolute top-2.5 right-2 w-4 h-4 cursor-pointer" icon="fa-light fa-xmark"
          @click="doClear"
        />
      </div>
      <div v-if="hasError">
        <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500 overflow-auto">
          {{ error.$message }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ErrorObject } from '@vuelidate/core'
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'

defineOptions({
  inheritAttrs: false,
})

const props = withDefaults(defineProps<IProps>(), { type: 'int', label: '', errors: () => [] as ErrorObject[], fromPlaceholder: '', toPlaceholder: '', clearable: false, disabled: false, required: false, icon: '', class: '' })

const emit = defineEmits<{
  (e: 'update:from', val?: number | null): void
  (e: 'update:to', val?: number | null): void
}>()

interface IProps {
  from?: number | null
  to?: number | null
  type?: 'int' | 'decimal'
  label?: string
  errors?: ErrorObject[]
  fromPlaceholder?: string
  toPlaceholder?: string
  clearable?: boolean
  disabled?: boolean
  required?: boolean
  class?: string
}
const { t } = useI18n()

const refFromInput = ref<HTMLInputElement>()
const refToInput = ref<HTMLInputElement>()

function doClear() {
  refFromInput.value!.value = ''
  refToInput.value!.value = ''
  emit('update:from', null)
  emit('update:to', null)
}

const hasError = computed(() => props.errors.length > 0)

function doFromInput(e: Event) {
  const val = (e?.target as HTMLInputElement).value
  emit('update:from', val ? Number.parseFloat(val) : null)
}

function doToInput(e: Event) {
  const val = (e?.target as HTMLInputElement).value
  emit('update:to', val ? Number.parseFloat(val) : null)
}

function focus() {
  refFromInput.value?.focus()
  refFromInput.value?.select()
}

defineExpose({
  focus,
})
</script>
