import { fabric } from 'fabric'
import type { EventBusListener, UseEventBusReturn } from '@vueuse/core'
import { useEventBus } from '@vueuse/core'
import { ref, shallowRef } from 'vue'
import { isArray } from 'lodash-es'
import MbGroup from './group'
import MbArticleImage from './articleImage'
import MbTextBox from './textBox'
import MbLine from './line'
import MbRectangle from './rectangle'
import MbTriangle from './triangle'
import MbCircle from './circle'
import MbImage from './image'
import MbArticleDetails from './articleDetails'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'
import MerchSlide from '@/modules/merch/services/merchSlide'
import { createUpdateMerchSlide, deleteSlide, getMySlides, getSharedFolders, getSharedSlides, shareSlide, updateSlidesSortOrders } from '@/api/t1/merch'
import { merchConstants } from '@/models/constants'
import type MyArticle from '@/models/myArticle'
import type Article from '@/models/article'
import type SharedFolder from '@/models/sharedFolder'
import type { sharedFolderModel, sharedUserGroupModel, sharedUserModel } from '@/api/t1/model/merchModel'

interface IHistoryItem {
  op: 'add' | 'rem' | 'mod' | 'group' | 'ungroup'
  obj: IMbObject | IMbObject[]
  state?: { org: string, cur: string } | { org: string, cur: string }[]
  groupsAllObjects?: IMbObject[]
}

interface MerchFolder {
  FolderIdSortOrder: number
  FolderName: string
}

class Merch {
//   moveToObject?: string
  canvas: fabric.Canvas
  activeSlide: MerchSlide | null
  merchSlides = shallowRef<Record<number, MerchSlide>>({})
  sharedFolders = shallowRef<Record<number, SharedFolder>>({})
  sharedSlides = shallowRef<Record<number, MerchSlide>>({})
  merchFolderMap = shallowRef<Record<string, MerchFolder>>({})
  deletedSlides = ref<string[]>([])
  keyDown = ref(null)
  history = shallowRef<IHistoryItem[]>([])
  historyPointer = ref(0)
  private eventBus: { [event in CanvasEventName]: UseEventBusReturn<CanvasEventType<event>, any> }
  canvasWidth: number
  canvasHeight: number
  templateObjectsSelectable: boolean
  showFavorites = ref(false)
  // arIndicatorKey: string | undefined
  // bAndTIndicatorKey: string | undefined
  // ghlIndicatorKey: string | undefined
  _isDirty: boolean = false
  currentSelectedFindNextIndexInCurrentActiveObject: number = -1
  constructor(canvasId: string) {
    this.activeSlide = null
    // Initialize event bus
    this.eventBus = {
      'zoom': useEventBus<ICanvasZoomEvent>('zoom'),
      'mySlides-loaded': useEventBus<IObjectMySlidesLoadedEvent>('mySlides-loaded'),
      'slide-active': useEventBus<IObjectActiveSlideEvent>('slide-active'),
      'object-added': useEventBus<IObjectAddedEvent>('object-added'),
      'object-removed': useEventBus<IObjectRemovedEvent>('object-removed'),
      'object-modified': useEventBus<IObjectModifiedEvent>('object-modified'),
      'object-moving': useEventBus<IObjectModifiedEvent>('object-moving'),
      'merch-dirty': useEventBus<IObjectMerchDirtyEvent>('merch-dirty'),
      'slide-added': useEventBus<IObjectSlideAddedEvent>('slide-added'),
      'sharedSlides-loaded': useEventBus<IObjectSharedSlidesLoadedEvent>('sharedSlides-loaded'),
      'add-folder': useEventBus<IObjectFolderAddedEvent>('add-folder'),
      'slide-shared-changed': useEventBus<IObjectSlideSharedChangedEvent>('slide-shared-changed'),
      'update-slide': useEventBus<IObjectUpdateSlideEvent>('update-slide'),
      'slide-dirty': useEventBus<IObjectSlideDirtyEvent>('slide-dirty'),
      'slides-saved': useEventBus<IObjectSlidesSavedEvent>('slides-saved'),
      'find-next-reached-end-of-slide': useEventBus<IObjectFindNextReachedEndOfSlideEvent>('find-next-reached-end-of-slide'),
      'searched-text-highlighted': useEventBus<IObjectSearchedTextHighlighted>('searched-text-highlighted'),
    }
    // Initialize canvas
    this.canvas = new fabric.Canvas(canvasId, {
      width: 1280,
      height: 720,
      containerClass: 'canvas-slide',
      backgroundColor: 'white',
      fireRightClick: true,
      stopContextMenu: false,
      allowTouchScrolling: true,
      preserveObjectStacking: true,
      fireMiddleClick: true,
    })
    this.canvasWidth = this.canvas.getWidth()
    this.canvasHeight = this.canvas.getHeight()
    this.templateObjectsSelectable = false
    // Enable selection mode
    this.canvas.selection = true

    // Enable object manipulation
    this.canvas.isDrawingMode = false
    this.canvas.selectionKey = ['shiftKey', 'ctrlKey']
    // Register events
    this.handleOnWheel()
    // // this.handleBg()
    this.handleObjectEvents()
    this.handleDrop()
    this.handleDragOver()
    this.handleMouseEvents()
    this.handleSelectionCreation()
    // const userStore = useUserStore()
    // this.arIndicatorKey = userStore.activeCatalog?.Config.ArIndicatorAttribute
    // this.ghlIndicatorKey = userStore.activeCatalog?.Config.GhlIndicatorAttribute
    // this.bAndTIndicatorKey = userStore.activeCatalog?.Config.BAndTIndicatorAttribute
  }

  on<T extends CanvasEventName>(event: T, f: EventBusListener<CanvasEventType<T>>) {
    this.eventBus[event].on(f as EventBusListener<CanvasEventType<CanvasEventName>>)
  }

  off<T extends CanvasEventName>(event: T, f: EventBusListener<CanvasEventType<T>>) {
    this.eventBus[event].off(f as EventBusListener<CanvasEventType<CanvasEventName>>)
  }

  get isDirty() {
    return this._isDirty
  }

  set isDirty(val) {
    if (val === this._isDirty) { return }
    this._isDirty = val
    this.eventBus['merch-dirty'].emit({ value: val })
  }

  private handleMouseEvents() {
    this.canvas.on('mouse:up', () => {
      // TODO convert as per web
      // TODO:Merch @aanchal why only on moving it should added to hotspot?
      // allow snaping of hotpsot only if left button is clicked and article image is moved and selected object is articleImage
      //  if(this.articleImageMoved && options.button === 1 && options.target != null && options.target.type === 'articleImage' ) {
      //   this.articleImageMoved = false
      //   this.moveSelectedObjectsOnStack(true)

      //   if (options.e.ctrlKey)  {
      //     //if ctrl key pressed on mouse release reset the opacity of selected article image object and return
      //     options.target.set({
      //       opacity: 1
      //     })
      //     return
      //   }

      //   let allObjects = this.canvas.getObjects()
      //   let hotspotObject
      //   //check mouse pointer lies inside any of the hotspot if yes then get the hotspotObject
      //   for(let index = allObjects.length -1; index != -1; index--) {
      //     if(allObjects[index].type === 'hotspot') {
      //       const hotspot = allObjects[index]
      //       const pointerX = this.canvas.getPointer(event).x
      //       const pointerY = this.canvas.getPointer(event).y
      //       const hotspotLeft = hotspot.left - hotspot.width / 2
      //       const hotspotRight = hotspotLeft + hotspot.width
      //       const hotspotTop = hotspot.top - hotspot.height / 2
      //       const hotspotBottom = hotspotTop + hotspot.height
      //       if(pointerX > hotspotLeft && pointerX < hotspotRight && pointerY > hotspotTop && pointerY < hotspotBottom) {
      //         hotspotObject = allObjects[index]
      //         break
      //       }
      //     }
      //   }
      //   //if pointer lies on any hotspot
      //   if(hotspotObject) {
      //     //check for previously it is on any hotspot, if yes then set that hotspot property available to true so that it become available to add any other image
      //     if(options.target.hotspot == true) {
      //       let oldHotspot = canvas.getObjects().find(obj => obj.id === options.target.hotspotId)
      //       if(oldHotspot) {
      //         oldHotspot.set({
      //           available: true
      //         })
      //       }
      //     }
      //     //check for hotspotObject available - if yes then only can snap the image inside the hotspot else it will on the hotspot but not snapped.
      //     if(hotspotObject.available) {
      //       hotspotObject.bringToFront()
      //       options.target.set({
      //           left: hotspotObject.left,
      //           top: hotspotObject.top,
      //           scaleX: (hotspotObject.width -1)/ options.target.width,
      //           scaleY: (hotspotObject.height - 1)/ options.target.height,
      //           hotspot: true,
      //           hotspotId: hotspotObject.id,
      //           opacity: 1
      //       })
      //       options.target.setCoords()
      //       hotspotObject.set({
      //         available: false
      //       })

      //     } else {
      //       options.target.set({
      //         opacity: 1
      //       })
      //     }
      //     this.moveSelectedObjectsOnStack(true)
      //   } else {
      //     //if pointer not lies on any hotspot
      //     //check for previously it is on any hotspot, if yes then set that hotspot property available to true so that it become available to add any other image
      //     if(options.target != null && options.target.type === 'articleImage' ) {
      //       if(options.target.hotspot == true) {
      //         let oldHotspot = canvas.getObjects().find(obj => obj.id === options.target.hotspotId)
      //         if(oldHotspot) {
      //           oldHotspot.set({
      //             available: true
      //           })
      //         }
      //       }
      //       //set selected object hotspot info to default
      //       options.target.set({
      //         hotspot: false,
      //         hotspotId: '',
      //         opacity: 1
      //       })
      //     }
      //   }
      // }

      // //check for weather after completing the snapping hotspots should be visible or not depends on show hotspots and hotspots editable or not
      // if(!this.showHotspots && !this.hotspotObjectsSelectable) {
      //   let allObjs = canvas.getObjects()
      //   for(let i = 0 ; i<allObjs.length; i++) {
      //     if(allObjs[i].type === 'hotspot' && allObjs[i].visible == true) {
      //       allObjs[i].set('visible', false)
      //     }
      //   }
      // }
    })
  }

  private handleOnWheel() {
    // handel zoom and pan by track pad
    this.canvas.on('mouse:wheel', (opt) => {
      if (opt.e.ctrlKey) {
        this.handleZooming(opt.e)
      }
    })
  }

  // Handle the ability to zoom in and out of the board
  private handleZooming(event: WheelEvent) {
    event.preventDefault()
    event.stopPropagation()
    const delta = event.deltaY
    let zoom = this.canvas.getZoom()
    zoom *= 0.999 ** delta
    if (zoom > 20) { zoom = 20 }
    if (zoom < 0.01) { zoom = 0.01 }
    this.eventBus.zoom.emit({ event: 'zoom', factor: zoom, point: { x: event.offsetX, y: event.offsetY } })
  }

  private handleDrop() {
    this.canvas.on('drop', () => {
      // const  draggedArticleId = options.e.dataTransfer.getData('article')
      // if(utils.isDefined(draggedArticleId)) {
      //   const article = this.catalogData.getArticleById(draggedArticleId)
      //   if(utils.isDefined(article)) {
      //     if( options.target != null && options.target.type === 'lineArchitectureArticlePlaceHolder' ) {
      //       //replace the lineArchitectureArticlePlaceHolderObject with the article object and add the article details as well.
      //       let lineArchitectureArticlePlaceHolderObject = options.target
      //       let newOptions = {
      //         left: lineArchitectureArticlePlaceHolderObject.left,
      //         top: lineArchitectureArticlePlaceHolderObject.top,
      //         scaleX: lineArchitectureArticlePlaceHolderObject.scaleX,
      //         scaleY: lineArchitectureArticlePlaceHolderObject.scaleY
      //       }
      //       this.activeSlide.addCanvasArticleImage(article.ArticleId, newOptions, true, true, false)
      //       //remove the placeholder object
      //       this.activeSlide.remObjects([lineArchitectureArticlePlaceHolderObject], true)
      //     } else {
      //       this.activeSlide.addCanvasArticleImage(article.ArticleId, null, true, true, false)
      //     }
      //   }
      // }
    })
  }

  private handleDragOver() {
    this.canvas.on('dragover', () => {
      // if target is lineArchitectureArticlePlaceHolder then i need to highlight it
      // const allObjects = this.canvas.getObjects()
      // for (let index = 0; index < allObjects.length; index++) {
      //   if (allObjects[index].type === 'lineArchitectureArticlePlaceHolder') {
      //     if (option.target != null && option.target.type === 'lineArchitectureArticlePlaceHolder' && option.target.id === allObjects[index].id) {
      //       option.target.set({
      //         stroke: '#45A515',
      //       })
      //     }
      //     else {
      //       allObjects[index].set({
      //         stroke: '#5a5a5a',
      //       })
      //     }
      //   }
      // }
      // this.canvas.renderAll()
    })
  }

  private handleSelectionCreation() {
    this.canvas.on('selection:created', () => {
      // const group = this.canvas.getActiveObject()
      // if (group && group.type === 'activeSelection') {
      //   group.forEachObject(obj => {
      //     if (obj.locked) {
      //       group.set('lockMovementX', true)
      //       group.set('lockMovementY', true)
      //       group.set('lockRotation', true)
      //       group.set('lockScalingFlip', true)
      //       group.set('lockScalingX', true)
      //       group.set('lockScalingY', true)
      //       group.set('hasControls', false)
      //     }
      //   })
      // }
    })
  }

  private handleBg(color) {
    this.canvas.setBackgroundColor(color, () => {
      setTimeout(() => this.canvas.requestRenderAll(), 500)
    })
  }

  private makeActiveSlideDirty() {
    this.isDirty = true
    if (this.activeSlide?.SlideId) {
      this.activeSlide.isDirty = true
      this.eventBus['slide-dirty'].emit({ slideId: this.activeSlide.SlideId, value: true })
    }
  }

  private setSlideDirtyFlag(slideId: string, value: boolean = true) {
    this.isDirty = true
    if (this.merchSlides.value[slideId]) {
      this.merchSlides.value[slideId].isDirty = value
      this.eventBus['slide-dirty'].emit({ slideId, value })
    }
  }

  private handleObjectEvents() {
    // this.canvas.on('object:added', (opt) => {
    //   if (opt.target) {
    //     this.eventBus['object-added'].emit({ target: opt.target })
    //   }
    // })
    this.canvas.on('text:changed', () => {
      // on text change make the slide dirty
      this.makeActiveSlideDirty()
    })
    this.canvas.on('object:modified', async (opt) => {
      if (opt.target) {
        const mbObject = opt.target as IMbObject
        // // skipping the mask rect to go to history
        // if(mbObject.mask && mbObject.mask === true) {
        //   return
        // }
        const objs = this.canvas.getActiveObjects()
        this.canvas.discardActiveObject()
        const sel = new fabric.ActiveSelection(objs, { canvas: this.canvas })
        sel.setObjectsCoords()
        sel.setCoords()
        this.canvas.setActiveObject(sel)
        this.canvas.requestRenderAll()

        //         //Removing this.isloading check as when we click on slide we are setting isLoading to true, it will never be added to th history. when Removing the object we are directly adding to history and isLoading is reset.
        // //So, its not required, whenever we do any changes to object it should be added to history
        // if (!this.activeSlide) {
        //   return
        // }
        // if(util.isDefined(e.target) && e.target._objects && e.target._objects.length) {
        //   let textTypeObjects = e.target._objects.filter(object => object.type === 'articleDetails' ||  object.type === 'textbox')
        //   if(util.isDefined(textTypeObjects)) {
        //     textTypeObjects.forEach( object => {
        //       object.set({
        //         fontSize: (object.fontSize * object.scaleX).toFixed(0),
        //         width: (object.width * object.scaleX),
        //         scaleX: 1,
        //         scaleY: 1
        //       })
        //     })
        //   }
        // }
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        if (!opt.ignoreHistory) {
          this.addToHistory('mod', mbObject)
          // if(wbObject.type === whiteboardConstants.objectTypes.frame) {
          //   const wbFrame = wbObject as WbFrame
          //   if(wbFrame.movingChangedObjsMap.size > 0) {
          //     const allObjs = this.canvas.getObjects() as Array<IMbObject>
          //     const wbObjects: IMbObject[] = [wbObject]
          //     allObjs.forEach(obj => {
          //       if(obj.id && wbFrame.movingChangedObjsMap.has(obj.id)) {
          //         wbObjects.push(obj)
          //       }
          //     })
          //     this.addToHistory('mod', wbObjects)
          //   } else {
          //     this.addToHistory('mod', wbObject)
          //   }
          // } else {
          // this.addToHistory('mod', mbObject)
          // }
        }
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        if (opt.articleStatusUpdated) {
          // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
          const statusValue = opt.statusValue
          if (this.activeSlide) {
            await this.activeSlide.updateUnavailableArticleCount()
          }
          this.eventBus['object-modified'].emit({ target: opt.target, countOperation: statusValue === 1 ? 'remove' : 'add' })
        }
        else {
          if (!utils.isDefined(mbObject) || !mbObject.type || (mbObject.type !== 'discussionOutline' && mbObject.type !== 'discussionIcon')) {
            this.makeActiveSlideDirty()
          }
          this.eventBus['object-modified'].emit({ target: opt.target })
        }
      }
    })

    // this.canvas.on('object:removed', (opt) => {
    //   if (opt.target) {
    //     this.eventBus['object-removed'].emit({ target: opt.target })
    //   }
    // })

    this.canvas.on('object:moving', (options) => {
      if (options.target) {
        // check hotspots when selected  object is article image and is moved in canvas
        // if (options.target.type === 'articleImage') {
        // // double click event triggers mouseUp event as well, to avoid the mouseUp event added this property . Now, mouse up will work only is article image is moved ie if this proprty is true
        //   this.articleImageMoved = true
        //   options.target.setCoords()
        //   const canvasObj = this.canvas.getObjects()

        //   // set hotspots visiblitly true when move object , check if already true or not
        //   if (!this.showHotspots && !this.hotspotObjectsSelectable) {
        //     for (let i = 0; i < canvasObj.length; i++) {
        //       if (canvasObj[i].type === 'hotspot' && canvasObj[i].visible == false) {
        //         canvasObj[i].set('visible', true)
        //         canvasObj[i].set('selectable', false)
        //         canvasObj[i].lockObject(true)
        //       }
        //     }
        //   }
        //   options.target.set({
        //     opacity: 0.5,
        //   })
        //   this.moveSelectedObjectsOnStack(true)
        //   if (!options.e.ctrlKey) {
        //     const allObjects = this.canvas.getObjects()
        //     for (let index = 0; index < allObjects.length; index++) {
        //       if (allObjects[index].type === 'hotspot') {
        //         const hotspot = allObjects[index]
        //         const pointerX = this.canvas.getPointer(event).x
        //         const pointerY = this.canvas.getPointer(event).y
        //         const hotspotLeft = hotspot.left - hotspot.width / 2
        //         const hotspotRight = hotspotLeft + hotspot.width
        //         const hotspotTop = hotspot.top - hotspot.height / 2
        //         const hotspotBottom = hotspotTop + hotspot.height
        //         if (pointerX > hotspotLeft && pointerX < hotspotRight && pointerY > hotspotTop && pointerY < hotspotBottom) {
        //           allObjects[index].set({
        //             stroke: 'rgb(18,109,207)',
        //           })
        //         }
        //         else {
        //           allObjects[index].set({
        //             stroke: 'rgb(203,203,203)',
        //           })
        //         }
        //       }
        //       else if (allObjects[index].type === 'lineArchitectureArticlePlaceHolder') {
        //         const lineArchitectureArticlePlaceHolder = allObjects[index]
        //         const pointerX = this.canvas.getPointer(event).x
        //         const pointerY = this.canvas.getPointer(event).y
        //         const width = lineArchitectureArticlePlaceHolder.width * lineArchitectureArticlePlaceHolder.scaleX
        //         const height = lineArchitectureArticlePlaceHolder.height * lineArchitectureArticlePlaceHolder.scaleY
        //         const placeholderLeft = lineArchitectureArticlePlaceHolder.left - width / 2
        //         const placeholderRight = placeholderLeft + width
        //         const placeholderTop = lineArchitectureArticlePlaceHolder.top - height / 2
        //         const placeholderBottom = placeholderTop + height
        //         if (pointerX > placeholderLeft && pointerX < placeholderRight && pointerY > placeholderTop && pointerY < placeholderBottom) {
        //           allObjects[index].set({
        //             stroke: '#45A515',
        //           })
        //         }
        //         else {
        //           allObjects[index].set({
        //             stroke: '#5a5a5a',
        //           })
        //         }
        //       }
        //     }
        //   }
        //   this.canvas.renderAll()
        // }

        // if (this.snapToGrid) {
        //   options.target.set({
        //     left: Math.round(options.target.left / this.gridSize) * this.gridSize,
        //     top: Math.round(options.target.top / this.gridSize) * this.gridSize
        //   })
        // }
        this.eventBus['object-moving'].emit({ target: options.target })
      }
    })
    this.canvas.on('object:scaling', () => {
      // this.removeInfoIconIfRequired(e)
      // const obj = e.target
      // // avoid mask rectangle to grow bigger than target object
      // if(obj.mask && obj.mask === true) {
      //   const maskRectangle = obj
      //   maskRectangle.setCoords()
      //   let boundingRectNew = maskRectangle.getBoundingRect(true)
      //   // if mask leak out of target object then apply cached scale properties else
      //   if(this.scalingProperties &&
      //   (Math.round(boundingRectNew.left) < Math.ceil(maskRectangle.refImage.aCoords.tl.x) ||
      //   Math.round(boundingRectNew.top) < Math.ceil(maskRectangle.refImage.aCoords.tl.y) ||
      //   Math.round(boundingRectNew.left + boundingRectNew.width) > Math.ceil(maskRectangle.refImage.aCoords.br.x) ||
      //   Math.round(boundingRectNew.top + boundingRectNew.height) > Math.ceil(maskRectangle.refImage.aCoords.br.y))) {
      //     maskRectangle.left = this.scalingProperties.left
      //     maskRectangle.top = this.scalingProperties.top
      //     maskRectangle.scaleX = this.scalingProperties.scaleX
      //     maskRectangle.scaleY = this.scalingProperties.scaleY
      //     maskRectangle.width = this.scalingProperties.width
      //     maskRectangle.height = this.scalingProperties.height
      //   } else {
      //     this.scalingProperties = {
      //       left: maskRectangle.left,
      //       top: maskRectangle.top,
      //       scaleX: maskRectangle.scaleX,
      //       scaleY: maskRectangle.scaleY,
      //       width: maskRectangle.width,
      //       height: maskRectangle.height
      //     }
      //   }
      // }
    })
  }

  // removeInfoIconIfRequired(e) {
  //   const objects = this.canvas.getObjects()
  //   for (let i in objects) {
  //     if(objects[i].type === 'image' && objects[i].hasOwnProperty('isIcon') && objects[i].isIcon) {
  //       this.canvas.remove(objects[i])
  //     }
  //   }
  // }
  /**
   * Resizes the canvas
   *
   * @param width Width of canvas
   * @param height Height of canvas
   */
  setSize(width: number, height: number) {
    this.canvas.setWidth(width)
    this.canvas.setHeight(height)
    this.canvas.calcOffset()
  }

  addToHistory(op: 'add' | 'rem' | 'mod' | 'group' | 'ungroup', obj: IMbObject | IMbObject[]) {
    const maxUndoHistory = 10

    if (this.historyPointer.value > 0) {
      for (let index = 0; index < this.historyPointer.value; index++) {
        this.history.value.pop()
      }
      this.historyPointer.value = 0
    }
    else {
      this.historyPointer.value = 0
    }
    if (this.history.value.length >= maxUndoHistory) { this.history.value.shift() }

    if (obj && !Array.isArray(obj) && obj.type === 'activeSelection') {
      // eslint-disable-next-line ts/ban-ts-comment
      // @ts-expect-error
      obj = obj.getObjects()
    }
    const ao = this.canvas.getActiveObjects()
    this.canvas.discardActiveObject()

    if (op === 'group') {
      const states: { org: string, cur: string }[] = []
      this.history.value.push({ op, obj, state: states })
    }
    else if (op === 'ungroup') {
      const states: { org: string, cur: string }[] = []
      this.history.value.push({ op, obj, state: states })
    }
    else if (Array.isArray(obj)) {
      const states: { org: string, cur: string }[] = []
      const simpleObjects: any[] = []

      obj.forEach((itm: IMbObject) => {
        const org = JSON.stringify(utils.getStateProperties(itm))
        itm.saveState()
        const cur = JSON.stringify(utils.getStateProperties(itm))
        if (op === 'mod') { states.push({ org, cur }) }
        simpleObjects.push(itm.toObject())
      })

      this.history.value.push({ op, obj: simpleObjects, state: op === 'mod' ? states : undefined })
    }
    else if (obj) {
      const org = JSON.stringify(utils.getStateProperties(obj))
      obj.saveState()
      const cur = JSON.stringify(utils.getStateProperties(obj))
      this.history.value.push({ op, obj: obj.toObject(), state: op === 'mod' ? { org, cur } : undefined })
    }

    this.history.value = [...this.history.value]

    if (ao && ao.length > 0) {
      if (ao.length === 1) {
        this.canvas.setActiveObject(ao[0])
      }
      else {
        this.canvas.setActiveObject(new fabric.ActiveSelection(ao, { canvas: this.canvas }))
      }
    }
  }

  drawFromHistory(undo: boolean) {
    this.canvas.discardActiveObject()

    const histItem = this.history.value[this.history.value.length - this.historyPointer.value - 1]
    let newSelection: IMbObject | IMbObject[] | null = null

    const allObjs = this.canvas.getObjects() as Array<IMbObject>

    if (histItem.op === 'mod') {
      if (Array.isArray(histItem.obj)) {
        newSelection = []
        for (let index = 0; index < histItem.obj.length; index++) {
          const mbObj = allObjs.find(itm => itm.id === histItem.obj[index].id)
          if (mbObj && histItem.state && Array.isArray(histItem.state)) {
            mbObj.setOptions(JSON.parse(undo ? histItem.state[index].org : histItem.state[index].cur))
            mbObj.dirty = true
            mbObj.setCoords()
            mbObj.saveState()
            this.canvas.fire('object:modified', { target: mbObj, ignoreHistory: true })
            newSelection.push(mbObj)
          }
          else {
            console.warn('Cannot find element with id', histItem.obj[index].id)
          }
        }
      }
      else {
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        const mbObj = allObjs.find(itm => itm.id === histItem.obj.id)
        if (mbObj && histItem.state && !Array.isArray(histItem.state)) {
          mbObj.setOptions(JSON.parse(undo ? histItem.state.org : histItem.state.cur))
          mbObj.dirty = true
          mbObj.setCoords()
          mbObj.saveState()
          this.canvas.fire('object:modified', { target: mbObj, ignoreHistory: true })
          newSelection = mbObj
        }
        else {
          console.warn('Cannot find element with id', histItem.obj.id)
        }
      }
    }
    else if (histItem.op === 'group' || histItem.op === 'ungroup') {
      if ((histItem.op === 'group' && undo) || (histItem.op === 'ungroup' && !undo)) {
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        const element = allObjs.find(itm => itm.id === histItem.obj.id)
        if (element) {
          this.ungroupObject(element, false)
        }
        else {
          // eslint-disable-next-line ts/ban-ts-comment
          // @ts-expect-error
          console.warn('Cannot find group with id', histItem.obj.id)
        }
      }
      else {
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        const elements = histItem.groupsAllObjects
          .map(obj => allObjs.find(itm => itm.id === obj.id))
          .filter((obj): obj is IMbObject => obj !== undefined)

        this.groupObjects(elements, histItem.obj, false)
      }
    }
    else if ((histItem.op === 'add' && undo) || (histItem.op === 'rem' && !undo)) {
      const objectsToRemove: IMbObject[] = []
      if (Array.isArray(histItem.obj)) {
        histItem.obj.forEach((itm) => {
          const element = allObjs.find(obj => obj.id === itm.id)
          if (element) {
            objectsToRemove.push(element)
          }
          else {
            console.warn('Cannot find element with id', itm.id)
          }
        })
      }
      else {
        // eslint-disable-next-line ts/ban-ts-comment
        // @ts-expect-error
        const element = allObjs.find(obj => obj.id === histItem.obj.id)
        if (element) {
          objectsToRemove.push(element)
        }
        else {
          console.warn('Cannot find element with id', histItem.obj.id)
        }
      }
      this.removeObjects(objectsToRemove)
    }
    else {
      const objectsToAdd: IMbObject[] = []
      if (Array.isArray(histItem.obj)) {
        objectsToAdd.push(...histItem.obj)
      }
      else {
        objectsToAdd.push(histItem.obj)
      }
      this.activeSlide!.createObjects(objectsToAdd, this.showFavorites.value).then((createdObjects) => {
        this.addObjects(createdObjects)
        createdObjects.forEach((object) => {
          this.eventBus['object-modified'].emit({ target: object })
        })
      })
    }

    this.canvas.requestRenderAll()
  }

  // new functions
  async getSharedFolders(catalogCode) {
    return getSharedFolders(catalogCode).then((sharedFolderResponse) => {
      return sharedFolderResponse
    }).catch(() => {
      return { data: [] }
    })
  }

  async loadSharedSlides() {
    const userStore = useUserStore()
    if (userStore.activeCatalog) {
      const sharedFolderResponse = await this.getSharedFolders(userStore.activeCatalog.CatalogCode)
      if (sharedFolderResponse && sharedFolderResponse.data) {
        for (let i = 0; i < sharedFolderResponse.data.length; i++) {
          const sharedFolder = sharedFolderResponse.data[i]
          this.sharedFolders.value[sharedFolder.Id] = sharedFolder
        }
      }
      const sharedSlides = <Record<number, MerchSlide>>({})
      const response = await getSharedSlides(userStore.activeCatalog.CatalogCode)
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          const slide = response.data[i]
          if (slide.Status === 1) {
            const newSlide = new MerchSlide(slide)
            sharedSlides[slide.SlideId] = newSlide
          }
        }
        this.sharedSlides.value = sharedSlides
        this.eventBus['sharedSlides-loaded'].emit()
      }
    }
  }

  async setSharedActiveSlide(slideId: string | number) {
    const userStore = useUserStore()
    this.activeSlide = this.sharedSlides.value[slideId]
    try {
      if (this.activeSlide && userStore.activeCatalog) {
        // if (!this.activeSlide.isDownloaded) {
        const newSlideObjects = await this.activeSlide.getSlideData(userStore.activeCatalog.CatalogCode)
        if (newSlideObjects) {
          // this.activeSlide.initDiscussions()
          await this.drawActiveSlide(this.activeSlide)
          this.makeSharedTemplateObjectsUnSelectable()
          this.eventBus['slide-active'].emit({ target: this.activeSlide })
        }
      }
    }
    catch (error) {
      return Promise.reject(new Error(`Unable to load slide objects\n ${error}`))
    }
  }

  makeSharedTemplateObjectsUnSelectable() {
    this.canvas.discardActiveObject()
    // this.templateObjectsSelectable = false
    const allObjects = this.canvas.getObjects() as IMbObject[]
    if (allObjects && isArray(allObjects) && allObjects.length > 0) {
      allObjects.forEach((obj) => {
        obj.selectable = false
        obj.locked = true
      })
    }
    this.canvas.requestRenderAll()
    // this.events.dispatch('merch:templateobjects', false)
  }

  clearActiveSlide() {
    this.activeSlide = null
  }

  async loadMySlides() {
    const userStore = useUserStore()
    if (userStore.activeCatalog) {
      const mySlides = <Record<number, MerchSlide>>({})
      const myFolderMap = <Record<number, MerchFolder>>({})
      const response = await getMySlides(userStore.activeCatalog.CatalogCode)
      if (response.data) {
        for (let i = 0; i < response.data.length; i++) {
          const slide = response.data[i]
          const currentCustomerId = userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null
          if (slide.Status === 1 && ((currentCustomerId === null && slide.CustomerId == null) || (currentCustomerId === slide.CustomerId))) {
            const newSlide = new MerchSlide(slide)
            await newSlide.updateUnavailableArticleCount()
            mySlides[slide.SlideId] = newSlide
            myFolderMap[slide.FolderId] = {
              FolderName: slide.FolderName,
              FolderIdSortOrder: slide.FolderIdSortOrder,
            }
          }
        }
        this.merchSlides.value = mySlides
        this.merchFolderMap.value = myFolderMap
        this.eventBus['mySlides-loaded'].emit()
      }
    }
  }

  addFolder(folderName: string, parentPath: string, id: string) {
    this.eventBus['add-folder'].emit({ name: folderName, path: parentPath, id })
  }

  async addSlide(folderId: string, folderName: string, folderSortOrder: number, slideName: string, templateObjects: any[], slideSize: string, imageScaleFactor: number, sortOrder: number, isGenerateSlides: boolean = false, articleList: number[] = []) {
    const userStore = useUserStore()
    if (userStore.activeCatalog) {
      const userProfile = userStore.userProfile
      const activeCatalogCode = userStore.activeCatalog!.CatalogCode
      const customerId = userStore.currentCustomer ? userStore.currentCustomer.CustomerId : null
      const articleIdList: number[] = isGenerateSlides ? articleList : []
      if (!isGenerateSlides && templateObjects.length) {
        templateObjects.forEach((object) => {
          if (object.type === merchConstants.objectTypes.articleImage) {
            if (object.articleId != null && !articleIdList.includes(object.articleId)) {
              articleIdList.push(object.articleId)
            }
          }
          else if (object.type === merchConstants.objectTypes.group) {
            const groupObjects = object.objects || object._objects
            groupObjects.forEach((groupedObject) => {
              if (groupedObject.type === merchConstants.objectTypes.articleImage && groupedObject.articleId != null && !articleIdList.includes(groupedObject.articleId)) {
                articleIdList.push(groupedObject.articleId)
              }
            })
          }
        })
      }
      const formData = {
        activeCatalogCode,
        folderId,
        folderName,
        folderSortOrder,
        slideName,
        userProfile,
        customerId,
        slideSize,
        imageScaleFactor,
        sortOrder,
        templateObjects,
        articleList: articleIdList,
      }
      const newSlide = new MerchSlide(null, formData)
      if (!isGenerateSlides) {
        await newSlide.updateUnavailableArticleCount()
      }
      this.merchSlides.value[newSlide.SlideId] = newSlide
      this.merchSlides.value[newSlide.SlideId].isDirty = true
      this.isDirty = true
      this.eventBus['slide-added'].emit({ slide: newSlide })
      return newSlide
    }
  }

  async updateSlide(formModel, selectedSlideId, newFolderDetails) {
    const slideId = selectedSlideId
    if (this.merchSlides.value[slideId]) {
      const merchSlide: MerchSlide = this.merchSlides.value[slideId]
      const updatedKeys: string[] = []
      if (newFolderDetails && newFolderDetails.FolderName !== merchSlide.FolderName) {
        merchSlide.FolderId = newFolderDetails.FolderId
        merchSlide.FolderName = newFolderDetails.FolderName
        merchSlide.FolderIdSortOrder = newFolderDetails.FolderIdSortOrder
        merchSlide.SortOrder = newFolderDetails.SortOrder
        updatedKeys.push('folder')
      }
      if (merchSlide.SlideName !== formModel.SlideName) {
        merchSlide.SlideName = formModel.SlideName
        updatedKeys.push('slideName')
      }
      if (merchSlide.SlideSize !== formModel.SlideSize) {
        merchSlide.SlideSize = formModel.SlideSize
        updatedKeys.push('slidsize')
      }
      if (merchSlide.ImageSize !== formModel.ImageSize) {
        merchSlide.ImageSize = formModel.ImageSize
        updatedKeys.push('imageSize')
      }
      if (merchSlide.BackgroundColor !== formModel.BackgroundColor) {
        merchSlide.BackgroundColor = formModel.BackgroundColor
        updatedKeys.push('backgroundColor')
      }
      if (updatedKeys.length) {
        this.eventBus['update-slide'].emit({ slideId, slideData: merchSlide, updatedKeys })
      }
      if (utils.isDefined(this.activeSlide) && this.activeSlide.SlideId === slideId) {
        this.setActiveSlide(selectedSlideId)
      }
      await this.getSlidesData([selectedSlideId])

      this.setSlideDirtyFlag(merchSlide.SlideId)
    }
  }

  deleteSlides(slideIdList: string[]) {
    slideIdList.forEach((slideId) => {
      if (this.merchSlides.value[slideId]) {
        if (this.merchSlides.value[slideId].isSyncedWithServer) {
          this.deletedSlides.value.push(slideId)
        }
        delete this.merchSlides.value[slideId]
      }
    })
    this.isDirty = true
  }

  async updateSortDetailsOnSlides(slideUpdatedInfo: Record<string, any>, folderSortOrder: number | null, slideSortOrder: number | null, isSortOrderChanged: boolean = false) {
    await this.getSlidesData(Object.keys(slideUpdatedInfo))
    Object.keys(slideUpdatedInfo).forEach((slideId) => {
      if (this.merchSlides.value[slideId]) {
        if (isSortOrderChanged) {
          this.merchSlides.value[slideId].isSortOrderChanged = true
          if (slideSortOrder) {
            this.merchSlides.value[slideId].SortOrder = slideSortOrder
          }
        }
        else {
          this.merchSlides.value[slideId].FolderId = slideUpdatedInfo[slideId].id
          this.merchSlides.value[slideId].FolderName = slideUpdatedInfo[slideId].name
          if (folderSortOrder) {
            this.merchSlides.value[slideId].FolderIdSortOrder = folderSortOrder
          }
          else if (slideSortOrder) {
            this.merchSlides.value[slideId].SortOrder = slideSortOrder
          }
        }
        if (utils.isDefined(this.activeSlide) && this.activeSlide.SlideId === slideId) {
          this.setActiveSlide(slideId)
        }
        this.setSlideDirtyFlag(slideId)
      }
      else {
        console.warn('unable to find the slide, this should never happens')
      }
    })
  }

  cacheExistingSlideData() {
    if (utils.isDefined(this.activeSlide)) {
      // if active slide defined, if dirty cached its objects
      if (this.activeSlide.isDirty && this.activeSlide.isCanvasFullyRendered) {
        try {
          this.cacheActiveSlide()
          this.activeSlide.isCanvasFullyRendered = false
        }
        catch (error) {
          return Promise.reject(new Error(`Unable to catch existing active slide\n ${error}`))
        }
      }
    }
  }

  updateModifiedSlideObjects(slideId, slideObjects) {
    if (this.merchSlides.value[slideId]) {
      this.merchSlides.value[slideId].objects = slideObjects
      this.setSlideDirtyFlag(slideId)
      if (utils.isDefined(this.activeSlide) && this.activeSlide.SlideId === slideId) {
        this.setActiveSlide(slideId, false)
      }
    }
  }

  async renameFolder(slideIdNewFolderNameMap) {
    const slideIds = Object.keys(slideIdNewFolderNameMap)
    await this.getSlidesData(slideIds)
    slideIds.forEach((slideId) => {
      const newfolderName = slideIdNewFolderNameMap[slideId]
      const merchSlide = this.merchSlides.value[slideId]
      if (merchSlide) {
        merchSlide.FolderName = newfolderName
        this.setSlideDirtyFlag(merchSlide.SlideId)
      }
    })
  }

  cacheActiveSlide() {
    if (this.activeSlide && this.merchSlides.value[this.activeSlide.SlideId] && this.activeSlide.isDirty) {
      this.merchSlides.value[this.activeSlide.SlideId].objects = this.canvas.toObject().objects
    }
  }

  async setActiveSlide(slideId: string | number, cacheData = true) {
    const userStore = useUserStore()
    if (this.activeSlide) {
    // if active slide defined, if dirty cached its objects
      if (this.activeSlide.isDirty && this.activeSlide.isCanvasFullyRendered && cacheData) {
        try {
          this.cacheActiveSlide()
          this.activeSlide.isCanvasFullyRendered = false
        }
        catch (error) {
          return Promise.reject(new Error(`Unable to catch existing active slide\n ${error}`))
        }
      }
    }
    this.activeSlide = this.merchSlides.value[slideId]
    try {
      if (this.activeSlide && userStore.activeCatalog) {
        // if (!this.activeSlide.isDownloaded) {
        const newSlideObjects = await this.activeSlide.getSlideData(userStore.activeCatalog.CatalogCode)
        if (newSlideObjects) {
          // this.activeSlide.initDiscussions()
          await this.drawActiveSlide(this.activeSlide)
          this.makeTemplateObjectsSelectable(false)
          this.eventBus['slide-active'].emit({ target: this.activeSlide })
        }
      }
    }
    catch (error) {
      return Promise.reject(new Error(`Unable to load slide objects\n ${error}`))
    }
  }

  async getSlidesData(slideIds: string[], isSharedSlide = false) {
    let newSlides: Record<string, MerchSlide | string> = {}
    const chunks: string[][] = this.splitIntoChunks(slideIds, 30)
    try {
      for (const chunk of chunks) {
        newSlides = await this.processChunk(chunk, newSlides, isSharedSlide) as Record<string, MerchSlide | string>
      }
    }
    catch (err) {
      console.warn(err)
    }
    return newSlides
  }

  splitIntoChunks(data: string[], chunkSize: number) {
    const chunks: string[][] = []
    for (let i = 0; i < data.length; i += chunkSize) {
      chunks.push(data.slice(i, i + chunkSize) as string[])
    }
    return chunks
  }

  async processChunk(currentSlideIds, newSlides, isSharedSlide = false) {
    const userStore = useUserStore()
    return new Promise((resolve) => {
      const promises: Promise<any>[] = []
      for (let i = 0; i < currentSlideIds.length; i++) {
        const slideId = currentSlideIds[i]
        try {
          const merchSlide = isSharedSlide ? this.sharedSlides.value[slideId] : this.merchSlides.value[slideId]
          if (merchSlide && userStore.activeCatalog) {
            promises.push(merchSlide.getSlideData(userStore.activeCatalog.CatalogCode).then(() => {
              newSlides[slideId] = isSharedSlide ? this.sharedSlides.value[slideId] : this.merchSlides.value[slideId]
            }).catch(() => {
              newSlides[slideId] = 'TxError'
            }))
          }
        }
        catch (error) {
          console.warn(new Error(`Unable to load slide objects\n ${error}`))
        }
      }
      Promise.all(promises).then(() => {
        resolve(newSlides)
      })
    })
  }

  updateCanvasBackgroundColorAndDiamentions(merchSlide: MerchSlide) {
    this.handleBg(merchSlide.BackgroundColor)
    this.canvasWidth = merchConstants.slideSizeCanvasDimensions[merchSlide.SlideSize].width
    this.canvasHeight = merchConstants.slideSizeCanvasDimensions[merchSlide.SlideSize].height
    // this.canvas.backgroundColor = merchSlide.backgroundColor ? merchSlide.backgroundColor : this.canvas.backgroundColor

    // set the canvas width and height as per slideSize and zoom level
    this.canvas.setWidth(merchConstants.slideSizeCanvasDimensions[merchSlide.SlideSize].width * this.canvas.getZoom())
    this.canvas.setHeight(merchConstants.slideSizeCanvasDimensions[merchSlide.SlideSize].height * this.canvas.getZoom())

    // set the values of canvas on merch for zoom functionality need to zoom canvas according to current canvas width and height
    // this.canvasWidth = merchConstants.slideSizeCanvasDimensions[this.slideSize].width
    // this.canvasHeight = merchConstants.slideSizeCanvasDimensions[this.slideSize].height
  } // })

  async drawActiveSlide(merchSlide: MerchSlide, addToHistory = false) {
    this.canvas.renderOnAddRemove = false
    this.canvas.clear()
    this.updateCanvasBackgroundColorAndDiamentions(merchSlide)

    const newObjects: fabric.Object[] = await merchSlide.createObjects(merchSlide.objects, this.showFavorites.value)
    this.addObjects(newObjects, addToHistory)
    this.canvas.renderOnAddRemove = true
    this.activeSlide!.isCanvasFullyRendered = true
  }

  /**
   * Saves the current Merchandising Boards to server
   *
   * @returns A promise that resolves when the data is successfully saved
   */
  save(catalogCode: number, customerId: number | null) {
    return new Promise<Record<string, any>>((resolve) => {
      if (this.activeSlide && this.activeSlide.isDirty) {
        // if active slide defined, if dirty cached its objects to temporary file and release the memory by making objects variable empty, else only release the memory by making objects variable empty
        this.cacheActiveSlide()
        this.activeSlide.isCanvasFullyRendered = false
      }
      const promises: Promise<any>[] = []
      const failedSlides: number[] = []
      const updateSortOrders: any[] = []
      const merchSlidesKeys = Object.keys(this.merchSlides.value)
      // Add deleted sides code here, first sync the deleted slides then  push the dirty slides and then udate hte sort order changes to the server
      for (let i = 0; i < merchSlidesKeys.length; i++) {
        const slide = this.merchSlides.value[merchSlidesKeys[i]]
        if (slide.isDirty) {
          const slideContent = JSON.stringify({ slidesData: slide.objects, slideSortOrder: slide.SortOrder, folderSortOrder: slide.FolderIdSortOrder })
          const indicators = JSON.stringify({ ARIndicator: slide.addARIndicator, GHLIndicator: slide.addGHLIndicator, BAndTIndicator: slide.addBAndTIndicator })
          const requestObject: Record<string, any> = {
            SlideId: slide.SlideId,
            SlideName: slide.SlideName,
            FolderId: slide.FolderId,
            FolderName: slide.FolderName,
            Content: slideContent,
            ArticleList: JSON.stringify(slide.ArticleList),
            CustomerId: customerId,
            ImageSize: slide.ImageSize,
            SlideSize: slide.SlideSize,
            FlagIndicators: indicators,
            BackgroundColor: slide.BackgroundColor,
            SlideUpdatedDate: slide.UpdatedDate,
            SlideCreatedDate: slide.CreatedDate,
            LinkedArticles: slide.LinkedArticles,
            // fix to support the sort order upto 8 decimal point on server rounding off to 8 digit the sortOrder
            SortOrder: Math.round(slide.SortOrder * 100000000) / 100000000 || 0,
            FolderIdSortOrder: Math.round(slide.FolderIdSortOrder * 100000000) / 100000000 || 0,
          }
          promises.push(createUpdateMerchSlide(catalogCode, requestObject).then(() => {
            this.merchSlides.value[slide.SlideId].isDirty = false
            this.merchSlides.value[slide.SlideId].isSyncedWithServer = true
          }).catch(() => {
            failedSlides.push(slide.SlideId)
          }))
        }
        else if (slide.isSortOrderChanged) {
          updateSortOrders.push({ SlideId: slide.SlideId, SortOrder: slide.SortOrder, FolderIdSortOrder: slide.FolderIdSortOrder, CustomerId: customerId, FolderId: slide.FolderId })
        }
      }
      if (updateSortOrders.length) {
        updateSlidesSortOrders(catalogCode, updateSortOrders)
          .then(() => {
            for (let index = 0; index < updateSortOrders.length; index++) {
              if (this.merchSlides.value[updateSortOrders[index]]) {
                this.merchSlides.value[updateSortOrders[index]].isSortOrderChanged = false
              }
            }
          })
          .catch(() => {
            console.warn('unable to change sort orders of slides')
          })
      }
      Promise.all(promises)
        .then(() => {
          this.eventBus['slides-saved'].emit()
          if (this.deletedSlides.value.length) {
            const requestObject: any = []
            for (let i = 0; i < this.deletedSlides.value.length; i++) {
              requestObject.push({ SlideId: this.deletedSlides.value[i], Status: 0, CustomerId: customerId })
            }
            deleteSlide(catalogCode, requestObject).then(() => {
              this.deletedSlides.value = []
              this.isDirty = false
              resolve({ failedSlides, deletedSlides: this.deletedSlides.value })
            }).catch(() => {
              if (failedSlides.length) {
                resolve({ failedSlides, deletedSlides: this.deletedSlides.value })
              }
              else {
                this.isDirty = false
                resolve({ failedSlides, deletedSlides: this.deletedSlides.value })
              }
            })
          }
          else {
            if (failedSlides.length) {
              resolve({ failedSlides, deletedSlides: this.deletedSlides.value })
            }
            else {
              this.isDirty = false
              resolve({ failedSlides, deletedSlides: this.deletedSlides.value })
            }
          }
        })
    })
  }

  async shareSlides(catalogCode, customerId, slideIds: (string | number)[], selectedUsers: sharedUserModel[], selectedUserGroups: sharedUserGroupModel[], selectedSharedFolders: sharedFolderModel[]) {
    return await this.save(catalogCode, customerId).then(async (response) => {
      // if synced with server failed
      if (response.failedSlides.length === 0) {
        const requestObject = {
          SlideIds: slideIds,
          UserIds: selectedUsers.map(user => user.Id),
          AccountGroupIds: selectedUserGroups.map(user => user.Id),
          SharingFolderIds: selectedSharedFolders.map(folder => folder.Id),
          CustomerId: customerId,
        }
        return await shareSlide(catalogCode, requestObject).then(() => {
          this.updateMerchSlideSharedDetails(slideIds, selectedUsers, selectedUserGroups, selectedSharedFolders)
          return ''
        }).catch(() => {
          return 'Tx-ShareSlideFailed'
        })
      }
      else {
        return 'Tx-SlidesSaveFailed'
      }
    }).catch(() => {
      return 'Tx-SlidesSaveFailed'
    })
  }

  updateMerchSlideSharedDetails(slideIds: (string | number)[], selectedUsers: sharedUserModel[], selectedUserGroups: sharedUserGroupModel[], selectedSharedFolders: sharedFolderModel[]) {
    slideIds.forEach((slideId) => {
      if (this.merchSlides.value[slideId]) {
        this.merchSlides.value[slideId].SharedUsers = selectedUsers
        this.merchSlides.value[slideId].SharedUsersGroups = selectedUserGroups
        this.merchSlides.value[slideId].SharedFolders = selectedSharedFolders
      }
    })
    this.eventBus['slide-shared-changed'].emit({ slideIds, users: selectedUsers, groups: selectedUserGroups })
  }

  async addArticleImageToCanvas(catalogArticles: MyArticle[], requestArticles?: Article[]) {
    const userStore = useUserStore()
    if (this.activeSlide && userStore.activeCatalog) {
      for (let index = 0; index < catalogArticles.length; index++) {
        // find favcolorlist of the articles
        const favColorList = catalogArticles[index]._FavoriteTags.map(tag => tag.Color)
        const obj: any = await MbArticleImage.loadArticleImage(catalogArticles[index], null, this.activeSlide.ImageSize, this.showFavorites.value, favColorList)
        obj.left = (((obj.width || 0) * (obj.scaleX || 0)) / 2) + 10 + (index * 10)
        obj.top = (((obj.height || 0) * (obj.scaleY || 0)) / 2) + 10 + (index * 10)
        this.addObjects([obj], true)
        await this.activeSlide.updateArticleList(catalogArticles[index].Id)
        // if configuredAarticleDetailsDefaultAttributes configured add article details and group them
        if (userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes.length !== 0) {
          const opt = { left: obj.aCoords!.bl.x, top: obj.aCoords!.bl.y, showLabels: true, customOptions: { articleProps: userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes } }
          if (obj.articleId) {
            const articleDetails = await MbArticleDetails.loadArticleDetailsById(obj.articleId, obj.objectId, obj.isRequest, opt)
            if (articleDetails) {
              this.addObjects([articleDetails], true)
              // this.groupObjects([obj, articleDetails])
            }
          }
        }
      }
      if (requestArticles) {
        for (let index = 0; index < requestArticles.length; index++) {
          const obj: any = await MbArticleImage.loadArticleImage(requestArticles[index], null, this.activeSlide.ImageSize)
          obj.left = (((obj.width || 0) * (obj.scaleX || 0)) / 2) + 10 + (index * 10)
          obj.top = (((obj.height || 0) * (obj.scaleY || 0)) / 2) + 10 + (index * 10)
          this.addObjects([obj], true)
          await this.activeSlide.updateArticleList(requestArticles[index].Id)
          // if configuredAarticleDetailsDefaultAttributes configured add article details and group them
          if (userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes.length !== 0) {
            const opt = { left: obj.aCoords!.bl.x, top: obj.aCoords!.bl.y, showLabels: true, customOptions: { articleProps: userStore.activeCatalog.Config.MerchDefaultArticleDetailsAttributes } }
            if (obj.articleId) {
              const articleDetails = await MbArticleDetails.loadArticleDetailsById(obj.articleId, obj.objectId, obj.isRequest, opt)
              if (articleDetails) {
                this.addObjects([articleDetails], true)
                // this.groupObjects([obj, articleDetails])
              }
            }
          }
        }
      }
      this.makeActiveSlideDirty()
    }
  }

  insertObjects(key: string, image?) {
    switch (key) {
      case 'text': {
        const obj = new MbTextBox('Enter Text Here')
        this.addObjects([obj], true)
        break
      }
      case 'rect': {
        const obj = new MbRectangle({ width: 100, height: 100, left: 60, top: 60 })
        this.addObjects([obj], true)
        break
      }
      case 'triangle': {
        const obj = new MbTriangle({ width: 100, height: 100, left: 60, top: 60 })
        this.addObjects([obj], true)
        break
      }
      case 'circle': {
        const obj = new MbCircle({ radius: 50, left: 60, top: 60 })
        this.addObjects([obj], true)
        break
      }
      case 'line': {
        const obj = new MbLine({ width: 100, left: 60, top: 10 })
        this.addObjects([obj], true)
        break
      }
      case 'image': {
        const obj = new MbImage(image)
        obj.left = (((obj.width || 0) * (obj.scaleX || 0)) / 2) + 10
        obj.top = (((obj.height || 0) * (obj.scaleY || 0)) / 2) + 10
        this.addObjects([obj], true)
      }
    }
    this.makeActiveSlideDirty()
  }

  // merch slide object actions
  addObjects(newObjects: fabric.Object[], addToHistory: boolean = false, selected: boolean = false) {
    // todo
    if (addToHistory) {
      const mbObjects: IMbObject[] = newObjects as IMbObject[]
      this.addToHistory('add', mbObjects)
    }
    newObjects.forEach((object) => {
      this.canvas.add(object)
      object.saveState()
    })

    if (selected) {
      const selection = new fabric.ActiveSelection(newObjects, { canvas: this.canvas })
      this.canvas.setActiveObject(selection)
    }

    this.canvas.requestRenderAll()
    this.eventBus['object-added'].emit({ slideId: this.activeSlide!.SlideId })
  }

  /**
   * @description a canvas utility that return similar objects
   * @param targetedObjects a fabric object or list of fabric objects
   */
  getSimilarObjects(targetedObjects: Array<fabric.Object> | fabric.Object) {
    const canvasObjects = this.canvas.getObjects()
    if (!canvasObjects.length || !targetedObjects) {
      return []
    }

    if (!isArray(targetedObjects)) {
      targetedObjects = [targetedObjects]
    }
    this.canvas.discardActiveObject()
    const matchingObjects = canvasObjects.filter(canvasObject => (targetedObjects as Array<fabric.Object>).find(targetedObject => targetedObject.type === canvasObject.type))
    if (matchingObjects.length > 0) {
      const selection = new fabric.ActiveSelection(matchingObjects, { canvas: this.canvas })
      this.canvas.setActiveObject(selection)
      this.canvas.requestRenderAll()
    }
  }

  async removeObjects(objects: IMbObject[], addToHistory: boolean = false) {
    if (addToHistory) {
      this.addToHistory('rem', objects.length > 1 ? objects : objects[0])
    }
    // todo if disussion objects are implemented nee to add support and hotspot also need to be checked
    objects.forEach((object) => {
      this.canvas.remove(object)
    })
    // after deleting the objects re-calculate articleIdList
    const canvasObjectsAfterDelete = this.canvas.getObjects() as Array<IMbObject>
    const articleIdList: number[] = []
    canvasObjectsAfterDelete.forEach((canvasObject) => {
      if (canvasObject.type === 'articleImage' && canvasObject.articleId !== null && !articleIdList.includes(canvasObject.articleId!)) {
        articleIdList.push(canvasObject.articleId!)
      }
      // else if (canvasObject.type === 'group') {
      //   const groupObjects = canvasObject.objects || canvasObject._objects
      //   groupObjects.forEach((groupedObject) => {
      //     if (groupedObject.type === 'articleImage' && !this.articleIdList.includes(groupedObject.articleId)) {
      //       this.articleIdList.push(groupedObject.articleId)
      //     }
      //   })
      // }
    })
    await this.activeSlide?.reAssignArticleList(articleIdList)
    this.makeActiveSlideDirty()
    this.canvas.discardActiveObject().requestRenderAll()
    this.eventBus['object-removed'].emit({ slideId: this.activeSlide!.SlideId })
    // merch.events.dispatch('slideObjects:deleted', { ownerId: this.folder.sharedOwner ? this.folder.sharedOwner.id : null, folderId: this.folder.id, slideId: this.id, objects, isGrouping })
  }

  /**
   * Selects all the objects on the slide
   *
   * @returns New active selection or null if no objects found
   */
  selectAll(canSelectTemplateObject = false, canSelectHotspotObject = false) {
    this.canvas.discardActiveObject()
    let allObjects = this.canvas.getObjects() as Array<IMbObject>
    if (canSelectTemplateObject) {
      allObjects = allObjects.filter(canvasObject => canvasObject.templateObject && canvasObject.templateObject === true && canvasObject.type !== 'hotspot')
    }
    else {
      // if hotspots are editable then only hotspots should be selected else ignore hotspots objects
      if (canSelectHotspotObject) {
        allObjects = allObjects.filter(canvasObject => canvasObject.type === 'hotspot')
      }
      else {
        allObjects = allObjects.filter(canvasObject => ((canvasObject.templateObject == null || canvasObject.templateObject === false) && canvasObject.type !== 'hotspot'))
      }
    }
    if (allObjects && isArray(allObjects) && allObjects.length > 0) {
      const sel = new fabric.ActiveSelection(allObjects, { canvas: this.canvas })
      this.canvas.setActiveObject(sel)
      this.canvas.requestRenderAll()
      return sel
    }
    return null
  }

  // /**
  //  * @description copy the selected object on canvas into merch board's clipboard and will clear the system clipboard
  //  * @returns void
  //  */
  copySelectedObjects() {
    const selectedObject: fabric.Object | null = this.canvas.getActiveObject()
    if (selectedObject && selectedObject.type === 'activeSelection') {
      const activeSelection = selectedObject as fabric.ActiveSelection
      if (activeSelection._objects && activeSelection._objects.length) {
        const copiedObjects: { object: any, relativePosition: { left: number, top: number } }[] = []
        activeSelection._objects.forEach((obj) => {
          const objTop = obj.top ?? 0
          const objLeft = obj.left ?? 0
          copiedObjects.push({ object: obj.toObject(), relativePosition: {
            top: (selectedObject.top || 0) + (objTop - (selectedObject.top || 0)) + (selectedObject.height || 0) / 2,
            left: (selectedObject.left || 0) + (objLeft - (selectedObject.left || 0)) + (selectedObject.width || 0) / 2,
          } })
        })
        const objBlob = new Blob([JSON.stringify(copiedObjects)], { type: 'text/plain' })
        navigator.clipboard.write([new ClipboardItem({ 'text/plain': objBlob })])
      }
    }
    else if (selectedObject) {
      const copiedObjects: { object: any, relativePosition: { left: number, top: number } }[] = []
      copiedObjects.push({ object: selectedObject.toObject(), relativePosition: {
        top: selectedObject.top || 0 + (selectedObject.height || 0) / 2,
        left: selectedObject.left || 0 + (selectedObject.width || 0) / 2,
      } })
      const objBlob = new Blob([JSON.stringify(copiedObjects)], { type: 'text/plain' })
      navigator.clipboard.write([new ClipboardItem({ 'text/plain': objBlob })])
    }
  }

  undo() {
    if (this.historyPointer.value >= this.history.value.length) { return }

    this.drawFromHistory(true)
    this.historyPointer.value++
  }

  redo() {
    if (this.historyPointer.value <= 0) { return }

    this.historyPointer.value--
    this.drawFromHistory(false)
  }

  makeTemplateObjectsSelectable(selectable: boolean) {
    // if (this.templateObjectsSelectable === selectable) { return }

    this.canvas.discardActiveObject()
    this.templateObjectsSelectable = selectable
    const allObjects: any[] = this.canvas.getObjects()
    if (allObjects && Array.isArray(allObjects) && allObjects.length > 0) {
      allObjects.forEach((obj) => {
        // if object type is a textbox, selectable and editable property depends on templateObject property
        if (obj.type === 'textbox' && obj.hasOwnProperty('editable')) {
          obj.editable! = obj.templateObject === selectable
        }
        obj.selectable = obj.templateObject === selectable
        if (obj.templateObject) {
          obj.locked = !selectable
        }
        else {
          obj.set('opacity', selectable ? 0.5 : 1)
        }
      })
    }
    this.canvas.requestRenderAll()
  }

  showFavoritesOnSlide() {
    if (this.activeSlide) {
      this.showFavorites.value = !this.showFavorites.value
      this.setActiveSlide(this.activeSlide.SlideId)
    }
  }

  async paste() {
    // Read the clipboard data
    const clipboardItems = await navigator.clipboard.read()
    let newObjs: fabric.Object[] = []

    for (const clipboardItem of clipboardItems) {
      for (const type of clipboardItem.types) {
        const blob = await clipboardItem.getType(type)
        if (type === 'text/plain') {
          const text = await blob.text()
          let copiedObjects = text
          try {
            copiedObjects = JSON.parse(text)
          }
          catch (error) {
            console.error('Failed to read clipboard contents: ', error)
          }

          if (Array.isArray(copiedObjects) && copiedObjects.length > 0) {
            const newObjects = copiedObjects.map((objData) => {
              const copiedObject = objData.object
              copiedObject.left = 10 + objData.relativePosition.left
              copiedObject.top = 10 + objData.relativePosition.top
              const resetId = function (o) {
                if (o.hasOwnProperty('id')) {
                  delete o.id
                }
                if (o.hasOwnProperty('objects') && Array.isArray(o.objects)) {
                  o.objects.forEach(itm => resetId(itm))
                }
              }

              resetId(copiedObject)
              return copiedObject
            })

            if (newObjects.length) {
              newObjs = await this.activeSlide!.createObjects(newObjects, this.showFavorites.value)
            }
          }
          else if (utils.isDefined(copiedObjects) && utils.isValidStringValue(copiedObjects)) {
            newObjs.push(new MbTextBox(copiedObjects))
          }
        }
      }
    }

    if (newObjs.length) {
      this.addObjects(newObjs, true, true)
    }
  }

  groupObjects(objects: IMbObject[], groupSpecs?, addToHistory?) {
    if (!objects || !isArray(objects) || objects.length <= 0) {
      return
    }

    this.canvas.discardActiveObject()

    const group = new MbGroup(null, groupSpecs)

    objects.forEach((obj) => {
      if (obj.type !== 'hotspot') {
        group.addWithUpdate(obj)
      }
    })

    group.saveState()
    this.canvas.add(group)
    this.removeObjects(objects, false)
    group.setCoords()

    if (addToHistory) {
      this.addToHistory('group', group as IMbObject)
    }

    this.canvas.setActiveObject(group)
    this.makeActiveSlideDirty()
    return group
  }

  ungroupObject(group, addToHistory = false) {
    if (!group || group.type !== 'group') {
      return
    }

    if (addToHistory) {
      this.addToHistory('ungroup', group)
    }

    let sel = group.toActiveSelection()
    const objects = sel.getObjects()

    // add the ungrouped objects to history
    const histItem = this.history.value[this.history.value.length - this.historyPointer.value - 1]
    histItem.groupsAllObjects = objects

    this.canvas.discardActiveObject()
    objects.forEach((object) => {
      object.saveState()
    })
    sel = new fabric.ActiveSelection(objects, { canvas: this.canvas })
    this.canvas.setActiveObject(sel)
    this.canvas.requestRenderAll()

    this.makeActiveSlideDirty()
    return sel
  }

  /**
   * Aligns the selected objects on the slide
   *
   * @param {('left'|'center'|'right'|'top'|'middle'|'bottom'|'distributeH'|'distributeV'|'distributeHaS'|'distributeVaS')} direction The direction to allign
   */
  alignSelected(direction: string) {
    const ao = this.canvas.getActiveObject()
    const selObjects = this.canvas.getActiveObjects()
    if (ao === null || !selObjects || !isArray(selObjects) || selObjects.length <= 0) { return }

    const boundingBox = ao.getBoundingRect(true, false)

    let cumW = 0
    let cumH = 0
    let spacingH = 0
    let spacingV = 0
    let curPos = 0

    if (direction === 'distributeH' || direction === 'distributeHaS') {
      selObjects.sort((a, b) => (a.left || 0) - (b.left || 0))
      cumW = selObjects.reduce((acc, cur) => acc + cur.getBoundingRect(true, false).width, 0)
      spacingH = ((direction === 'distributeH' ? boundingBox.width : this.canvasWidth) - cumW) / (selObjects.length - 1)
    }
    else if (direction === 'distributeV' || direction === 'distributeVaS') {
      selObjects.sort((a, b) => (a.top || 0) - (b.top || 0))
      cumH = selObjects.reduce((acc, cur) => acc + cur.getBoundingRect(true, false).height, 0)
      spacingV = selObjects.length !== 1 ? ((direction === 'distributeV' ? boundingBox.height : this.canvasHeight) - cumH) / (selObjects.length - 1) : ((direction === 'distributeV' ? boundingBox.height : this.canvasHeight) - cumH)
    }

    selObjects.forEach((obj) => {
      const oBR = obj.getBoundingRect(true, false)
      const aoLeft = ao.left || 0
      const aoTop = ao.top || 0
      const aoWidth = ao.width || 0
      const aoHeight = ao.height || 0
      switch (direction) {
        case 'alignLeft':
          obj.set('left', obj.originX === 'center' ? aoLeft + (oBR.width / 2) : ao.left)
          break
        case 'alignRight':
          obj.set('left', obj.originX === 'center' ? ao.width || 0 + aoLeft - oBR.width / 2 : aoWidth + aoLeft - oBR.width)
          break

        case 'alignCenter':
          obj.set('left', obj.originX === 'center' ? aoLeft + aoWidth / 2 : aoLeft + (aoWidth / 2) - (oBR.width / 2))
          break

        case 'alignTop':
          obj.set('top', obj.originX === 'center' ? aoTop + (oBR.height / 2) : aoTop)
          break

        case 'alignMiddle':
          obj.set('top', obj.originX === 'center' ? aoTop + aoHeight / 2 : aoTop + (aoHeight / 2) - (oBR.height / 2))
          break

        case 'alignBottom':
          obj.set('top', obj.originY === 'center' ? aoHeight + aoTop - oBR.height / 2 : aoHeight + aoTop - oBR.height)
          break

        case 'distributeH':
          obj.set('left', obj.originX === 'center' ? aoLeft + curPos + oBR.width / 2 : aoLeft + curPos)
          curPos += spacingH + oBR.width
          break

        case 'distributeV':
          obj.set('top', obj.originY === 'center' ? boundingBox.top + curPos + oBR.height / 2 : boundingBox.top + curPos)
          curPos += spacingV + oBR.height
          break

        case 'distributeHaS':
          obj.set('left', obj.originX === 'center' ? curPos + oBR.width / 2 : curPos)
          curPos += spacingH + oBR.width
          break

        case 'distributeVaS':
          obj.set('top', obj.originY === 'center' ? curPos + oBR.height / 2 : curPos)
          curPos += spacingV + oBR.height
          break

        default:
          break
      }

      obj.setCoords()
    })

    this.addToHistory('mod', selObjects as IMbObject[])
    this.makeActiveSlideDirty()
    this.canvas.requestRenderAll()
  }

  async replaceTextInSelectedSlides(slideList: MerchSlide[], findWhat: string, replaceWith?: string) {
    if (this.activeSlide && this.activeSlide.isDirty) {
      this.cacheActiveSlide()
    }
    for (let index = 0; index < slideList.length; index++) {
      const slide = slideList[index]
      const merchSlide = this.merchSlides.value[slide.SlideId]
      const objects = merchSlide.objects
      for (let i = 0; i < objects.length; i++) {
        const object = objects[i]
        if (object.type === 'textbox' && object.text.toLowerCase().includes(findWhat.toLowerCase())) {
          object.text = object.text.replace(new RegExp(findWhat, 'gi'), replaceWith)
          this.setSlideDirtyFlag(merchSlide.SlideId)
        }
      }
    }
  }

  indexes(source: string, find: string) {
    const result: number[] = []
    for (let i = 0; i < source.length; ++i) {
      if (source.substring(i, i + find.length).toLowerCase() === find.toLowerCase()) {
        result.push(i)
      }
    }
    return result
  }

  async replaceText(text: string, replaceText: string, slideId: string) {
    const activeObject = this.canvas.getActiveObject()
    const objects: any[] = this.canvas.getObjects()
    const totalObjectsNumber = this.canvas.getObjects().length
    let nextIndex: number | null = null
    let textIndex = 0
    let selectNextObject = true
    let reachedEnd = false
    if (activeObject && activeObject.type === 'textbox') {
      if (activeObject instanceof MbTextBox || activeObject instanceof MbArticleDetails) {
        activeObject.setSelectionStyles({ textBackgroundColor: activeObject.backgroundColor }, activeObject.selectionStart, activeObject.selectionEnd)
        activeObject.insertChars(replaceText, null as unknown as any[], activeObject.selectionStart as number, activeObject.selectionEnd as number)
        // need to add condition if we are replacing we need to do -- from currentSelectedFindNextIndexInCurrentActiveObject
        const indexes = this.indexes(activeObject.text as string, text)
        const currentIndex = this.canvas.getObjects().indexOf(activeObject)
        this.setSlideDirtyFlag(slideId)
        // check weather we traversed all the indexes in current object
        if (indexes.length === 0) {
          // We reached the last item, move to next slide
          if (currentIndex === totalObjectsNumber - 1) {
            // emit to move to new slide -set reset
            reachedEnd = true
          }
          else {
            let found = false
            for (let j = currentIndex + 1; j < totalObjectsNumber; j++) {
              if (objects[j] && objects[j].type === 'textbox') {
                if (objects[j] instanceof MbTextBox || objects[j] instanceof MbArticleDetails) {
                  const indexes = this.indexes(objects[j].text, text)
                  if (indexes.length) {
                    textIndex = indexes[0]
                    nextIndex = j
                    found = true
                    break
                  }
                }
              }
            }
            if (!found) {
              reachedEnd = true
            }
          }
        }
        else {
          selectNextObject = false
          nextIndex = currentIndex
          textIndex = indexes[0]
        }
      }
    }
    if (!reachedEnd) {
      const selectedObject: any = this.canvas.item(nextIndex as number)
      if (selectNextObject) {
        this.canvas.setActiveObject(selectedObject)
      }
      if (selectedObject instanceof MbTextBox || selectedObject instanceof MbArticleDetails) {
        const start = textIndex
        const end = start + text.length
        selectedObject.selectionStart = start
        selectedObject.selectionEnd = end
        selectedObject.setSelectionStyles({ textBackgroundColor: '#FFFACD' }, start, end)
        this.canvas.renderAll()
        return Promise.resolve()
      }
    }
    else {
      try {
        await this.cacheActiveSlide()
        this.eventBus['find-next-reached-end-of-slide'].emit()
      }
      catch (error) {
        return Promise.reject(new Error(`Unable to catch existing active slide\n ${error}`))
      }
    }
  }

  selectCanvasObjectsSearchText(text) {
    const activeObject = this.canvas.getActiveObject()
    const objects: any[] = this.canvas.getObjects()
    const totalObjectsNumber = this.canvas.getObjects().length
    let nextIndex: number | null = null
    let textIndex = 0
    let selectNextObject = true
    let reachedEnd = false
    if (!activeObject) {
      for (let i = 0; i < totalObjectsNumber; i++) {
        if (objects[i].type === 'textbox' || objects[i].type === 'articleDetails') {
          if (objects[i] instanceof MbTextBox || objects[i] instanceof MbArticleDetails) {
            const indexes = this.indexes(objects[i].text, text)
            if (indexes.length) {
              textIndex = indexes[0]
              this.currentSelectedFindNextIndexInCurrentActiveObject = 1
              nextIndex = i
              break
            }
          }
        }
      }
    }
    else {
      if (activeObject instanceof MbTextBox || activeObject instanceof MbArticleDetails) {
        activeObject.setSelectionStyles({ textBackgroundColor: activeObject.backgroundColor }, activeObject.selectionStart, activeObject.selectionEnd)
        const indexes = this.indexes(activeObject.text as string, text)
        const currentIndex = this.canvas.getObjects().indexOf(activeObject)
        // check weather we traversed all the indexes in current object
        if (indexes.length === this.currentSelectedFindNextIndexInCurrentActiveObject) {
          // We reached the last item, move to next slide
          if (currentIndex === totalObjectsNumber - 1) {
            // emit to move to new slide -set reset
            /// need to reset few other thigns as well
            reachedEnd = true
            this.currentSelectedFindNextIndexInCurrentActiveObject = -1
            this.eventBus['find-next-reached-end-of-slide'].emit()
          }
          else {
            let found = false
            for (let j = currentIndex + 1; j < totalObjectsNumber; j++) {
              if (objects[j].type === 'textbox' || objects[j].type === 'articleDetails') {
                if (objects[j] instanceof MbTextBox || objects[j] instanceof MbArticleDetails) {
                  const indexes = this.indexes(objects[j].text as string, text)
                  if (indexes.length) {
                    textIndex = indexes[0]
                    nextIndex = j
                    found = true
                    break
                  }
                }
              }
            }
            if (!found) {
              reachedEnd = true
              this.currentSelectedFindNextIndexInCurrentActiveObject = -1
              this.eventBus['find-next-reached-end-of-slide'].emit()
            }
          }
        }
        else {
          selectNextObject = false
          nextIndex = currentIndex
          this.currentSelectedFindNextIndexInCurrentActiveObject++
          textIndex = indexes[this.currentSelectedFindNextIndexInCurrentActiveObject - 1]
        }
      }
    }
    if (!reachedEnd && nextIndex !== null) {
      const object = this.canvas.item(nextIndex)
      if (object instanceof MbTextBox || object instanceof MbArticleDetails) {
        if (selectNextObject) {
          this.canvas.setActiveObject(object)
        }
        const start = textIndex
        const end = start + text.length
        object.selectionStart = start
        object.selectionEnd = end
        object.setSelectionStyles({ textBackgroundColor: '#FFFACD' }, start, end)
        this.canvas.renderAll()
        this.eventBus['searched-text-highlighted'].emit()
      }
    }
  }

  highlightCanvasObjectsSearchText(findText: string) {
    const canvasObjects = this.canvas.getObjects()
    canvasObjects.forEach((object) => {
      if (object.type === 'textbox' || object.type === 'articleDetails') {
        if (object instanceof MbTextBox || object instanceof MbArticleDetails) {
          const indxs = this.indexes(object.text as string, findText)
          indxs.forEach((index) => {
            const start = index
            const end = start + findText.length
            object.selectionStart = start
            object.selectionEnd = end
            object.setSelectionStyles({ fill: '#228B22', fontStyle: 'italic' }, start, end)
          })
        }
      }
    })
    this.canvas.requestRenderAll()
  }

  async reRenderCurrentSlides() {
    try {
      const userStore = useUserStore()
      if (this.activeSlide && userStore.activeCatalog) {
        const newSlideObjects = await this.activeSlide.getSlideData(userStore.activeCatalog.CatalogCode)
        if (newSlideObjects) {
          await this.drawActiveSlide(this.activeSlide)
          this.makeTemplateObjectsSelectable(false)
        }
      }
    }
    catch (error) {
      return Promise.reject(new Error(`Unable to load slide objects\n ${error}`))
    }
  }
}

export interface ICanvasZoomEvent {
  event: 'zoom'
  factor: number
  point?: IPoint
}

interface IObjectAddedEvent {
  slideId: string
}

interface IObjectRemovedEvent {
  slideId: string
}

interface IObjectModifiedEvent {
  target: fabric.Object
  countOperation?: 'add' | 'remove'
}

interface IObjectMySlidesLoadedEvent {
  target: Record<number, MerchSlide>
}

interface IObjectSharedSlidesLoadedEvent {
  target: Record<number, MerchSlide>
}

interface IObjectActiveSlideEvent {
  target: MerchSlide
}
interface IObjectMerchDirtyEvent {
  value: boolean
}
interface IObjectSlideAddedEvent {
  slide: MerchSlide
}
interface IObjectFolderAddedEvent {
  name: string
  path: string
  id: string
}
interface IObjectSlideSharedChangedEvent {
  slideIds: (string | number)[]
  users: sharedUserModel[]
  groups: sharedUserGroupModel[]
}
interface IObjectUpdateSlideEvent {
  slideId: string
  slideData: MerchSlide
  updatedKeys: string[]
}

interface IObjectSlideDirtyEvent {
  slideId: string
  value: boolean
}
interface IObjectSlidesSavedEvent {
}
interface IObjectFindNextReachedEndOfSlideEvent {
}
interface IObjectSearchedTextHighlighted {}

type CanvasEventName = 'zoom' | 'object-added' | 'object-removed' | 'object-modified' | 'object-moving' | 'mySlides-loaded' | 'sharedSlides-loaded' | 'slide-active' | 'merch-dirty' | 'slide-dirty' | 'slide-added' | 'add-folder' | 'slide-shared-changed' | 'update-slide' | 'slides-saved' | 'find-next-reached-end-of-slide' | 'searched-text-highlighted'

type CanvasEventType<T> =
  T extends 'zoom' ? ICanvasZoomEvent :
    T extends 'object-added' ? IObjectAddedEvent :
      T extends 'object-removed' ? IObjectRemovedEvent :
        T extends 'object-modified' ? IObjectModifiedEvent :
          T extends 'object-moving' ? IObjectModifiedEvent :
            T extends 'mySlides-loaded' ? IObjectMySlidesLoadedEvent :
              T extends 'slide-active' ? IObjectActiveSlideEvent :
                T extends 'merch-dirty' ? IObjectMerchDirtyEvent :
                  T extends 'slide-added' ? IObjectSlideAddedEvent :
                    T extends 'sharedSlides-loaded' ? IObjectMySlidesLoadedEvent :
                      T extends 'add-folder' ? IObjectFolderAddedEvent :
                        T extends 'slide-shared-changed' ? IObjectSlideSharedChangedEvent :
                          T extends 'update-slide' ? IObjectUpdateSlideEvent :
                            T extends 'slide-dirty' ? IObjectSlideDirtyEvent :
                              T extends 'slides-saved' ? IObjectSlidesSavedEvent :
                                T extends 'find-next-reached-end-of-slide' ? IObjectFindNextReachedEndOfSlideEvent :
                                  T extends 'searched-text-highlighted' ? IObjectSearchedTextHighlighted :
                                    never

export default Merch
