const SPACE = '\u200A'

export default class RenderHelper {
  static drawText(ctx: CanvasRenderingContext2D, fontStyle: string, fontVariant: string, fontWeight: string, fontSize: number, font, mytext: string, x: number, y: number, width: number, height: number, align: 'right' | 'left' | 'middle', vAlign: 'top' | 'bottom' | 'center', justify: boolean, lineHeight: number, measureOnly: boolean, debug = false) {
    if (width <= 0 || height <= 0 || fontSize <= 0) {
      // width or height or font size cannot be 0
      return
    }

    // End points
    const xEnd = x + width
    const yEnd = y + height

    const style = `${fontStyle} ${fontVariant} ${fontWeight} ${fontSize}px ${font}`
    ctx.font = style

    let txtY = y + height / 2 + fontSize / 2

    let textanchor

    if (align === 'right') {
      textanchor = xEnd
      ctx.textAlign = 'right'
    }
    else if (align === 'left') {
      textanchor = x
      ctx.textAlign = 'left'
    }
    else {
      textanchor = x + width / 2
      ctx.textAlign = 'center'
    }

    // added one-line only auto linebreak feature
    const textarray: string[] = []
    const temptextarray = mytext.split('\n')

    const spaceWidth = justify ? ctx.measureText(SPACE).width : 0

    temptextarray.forEach((txtt) => {
      let textwidth = ctx.measureText(txtt).width
      if (textwidth <= width) {
        textarray.push(txtt)
      }
      else {
        let temptext = txtt
        const linelen = width
        let textlen
        let textpixlen
        let texttoprint
        textwidth = ctx.measureText(temptext).width
        while (textwidth > linelen) {
          textlen = 0
          textpixlen = 0
          texttoprint = ''
          while (textpixlen < linelen) {
            textlen++
            texttoprint = temptext.substr(0, textlen)
            textpixlen = ctx.measureText(temptext.substr(0, textlen)).width
          }
          // Remove last character that was out of the box
          textlen--
          texttoprint = texttoprint.substr(0, textlen)
          // if statement ensures a new line only happens at a space, and not amidst a word
          const backup = textlen
          if (temptext.substr(textlen, 1) !== ' ') {
            while (temptext.substr(textlen, 1) !== ' ' && textlen !== 0) {
              textlen--
            }
            if (textlen === 0) {
              textlen = backup
            }
            texttoprint = temptext.substr(0, textlen)
          }

          texttoprint = justify
            ? this.justifyLine(ctx, texttoprint, spaceWidth, SPACE, width)
            : texttoprint

          temptext = temptext.substr(textlen)
          textwidth = ctx.measureText(temptext).width
          textarray.push(texttoprint)
        }
        if (textwidth > 0) {
          textarray.push(temptext)
        }
      }
      // end foreach temptextarray
    })
    const charHeight = lineHeight || this.getTextHeight(ctx, mytext, style) // close approximation of height with width
    const vheight = charHeight * (textarray.length - 1)
    const negoffset = vheight / 2

    let debugY = y
    // Vertical Align
    if (vAlign === 'top') {
      txtY = y + fontSize
    }
    else if (vAlign === 'bottom') {
      txtY = yEnd - vheight
      debugY = yEnd
    }
    else {
      // defaults to center
      debugY = y + height / 2
      txtY -= negoffset
    }
    // print all lines of text
    if (!measureOnly) {
      textarray.forEach((txtline) => {
        txtline = txtline.trim()
        ctx.fillText(txtline, textanchor, txtY)
        txtY += charHeight
      })
    }

    if (debug) {
      // Text box
      ctx.lineWidth = 3
      ctx.strokeStyle = '#00909e'
      ctx.strokeRect(x, y, width, height)

      ctx.lineWidth = 2
      // Horizontal Center
      ctx.strokeStyle = '#f6d743'
      ctx.beginPath()
      ctx.moveTo(textanchor, y)
      ctx.lineTo(textanchor, yEnd)
      ctx.stroke()
      // Vertical Center
      ctx.strokeStyle = '#ff6363'
      ctx.beginPath()
      ctx.moveTo(x, debugY)
      ctx.lineTo(xEnd, debugY)
      ctx.stroke()
    }

    const TEXT_HEIGHT = vheight + charHeight

    return { height: TEXT_HEIGHT }
  }

  // Calculate Height of the font
  static getTextHeight(ctx: CanvasRenderingContext2D, text: string, style: string) {
    const previousTextBaseline = ctx.textBaseline
    const previousFont = ctx.font

    ctx.textBaseline = 'bottom'
    ctx.font = style
    const { actualBoundingBoxAscent: height } = ctx.measureText(text)

    // Reset baseline
    ctx.textBaseline = previousTextBaseline
    ctx.font = previousFont

    return height
  }

  /**
   * This function will insert spaces between words in a line in order
   * to raise the line width to the box width.
   * The spaces are evenly spread in the line, and extra spaces (if any) are inserted
   * between the first words.
   *
   * It returns the justified text.
   *
   * @param {CanvasRenderingContext2D} ctx
   * @param {string} line
   * @param {number} spaceWidth
   * @param {string} spaceChar
   * @param {number} width
   */
  static justifyLine(ctx, line, spaceWidth, spaceChar, width) {
    const text = line.trim()

    const lineWidth = ctx.measureText(text).width

    const nbSpaces = text.split(/\s+/).length - 1
    const nbSpacesToInsert = Math.floor((width - lineWidth) / spaceWidth)

    if (nbSpaces <= 0 || nbSpacesToInsert <= 0) { return text }

    // We insert at least nbSpacesMinimum and we add extraSpaces to the first words
    const nbSpacesMinimum = Math.floor(nbSpacesToInsert / nbSpaces)
    let extraSpaces = nbSpacesToInsert - nbSpaces * nbSpacesMinimum

    const spaces: string[] = []
    for (let i = 0; i < nbSpacesMinimum; i++) {
      spaces.push(spaceChar)
    }
    const spacesStr = spaces.join('')

    const justifiedText = text.replace(/\s+/g, (match) => {
      const allSpaces = extraSpaces > 0 ? spacesStr + spaceChar : spacesStr
      extraSpaces--
      return match + allSpaces
    })

    return justifiedText
  }

  static drawTextInBox(ctx: CanvasRenderingContext2D, font: string, fontSize: number, fontStyle: string, text: string, x: number, y: number, width: number, height: number, align: 'right' | 'left' | 'center', vAlign: 'top' | 'bottom' | 'middle', cancelIfBigger = false, debug = false) {
    if (width <= 0 || height <= 0 || fontSize <= 0) {
      // width or height or font size cannot be 0
      return false
    }

    const style = `${fontStyle} ${fontSize}px ${font}`
    ctx.font = style

    // Get a sample line height
    const lineHeight = this.getLineHeight(ctx, 'Sample')

    // Break the text into individual lines
    const finalLines: string[] = []
    const tempLines = text.split('\n')
    let currentHeight = 0

    // Loop thru each line
    for (let i = 0; i < tempLines.length; i++) {
      const line = tempLines[i]
      const res = this.wrapLineToWidth(ctx, line, width, cancelIfBigger)
      if (!res) { return false }
      const { totalHeight, newLines } = res
      if (currentHeight + totalHeight > height) {
        // We reach the maximum height
        // Stop if we should
        if (cancelIfBigger) { return false }

        // otherwise, take as many lines as possible then stop
        const maxLines = Math.trunc((height - currentHeight) / lineHeight)
        finalLines.push(...newLines.slice(0, maxLines))

        // Check if adding an ellipsis to the end of the last line will break the max width
        let lastLineIndex = finalLines.length - 1
        if (lastLineIndex <= 0) {
          break
        }
        else {
          while (lastLineIndex > 0 && ctx.measureText(`${finalLines[lastLineIndex]}...`).width > width) {
            const lastIndexOfSpace = finalLines[lastLineIndex].lastIndexOf(' ')
            if (lastIndexOfSpace === -1) {
              finalLines[lastLineIndex] = ''
              break
            }
            else {
              finalLines[lastLineIndex] = finalLines[lastLineIndex].substring(0, lastIndexOfSpace)
            }
            lastLineIndex++
          }
          // Add ellipsis
          finalLines[lastLineIndex] += '...'
          break
        }
      }
      else {
        finalLines.push(...newLines)
        currentHeight += totalHeight
      }
    }

    // Draw the lines
    for (let i = 0; i < finalLines.length; i++) {
      const line = finalLines[i]
      ctx.textAlign = 'center'
      ctx.fillText(line, x + width / 2, y + lineHeight + i * lineHeight)
    }

    // Draw debug box
    if (debug) {
      ctx.rect(x, y, width, height)
      ctx.stroke()
    }

    return finalLines
  }

  static wrapLineToWidth(ctx: CanvasRenderingContext2D, text: string, maxWidth: number, cancelIfOneWordBigger: boolean) {
    const newLines: string[] = []
    let totalHeight = 0
    const words = text.split(' ')
    let tmpLine = ''

    for (let i = 0; i < words.length; i++) {
      const word = words[i]
      const concatLine = tmpLine + (tmpLine.length > 0 ? ' ' : '') + word
      const measure = ctx.measureText(concatLine)
      if (measure.width > maxWidth) {
        if (cancelIfOneWordBigger && tmpLine.length === 0) { return false }
        newLines.push(tmpLine)
        totalHeight += measure.fontBoundingBoxDescent + measure.fontBoundingBoxAscent
        tmpLine = word
      }
      else {
        tmpLine = concatLine
      }
    }

    if (tmpLine.length > 0) {
      const measure = ctx.measureText(tmpLine)
      if (measure.width > maxWidth && cancelIfOneWordBigger) { return false }
      newLines.push(tmpLine)
      totalHeight += measure.fontBoundingBoxDescent + measure.fontBoundingBoxAscent
    }

    return { newLines, totalHeight }
  }

  static getLineHeight(ctx: CanvasRenderingContext2D, text: string) {
    const measure = ctx.measureText(text)
    return measure.fontBoundingBoxDescent + measure.fontBoundingBoxAscent
  }
}
