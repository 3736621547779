<template>
  <div class="w-full h-full">
    <div v-show="currentView === viewEnums.modelDetails" class="flex flex-col w-full h-full">
      <!-- Details -->
      <div class="flex flex-1 w-full h-full overflow-hidden">
        <!-- Model Details -->
        <div
          v-show="selectedArticleAction !== allArticleActions.manageArticleState.key"
          class="flex flex-col w-1/2 px-2 py-4 m-1 overflow-hidden border rounded box-border"
        >
          <tx-tabs v-model="activeModelTab" :tabs="getVisibleModelTabs" />
          <div class="flex px-2 my-1">
            <div class="flex-1 flex flex-wrap items-center">
              <span class="text-2xl font-semibold select-text whitespace-nowrap text-ellipsis">
                {{ article.ModelNumber }} /
              </span>
              <tx-input
                v-model="localModelName" type="text" :style="{ width: modelNameInputWidth }" :disabled="!isModelNameEditableAtStateLevel || localReadonly"
                text-style="text-2xl font-semibold select-text whitespace-nowrap border bg-card h-9 border-form shadow-input"
              />
              <div v-if="(utils.isDefined(attributesToCreateRequest.ModelName) && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditModelName) && !article._IsRequestArticle && (!isModelNameEditableAtStateLevel || isFieldsReadonlyForRegionalUser))" v-tooltip="{ text: t('requests.title.createEditRequest'), theme: { placement: 'left' } }">
                <font-awesome-icon icon="fa-light fa-registered" class="w-4 h-4 ml-0.5 cursor-pointer hover:text-primary-500" @click="onCreateRequest(userStore.myAttributes!.ModelName)" />
              </div>
              <span class="text-2xl font-semibold select-text whitespace-nowrap text-ellipsis">{{ getModelState }}</span>
            </div>
            <div class="flex">
              <settings-dropdown @change="onSettingsChanged" />
            </div>
          </div>
          <div v-if="hasModelNameMaxLengthError">
            <p class="text-xs italic text-red-500">
              {{ t('articleDetails.alerts.modelnameMaxLengthError', { max: modelNameFieldMaxLength }) }}
            </p>
          </div>
          <transition
            enter-from-class="opacity-0" enter-active-class="ease-out transition-medium"
            enter-to-class="opacity-100" leave-from-class="opacity-100" leave-active-class="ease-out transition-medium"
            leave-to-class="opacity-0"
          >
            <div v-if="activeModelTab" class="flex flex-col flex-auto overflow-hidden">
              <model-stats v-if="modelTabs.stats.visible" v-show="activeModelTab === modelTabs.stats.key" :article="article" :parent-loading="loading" />
              <!-- SEASONLESS MODEL FORM -->
              <attributes-editor
                v-show="activeModelTab === modelTabs.details.key"
                ref="seasonlessModelPropertiesAttributesEditorRef" :article="article"
                :non-grouped-attributes="seasonlessModelAttributes" :attributes="seasonlessModelAttributes"
                :columns="2" :read-only="localReadonly"
                :show-obsolete-master-sizescale-warning="isMasterSizeScaleObsolete"
                @form-dirty-state-changed="seasonlessModelFormDirtyStateChanged" @changed="seasonlessModelFormChanged"
              />
              <requests
                v-show="activeModelTab === modelTabs.requests.key" :items="modelRequests"
                :selected-request-id="selectedRequestId" :selected-object-id="selectedObjectId" :article-id-article-number-map="articleIdArticleNumberMap" :disable-request-actions="disableRequestActions"
                :is-confirmed-finished="isConfirmedFinished" @item-selected="onRequestArticleSelected" @confirm="onConfirmRequest" @refresh-request-details="refreshRequestDetails" @approve-reject="onApproveReject"
              />
            </div>
          </transition>
        </div>
        <!-- Article Details -->
        <div
          v-show="!selectedArticleAction"
          class="flex flex-col w-1/2 px-2 py-4 m-1 overflow-hidden border rounded"
        >
          <div class="flex">
            <tx-tabs v-model="activeArticleTab" :tabs="Object.values(articleTabs)" />
            <!-- ADD/UPDATE SAVE BUTTON -->
            <span class="select-all text-primary-500 flex-auto text-center">
              <tx-button
                v-show="showCreateOrUpdateSaveButton && (!localReadonly || (localReadonly && userStore.userProfile.AccountDetails.AccountTypeId !== 1 && userStore.userProfile.AccountDetails.AccountId !== userStore.activeCatalog!.AccountId))" type="confirm" width="100px" height="35px"
                :text="t('general.save')" :loading="isLoading" :disabled="seasonlessModelPropertiesAttributesEditorRef?.v$.$invalid || articlePropertiesAttributesEditorRef?.v$.$invalid || seasonalModelFormValidator.$errors.length > 0 || hasModelNameMaxLengthError"
                @click="onSaveButtonClicked"
              />
            </span>
            <!-- ARTICLE ACTIONS DROPDOWN -->
            <tx-dropdown
              v-if="isArticleActionsEnabled && !localReadonly" v-model="selectedArticleAction" class="mt-auto ml-auto mr-8"
              :items="getArticleActions" value-prop="key" display-prop="label"
            >
              <template #button>
                <icon-button
                  type="inline" faicon="fa-light fa-angle-down"
                  :label="t('general.actions', getArticleActions.length)" :right-icon="true" :width="100"
                />
              </template>
            </tx-dropdown>
          </div>
          <div class="flex flex-col w-full px-2 my-1">
            <div class="w-full flex items-center">
              <span class="flex-1 text-2xl font-semibold select-text whitespace-nowrap text-ellipsis">
                <span>{{ getArticleSeason(article) }}</span>
                <span> / {{ article.ArticleNumber }}</span>
                <span v-if="article.StateName"> / {{ article.StateName }}</span>
                <span v-if="article.StateName && utils.isDefined(attributesToCreateRequest.StateName) && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesState) && !article._IsRequestArticle && userStore.activeCatalog?.CatalogCode === article.CatalogCode" v-tooltip="t('requests.title.createEditRequest')">
                  <font-awesome-icon icon="fa-light fa-registered" class="w-4 h-4 ml-1 cursor-pointer hover:text-primary-500" @click="onCreateRequest(userStore.myAttributes!.StateName)" />
                </span>
                <span> / {{ getArticleStatus }}</span>
                <span v-if="utils.isDefined(attributesToCreateRequest.Status) && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesStatus) && !article._IsRequestArticle && userStore.activeCatalog?.CatalogCode === article.CatalogCode" v-tooltip="t('requests.title.createEditRequest')">
                  <font-awesome-icon icon="fa-light fa-registered" class="w-4 h-4 ml-1 cursor-pointer hover:text-primary-500" @click="onCreateRequest(userStore.myAttributes!.Status)" />
                </span>
              </span>
              <span
                v-if="article.SourceRequestId && article.SourceRequestNumber && article.SourceRequestNumber.length"
                class="cursor-pointer border rounded-md text-md p-1 mr-2 hover:border-primary-500 hover:text-primary-500"
                @click="onRequestNumberClick(article.SourceRequestId)"
              >{{ t('general.request') }}: {{ sourceRequestNumber }}</span>
              <span
                v-if="article._ConfirmArticleNumber && article._ConfirmArticleNumber.length"
                class="cursor-pointer border rounded-md text-md p-1 mr-2 hover:border-primary-500 hover:text-primary-500"
                @click="onArticleNumberClick(article._ConfirmArticleNumber)"
              >{{ t('general.article') }}: {{ article._ConfirmArticleNumber }}</span>
              <span class="font-semibold text-right select-all text-primary-500">{{ getRetailPrice }}</span>
              <font-awesome-icon
                v-if="doesArticleLockedDueToAnyModelArticle"
                class="w-4 h-4 m-2"
                icon="fa-light fa-lock"
              />
              <span v-if="utils.isDefined(attributesToCreateRequest.Prices) && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditPrices) && !article._IsRequestArticle && !isPriceGroupsNotLockedAtState && userStore.activeCatalog?.CatalogCode === article.CatalogCode" v-tooltip="{ text: t('requests.title.createEditRequest'), theme: { placement: 'left' } }">
                <!-- For Prices, sending one of the price attribute object(_RetailPrice) to differenciate. -->
                <font-awesome-icon icon="fa-light fa-registered" class="w-4 h-4 m-2 ml-1 cursor-pointer hover:text-primary-500" @click="onCreateRequest(userStore.myAttributes!._RetailPrice)" />
              </span>
            </div>
            <flags-band :article="article" :flags="getFlags" />
          </div>
          <transition
            enter-from-class="opacity-0" enter-active-class="ease-out transition-medium"
            enter-to-class="opacity-100" leave-from-class="opacity-100" leave-active-class="ease-out transition-medium"
            leave-to-class="opacity-0"
          >
            <div v-if="activeArticleTab === articleTabs.details.key" class="flex w-full gap-4 overflow-hidden">
              <!-- Images -->
              <div class="flex flex-col flex-1 w-full h-full min-w-0">
                <!-- FAVORITES -->
                <article-favorites v-if="!localReadonly && !article._IsRequestArticle" :article="article" @on-edit-fav="onEditFav" />
                <article-assets-viewer :article="article" />
              </div>

              <!-- EDITABLE ARTICLE ATTRIBUTES FORM -->
              <attributes-editor
                ref="articlePropertiesAttributesEditorRef" class="flex-1 w-full min-w-0 p-2 rounded"
                :article="article" :active-groups="editableArticlePropertiesActiveGroups"
                :non-grouped-attributes="processedArticleEditableAttributes.nonGroupedEditableArticleProperties"
                :grouped-attributes="processedArticleEditableAttributes.editableArticlePropertyGroups"
                :attributes="articleEditableAttributes" :columns="1" :read-only="localReadonly || !doesModelBelongsToActiveCatalog"
                @form-dirty-state-changed="articleFormDirtyStateChanged" @changed="articleFormChanged"
              />
            </div>
            <audit-trails v-else-if="activeArticleTab === articleTabs.history.key" :article="article" />
            <manage-object-relation v-else-if="activeArticleTab === articleTabs.related.key" :article="article" />
          </transition>
        </div>
        <!-- Update Action forms -->
        <div
          v-show="selectedArticleAction" class="relative flex flex-col w-1/2 h-full bg-default"
          :class="{ 'w-full items-center': selectedArticleAction === allArticleActions.manageArticleState.key }"
        >
          <tx-button v-show="showBackArrow" class="absolute top-7 left-7" type="icon" faicon="fa-light fa-chevron-left" @click="onBackClicked" />

          <!-- ACTIONS HEADER -->
          <div class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header">
            <!-- TITLE -->
            <div class="flex-1 text-xl font-semibold text-center text-tx-form-title" v-text="infoHeaderTitle" />
            <span v-if="selectedArticleAction === allArticleActions.assignColor.key" class="mt-3 text-base text-center lex-1 text-tx-form-title">{{ t('assignColor.header') }}</span>
          </div>

          <!-- ACTIONS FORM -->
          <active-or-deactive-article
            v-if="selectedArticleAction === allArticleActions.activate.key || selectedArticleAction === allArticleActions.deactivate.key"
            class="grow"
            :type="selectedArticleAction === allArticleActions.activate.key ? 'activate' : 'deactivate'" :show-header="false"
            :articles="[article]" :confirm-request-id="confirmRequestId" @cancel="onBackClicked" @updated="onArticleUpdated" @refresh-request-details="refreshRequestDetails"
          />
          <lock-or-unlock-article
            v-if="selectedArticleAction === allArticleActions.lock.key || selectedArticleAction === allArticleActions.unlock.key"
            class="grow"
            :type="selectedArticleAction === allArticleActions.lock.key ? 'lock' : 'unlock'" :show-header="false"
            :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
          />
          <update-article-segmentation
            v-else-if="selectedArticleAction === allArticleActions.editSegmentations.key"
            class="grow" :articles="[article]"
            :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <update-article-retail-window
            v-else-if="selectedArticleAction === allArticleActions.editRetailWindow.key"
            class="grow" :articles="[article]"
            :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <update-article-forecast
            v-else-if="selectedArticleAction === allArticleActions.editForecasts.key" class="grow"
            :article="article" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <assign-article-resource
            v-else-if="selectedArticleAction === allArticleActions.assignResources.key"
            class="grow" :articles="[article]"
            :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <manage-object-relation
            v-else-if="selectedArticleAction === allArticleActions.editRelations.key" class="grow"
            :article="article" @cancel="onBackClicked"
          />

          <update-size-scale
            v-else-if="selectedArticleAction === allArticleActions.editSizes.key" class="grow"
            :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <update-prices
            v-else-if="selectedArticleAction === allArticleActions.editPrices.key" class="grow"
            :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <update-period
            v-else-if="selectedArticleAction === allArticleActions.editPeriod.key" class="grow"
            :show-header="false" :article="article" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <assign-color
            v-else-if="selectedArticleAction === allArticleActions.assignColor.key" class="grow"
            :show-header="false" :context-article="article" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <manage-article-state
            v-else-if="selectedArticleAction === allArticleActions.manageArticleState.key"
            class="grow" :articles="[article]" :confirm-request-id="confirmRequestId" :request-confirm-state-id="requestedValue"
            :show-header="false" @cancel="onBackClicked" @updated="onArticleUpdated" @refresh-request-details="refreshRequestDetails"
          />

          <update-parent-model
            v-else-if="selectedArticleAction === allArticleActions.editParentModel.key" class="grow"
            :show-header="false" :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <overwrite-color-code
            v-else-if="selectedArticleAction === allArticleActions.overwriteColorCode.key" class="grow"
            :show-header="false" :context-article="article" @cancel="onBackClicked" @updated="onArticleUpdated"
          />

          <assort-articles
            v-if="selectedArticleAction === allArticleActions.assortArticles.key" :show-header="false"
            :articles="[article]" @cancel="onBackClicked" @updated="onArticleUpdated"
          />
        </div>
      </div>
      <div class="relative m-1 overflow-x-auto border rounded">
        <table class="w-full text-sm text-left">
          <thead class="text-xs text-gray-700 bg-gray-50">
            <tr class="border">
              <th
                v-for="column in seasonalTableColumns"
                :key="column.SystemName"
                v-tooltip="{ text: t('requests.title.createEditRequest'), theme: { placement: 'bottom' } }"
                scope="col"
                class="px-2 py-2 text-center border whitespace-nowrap label"
                :class="{ 'min-w-[150px]': column.AttributeType !== AttributeType.Bool, 'required': column.IsRequired }"
              >
                <span class="mr-1 uppercase" v-text="column.DisplayName" />

                <font-awesome-icon
                  v-if="column.AllowCreateRequest && !article._IsRequestArticle && props.article.CatalogCode === userStore.activeCatalog?.CatalogCode"
                  icon="fa-light fa-registered"
                  class="w-4 h-4 cursor-pointer hover:text-primary-500"
                  style="vertical-align: middle;"
                  @click="onCreateRequest(column)"
                />
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(seasonalTableRowData, index) in catalogsData" :key="index" class="border"
              :class="{ 'bg-yellow-100': index === 0 }"
            >
              <!-- eslint-disable-next-line vue/no-useless-template-attributes -->
              <template v-for="column in seasonalTableColumns" :key="index + column.SystemName" class="mb-5 last-of-type:mb-4">
                <th
                  v-if="column.isSeasonColumn" scope="row" class="px-2 py-1 font-medium text-gray-900 border whitespace-nowrap"
                  v-text="seasonalTableRowData[column.SystemName]"
                />
                <td v-else-if="column.isPeriodColumn" class="px-2 py-1 border">
                  <div class="flex items-center">
                    <color-code-chip
                      v-for="art in seasonalTableRowData[column.SystemName]"
                      :key="art.CatalogArticleId" class="z-123" :active="selectedChip"
                      :chip="art" @click="onColorCodeClicked"
                    />
                    <div v-if="index === 0 && column.showAddOrCarryover && !localReadonly" v-tooltip="{ text: t('general.addArticles'), theme: { placement: 'top' } }">
                      <font-awesome-icon
                        class="w-4 h-4 m-1 cursor-pointer hover:text-primary-500"
                        :icon="newOrCarryoverArticlesOrRequests[column.SystemName] && (newOrCarryoverArticlesOrRequests[column.SystemName].ph > 0 || newOrCarryoverArticlesOrRequests[column.SystemName].articleIds.size > 0) ? 'fa-light fa-pen-square' : 'fa-light fa-square-plus'"
                        @click="onAddOrCarryover($event, column.SystemName)"
                      />
                    </div>
                    <div v-if="index === 0 && column.showAddOrCarryoverRequest && (!localReadonly || (localReadonly && userStore.userProfile.AccountDetails.AccountTypeId !== 1 && userStore.userProfile.AccountDetails.AccountId !== userStore.activeCatalog!.AccountId))" v-tooltip="{ text: t('general.addRequests'), theme: { placement: 'top' } }">
                      <font-awesome-icon
                        class="w-4 h-4 m-1 cursor-pointer hover:text-primary-500"
                        :icon="newOrCarryoverArticlesOrRequests[column.SystemName] && (newOrCarryoverArticlesOrRequests[column.SystemName].ph > 0 || newOrCarryoverArticlesOrRequests[column.SystemName].articleIds.size > 0) ? 'fa-light fa-pen-circle' : 'fa-light fa-circle-plus'"
                        @click="onAddOrCarryover($event, column.SystemName, true)"
                      />
                    </div>
                  </div>
                </td>
                <td v-else class="px-2 py-1 border whitespace-nowrap">
                  <attribute-editor
                    v-if="seasonalTableRowData.catalogCode === userStore.activeCatalog?.CatalogCode && column.Editable"
                    v-model="seasonalModelForm[column.SystemName]" :attribute="column" :form="seasonalModelForm"
                    :required="column.IsRequired" :articles="[article]" :disabled="seasonalTableRowData.ReadOnly || localReadonly || !doesModelBelongsToActiveCatalog" :show-label="false"
                    :errors="seasonalModelFormValidator[column.SystemName].$errors"
                    @blur="seasonalModelFormValidator[column.SystemName].$touch"
                    @change="onCatalogSeasonalDataChange(column)"
                  />
                  <span v-else>{{ seasonalTableRowData[column.SystemName] }}</span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-show="showAddOrCarryoverEditor || showAddOrCarryoverRequestEditor" ref="refAddOrCarryoverEditor"
        class="absolute z-[1500] px-1 py-2 flex flex-col bg-grey-light rounded-lg shadow-toolbar"
      >
        <div class="text-center border-b label mb-1">
          {{ showAddOrCarryoverRequestEditor ? t('general.addRequests') : t('general.addArticles') }}
        </div>
        <div class="flex flex-wrap max-w-sm gap-2">
          <tx-checkbox
            v-for="art in getAvailableCarryoverArticles(selectedPeriod)" :key="art.CatalogArticleId"
            v-model="addOrCarryoverEditorForm[art.ArticleId]"
          >
            <color-code-chip :chip="art" />
          </tx-checkbox>
        </div>
        <div class="flex mt-1">
          <tx-input v-model="addOrCarryoverEditorForm.ph" class="flex-1" type="number" placeholder="PH" />
          <tx-button type="icon" faicon="fa-light fa-check" @click="onSaveAddOrCarryover" />
          <tx-button type="icon" faicon="fa-light fa-xmark" @click="closeAddOrCarryoverEditor" />
        </div>
      </div>
    </div>
    <add-articles
      v-if="currentView === viewEnums.addArticleForm" :visible="currentView === viewEnums.addArticleForm"
      :action-type="styleCreateAction" :context-article="article" :request-articles="selectedRequestArticles" :is-add-request="isRequestToAdd" :is-confirm-request="isRequestToConfirm"
      :external-data="newOrCarryoverArticlesOrRequests" :external-data-updated="addOrCarryoverNewUpdate" :external-linked-catalog-articles="externalLinkedCatalogArticles" :link-catalog-indexed-by-price-name-to-id="linkCatalogIndexedByPriceNameToId"
      @close="closeDrawer" @show-model-details="currentView = viewEnums.modelDetails"
    />
    <create-edit-article-request-dialog ref="updateAttributeRequestDlg" @edit-request-created="onEditRequest" />
  </div>
</template>

<script setup lang="ts">
import { computed, nextTick, onMounted, provide, reactive, ref, watch, watchEffect } from 'vue'
import { clone, isArray, isEmpty, unionBy } from 'lodash-es'
import { computedAsync, onClickOutside } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import { helpers, required } from '@vuelidate/validators'
import type { ValidationRuleWithParams } from '@vuelidate/core'
import useVuelidate from '@vuelidate/core'
import type { ISettingItem } from '../articleDetails/components/SettingsDropdown.vue'
import SettingsDropdown from '../articleDetails/components/SettingsDropdown.vue'
import LockOrUnlockArticle from '../articleDetails/components/LockOrUnlockArticle.vue'
import ActiveOrDeactiveArticle from './components/ActiveOrDeactiveArticle.vue'
import ArticleAssetsViewer from './components/ArticleAssetsViewer.vue'
import ArticleFavorites from './components/ArticleFavorites.vue'
import AssignArticleResource from './components/AssignArticleResource.vue'
import AssignColor from './components/AssignColor.vue'
import AttributesEditor from './components/AttributesEditor.vue'
import AuditTrails from './components/AuditTrails.vue'
import type { IColorCodeChip } from './components/ColorCodeChip.vue'
import ColorCodeChip from './components/ColorCodeChip.vue'
import ManageArticleState from './components/ManageArticleState.vue'
import ManageObjectRelation from './components/ManageObjectRelation.vue'
import ModelStats from './components/ModelStats.vue'
import Requests from './components/Requests.vue'
import UpdateArticleForecast from './components/UpdateArticleForecast.vue'
import UpdateArticleSegmentation from './components/UpdateArticleSegmentation.vue'
import UpdatePeriod from './components/UpdatePeriod.vue'
import UpdatePrices from './components/UpdatePrices.vue'
import UpdateSizeScale from './components/UpdateSizeScale.vue'
import UpdateParentModel from './components/UpdateParentModel.vue'
import AssortArticles from './components/AssortArticles.vue'
import useArticleActions from './composables/articleActions'
import useArticleLocalDataUpdater from './composables/articleLocalDataUpdater'
import OverwriteColorCode from './components/OverwriteColorCode.vue'
import CreateEditArticleRequestDialog from './components/CreateEditArticleRequestDialog.vue'
import useRequestLocalDataUpdater from './composables/requestLocalDataUpdater'
import MyArticle from '@/models/myArticle'
import TxButton from '@/shared/components/TxButton.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import TxDropdown from '@/shared/components/TxDropdown.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxTabs from '@/shared/components/TxTabs.vue'
import UpdateArticleRetailWindow from '@/shared/components/UpdateArticleRetailWindow.vue'
import appConfig from '@/services/appConfig'
import utils from '@/services/utils'
import IconButton from '@/shared/components/IconButton.vue'
import FlagsBand from '@/shared/components/FlagsBand.vue'
import type CatalogShipmentWindowRange from '@/models/catalogShipmentWindowRange'
import AttributeEditor from '@/shared/components/AttributeEditor.vue'
import AddArticles from '@/modules/browse/components/addArticles'
import type { ArticlePriceModel, ArticleStateModel, UpdateArticlePricesModel } from '@/api/t1/model/articleModel'
import { AttributeType } from '@/models/catalogAttribute'
import { getArticleAssets, getLinkedCatalogArticles, getModelPrices, updateArticle, updateArticlePrices, updateModelAttributes, updateModelSeasonlessAttributes } from '@/api/t1/article'
import { appConstants, editRequestKeys, privileges, requestConstants } from '@/models/constants'
import { useArticleFormHelper } from '@/shared/composables/articleFormHelper'
import { useNotificationStore } from '@/store/notification'
import { useUserStore } from '@/store/userData'
import Article from '@/models/article'
import type RequestModel from '@/models/request'
import { useConfirmDialog } from '@/shared/composables/confirmDialog'
import { updateModelSizeScale } from '@/api/t1/size'
import ArticlePrice from '@/models/articlePrice'
import useEventsBus from '@/shared/composables/eventBus'

interface IProps {
  article: MyArticle
  readOnly?: boolean
  requestId?: number
}
const props = withDefaults(defineProps<IProps>(), {
  readOnly: false,
})
const emit = defineEmits<{
  (e: 'updated', key: string, value: any): void
  (e: 'change', article: MyArticle, reload: boolean): void
  (e: 'changeArticleSelection', article: MyArticle): void
  (e: 'close'): void
  (e: 'onEditFav', article: MyArticle): void
}>()

const currentArticle = computed(() => props.article)

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const { selectedArticleAction, allArticleActions, getArticleActions, getUpdatedMessage } = useArticleActions(currentArticle)
const { normalizeAttributeValue, updateCriteriaAttributeAllowedValues, updateLookupAttributeValues, getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank, getIndexedRestrictedAttributesBasedOnArticlesStateRank, getArticlesLocked, getArticlesMaxStateRank } = useArticleFormHelper()
const { refreshLocalArticlesData } = useArticleLocalDataUpdater()
const { updateRequestsLocally } = useRequestLocalDataUpdater()
const confirmDialog = useConfirmDialog()
const { emitter } = useEventsBus()

const viewEnums = {
  modelDetails: 0,
  addArticleForm: 1,
}
const articlePropertiesAttributesEditorRef = ref<InstanceType<typeof AttributesEditor> | null>(null)
const seasonlessModelPropertiesAttributesEditorRef = ref<InstanceType<typeof AttributesEditor> | null>(null)
const localReadonly = ref(props.readOnly)
const isLoading = ref(false)
const currentView = ref(viewEnums.modelDetails)
const loading = ref(false)
const refAddOrCarryoverEditor = ref<HTMLElement>()
const showAddOrCarryoverEditor = ref(false)
const showAddOrCarryoverRequestEditor = ref(false)
const addOrCarryoverEditorForm = reactive<Record<string, any>>({})
const selectedPeriod = ref('')
const selectedChip = ref<IColorCodeChip>()
const newOrCarryoverArticlesOrRequests = ref<Record<string, { ph: number, articleIds: Set<number> }>>({})
const styleCreateAction = ref<StyleCreateActionType | undefined>(undefined)
const isSeasonlessModelFormDirty = ref(false)
let seasonlessModelFormModel: Record<string, any> = {}
const isArticleFormDirty = ref(false)
let articleFormModel: Record<string, any> = {}
const isSeasonalModelFormDirty = ref(false)
const seasonalModelForm: Record<string, any> = reactive({})
const carryoverModelContainsArticleOrPlaceholder = ref(false)
const showCreateOrUpdateSaveButton = ref(false)
const addOrCarryoverNewUpdate = ref(false)
const seasonalModelFormDirtyAttributes: string[] = []
const modelArticlesPerSeasonMap = ref<Record<number, (Article | MyArticle)[]>>({})
const doesArticleLockedDueToAnyModelArticle = ref(false)
const modelRequests = ref<RequestModel[]>([])
const modelTabs = reactive({
  stats: { key: 'stats', label: 'articleDetails.modelStats', visible: true },
  details: { key: 'details', label: 'articleDetails.modelDetails', visible: true },
  requests: { key: 'requests', label: 'articleDetails.requests', visible: false },
})
const getVisibleModelTabs = computed(() => {
  return Object.values(modelTabs).filter(t => t.visible)
})
const activeModelTab = ref(modelTabs.stats.key)
const modelNameInputWidth = ref('auto')
const skipRestrictingPropertyUpdateBasedOnArticleState: boolean = userStore.userProfile.Permissions.has('SpecialPermissionToIgnoreArticleState')
const modelNameFieldMaxLength = userStore.sellerDetails?.ModelNameLength || 100
const isModelNameEditableAtStateLevel = ref(true)
const hasModelNameMaxLengthError = ref(false)
const isRequestToAdd = ref(false)
const selectedRequestId = ref<number>()
const selectedObjectId = ref<string>()
const selectedRequestArticles = ref([] as MyArticle[])
const doesModelBelongsToActiveCatalog = ref(false)
const sourceRequestNumber = ref<string | null>(null)
const confirmRequestId = ref<number | null>(null)
const requestedValue = ref<number | null>(null)
const articleIdArticleNumberMap: Record<number, string> = {}
const disableRequestActions = ref(false)
const showRequests = ref(true)
const isRequestToConfirm = ref(false)
const isFieldsReadonlyForRegionalUser = ref(false)

const linkedCatalogCodesNotPartOfCurrentCatalog = ref<number[]>([])
const linkCatalogIndexedByPriceNameToId = ref<Record<number, Record<string, number>>>({})
const isConfirmedFinished = ref(false)
const articleTabs = {
  details: { key: 'details', label: 'articleDetails.articleDetails', visible: true },
  history: { key: 'history', label: 'general.history', visible: userStore.userProfile.isValidPrivilege(privileges.article.getHistory) },
  related: { key: 'related', label: 'general.related', visible: userStore.userProfile.isValidPrivilege(privileges.objectRelation.get) },
}
const activeArticleTab = ref(articleTabs.details.key)
const editableArticlePropertiesActiveGroups: Array<string> = reactive([])

const updateAttributeRequestDlg = ref<InstanceType<typeof CreateEditArticleRequestDialog>>()
const localModelName = ref('')

const externalLinkedCatalogArticles = computed(() => {
  const idToArticleMap = {}
  linkedCatalogCodesNotPartOfCurrentCatalog.value.forEach((catalogCode) => {
    if (modelArticlesPerSeasonMap.value[catalogCode]) {
      modelArticlesPerSeasonMap.value[catalogCode].forEach((article) => {
        if (!idToArticleMap[article.Id]) {
          idToArticleMap[article.Id] = article as Article
        }
      })
    }
  })
  return idToArticleMap
})

const indexedRestrictedAttributesBasedOnArticlesMaxSateRank = computed(() => getIndexedRestrictedAttributesBasedOnArticlesMaxSateRank([props.article]))
const indexedRestrictedAttributesBasedOnArticlesStateRank = computed(() => getIndexedRestrictedAttributesBasedOnArticlesStateRank([props.article]))

// const indexedAttributes = computed(() => {
//   const indexedAttributes: Record<string, IMyAttribute> = {}
//   userStore.activeCatalog!.AssignedCatalogAttributes.forEach((catalogAttribute) => {
//     const attribute = userStore.myAttributes![catalogAttribute.AttributeSystemName]
//     indexedAttributes[attribute.SystemName] = attribute
//   })
//   return indexedAttributes
// })
const indexedModelAttributes = computed(() => {
  const indexedModelAttributes: Record<string, IMyAttribute> = {}
  userStore.activeCatalog!.ModelLevelAttributeList.forEach((modelAttribute) => {
    const attribute = userStore.myAttributes![modelAttribute.AttributeSystemName]
    indexedModelAttributes[attribute.SystemName] = attribute
  })
  return indexedModelAttributes
})

const attributesToCreateRequest = computed(() => {
  const result: Record<string, IMyAttribute> = {}
  if (userStore.activeCatalog && userStore.myAttributes) {
    const requestArticleAttributesSet = new Set(userStore.activeCatalog.Config.RequestArticleAttributes)
    for (const key in userStore.myAttributes) {
      if (requestArticleAttributesSet.size === 0 || requestArticleAttributesSet.has(key)) {
        result[key] = userStore.myAttributes[key]
      }
    }

    if (requestArticleAttributesSet.has(editRequestKeys.prices)) {
      result[editRequestKeys.prices] = userStore.myAttributes._RetailPrice
    }
  }
  return result
})

const getArticleStatus = computed(() => {
  if (userStore.myAttributes && userStore.activeCatalog) {
    return utils.getAttributeTypeSpecificValueWithValue(userStore.myAttributes.Status, props.article.Status)
  }
  else {
    return props.article.Status
  }
})

const seasonlessModelAttributes = computed(() => {
  const result: IMyAttribute[] = []

  result.push(appConstants.staticAttributes.ParentModelName)
  result.push(appConstants.staticAttributes.ParentModelNumber)
  const sizeScaleStaticAttribute = clone(appConstants.staticAttributes.SizeScale)
  sizeScaleStaticAttribute.ReadOnly = true
  sizeScaleStaticAttribute.AllowCreateRequest = (userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesSizeScale) && (sizeScaleStaticAttribute.ReadOnly || isFieldsReadonlyForRegionalUser.value) && utils.isDefined(attributesToCreateRequest.value[sizeScaleStaticAttribute.SystemName]) && !props.article._IsRequestArticle)
  const masterSizeScaleField: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
    SystemName: 'MasterSizeScale',
    DisplayName: t('general.masterSizeScale'),
    AttributeType: AttributeType.Nvarchar,
    ReadOnly: true,
  })
  result.push(masterSizeScaleField)
  result.push(sizeScaleStaticAttribute)
  if (userStore.activeCatalog && userStore.myAttributes) {
    userStore.activeCatalog.AssignedCatalogAttributes.forEach((attr) => {
      const attribute = clone(userStore.myAttributes![attr.AttributeSystemName])
      if (utils.isDefined(attribute) && !attribute.IsStatic && attr.IsSeasonlessModelAttribute
        && (!utils.isDefined(attribute.AttributeSource) || (attribute.AttributeSource.toLowerCase() === 'self'
        || (attribute.AttributeSource.toLowerCase() === 'parent' && attribute.Overridable)))
      ) {
        // update attribute based on article state
        const configuredStateLockingExternalChangeManagementURL = attribute.SystemName !== 'ModelName' ? appConfig.stateLockingExternalChangeManagementURL : appConfig.stateLockingExternalChangeManagementURLForModelName
        const stateLockingExternalChangeManagementURL = configuredStateLockingExternalChangeManagementURL && configuredStateLockingExternalChangeManagementURL.toString().trim().length
          && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.value.hasOwnProperty(attribute.SystemName)
          && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.value[attribute.SystemName].AllowChangeRequest
          ? configuredStateLockingExternalChangeManagementURL
          : undefined
        attribute.ReadOnly = attribute.ReadOnly || indexedRestrictedAttributesBasedOnArticlesMaxSateRank.value.hasOwnProperty(attribute.SystemName)
        attribute.ExternalChangeManagementURL = stateLockingExternalChangeManagementURL
        attribute.AllowCreateRequest = (userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesAttributes) && utils.isDefined(attributesToCreateRequest.value[attribute.SystemName]) && (attribute.ReadOnly || !attribute.Editable || isFieldsReadonlyForRegionalUser.value) && attribute.AttributeType !== AttributeType.Calc && !props.article._IsRequestArticle)
        result.push(attribute)
      }
    })
  }
  return result
})

const isMasterSizeScaleObsolete = computed(() => {
  const assignedMasterSizeScale = userStore.masterSizeScales[props.article.MasterSizeScaleId!]
  return !!(assignedMasterSizeScale && assignedMasterSizeScale.IsObsolete)
})

const articleEditableAttributes = computed(() => {
  const result: IMyAttribute[] = []
  // create index of attributes from ModelDetailsForm
  const indexedModelDetailsFormConfiguredAttributes: Record<string, { attribute: string, readOnly: boolean, required: boolean }> = {} as never as Record<string, { attribute: string, readOnly: boolean, required: boolean }>

  if (userStore.activeCatalog?.Config?.ModelDetailsForm && userStore.activeCatalog?.Config?.ModelDetailsForm.ArticleProperties) {
    const articlePropertiesFormConfig = userStore.activeCatalog?.Config?.ModelDetailsForm.ArticleProperties
    // get group attributes (priority given to group attributes)
    if (articlePropertiesFormConfig.groups && Array.isArray(articlePropertiesFormConfig.groups)) {
      articlePropertiesFormConfig.groups.forEach((group) => {
        group.attributes.forEach((attributeConfig) => {
          if (!indexedModelDetailsFormConfiguredAttributes.hasOwnProperty(attributeConfig.attribute)) {
            indexedModelDetailsFormConfiguredAttributes[attributeConfig.attribute] = attributeConfig
          }
        })
      })
    }
    // get on grouped attributes
    if (articlePropertiesFormConfig.hasOwnProperty('attributes') && Array.isArray(articlePropertiesFormConfig.attributes)) {
      articlePropertiesFormConfig.attributes.forEach((attributeConfig) => {
        if (!indexedModelDetailsFormConfiguredAttributes.hasOwnProperty(attributeConfig.attribute)) {
          indexedModelDetailsFormConfiguredAttributes[attributeConfig.attribute] = attributeConfig
        }
      })
    }
  }
  if (userStore.activeCatalog && userStore.myAttributes) {
    userStore.activeCatalog.AssignedCatalogAttributes.forEach((attribute) => {
      const attributeClone = clone(userStore.myAttributes![attribute.AttributeSystemName])
      if (utils.isDefined(attributeClone) && !attributeClone.IsStatic && !indexedModelAttributes.value[attribute.AttributeSystemName]
        && (!utils.isDefined(attributeClone.AttributeSource) || (attributeClone.AttributeSource.toLowerCase() === 'self'
        || (attributeClone.AttributeSource.toLowerCase() === 'parent' && attributeClone.Overridable)))
      ) {
        if (props.article.CatalogCode !== userStore.activeCatalog?.CatalogCode) {
          attributeClone.ReadOnly = true
        }
        // update attribute based on article state
        const configuredStateLockingExternalChangeManagementURL = attributeClone.SystemName !== 'ModelName' ? appConfig.stateLockingExternalChangeManagementURL : appConfig.stateLockingExternalChangeManagementURLForModelName
        const stateLockingExternalChangeManagementURL = configuredStateLockingExternalChangeManagementURL && configuredStateLockingExternalChangeManagementURL.toString().trim().length
          && indexedRestrictedAttributesBasedOnArticlesStateRank.value.hasOwnProperty(attributeClone.SystemName)
          && indexedRestrictedAttributesBasedOnArticlesStateRank.value[attributeClone.SystemName].AllowChangeRequest
          ? configuredStateLockingExternalChangeManagementURL
          : undefined
        attributeClone.ReadOnly = attributeClone.ReadOnly || indexedRestrictedAttributesBasedOnArticlesStateRank.value.hasOwnProperty(attributeClone.SystemName)
        attributeClone.ExternalChangeManagementURL = stateLockingExternalChangeManagementURL
        if (indexedModelDetailsFormConfiguredAttributes.hasOwnProperty(attributeClone.SystemName) && indexedModelDetailsFormConfiguredAttributes[attributeClone.SystemName].required) {
          attributeClone.IsRequired = true
        }
        attributeClone.AllowCreateRequest = (userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesAttributes) && utils.isDefined(attributesToCreateRequest.value[attributeClone.SystemName]) && (attributeClone.ReadOnly || !attributeClone.Editable || isFieldsReadonlyForRegionalUser.value) && attributeClone.AttributeType !== AttributeType.Calc && !props.article._IsRequestArticle)
        result.push(attributeClone)
      }
    })
    // add static fields such as ColorId, ColorGroup only if it is being configured in ModelDetailsForm.ArticleProperties configuration
    if (userStore.activeCatalog?.Config?.ModelDetailsForm && userStore.activeCatalog?.Config?.ModelDetailsForm.ArticleProperties) {
      const articlePropertiesFormConfig = userStore.activeCatalog.Config.ModelDetailsForm.ArticleProperties
      const staticFieldsToCheck = Object.keys(appConstants.staticAttributes)

      // check if any of the static fields is configured in groups or attributes
      const isStaticFieldConfigured = staticFieldsToCheck.some((staticField) => {
        if (articlePropertiesFormConfig.groups && Array.isArray(articlePropertiesFormConfig.groups)) {
          return articlePropertiesFormConfig.groups.some(group =>
            group.attributes.some(attributeConfig => attributeConfig.attribute === staticField),
          )
        }

        if (articlePropertiesFormConfig.attributes && Array.isArray(articlePropertiesFormConfig.attributes)) {
          return articlePropertiesFormConfig.attributes.some(attributeConfig => attributeConfig.attribute === staticField)
        }

        return false
      })

      if (isStaticFieldConfigured) {
        staticFieldsToCheck.forEach((staticField) => {
          if (articlePropertiesFormConfig.groups && Array.isArray(articlePropertiesFormConfig.groups)) {
            const groupWithStaticField = articlePropertiesFormConfig.groups.find(group =>
              group.attributes.some(attributeConfig => attributeConfig.attribute === staticField),
            )
            if (groupWithStaticField) {
              const allowCreateRequest = ((appConstants.staticAttributes[staticField].SystemName === 'ColorId' && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesPrimaryColor) && props.article[appConstants.staticAttributes[staticField].SystemName] === null && utils.isDefined(attributesToCreateRequest.value[appConstants.staticAttributes[staticField].SystemName]) && (appConstants.staticAttributes[staticField].ReadOnly || !appConstants.staticAttributes[staticField].Editable || isFieldsReadonlyForRegionalUser.value) && !props.article._IsRequestArticle)
                || (appConstants.staticAttributes[staticField].SystemName !== 'ColorId' && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesAttributes) && utils.isDefined(attributesToCreateRequest.value[appConstants.staticAttributes[staticField].SystemName]) && (appConstants.staticAttributes[staticField].ReadOnly || !appConstants.staticAttributes[staticField].Editable || isFieldsReadonlyForRegionalUser.value) && appConstants.staticAttributes[staticField].AttributeType !== AttributeType.Calc && !props.article._IsRequestArticle))
              result.push(Object.assign({}, appConstants.staticAttributes[staticField], { IsRequired: false, AllowCreateRequest: allowCreateRequest }))
            }
          }

          if (articlePropertiesFormConfig.attributes && Array.isArray(articlePropertiesFormConfig.attributes)) {
            const attributeConfig = articlePropertiesFormConfig.attributes.find(attributeConfig =>
              attributeConfig.attribute === staticField,
            )
            if (attributeConfig) {
              const allowCreateRequest = ((appConstants.staticAttributes[staticField].SystemName === 'ColorId' && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesPrimaryColor) && props.article[appConstants.staticAttributes[staticField].SystemName] === null && utils.isDefined(attributesToCreateRequest.value[appConstants.staticAttributes[staticField].SystemName]) && utils.isDefined(attributesToCreateRequest.value[appConstants.staticAttributes[staticField].SystemName]) && (appConstants.staticAttributes[staticField].ReadOnly || !appConstants.staticAttributes[staticField].Editable || isFieldsReadonlyForRegionalUser.value) && !props.article._IsRequestArticle)
                || (appConstants.staticAttributes[staticField].SystemName !== 'ColorId' && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesAttributes) && utils.isDefined(attributesToCreateRequest.value[appConstants.staticAttributes[staticField].SystemName]) && (appConstants.staticAttributes[staticField].ReadOnly || !appConstants.staticAttributes[staticField].Editable || isFieldsReadonlyForRegionalUser.value) && appConstants.staticAttributes[staticField].AttributeType !== AttributeType.Calc && !props.article._IsRequestArticle))
              result.push(Object.assign({}, appConstants.staticAttributes[staticField], { IsRequired: false, AllowCreateRequest: allowCreateRequest }))
            }
          }
        })
      }
    }
  }
  return result
})

const processedArticleEditableAttributes = computed(() => {
  const editableArticlePropertyGroups: Array<{ label: string, attributes: Array<IMyAttribute> }> = []
  let nonGroupedEditableArticleProperties: Array<IMyAttribute> = []

  if (userStore.activeCatalog?.Config?.ModelDetailsForm && userStore.activeCatalog?.Config?.ModelDetailsForm.ArticleProperties) {
    const indexedArticleEditableAttributes = utils.arrayToStringDictionary(articleEditableAttributes.value, 'SystemName')
    const articlePropertiesFormConfig = userStore.activeCatalog?.Config?.ModelDetailsForm.ArticleProperties
    if (articlePropertiesFormConfig.groups && Array.isArray(articlePropertiesFormConfig.groups)) {
      articlePropertiesFormConfig.groups.forEach((group) => {
        const currentGroup = {
          label: group.label as string,
          attributes: [] as Array<IMyAttribute>,
        }
        // insert group attributes
        if (group.hasOwnProperty('attributes') && Array.isArray(group.attributes)) {
          group.attributes.forEach((attributeConfig, index) => {
            if (indexedArticleEditableAttributes.hasOwnProperty(attributeConfig.attribute)) {
              const currentAttribute = indexedArticleEditableAttributes[attributeConfig.attribute]
              const isRequired = currentAttribute.IsRequired || attributeConfig.required
              const readOnly = props.article.CatalogCode !== userStore.activeCatalog?.CatalogCode || (!isRequired && (currentAttribute.ReadOnly || attributeConfig.readOnly)) || props.article._IsRequestArticle
                || (attributeConfig.attribute === 'ColorId' && props.article.ColorId != null && props.article.ColorId !== 0)
                || indexedRestrictedAttributesBasedOnArticlesStateRank.value.hasOwnProperty(currentAttribute.SystemName)
              const sortOrder = index
              const allowCreateRequest = userStore.activeCatalog?.CatalogCode === props.article.CatalogCode && (((attributeConfig.attribute === 'ColorId' && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesPrimaryColor) && props.article[attributeConfig.attribute] === null && utils.isDefined(attributesToCreateRequest.value[attributeConfig.attribute]) && utils.isDefined(attributesToCreateRequest.value[attributeConfig.attribute]) && (readOnly || currentAttribute.Editable || isFieldsReadonlyForRegionalUser.value) && !props.article._IsRequestArticle)
                || (attributeConfig.attribute !== 'ColorId' && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesAttributes) && utils.isDefined(attributesToCreateRequest.value[attributeConfig.attribute]) && (readOnly || !currentAttribute.Editable || isFieldsReadonlyForRegionalUser.value) && currentAttribute.AttributeType !== AttributeType.Calc && !props.article._IsRequestArticle)))
              const attributeToInsert = Object.assign({}, currentAttribute, { ReadOnly: readOnly, IsRequired: isRequired, SortOrder: sortOrder, ExternalChangeManagementURL: currentAttribute.ExternalChangeManagementURL, AllowCreateRequest: allowCreateRequest })
              utils.insertSorted(attributeToInsert, currentGroup.attributes, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
              // attribute priority will be given to group (first encountered group in articlePropertiesFormConfig) and then to non groups(based on T1S logic), delete so it will not duplicated if repeated in configuration
              delete indexedArticleEditableAttributes[attributeConfig.attribute]
            }
          })
        }
        // if group contains valid attributes
        if (currentGroup.attributes.length) {
          editableArticlePropertyGroups.push(currentGroup)
          if (group.expanded) {
            editableArticlePropertiesActiveGroups.push(group.label)
          }
        }
      })
    }
    if (articlePropertiesFormConfig.hasOwnProperty('attributes') && Array.isArray(articlePropertiesFormConfig.attributes)) {
      articlePropertiesFormConfig.attributes.forEach((attributeConfig, index) => {
        if (indexedArticleEditableAttributes.hasOwnProperty(attributeConfig.attribute)) {
          const currentAttribute = indexedArticleEditableAttributes[attributeConfig.attribute]
          const isRequired = currentAttribute.IsRequired || attributeConfig.required
          const readOnly = props.article.CatalogCode !== userStore.activeCatalog?.CatalogCode || (!isRequired && (currentAttribute.ReadOnly || attributeConfig.readOnly))
            || (attributeConfig.attribute === 'ColorId' && props.article.ColorId != null && props.article.ColorId !== 0)
            || indexedRestrictedAttributesBasedOnArticlesStateRank.value.hasOwnProperty(currentAttribute.SystemName)
          const sortOrder = index
          const attributeToInsert = Object.assign({}, currentAttribute, { ReadOnly: readOnly, IsRequired: isRequired, SortOrder: sortOrder, ExternalChangeManagementURL: currentAttribute.ExternalChangeManagementURL })
          utils.insertSorted(attributeToInsert, nonGroupedEditableArticleProperties, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
          // delete so it will not duplicated if repeated in configuration
          delete indexedArticleEditableAttributes[attributeConfig.attribute]
        }
      })
    }
  }
  else {
    // if not configured assign all editable attributes
    nonGroupedEditableArticleProperties = articleEditableAttributes.value
  }
  return {
    nonGroupedEditableArticleProperties,
    editableArticlePropertyGroups,
  }
})

const seasonalModelAttributes = computed(() => {
  const seasonalAttributes: Record<string, IMyAttribute> = {}
  // As only modelLifeCycle Needs to be included in the
  seasonalAttributes[appConstants.staticAttributes.ModelLifecycle.SystemName] = appConstants.staticAttributes.ModelLifecycle
  if (userStore.activeCatalog && userStore.myAttributes) {
    userStore.activeCatalog?.ModelLevelAttributeList.forEach((attribute) => {
      const currentAttribute = clone(userStore.myAttributes![attribute.AttributeSystemName])
      if (!attribute.IsSeasonless && !attribute.IsSeasonlessModelAttribute) {
        // update attribute based on article state
        const configuredExternalChangeManagementURL = currentAttribute.SystemName !== 'ModelName' ? appConfig.stateLockingExternalChangeManagementURL : appConfig.stateLockingExternalChangeManagementURLForModelName
        const stateLockingExternalChangeManagementURL = configuredExternalChangeManagementURL && configuredExternalChangeManagementURL.toString().trim().length
          && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.value.hasOwnProperty(currentAttribute.SystemName)
          && indexedRestrictedAttributesBasedOnArticlesMaxSateRank.value[currentAttribute.SystemName].AllowChangeRequest
          ? configuredExternalChangeManagementURL
          : undefined
        currentAttribute.ReadOnly = currentAttribute.ReadOnly || indexedRestrictedAttributesBasedOnArticlesMaxSateRank.value.hasOwnProperty(currentAttribute.SystemName)
        currentAttribute.ExternalChangeManagementURL = stateLockingExternalChangeManagementURL
        seasonalAttributes[currentAttribute.SystemName] = currentAttribute
      }
    })
  }
  const seasonalModelPropertiesFormConfig = userStore.activeCatalog?.Config?.ModelDetailsForm && userStore.activeCatalog?.Config?.ModelDetailsForm.SeasonalModelProperties
  if (seasonalModelPropertiesFormConfig && seasonalModelPropertiesFormConfig.hasOwnProperty('attributes') && Array.isArray(seasonalModelPropertiesFormConfig.attributes)) {
    const configuredAttributes = []
    seasonalModelPropertiesFormConfig.attributes.forEach((attributeConfig, index) => {
      if (seasonalAttributes.hasOwnProperty(attributeConfig.attribute)) {
        const currentAttribute = seasonalAttributes[attributeConfig.attribute]
        const isRequired = currentAttribute.IsRequired || attributeConfig.required
        const readOnly = currentAttribute.ReadOnly || attributeConfig.readOnly || indexedRestrictedAttributesBasedOnArticlesMaxSateRank.value.hasOwnProperty(currentAttribute.SystemName)
          || currentAttribute.SystemName === appConstants.staticAttributes.ModelLifecycle.SystemName
        const sortOrder = index
        const attributeToInsert = Object.assign({}, currentAttribute, { ReadOnly: readOnly, IsRequired: isRequired, SortOrder: sortOrder, ExternalChangeManagementURL: currentAttribute.ExternalChangeManagementURL })
        utils.insertSorted(attributeToInsert, configuredAttributes, (a, b) => utils.comparer(a, b, ['SortOrder', 'DisplayName']))
        // delete so it will not duplicated if repeated in configuration
        delete seasonalAttributes[attributeConfig.attribute]
      }
    })
    return configuredAttributes
  }
  else {
    return Object.values(seasonalAttributes)
  }
})

const uniquePeriods = computed(() => {
  const result: Record<string, number> = {}
  if (userStore.activeCatalog) {
    const isRetailWindowAttributeValid = utils.isValidStringValue(userStore.activeCatalog.RetailWindowAttribute)
    const criteriaAttributeValue = props.article[userStore.activeCatalog.RetailWindowAttribute]
    const retailWindows = userStore.activeCatalog.ShipmentWindowRangeList
      .filter(s => utils.isValidStringValue(s.Period) && s.Status && (!isRetailWindowAttributeValid || utils.haveEqualStringValue(s.CriteriaAttributeValue, criteriaAttributeValue)))
    const periods = unionBy<CatalogShipmentWindowRange>(retailWindows, 'Period')
      .map(a => a.Period)
      .sort((a, b) => a.localeCompare(b))
    if (periods.length > 0) {
      periods.forEach(period => result[period] = userStore.activeCatalog!.CatalogCode)
    }
    // check link catalogs periods
    for (const linkedCatalog of userStore.activeCatalog.LinkedCatalogList) {
      const linkedCatalogDetails = userStore.linkedCatalogDetails[linkedCatalog.CatalogCode]
      if (linkedCatalogDetails) {
        linkedCatalogDetails.ShipmentWindowRangeList.forEach((s) => {
          if (!result.hasOwnProperty(s.Period) && utils.isValidStringValue(s.Period) && (!isRetailWindowAttributeValid || utils.haveEqualStringValue(s.CriteriaAttributeValue, criteriaAttributeValue))) {
            result[s.Period] = linkedCatalog.CatalogCode
          }
        })
      }
      else {
        if (linkedCatalogCodesNotPartOfCurrentCatalog.value.includes(linkedCatalog.CatalogCode) && modelArticlesPerSeasonMap.value[linkedCatalog.CatalogCode] && modelArticlesPerSeasonMap.value[linkedCatalog.CatalogCode].length !== 0) {
          modelArticlesPerSeasonMap.value[linkedCatalog.CatalogCode].forEach((article) => {
            if (article.Period && !result.hasOwnProperty(article.Period) && utils.isValidStringValue(article.Period)) {
              result[article.Period] = linkedCatalog.CatalogCode
            }
          })
        }
      }
    }
    return Object.keys(result).sort().reduce((obj, key) => {
      obj[key] = result[key]
      return obj
    }, {})
  }
  return result
})

const catalogsData = computedAsync(
  async () => {
    const result: Record<string, any>[] = []
    if (userStore && userStore.activeCatalog) {
      const catalogPeriodMap = new Map<string, IColorCodeChip[]>()
      const configuredChipAttribute = userStore.activeCatalog.Config.ModelDetailsChipAttribute
      const isChipConfigurationValid = !!(configuredChipAttribute && userStore.myAttributes && userStore.myAttributes[configuredChipAttribute])
      for (const catalogCode in modelArticlesPerSeasonMap.value) {
        for (const article of modelArticlesPerSeasonMap.value[catalogCode]) {
          if (utils.isValidStringValue(article.Period) && (!utils.isDefined(userStore.currentCustomer) || !article._IsNonSegmented)
            && (!article._IsRequestArticle || (article._IsRequestArticle && showRequests.value))) {
            const key = `${catalogCode}-${article.Period}`
            const item = catalogPeriodMap.get(key) || [] as IColorCodeChip[]
            const articleNumberParts = article.ArticleNumber?.split('-')
            const chip: IColorCodeChip = {
              Type: article._IsRequestArticle ? 'circle' : 'rect',
              CatalogCode: article.CatalogCode,
              ArticleId: article.Id,
              ArticleNumber: article.ArticleNumber,
              CatalogArticleId: article.CatalogArticleId,
              SourceArticleId: article._SourceArticleId ? Number(article._SourceArticleId) : null,
              IsRequest: !!article._IsRequestArticle,
              ColorCode: article.ColorwayCode ? article.ColorwayCode.toString() : articleNumberParts && articleNumberParts.length > 1 ? articleNumberParts[1] : '',
              Status: article.Status,
              HexCode: article.HexCode,
              Text: isChipConfigurationValid && userStore.myAttributes ? await utils.getAttributeTypeSpecificValue(userStore.myAttributes[configuredChipAttribute], article) : '',
              TooltipText: getToolTipText(article),
            }
            item.push(chip)
            catalogPeriodMap.set(key, item)
            if (article.CatalogArticleId === props.article.CatalogArticleId) {
              selectedChip.value = chip
            }
          }
        }
      }
      // Sort the items by ColorCode
      catalogPeriodMap.forEach((item, key) => {
        item.sort((a, b) => a.ColorCode.localeCompare(b.ColorCode))
        catalogPeriodMap.set(key, item)
      })
      const item: Record<string, any> = { Season: userStore.activeCatalog.Season, catalogCode: userStore.activeCatalog.CatalogCode }
      for (const period in uniquePeriods.value) {
        if (utils.isValidStringValue(period)) {
          const data = catalogPeriodMap.get(`${userStore.activeCatalog?.CatalogCode}-${period}`)
          item[period] = data || []
        }
      }
      item.ReadOnly = false
      for (const attribute of seasonalModelAttributes.value) {
        if (modelArticlesPerSeasonMap.value && modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode] && modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode].length) {
          item[attribute.SystemName] = await utils.getAttributeTypeSpecificValue(attribute, modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode][0])
        }
        else {
          item.ReadOnly = true
        }
      }
      result.push(item)

      for (const linkedCatalog of userStore.activeCatalog.LinkedCatalogList) {
        const item: Record<string, any> = { Season: linkedCatalog.Season, catalogCode: linkedCatalog.CatalogCode }
        for (const period in uniquePeriods.value) {
          if (utils.isValidStringValue(period)) {
            const data = catalogPeriodMap.get(`${linkedCatalog.CatalogCode}-${period}`)
            item[period] = data || []
          }
        }
        if (modelArticlesPerSeasonMap.value && modelArticlesPerSeasonMap.value[linkedCatalog.CatalogCode] && modelArticlesPerSeasonMap.value[linkedCatalog.CatalogCode].length) {
          for (const attribute of seasonalModelAttributes.value) {
            item[attribute.SystemName] = await utils.getAttributeTypeSpecificValue(attribute, modelArticlesPerSeasonMap.value[linkedCatalog.CatalogCode][0])
          }
        }
        result.push(item)
      }
      const sortedCatalogCode = utils.sort(Object.values(userStore.linkedCatalogDetails), ['SeasonalSequence'], false).map(c => c.CatalogCode)
      sortedCatalogCode.splice(0, 0, userStore.activeCatalog.CatalogCode)
      result.sort((a, b) => sortedCatalogCode.indexOf(a.CatalogCode) - sortedCatalogCode.indexOf(b.CatalogCode))
    }
    return result
  },
  [],
)

const articleStatesMap = computed(() => userStore.articleStateList.reduce((acu, cur) => (acu[cur.StateId] = cur) && acu, {} as Record<number, ArticleStateModel>))

const getModelState = computed(() => {
  let result = ''
  if (props.article && props.article.ModelStateRank) {
    const modelState = articleStatesMap.value[props.article.ModelStateRank]
    if (utils.isDefined(modelState)) {
      result = ` / ${modelState.StateName}`
    }
  }
  return result
})

const seasonalModelFormRules = computed(() => {
  const result: Record<string, any> = {}
  seasonalModelAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.AttributeType !== AttributeType.Calc) {
      if (attribute.IsRequired) {
        result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
      }
      else if (utils.isValidStringValue(attribute.ValidationExpression)) {
        let message = utils.isValidStringValue(attribute.ValidationMessage) ? attribute.ValidationMessage : t('validations.invalidValue')
        if (utils.isDefined(attribute.parsedValidationExpression) && attribute.parsedValidationExpression.length) {
          const validationRules: ValidationRuleWithParams[] = []

          attribute.parsedValidationExpression.forEach((validationExpression) => {
            if (utils.isValidStringValue(validationExpression.Message)) {
              message = validationExpression.Message
            }
            const validateExpression = () => {
              let isValid = false
              if (seasonalModelForm.hasOwnProperty(attribute.SystemName) && (seasonalModelForm.hasOwnProperty(validationExpression.SourceField) || props.article.hasOwnProperty(validationExpression.SourceField))) {
                let currentValue = seasonalModelForm[attribute.SystemName]
                let sourceFieldValue = seasonalModelForm.hasOwnProperty(validationExpression.SourceField) ? seasonalModelForm[validationExpression.SourceField] : props.article[validationExpression.SourceField]
                if (utils.isValidStringValue(currentValue) && utils.isValidStringValue(sourceFieldValue)) {
                  switch (attribute.AttributeType) {
                    case AttributeType.Date:
                    case AttributeType.DateOption:
                    case AttributeType.DateTime:
                      currentValue = new Date(currentValue).getTime()
                      sourceFieldValue = new Date(sourceFieldValue).getTime()
                      break
                    default:
                      currentValue = currentValue.toString().trim().toLowerCase()
                      sourceFieldValue = sourceFieldValue.toString().trim().toLowerCase()
                  }
                }
                isValid = utils.validateExpression(currentValue, sourceFieldValue, validationExpression)
              }
              return isValid
            }
            validationRules.push(helpers.withMessage(message, validateExpression))
          })
          result[attribute.SystemName].validateExpression = validationRules
        }
        else {
          try {
            const pattern = helpers.regex(new RegExp(attribute.ValidationExpression))
            result[attribute.SystemName].pattern = helpers.withMessage(message, pattern)
          }
          catch (e) {
            console.warn(`${attribute.SystemName} validation expression is invalid`)
          }
        }
      }
    }
  })
  return result
})

const seasonalTableColumns = computed(() => {
  interface ISeasonalTableColumns extends IMyAttribute {
    isSeasonColumn: boolean
    isPeriodColumn: boolean
    isValidInActiveCatalog: boolean
    showAddOrCarryover: boolean
    showAddOrCarryoverRequest: boolean
  }
  const seasonalTableColumns: Array<ISeasonalTableColumns> = []

  seasonalTableColumns.push({
    AllowFiltering: false,
    AttributeSource: 'self',
    AttributeType: AttributeType.Nvarchar,
    Creatable: false,
    Criteria: {},
    DefaultValue: undefined,
    DisplayName: t('general.season'),
    Editable: false,
    ExternalChangeManagementURL: undefined,
    FilterLookup: new Map(),
    IsPersonal: false,
    IsRequired: false,
    IsSeasonless: false,
    IsModelLevel: false,
    IsRequest: false,
    IsStatic: true,
    Overridable: false,
    SystemName: 'Season',
    ValidationExpression: '',
    ValidationMessage: '',
    VettingList: undefined,
    Visible: true,
    isPeriodColumn: false,
    isSeasonColumn: true,
    isValidInActiveCatalog: true,
    showAddOrCarryover: false,
    showAddOrCarryoverRequest: false,
  })

  for (const period in uniquePeriods.value) {
    const catalogCode = uniquePeriods.value[period]
    if (utils.isValidStringValue(period)) {
      seasonalTableColumns.push({
        AllowFiltering: false,
        AttributeSource: 'self',
        AttributeType: AttributeType.MultiValue,
        Creatable: false,
        Criteria: {},
        DefaultValue: undefined,
        DisplayName: period,
        Editable: false,
        ExternalChangeManagementURL: undefined,
        FilterLookup: new Map(),
        IsPersonal: false,
        IsRequired: false,
        IsSeasonless: false,
        IsModelLevel: false,
        IsRequest: false,
        IsStatic: true,
        Overridable: false,
        SystemName: period,
        ValidationExpression: '',
        ValidationMessage: '',
        VettingList: undefined,
        Visible: true,
        isPeriodColumn: true,
        isSeasonColumn: false,
        isValidInActiveCatalog: catalogCode === userStore.activeCatalog?.CatalogCode,
        showAddOrCarryover: catalogCode === userStore.activeCatalog?.CatalogCode && userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticles),
        showAddOrCarryoverRequest: catalogCode === userStore.activeCatalog?.CatalogCode && userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticlesRequest),
      })
    }
  }

  seasonalModelAttributes.value.forEach((currentAttribute) => {
    const isEditable = currentAttribute.Editable && currentAttribute.AttributeType !== AttributeType.Calc && !currentAttribute.ReadOnly
      && (
        !utils.isDefined(currentAttribute.AttributeSource)
        || (
          currentAttribute.AttributeSource.toLowerCase() === 'self' || (currentAttribute.AttributeSource.toLowerCase() === 'parent' && currentAttribute.Overridable)
        )
      )
    if (currentAttribute.Criteria && Object.keys(currentAttribute.Criteria).length) {
      currentAttribute.CriteriaVettingList = currentAttribute.VettingList ? [...currentAttribute.VettingList as string[]] : []
    }
    const allowCreateRequest = currentAttribute.SystemName !== 'ModelLifecycle' && userStore.userProfile.isValidPrivilege(privileges.request.requestsToEditArticlesAttributes) && utils.isDefined(attributesToCreateRequest.value[currentAttribute.SystemName]) && (currentAttribute.ReadOnly || !currentAttribute.Editable || isFieldsReadonlyForRegionalUser.value) && currentAttribute.AttributeType !== AttributeType.Calc && !props.article._IsRequestArticle
    const attributeToPush = Object.assign({}, currentAttribute, { Editable: isEditable, isPeriodColumn: false, isSeasonColumn: false, isValidInActiveCatalog: true, showAddOrCarryover: false, showAddOrCarryoverRequest: false, AllowCreateRequest: allowCreateRequest })
    seasonalTableColumns.push(attributeToPush)
  })
  return seasonalTableColumns
})

const getFlags = computed(() => {
  if (userStore.activeCatalog?.Config.ArticleThumbnailFlags) {
    return userStore.activeCatalog?.Config.ArticleThumbnailFlags
  }
  else {
    return [] as IFlagsBandFlag[]
  }
})

const getRetailPrice = computed(() => {
  return userStore.activeCatalog?.IsLinePlan !== 1
    ? utils.formatPrice(userStore.priceGroups.retail, props.article._RetailPrice, userStore.activeCatalog?.Config.ShowPriceThousandsSeparated)
    : ''
})

// if any of the pricegroup is non editable or locked based on article state then only show the edit request button
const isPriceGroupsNotLockedAtState = computed(() => {
  if (isFieldsReadonlyForRegionalUser.value) {
    return false
  }
  else {
    const maxArticleStateRank = getArticlesMaxStateRank([props.article], !!userStore.activeCatalog!.IsPriceByStyleApply)
    for (const priceGroup of Object.values(userStore.priceGroups)) {
      if (priceGroup) {
        const priceGroupStateRank = priceGroup.StateRank || 0
        const lockBasedOnArticlesState = !skipRestrictingPropertyUpdateBasedOnArticleState && priceGroup.StateRank != null && maxArticleStateRank >= priceGroupStateRank

        if (!priceGroup.IsEditable || lockBasedOnArticlesState || doesArticleLockedDueToAnyModelArticle.value) {
          return false
        }
      }
    }
    return true
  }
})

const showBackArrow = computed(() => utils.isDefined(selectedArticleAction.value))

const infoHeaderTitle = computed(() => utils.isDefined(selectedArticleAction.value) ? allArticleActions[selectedArticleAction.value].label : '')

const isArticleActionsEnabled = computed(() => getArticleActions.value.length > 0 && userStore.activeCatalog?.CatalogCode === props.article.CatalogCode)
  && (!utils.isDefined(userStore.currentCustomer) || !props.article._IsNonSegmented)

const seasonalModelFormValidator = useVuelidate(seasonalModelFormRules, seasonalModelForm)

function seasonlessModelFormDirtyStateChanged(isDirty) {
  isSeasonlessModelFormDirty.value = isDirty
}

function seasonlessModelFormChanged(dirtyAttributesValue) {
  seasonlessModelFormModel = dirtyAttributesValue
}

function onSettingsChanged(form: ISettingItem) {
  showRequests.value = form.showRequests
}

function onCatalogSeasonalDataChange(column) {
  // once any attribute value in seasonal model form change, mark the entire form touched
  for (const key in seasonalModelForm) {
    if (seasonalModelFormValidator.value.hasOwnProperty(key)) {
      seasonalModelFormValidator.value[key].$touch()
    }
  }
  seasonalModelFormDirtyAttributes.push(column.SystemName)
  updateCriteriaAttributeAllowedValues(seasonalModelAttributes, seasonalModelForm, props.article)
  updateLookupAttributeValues(seasonalModelAttributes, seasonalModelForm, props.article, seasonalModelFormDirtyAttributes)
  isSeasonalModelFormDirty.value = true
}

function articleFormDirtyStateChanged(isDirty) {
  isArticleFormDirty.value = isDirty
}

function articleFormChanged(dirtyAttributesValue) {
  articleFormModel = dirtyAttributesValue
}

function getArticleSeason(article: MyArticle) {
  return userStore.activeCatalog?.CatalogCode === article.CatalogCode
    ? userStore.activeCatalog.Season
    : userStore.linkedCatalogDetails[article.CatalogCode] ? userStore.linkedCatalogDetails[article.CatalogCode].Season : article._Seasons
}

function onBackClicked() {
  selectedArticleAction.value = null
}

function onArticleUpdated(articles: MyArticle[] | Article[], type: string = '', isRequestConfirmed = false) {
  const message = getUpdatedMessage(articles, type)
  notificationStore.addNotification({ message, type: 'Success' })
  onBackClicked()
  onLoad()
  if (isRequestConfirmed) {
    isConfirmedFinished.value = true
  }
}

// WATCHERS
watchEffect(() => {
  if (props.article && props.article.ModelStateRank) {
    const modelState = articleStatesMap.value[props.article.ModelStateRank]
    if (utils.isDefined(modelState)) {
      // if user has special permission assigned to his role then skip restriction
      if (!skipRestrictingPropertyUpdateBasedOnArticleState) {
        // check if the model name is editable
        isModelNameEditableAtStateLevel.value = modelState.IsModelNameEditable === 1
      }
    }
  }
})

watchEffect(() => {
  carryoverModelContainsArticleOrPlaceholder.value = false
  Object.values(newOrCarryoverArticlesOrRequests.value).forEach((perPeriodForm) => {
    if (perPeriodForm.ph > 0 || perPeriodForm.articleIds.size > 0) {
      carryoverModelContainsArticleOrPlaceholder.value = true
    }
  })
})

watchEffect(() => {
  showCreateOrUpdateSaveButton.value = false
  if (carryoverModelContainsArticleOrPlaceholder.value || isSeasonlessModelFormDirty.value || isArticleFormDirty.value || isSeasonalModelFormDirty.value || props.article.ModelName !== localModelName.value) {
    showCreateOrUpdateSaveButton.value = true
  }
})

watchEffect(() => {
  // TODO: find better solution to find out the width of textbox
  if (props.article && localModelName.value) {
    const modelNameString = localModelName.value || props.article.ModelName
    const characterLength = modelNameString ? modelNameString.length : 0
    const calculatedWidth = characterLength * 12.7
    // setting a minimum width
    const minWidth = '100px'
    // set the width for the input box
    modelNameInputWidth.value = `max(${calculatedWidth}px, ${minWidth})`
    // check if the model name length is above configured value
    if (characterLength > modelNameFieldMaxLength && isModelNameEditableAtStateLevel.value) {
      hasModelNameMaxLengthError.value = true
    }
    else {
      hasModelNameMaxLengthError.value = false
    }
  }
})
// WATCHERS - END
function getToolTipText(article) {
  let tooltipText = ''
  if (userStore && userStore.activeCatalog) {
    const modelDetailsChipTooltipTextConfiguration = userStore.activeCatalog.Config.ModelDetailsChipTooltipText
    const isChipConfigurationValid = modelDetailsChipTooltipTextConfiguration && userStore.myAttributes
    if (isChipConfigurationValid) {
      tooltipText = modelDetailsChipTooltipTextConfiguration.replace(/\$([\w@#]+)/g, (match, key) => getDisplayValueForToolTip(article, key) || '')
    }
  }
  return tooltipText
}
function getDisplayValueForToolTip(article, key) {
  if (userStore && userStore.myAttributes && userStore.myAttributes[key] && userStore.myAttributes[key].AttributeType === AttributeType.ColorPalette) {
    let articleValue = article[key]
    if (utils.isDefined(articleValue)) {
      const parsedValue = utils.tryParse(articleValue.toString())
      if (parsedValue) {
        const values = Object.values(parsedValue) as string[]
        articleValue = values.length > 0 ? values[0] : ''
      }
      return articleValue
    }
    else {
      return article[key]
    }
  }
  else {
    return article[key]
  }
}
function getAvailableCarryoverArticles(period: string) {
  const articleIdSet = new Set<number>()
  const result: IColorCodeChip[] = []
  if (userStore.activeCatalog && catalogsData.value && catalogsData.value.length) {
    const currentCatalogArticleIdSet = new Set<number>()
    if (props.article.CatalogCode === userStore.activeCatalog.CatalogCode) {
      currentCatalogArticleIdSet.add(props.article.Id)
    }
    for (const period in uniquePeriods.value) {
      if (catalogsData.value[0][period] && catalogsData.value[0][period].length) {
        catalogsData.value[0][period].forEach((art) => {
          if (art.CatalogCode === userStore.activeCatalog!.CatalogCode) {
            if (art.IsRequest && art.SourceArticleId) {
              currentCatalogArticleIdSet.add(art.SourceArticleId)
            }
            else {
              currentCatalogArticleIdSet.add(art.ArticleId)
            }
          }
        })
      }
    }
    Object.keys(modelArticlesPerSeasonMap.value).forEach((catalogCode) => {
      modelArticlesPerSeasonMap.value[catalogCode].forEach((art) => {
        if (art.Status === 1 && utils.isDefined(art.ColorId) && utils.isDefined(art.Period) && uniquePeriods.value.hasOwnProperty(art.Period) && !articleIdSet.has(art.Id) && !art._IsRequestArticle) {
          const isArticleAlreadyInCurrentCatalog = currentCatalogArticleIdSet.has(art.Id)
          let isAvailableInOtherPeriod = false
          for (const key in newOrCarryoverArticlesOrRequests.value) {
            if (key !== period && newOrCarryoverArticlesOrRequests.value[key].articleIds.has(art.Id)) {
              isAvailableInOtherPeriod = true
            }
          }
          if (!isAvailableInOtherPeriod && !isArticleAlreadyInCurrentCatalog) {
            const articleNumberParts = art.ArticleNumber?.split('-')
            result.push({
              Type: art._IsRequestArticle ? 'circle' : 'rect',
              CatalogCode: art.CatalogCode,
              ArticleId: art.Id,
              ArticleNumber: art.ArticleNumber,
              CatalogArticleId: art.CatalogArticleId,
              SourceArticleId: art._SourceArticleId,
              IsRequest: art._IsRequestArticle,
              ColorCode: art.ColorwayCode ? art.ColorwayCode.toString() : articleNumberParts && articleNumberParts.length > 1 ? articleNumberParts[1] : '',
              Status: art.Status,
              HexCode: art.HexCode,
              Text: '',
              TooltipText: '',
            })
            articleIdSet.add(art.Id)
          }
        }
      })
    })
  }
  return result.sort((a, b) => a.ColorCode.localeCompare(b.ColorCode))
}

async function refreshRequestDetails() {
  disableRequestActions.value = false
  onLoad()
}

async function onRequestArticleSelected(requestId: number) {
  if (userStore.activeCatalog) {
    const requestArticle = await appConfig.DB!.getRequestArticleById(userStore.activeCatalog, requestId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    if (requestArticle) {
      onArticleChange(requestArticle)
    }
  }
}

async function onRequestNumberClick(requestId: number | null) {
  if (utils.isDefined(requestId)) {
    if (modelTabs.requests.visible) {
      activeModelTab.value = modelTabs.requests.key
      selectedRequestId.value = undefined
      nextTick(() => {
        selectedRequestId.value = requestId
      })
    }
    else if (userStore.activeCatalog) {
      const requestArticle = await appConfig.DB!.getRequestArticleById(userStore.activeCatalog, requestId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
      if (requestArticle) {
        onArticleChange(requestArticle, true)
      }
    }
  }
}

async function onArticleNumberClick(articleNumber: string) {
  if (userStore.activeCatalog && utils.isValidStringValue(articleNumber)) {
    const articles = await appConfig.DB!.getMyArticlesByArticleNumbers(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, [articleNumber], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, userStore.currentCustomer, userStore.currentCustomerSegmentations)
    if (articles.length) {
      onArticleChange(articles[0])
    }
  }
}

async function onColorCodeClicked(chip: IColorCodeChip) {
  selectedChip.value = chip
  const catalogDetails = userStore.activeCatalog?.CatalogCode === chip.CatalogCode ? userStore.activeCatalog : userStore.linkedCatalogDetails[chip.CatalogCode]
  let articles: MyArticle[] = []
  if (chip.IsRequest) {
    const requestArticle = await appConfig.DB!.getRequestArticleById(catalogDetails, chip.ArticleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    if (requestArticle) {
      articles.push(requestArticle)
    }
  }
  else {
    // need to load from the memory data
    if (linkedCatalogCodesNotPartOfCurrentCatalog.value && linkedCatalogCodesNotPartOfCurrentCatalog.value.includes(chip.CatalogCode)) {
      const linkedArticle: MyArticle | Article | undefined = modelArticlesPerSeasonMap.value[chip.CatalogCode].find(article => article.Id === chip.ArticleId)
      if (linkedArticle instanceof MyArticle) {
        emit('changeArticleSelection', linkedArticle)
        return
      }
      else {
        console.warn('should never happen')
      }
    }
    else {
      articles = await appConfig.DB!.getMyArticlesById(catalogDetails, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, chip.ArticleId, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
    }
  }
  if (articles.length) {
    onArticleChange(articles[0])
  }
  if (chip.IsRequest) {
    selectedRequestId.value = chip.ArticleId
    selectedObjectId.value = chip.CatalogArticleId
  }
}

function onAddOrCarryover(e: MouseEvent, period: string, isRequest = false) {
  refAddOrCarryoverEditor.value!.style.left = `${e.clientX - 100}px`
  refAddOrCarryoverEditor.value!.style.top = `${e.clientY + 10}px`
  if (!newOrCarryoverArticlesOrRequests.value.hasOwnProperty(period)) {
    newOrCarryoverArticlesOrRequests.value[period] = { ph: 0, articleIds: new Set<number>() }
  }
  // reset form
  for (const key in addOrCarryoverEditorForm) {
    if (key !== 'ph' && addOrCarryoverEditorForm[key]) {
      addOrCarryoverEditorForm[key] = false
    }
  }
  addOrCarryoverEditorForm.ph = newOrCarryoverArticlesOrRequests.value[period].ph
  newOrCarryoverArticlesOrRequests.value[period].articleIds.forEach((artId) => {
    addOrCarryoverEditorForm[artId] = true
  })
  selectedPeriod.value = period
  if (isRequest) {
    showAddOrCarryoverRequestEditor.value = true
  }
  else {
    showAddOrCarryoverEditor.value = true
  }
}

function closeAddOrCarryoverEditor() {
  showAddOrCarryoverEditor.value = false
  showAddOrCarryoverRequestEditor.value = false
  selectedPeriod.value = ''
}

function onSaveAddOrCarryover() {
  if (newOrCarryoverArticlesOrRequests.value[selectedPeriod.value]) {
    newOrCarryoverArticlesOrRequests.value[selectedPeriod.value].ph = addOrCarryoverEditorForm.ph
    newOrCarryoverArticlesOrRequests.value[selectedPeriod.value].articleIds.clear()
    for (const key in addOrCarryoverEditorForm) {
      if (key !== 'ph' && addOrCarryoverEditorForm[key]) {
        newOrCarryoverArticlesOrRequests.value[selectedPeriod.value].articleIds.add(Number(key))
      }
    }

    // hide other add button
    let showAddOrCarryover = userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticles)
    let showAddOrCarryoverRequest = userStore.userProfile.isValidPrivilege(privileges.article.addOrCarryoverArticlesRequest)
    if (newOrCarryoverArticlesOrRequests.value) {
      for (const period in newOrCarryoverArticlesOrRequests.value) {
        if (newOrCarryoverArticlesOrRequests.value[period].ph > 0 || newOrCarryoverArticlesOrRequests.value[period].articleIds.size > 0) {
          if (showAddOrCarryoverRequestEditor.value) {
            showAddOrCarryover = false
          }
          else {
            showAddOrCarryoverRequest = false
          }
        }
      }
    }
    if (seasonalTableColumns.value) {
      seasonalTableColumns.value.forEach((column) => {
        if (column.isValidInActiveCatalog) {
          column.showAddOrCarryover = showAddOrCarryover
          column.showAddOrCarryoverRequest = showAddOrCarryoverRequest
        }
      })
    }
  }
  addOrCarryoverNewUpdate.value = true
  isRequestToAdd.value = showAddOrCarryoverRequestEditor.value
  closeAddOrCarryoverEditor()
}

function onArticleChange(article: MyArticle, reload = false) {
  emit('change', article, reload)
}

function onEditFav() {
  emit('onEditFav', props.article)
}

async function getModelArticles() {
  if (userStore.activeCatalog && props.article._RequestSource !== requestConstants.requestSources.similarStyle) {
    modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode] = []
    const queryCriterion: Array<[number, number]> = [[userStore.activeCatalog.CatalogCode, props.article.ModelId]]
    userStore.activeCatalog.LinkedCatalogList.forEach((linkedCatalog) => {
      modelArticlesPerSeasonMap.value[linkedCatalog.CatalogCode] = []
      if (userStore.linkedCatalogDetails[linkedCatalog.CatalogCode]) {
        queryCriterion.push([+linkedCatalog.CatalogCode, props.article!.ModelId])
      }
      else {
        if (linkedCatalogCodesNotPartOfCurrentCatalog.value.length < 2) { linkedCatalogCodesNotPartOfCurrentCatalog.value.push(linkedCatalog.CatalogCode) }
      }
    })
    const res = await appConfig.DB!.getArticlesOrMyArticlesByCatalogCodeModelId(userStore.activeCatalog, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, queryCriterion, undefined, false, userStore.currentCustomer, userStore.currentCustomerSegmentations, true)
    if (res && isArray(res) && res[0]) {
      res.forEach((article) => {
        modelArticlesPerSeasonMap.value[article.CatalogCode].push(article)
        articleIdArticleNumberMap[article.Id] = article.ArticleNumber
      })
    }
    // If any further linked catalogs are configured then need to fetch the details of the catalog
    if (linkedCatalogCodesNotPartOfCurrentCatalog.value.length && userStore.activeCatalog) {
      try {
        const linkedCatalogArticlesResponse = await getLinkedCatalogArticles(userStore.activeCatalog.CatalogCode, props.article.ModelId, linkedCatalogCodesNotPartOfCurrentCatalog.value)
        if (linkedCatalogArticlesResponse.data && linkedCatalogArticlesResponse.data.length > 0) {
          const modelPrices: Record<number, ArticlePriceModel[]> = {}
          for (let i = 0; i < linkedCatalogCodesNotPartOfCurrentCatalog.value.length; i++) {
            const linkedCatalogHasAnyArticle = linkedCatalogArticlesResponse.data.filter(catalogData => catalogData.Articles.length > 0)
            if (userStore.activeCatalog!.IsPriceByStyleApply && linkedCatalogHasAnyArticle.length) {
              linkCatalogIndexedByPriceNameToId.value[linkedCatalogCodesNotPartOfCurrentCatalog.value[i]] = {}
              const modelResponse = await getModelPrices(linkedCatalogCodesNotPartOfCurrentCatalog.value[i], props.article.ModelId)
              if (modelResponse.data && modelResponse.data.length > 0) {
                modelPrices[linkedCatalogCodesNotPartOfCurrentCatalog.value[i]] = modelResponse.data
                modelResponse.data.forEach((priceData) => {
                  linkCatalogIndexedByPriceNameToId.value[linkedCatalogCodesNotPartOfCurrentCatalog.value[i]][priceData.Name.toString().trim().toLowerCase()] = priceData.PriceGroupId
                })
              }
            }
          }
          for (let i = 0; i < linkedCatalogArticlesResponse.data.length; i++) {
            const catalog = linkedCatalogArticlesResponse.data[i]
            const articles: Article[] = []
            for (let j = 0; j < catalog.Articles.length; j++) {
              const article: any = catalog.Articles[j]
              article._Prices = {}
              if (modelPrices[catalog.CatalogCode]) {
                modelPrices[catalog.CatalogCode].forEach((price) => {
                  article._Prices[price.PriceGroupId] = price
                })
              }
              articles.push(new Article(catalog.CatalogCode, userStore.activeCatalog!.AssignedCatalogAttributes, article, catalog.DataSourcetypeId))
            }
            const myArticles: MyArticle[] = appConfig.DB!.buildMyArticlesForLinkedCatalogArticleForDetails(articles, userStore.activeCatalog!.AssignedCatalogAttributes, catalog.Season, catalog.DataSourcetypeId, userStore.activeCatalog!.Config.ArticlesCustomSortOrder, linkCatalogIndexedByPriceNameToId.value[catalog.CatalogCode] || [], userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet)
            if (myArticles.length) {
              modelArticlesPerSeasonMap.value[catalog.CatalogCode] = myArticles
            }
          }
        }
      }
      catch (e) {
        notificationStore.addNotification(({ message: t('articleDetails.alerts.linkedCatalogFetchFailed', { linkedCatalogList: linkedCatalogCodesNotPartOfCurrentCatalog.value.join(',') }), type: 'Alert' }))
      }
    }
  }
  if (userStore.activeCatalog) {
    doesModelBelongsToActiveCatalog.value = (await appConfig.DB!.articles
      .where({ CatalogCode: userStore.activeCatalog.CatalogCode, ModelId: props.article.ModelId }).count()) > 0
  }
  doesArticleLockedDueToAnyModelArticle.value = await getArticlesLocked([props.article], !!userStore.activeCatalog!.IsPriceByStyleApply)
}

async function getModelRequests() {
  modelRequests.value = []
  if (userStore.activeCatalog && appConfig.DB) {
    const requests = await appConfig.DB.requests
      .where({ SourceModelId: props.article.ModelId })
      .filter((r) => {
        if (r.CatalogCode === userStore.activeCatalog!.CatalogCode && (props.article._RequestSource === requestConstants.requestSources.similarStyle
          ? r.RequestSource === requestConstants.requestSources.similarStyle && r.RequestModelNumber === props.article.ModelNumber
          : r.RequestSource === requestConstants.requestSources.new || r.RequestSource === requestConstants.requestSources.carryover)) {
          return true
        }
        else if (r.RequestSource === requestConstants.requestSources.editArticle) {
          return (r.CatalogCode === userStore.activeCatalog!.CatalogCode || (r.ImpactedCatalogs && r.ImpactedCatalogs.some(x => x.CatalogCode === userStore.activeCatalog!.CatalogCode)))
        }
        return false
      })
      .toArray()

    if (userStore.myAttributes && requests.length && userStore.activeCatalog.RequestAttributeList && userStore.activeCatalog.RequestAttributeList.length) {
      for (const requestAttribute of userStore.activeCatalog.RequestAttributeList) {
        const attribute = userStore.myAttributes[requestAttribute.AttributeSystemName]
        if (attribute && attribute.AttributeType === AttributeType.Calc && attribute._calcFunction) {
          for (const request of requests) {
            const value = await utils.getCalcRequestAttributeValue(userStore.activeCatalog, attribute, request, request.Content, appConfig.DB, request.RequestAttributes, userStore.priceGroups)
            if (utils.isDefined(value)) {
              if (!utils.isDefined(request.RequestAttributes)) {
                request.RequestAttributes = {}
              }
              request.RequestAttributes[attribute.SystemName] = [value]
            }
          }
        }
      }
    }

    modelRequests.value = requests
  }
}

async function onLoad() {
  if (userStore.activeCatalog) {
    loading.value = true
    modelTabs.requests.visible = false
    modelArticlesPerSeasonMap.value = {}

    if (props.article._RequestSource === requestConstants.requestSources.similarStyle) {
      localReadonly.value = true
      modelTabs.stats.visible = false
      activeModelTab.value = modelTabs.details.key
    }

    // regional users are not allowed to do any change
    if ((userStore.userProfile.AccountDetails.AccountTypeId !== 1 && userStore.userProfile.AccountDetails.AccountId !== userStore.activeCatalog!.AccountId)
      || (utils.isDefined(userStore.currentCustomer) && props.article._IsNonSegmented)) {
      localReadonly.value = true
      isFieldsReadonlyForRegionalUser.value = true
    }

    Promise.all([
      getModelArticles(),
      getModelRequests(),
    ]).then(() => {})
      .catch(e => console.error(e))
      .finally(async () => {
        if (modelRequests.value.length) {
          modelTabs.requests.visible = true
          if (!utils.isDefined(modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode])) {
            modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode] = []
          }
          for (const request of modelRequests.value) {
            if (request.Content) {
              const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB, userStore.myAttributes, userStore.priceGroups)
              if (request.IsCreateArticleRequest && (request.RequestSource === requestConstants.requestSources.similarStyle
                || requestArticle._RequestState !== requestConstants.requestStates.confirm)) {
                const articlesInSeason = modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode]
                const articleExists = articlesInSeason.some(article => article.Id === requestArticle.Id)
                if (!articleExists) {
                  articlesInSeason.push(requestArticle)
                }
              }
            }
          }
        }
        // init current catalog seasonal article level form data
        const article = utils.isDefined(modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode]) && modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode].length > 0
          ? modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode][0]
          : undefined

        seasonalModelAttributes.value.forEach((attribute) => {
          let articleAttributeValue = article ? utils.getArticleAttributeValue(attribute, article) : undefined
          const sourceFields: Record<string, any> = {}
          if (utils.isDefined(attribute.parsedValidationExpression) && attribute.parsedValidationExpression.length) {
            attribute.parsedValidationExpression.forEach((itm) => {
              if (utils.isDefined(itm.SourceField) && props.article.hasOwnProperty(itm.SourceField)) {
                sourceFields[itm.SourceField] = props.article[itm.SourceField]
              }
            })
          }
          if (!utils.isDefined(articleAttributeValue) && attribute.AttributeType === AttributeType.MultiValue && attribute.VettingList && attribute.VettingList.length > 0) {
            articleAttributeValue = []
          }
          seasonalModelForm[attribute.SystemName] = articleAttributeValue
        })

        loading.value = false
      })
    isSeasonalModelFormDirty.value = false
    nextTick(() => {
      if (window.history.state.requestId) {
        activeModelTab.value = modelTabs.requests.key
        selectedRequestId.value = window.history.state.requestId
      }
      else if (props.requestId) {
        activeModelTab.value = modelTabs.requests.key
        selectedRequestId.value = props.requestId
      }
    })
  }
}

async function onSaveButtonClicked() {
  addOrCarryoverNewUpdate.value = false
  if (isSeasonlessModelFormDirty.value || isArticleFormDirty.value || isSeasonalModelFormDirty.value || props.article.ModelName !== localModelName.value) {
    // the API used to update seasonal data does not support the model name , if model name is changed then need to call the updateArticle() only
    if (isArticleFormDirty.value || (isSeasonalModelFormDirty.value && props.article.ModelName !== localModelName.value)) {
      await updateArticles()
    }
    else if (isSeasonalModelFormDirty.value) {
      await updateModelAttribute()
    }
    else {
      await updateModelSeasonlessAttribute()
    }
  }
  if (carryoverModelContainsArticleOrPlaceholder.value) {
    selectedRequestArticles.value = []
    currentView.value = viewEnums.addArticleForm
    styleCreateAction.value = 'carryoverModel'
  }
}

async function updateModelSeasonlessAttribute() {
  isLoading.value = true
  const dynamicAttributesPayload: Record<string, any> = {}
  seasonlessModelAttributes.value.forEach((attribute) => {
    if (seasonlessModelFormModel.hasOwnProperty(attribute.SystemName)) {
      const modelValue = seasonlessModelFormModel[attribute.SystemName]
      dynamicAttributesPayload[attribute.SystemName] = normalizeAttributeValue(modelValue, attribute)
    }
  })
  const updateModelPayload: Record<string, any> = {
    ModelNumber: props.article.ModelNumber,
    ...dynamicAttributesPayload,
  }
  if (props.article.ModelName !== localModelName.value) {
    updateModelPayload.ModelName = localModelName.value
  }
  try {
    await updateModelSeasonlessAttributes(userStore.activeCatalog!.AccountId, userStore.activeCatalog!.AttributeGroupId, props.article.ModelId, updateModelPayload)
    await refreshLocalArticlesData(true, props.article.ModelId)
    notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
  }
  catch (error) {
    console.error(error)
  }
  finally {
    isLoading.value = false
    onLoad()
  }
}
async function updateModelAttribute() {
  isLoading.value = true
  const dynamicAttributesPayload: Record<string, any> = {}
  seasonlessModelAttributes.value.forEach((attribute) => {
    if (seasonlessModelFormModel.hasOwnProperty(attribute.SystemName)) {
      const modelValue = seasonlessModelFormModel[attribute.SystemName]
      dynamicAttributesPayload[attribute.SystemName] = normalizeAttributeValue(modelValue, attribute)
    }
  })
  seasonalModelAttributes.value.forEach((attribute) => {
    if (seasonalModelForm.hasOwnProperty(attribute.SystemName) && seasonalModelFormDirtyAttributes.includes(attribute.SystemName)) {
      const modelValue = seasonalModelForm[attribute.SystemName]
      dynamicAttributesPayload[attribute.SystemName] = normalizeAttributeValue(modelValue, attribute)
    }
  })
  const updateModelPayload = {
    ModelNumber: props.article.ModelNumber,
    ...dynamicAttributesPayload,
  }
  try {
    await updateModelAttributes(userStore.activeCatalog!.CatalogCode, props.article.ModelId, updateModelPayload)
    await refreshLocalArticlesData(false, props.article.ModelId)
    notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
  }
  catch (error) {
    console.error(error)
  }
  finally {
    isLoading.value = false
    seasonalModelFormDirtyAttributes.length = 0
    onLoad()
  }
}
async function updateArticles() {
  isLoading.value = true
  const dynamicAttributesPayload = {}
  // TODO: check if this can be handle in attributeEditor
  seasonalModelAttributes.value.forEach((attribute) => {
    if (seasonalModelForm.hasOwnProperty(attribute.SystemName) && seasonalModelFormDirtyAttributes.includes(attribute.SystemName)) {
      const modelValue = seasonalModelForm[attribute.SystemName]
      dynamicAttributesPayload[attribute.SystemName] = normalizeAttributeValue(modelValue, attribute)
    }
  })
  seasonlessModelAttributes.value.forEach((attribute) => {
    if (seasonlessModelFormModel.hasOwnProperty(attribute.SystemName)) {
      const modelValue = seasonlessModelFormModel[attribute.SystemName]
      dynamicAttributesPayload[attribute.SystemName] = normalizeAttributeValue(modelValue, attribute)
    }
  })
  articleEditableAttributes.value.forEach((attribute) => {
    if (articleFormModel.hasOwnProperty(attribute.SystemName)) {
      const modelValue = articleFormModel[attribute.SystemName]
      dynamicAttributesPayload[attribute.SystemName] = normalizeAttributeValue(modelValue, attribute)
    }
  })
  const updateModelPayload = {
    ModelId: props.article.ModelId,
    ModelNumber: props.article.ModelNumber,
    ArticleNumber: props.article.ArticleNumber,
    ArticleName: localModelName.value,
    ...dynamicAttributesPayload,
  }
  if (!isEmpty(seasonlessModelFormModel) || !isEmpty(articleFormModel) || !isEmpty(seasonalModelForm)) {
    const modelLevelAttributesSystemNameList = userStore.activeCatalog?.ModelLevelAttributeList.map(attribute => attribute.AttributeSystemName)
    try {
      // if model level seasonless is dirty (!isEmpty(seasonlessModelFormModel)) or if seasonal article form is dirty and it contains model level make createModelFlag true
      const doesContainsModelLevelAttribute = !isEmpty(seasonlessModelFormModel) || (isSeasonalModelFormDirty.value && seasonalModelAttributes.value.some(attribute => modelLevelAttributesSystemNameList?.includes(attribute.SystemName)))
      await updateArticle(userStore.activeCatalog!.CatalogCode, props.article.Id, updateModelPayload, doesContainsModelLevelAttribute)
      await refreshLocalArticlesData(true, props.article.ModelId)
      notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
    }
    catch (error) {
      console.error(error)
    }
    finally {
      isLoading.value = false
      seasonalModelFormDirtyAttributes.length = 0
      onLoad()
    }
  }
  else {
    isLoading.value = false
  }
}

function closeDrawer() {
  emit('close')
}

async function loadAssets() {
  if (!props.article && !userStore.activeCatalog) { return }
  // as discussed with Amardeep if catalog details are not present for linked catalog use the current catalog context
  const cat = props.article.CatalogCode === userStore.activeCatalog?.CatalogCode ? userStore.activeCatalog : userStore.linkedCatalogDetails[props.article.CatalogCode] ? userStore.linkedCatalogDetails[props.article.CatalogCode] : userStore.activeCatalog!
  const res = await utils.tryAsync(getArticleAssets(cat.DuneContext, cat.ContextKey, props.article.ArticleNumber, userStore.activeCatalog?.Config.NewestImageAssetKeyList))
  if (res.success) {
    emit('updated', '_Assets', res.result)
  }
}

onMounted(() => {
  onLoad()
  loadAssets()
  onClickOutside(refAddOrCarryoverEditor.value, () => {
    closeAddOrCarryoverEditor()
  })
})

function onCreateRequest(attribute: IMyAttribute) {
  if (attribute.SystemName === '_RetailPrice') { // __RetailPrice is just a temporary price attribute considered for the Prices. From here we will send SystemName as 'Prices' to the Form.
    const pricesAttribute: IMyAttribute = Object.assign({}, appConstants.staticFieldTemplate, {
      SystemName: 'Prices',
      DisplayName: editRequestKeys.prices,
      Creatable: true,
      AttributeType: AttributeType.Decimal,
    })
    updateAttributeRequestDlg.value?.showDialog(props.article, pricesAttribute, doesArticleLockedDueToAnyModelArticle.value)
  }
  else {
    updateAttributeRequestDlg.value?.showDialog(props.article, attribute)
  }
}

async function onEditRequest() {
  loading.value = true
  try {
    await getModelRequests()

    if (modelRequests.value.length) {
      if (!utils.isDefined(modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode])) {
        modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode] = []
      }

      for (const request of modelRequests.value) {
        if (request.Content) {
          const requestArticle = await utils.getRequestArticle(userStore.activeCatalog!, request, appConfig.DB, userStore.myAttributes, userStore.priceGroups)
          if (request.IsCreateArticleRequest && (request.RequestSource === requestConstants.requestSources.similarStyle
            || requestArticle._RequestState !== requestConstants.requestStates.confirm)) {
            const articlesInSeason = modelArticlesPerSeasonMap.value[userStore.activeCatalog!.CatalogCode]
            const articleExists = articlesInSeason.some(article => article.Id === requestArticle.Id)
            if (!articleExists) {
              articlesInSeason.push(requestArticle)
            }
          }
        }
      }

      modelTabs.requests.visible = true
    }
  }
  catch (e) {
    console.error(e)
  }
  finally {
    loading.value = false
  }
}

async function onConfirmRequest(request: RequestModel) {
  selectedRequestArticles.value = []
  isConfirmedFinished.value = false
  if (userStore.activeCatalog && request.Content) {
    if (request.IsCreateArticleRequest) {
      isRequestToConfirm.value = true
      isRequestToAdd.value = false
      newOrCarryoverArticlesOrRequests.value = {}
      const requestArticle = await utils.getRequestArticle(userStore.activeCatalog, request, appConfig.DB, userStore.myAttributes, userStore.priceGroups)
      const retailPrice = userStore.priceGroups.retail && requestArticle._Prices ? requestArticle._Prices[userStore.priceGroups.retail.Id]?.Price || 0 : 0
      const wholesalePrice = userStore.priceGroups.wholesale && requestArticle._Prices ? requestArticle._Prices[userStore.priceGroups.wholesale.Id]?.Price || 0 : 0
      const outletPrice = userStore.priceGroups.outlet && requestArticle._Prices ? requestArticle._Prices[userStore.priceGroups.outlet.Id]?.Price || 0 : 0
      selectedRequestArticles.value.push(new MyArticle(requestArticle, userStore.activeCatalog!.AssignedCatalogAttributes, retailPrice, wholesalePrice, outletPrice, [], true, [], [], [], userStore.activeCatalog!.Season, userStore.activeCatalog!.DataSourceTypeId, userStore.activeCatalog!.Config.ArticlesCustomSortOrder))
      if (selectedRequestArticles.value.length) {
        currentView.value = viewEnums.addArticleForm
        const requestModelHasAnyConfirmedRequest = await appConfig.DB?.requests.where({ CatalogCode: userStore.activeCatalog.CatalogCode, RequestModelNumber: requestArticle.ModelNumber }).filter(request => request.State === requestConstants.requestStates.confirm).first()
        styleCreateAction.value = request.RequestSource === requestConstants.requestSources.similarStyle && !requestModelHasAnyConfirmedRequest ? 'copyModel' : 'carryoverModel'
      }
    }
    else { // edit request
      disableRequestActions.value = true
      confirmRequestId.value = null
      const title = t('general.alert')
      let message = t('requests.confirmRequestMessage')
      // add comment and reason
      const confirmContentArray: any[] = []
      confirmContentArray.push({ header: `<strong>${t('requests.title.approvalRejectionReason')}:</strong>` })
      confirmContentArray.push(`${t('requestsTable.fields.reason')}: ${request.Reason}`)
      confirmContentArray.push(`${t('requestsTable.fields.comment')}: ${request.Comment}`)

      const attributesList = Object.values(userStore.myAttributes!)
      const modelLevelAttributeNames = attributesList
        .filter(attribute => attribute.IsModelLevel)
        .map(attribute => attribute.SystemName)

      const key = Object.keys(request.Content)[0]
      // show impacted seasons for confirm edit prices/ seasonless attributes request
      if (request.ImpactedCatalogs && request.ImpactedCatalogs.length) {
        const uniqueSeasons = [...new Set(request.ImpactedCatalogs.map(season => season.Season))].join(',')
        const affectedSeasonsString = uniqueSeasons
        message = t('requests.confirmEditPricesRequestMessage', { seasons: affectedSeasonsString })
      }

      const wasConfirmed = await confirmDialog(title, message, confirmContentArray, async () => {
        let newValue = request.Content[key]
        const propertiesToUpdate = { state: requestConstants.requestStates.confirm, comment: request.Comment, reason: request.Reason, updatedByUserName: userStore.currentUsername }
        const attr = attributesList.filter(attribute => attribute.SystemName === key)[0]
        if (attr && (!attr.IsStatic || key === editRequestKeys.modelName)) {
          const updatePayload = {
            ModelId: props.article.ModelId,
            ModelNumber: props.article.ModelNumber,
            ArticleNumber: props.article.ArticleNumber,
            ArticleName: props.article.ArticleName,
          }
          if (attr.AttributeType === AttributeType.ColorPalette) {
            newValue = JSON.stringify(newValue)
          }
          if (key === editRequestKeys.modelName) {
            updatePayload.ArticleName = newValue
          }
          else {
            updatePayload[key] = newValue
          }
          try {
            await updateArticle(userStore.activeCatalog!.CatalogCode, props.article.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
            await refreshLocalArticlesData(true, props.article.ModelId)
            await updateRequestsLocally([request.Id], propertiesToUpdate)
            refreshRequestDetails()
            isConfirmedFinished.value = true
            notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
          }
          catch (error) {
            disableRequestActions.value = false
            console.error(error)
          }
        }
        if ((attr && attr.IsStatic) || key === editRequestKeys.prices) {
          if (key === editRequestKeys.stateName) {
            requestedValue.value = Number.parseInt(newValue)
            confirmRequestId.value = request.Id
            selectedArticleAction.value = allArticleActions.manageArticleState.key
          }
          else if (key === editRequestKeys.colorId) {
            const updatePayload = {
              ModelId: props.article.ModelId,
              ModelNumber: props.article.ModelNumber,
              ArticleNumber: props.article.ArticleNumber,
              ArticleName: props.article.ArticleName,
            }
            const colorId = newValue ? Object.keys(newValue)[0] : newValue
            updatePayload[key] = colorId
            try {
              await updateArticle(userStore.activeCatalog!.CatalogCode, props.article.Id, updatePayload, modelLevelAttributeNames.includes(key), request.Id)
              await refreshLocalArticlesData(true, props.article.ModelId)
              await updateRequestsLocally([request.Id], propertiesToUpdate)
              refreshRequestDetails()
              isConfirmedFinished.value = true
              notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              disableRequestActions.value = false
              console.error(error)
            }
          }
          else if (key === editRequestKeys.status) {
            requestedValue.value = Number.parseInt(newValue)
            confirmRequestId.value = request.Id
            selectedArticleAction.value = newValue === 1 ? allArticleActions.activate.key : allArticleActions.deactivate.key
          }
          else if (key === editRequestKeys.sizeScale) {
            try {
              await updateModelSizeScale(userStore.activeCatalog!.CatalogCode, request.SourceModelId!, { SizeScaleId: newValue }, request.Id)
              await refreshLocalArticlesData(true, props.article.ModelId)
              await updateRequestsLocally([request.Id], propertiesToUpdate)
              refreshRequestDetails()
              isConfirmedFinished.value = true
              notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              disableRequestActions.value = false
              console.error(error)
            }
          }
          else if (key === editRequestKeys.prices) {
            const requestedPrices = request.Content.Prices.map(contentPrice => ({
              PriceGroupId: contentPrice.Id,
              Price: Number.parseFloat(contentPrice.Price),
            }))

            const articleIdField = userStore.activeCatalog?.IsPriceByStyleApply ? 'ModelNumber' : 'Id'
            const payload: UpdateArticlePricesModel = {
              Prices: requestedPrices,
            }

            try {
              const response = await updateArticlePrices(userStore.activeCatalog!.CatalogCode, props.article[articleIdField], payload, userStore.activeCatalog!.IsPriceByStyleApply, request.Id)

              await refreshLocalArticlesData(true, props.article.ModelId)
              await updateRequestsLocally([request.Id], propertiesToUpdate)
              refreshRequestDetails()
              isConfirmedFinished.value = true
              // update local articles data
              const responseArticleMap: Record<number, ArticlePriceModel[]> = {}

              if (response.data && Array.isArray(response.data)) {
                response.data.forEach((item: ArticlePriceModel) => {
                  if (!responseArticleMap.hasOwnProperty(item.ArticleId)) {
                    responseArticleMap[item.ArticleId] = []
                  }
                  responseArticleMap[item.ArticleId].push(item)
                })
              }

              const articlePriceData: ArticlePrice[] = []
              for (const articleId in responseArticleMap) {
                const articlePrice = { Id: Number(articleId), Prices: responseArticleMap[articleId] }
                articlePriceData.push(new ArticlePrice(userStore.activeCatalog!.CatalogCode, articlePrice))
              }

              await appConfig.DB!.bulkUpdateArticles(userStore.activeCatalog!.CatalogCode, 'prices', articlePriceData, true)
              emitter('catalogDataUpdated', { source: 'Price' })

              notificationStore.addNotification({ message: t('updateArticle.updateSuccessfully'), type: 'Success' })
            }
            catch (error) {
              disableRequestActions.value = false
              console.error(error)
            }
          }
        }
      })

      if (!wasConfirmed) {
        disableRequestActions.value = false
      }
    }
  }
}

async function onApproveReject(requestIds: number[], propertiesToUpdate: { state: number, comment: string, reason: string, updatedByUserName: string }) {
  await updateRequestsLocally(requestIds, propertiesToUpdate)
  refreshRequestDetails()
}

provide('modelDetailsProvide', { createRequest: onCreateRequest })

watchEffect(() => {
  sourceRequestNumber.value = props.article.SourceRequestNumber
  if (userStore.activeCatalog && props.article.SourceRequestId) {
    appConfig.DB!.requests.where({ CatalogCode: userStore.activeCatalog.CatalogCode, Id: props.article.SourceRequestId })
      .first()
      .then((request) => {
        if (request) {
          sourceRequestNumber.value = request.Content.ArticleNumber
        }
      })
  }
})

watch(() => props.article, async (article) => {
  localModelName.value = props.article.ModelName
  loadAssets()
  if (article._IsRequestArticle) { // to fix the rendering issue of requests setting selected request id on componenet creation
    selectedRequestId.value = article.Id
    selectedObjectId.value = article.CatalogArticleId
  }
}, { immediate: true })
</script>
