<template>
  <auth-layout>
    <div class="w-80">
      <app-logo class="w-14" />
      <div v-if="currentStep !== AvailableStep.Users" class="mt-8 text-4xl font-extrabold leading-tight tracking-tight">
        {{ t('login.title') }}
      </div>
      <div v-if="currentStep !== AvailableStep.Users" class="flex items-baseline mt-0.5 font-medium">
        <div>{{ t('login.haveRegistration') }}</div>
        <a class="ml-1 text-primary-500 hover:underline" href="/register">{{ t('login.registerNow') }}</a>
      </div>

      <tx-alert class="mt-1" :show="hasError" type="error" :text="errorMessage" dismissible />

      <login-with-username v-if="currentStep === AvailableStep.Username" :loading="loading" @next="doNext" />

      <login-with-password
        v-else-if="currentStep === AvailableStep.Password" :loading="loading"
        :first-name="user.firstName" :user-name="user.userName" @cancel="goBack" @forgot-password="doForgotPassword"
        @login="doLogin"
      />

      <login-users
        v-else-if="currentStep === AvailableStep.Users" :loading="loading" :first-name="user.firstName"
        :user-name="user.userName" :users="user.myUsers" @cancel="goBack" @user-selected="doLoginByUser"
      />

      <reset-password
        v-else-if="currentStep === AvailableStep.ResetPassword" :loading="loading"
        :first-name="user.firstName" :user-name="user.userName" @cancel="goBack" @forgot-password="doForgotPassword"
      />
    </div>
  </auth-layout>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { onMounted, reactive, ref, watch } from 'vue'
import AuthLayout from '@/layouts/AuthLayout.vue'
import AppLogo from '@/shared/components/svg/AppLogo.vue'
import Auth from '@/services/auth'
import Net from '@/services/net'
import TxAlert from '@/shared/components/TxAlert.vue'
import LoginWithUsername from '@/modules/auth/components/LoginWithUsername.vue'
import LoginWithPassword from '@/modules/auth/components/LoginWithPassword.vue'
import LoginUsers from '@/modules/auth/components/LoginUsers.vue'
import ResetPassword from '@/modules/auth/components/ResetPassword.vue'
import type { ApiResponse } from '@/models/ApiResponse'
import type { AuthUser } from '@/models/auth'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import utils from '@/services/utils'

const { t, te } = useI18n()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const { errorMessage, hasError } = useErrorMessage()
const redirectUrl = route.query.redirect?.toString() || '/catalogs'

enum AvailableStep {
  Username,
  Password,
  Users,
  ResetPassword,
}

const loading = ref(false)
const firstLogin = ref(true)
const currentStep = ref(AvailableStep.Username)
const user = reactive<{ userName: string, firstName: string, myUsers: AuthUser[] }>({
  userName: '',
  firstName: '',
  myUsers: [],
})

function setErrorMessage(res: ApiResponse) {
  let key = 'login.errors.unexpectedError'
  if (res.error) {
    if (res.error.errorCode && te(`login.errors.${res.error.errorCode}`)) {
      key = `login.errors.${res.error.errorCode}`
    }
    else if (res.error.status && te(`login.errors.${res.error.status}`)) {
      key = `login.errors.${res.error.status}`
    }
  }
  errorMessage.value = t(key)
}

function setToken(token: string) {
  const validToken = Auth.hasValidToken(token)
  if (validToken) {
    Auth.setLocalToken(token)
    Net.setToken(token)
    userStore.setToken(validToken)
  }
  return validToken
}

async function checkMyUsers(token: string) {
  if (setToken(token)) {
    try {
      user.myUsers = await Auth.getMyUsers()
      userStore.setTotalUser(user.myUsers.length)
      if (user.myUsers.length > 1) {
        currentStep.value = AvailableStep.Users
      }
    }
    catch (e) {
      console.warn(e)
    }
    if (!user.myUsers.length || user.myUsers.length < 2) {
      userStore.setProfile()
      router.replace(redirectUrl)
    }
  }
  else {
    currentStep.value = AvailableStep.Username
    errorMessage.value = t('login.errors.session')
  }
}

async function doNext(userName: string) {
  loading.value = true
  if (userName.length > 0) {
    user.userName = userName
    try {
      errorMessage.value = ''
      const res = await Auth.getLoginType(user.userName)
      if (res.status && res.data) {
        user.firstName = res.data.firstName
        if (res.data.requirePasswordReset) {
          currentStep.value = AvailableStep.ResetPassword
        }
        else if (res.data.loginType === 2) {
          const token = await Auth.doExternalLogin(user.userName, res.data.loginType)
          await checkMyUsers(token)
        }
        else {
          currentStep.value = AvailableStep.Password
        }
      }
      else {
        setErrorMessage(res)
      }
    }
    catch (e) {
      console.error(e)
    }
  }
  loading.value = false
}

function goBack() {
  if (!firstLogin.value) {
    router.go(-1)
    return
  }
  currentStep.value = AvailableStep.Username
}

async function doForgotPassword(userName: string) {
  loading.value = true
  if (userName.length > 0) {
    try {
      errorMessage.value = ''
      const res = await Auth.forgot(userName)
      if (res.status && res.data) {
        router.push({ name: 'SetPassword', query: { userName: encodeURIComponent(userName) } })
      }
      else {
        setErrorMessage(res)
      }
    }
    catch (e) {
      console.error(e)
    }
  }
  loading.value = false
}

async function doLogin(userName: string, password: string) {
  loading.value = true
  if (userName.length > 0 && password.length > 0) {
    try {
      errorMessage.value = ''
      const res = await Auth.login(userName, password)
      if (res.status && res.data) {
        const token = res.data
        await checkMyUsers(token)
      }
      else {
        setErrorMessage(res)
      }
    }
    catch (e) {
      console.error(e)
    }
  }
  loading.value = false
}

async function doLoginByUser(selectedUser: AuthUser) {
  if (user.userName !== selectedUser.username) {
    loading.value = true
    try {
      errorMessage.value = ''
      const res = await Auth.getChildToken(selectedUser.username)
      if (res.status && res.data) {
        const token = res.data
        if (setToken(token)) {
          userStore.setProfile()
          router.replace(redirectUrl)
        }
        else {
          errorMessage.value = t('login.errors.session')
        }
      }
      else {
        setErrorMessage(res)
      }
    }
    catch (e) {
      console.error(e)
    }
    loading.value = false
  }
  else {
    userStore.setProfile()
    router.replace(redirectUrl)
  }
}

watch(() => userStore.userProfile, () => {
  if (userStore.userProfile.FirstName && !user.firstName) {
    user.firstName = userStore.userProfile.FirstName
  }
})

onMounted(() => {
  const token = Auth.getLocalToken()
  if (utils.isValidStringValue(token)) {
    firstLogin.value = false
    currentStep.value = AvailableStep.Users
    if (userStore.userProfile) {
      user.firstName = userStore.userProfile.FirstName
      user.userName = userStore.userProfile.UserName
    }
    else {
      user.userName = userStore.currentUsername
    }
    checkMyUsers(token!)
  }
})
</script>
