<template>
  <div class="flex flex-row">
    <div :class="props.allowEditing ? 'w-[97%]' : 'w-full'">
      <tx-select
        v-model="form.folder" :label="t('merch.dialog.addSlide.fields.folder')" :data="parentFolders" required
        :errors="v$.folder?.$errors" :disabled="!props.allowEditing" value-prop="key" display-prop="path" @change="onFolderChange"
      />
    </div>

    <div
      v-if="props.allowEditing"
      v-tooltip="t('merch.addFolder')"
      class="w-4 h-4 mt-6 mx-1 hover:text-primary-500" @click="addFolder"
    >
      <font-awesome-icon class="w-4 h-4" icon="fa-light fa-plus" />
    </div>
    <!-- create Folder Dialog -->
    <create-update-folder-dialog ref="createFolderDialogRef" :folder-path-separator="merchConstants.folderPathSeparator" @save="onCreateFolder" />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, reactive, ref, watch } from 'vue'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, required } from '@vuelidate/validators'
import type Merch from '../services/merch'
import { getAllPathsWithParents, searchNodeInTree } from '../utils'
import CreateUpdateFolderDialog from '@/shared/components/CreateUpdateFolderDialog.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import { merchConstants } from '@/models/constants'
import utils from '@/services/utils'

const props = withDefaults(defineProps<{
  modelValue?: string | number | null
  slideTree: ITreeNode[] | undefined
  merch: Merch | undefined
  allowEditing?: boolean
}>(), { allowEditing: true })

const emit = defineEmits<{
  (e: 'update', folder: string, parentNode: ITreeNode | undefined): void
  // (e: 'createFolder', name: string, parentFolderName: string): void
}>()

const { t } = useI18n()
const withI18nMessage = createI18nMessage({ t })
const createFolderDialogRef = ref<InstanceType<typeof CreateUpdateFolderDialog>>()
const form = reactive<Record<string, any>>({})
const parentFolders = computed(() => {
  let folders: any[] = []
  if (props.slideTree && props.slideTree.length !== 0) {
    folders = getAllPathsWithParents(props.slideTree)
  }
  return folders
})
const rules = computed(() => {
  const result: Record<string, any> = {}
  result.folder = { required: withI18nMessage(required) }
  return result
})
const v$ = useVuelidate(rules, form)

function onFolderChange() {
  const path = form.folder.split(merchConstants.folderPathSeparator)
  const parentFolderNodeId = path.pop() || ''
  const parentNode = searchNodeInTree(props.slideTree!, parentFolderNodeId, 'key')
  emit('update', form.folder, parentNode)
}
function addFolder() {
  createFolderDialogRef.value?.showDialog(parentFolders.value.map(folder => folder.path))
}
function onCreateFolder(name: string, parentFolder: string) {
  if (props.merch) {
    const folderId = utils.randomId()
    props.merch.addFolder(name, parentFolder, folderId)
    form.folder = folderId
    onFolderChange()
  }
  createFolderDialogRef.value?.closeDialog()
}

watch(() => props.modelValue, (newValue) => {
  if (newValue !== null && newValue !== undefined) {
    form.folder = newValue
    onFolderChange()
  }
}, { immediate: true })
</script>
