import isEmpty from 'lodash-es/isEmpty'
import type { ComposerTranslation } from 'vue-i18n'
import { clone, isNull, isUndefined } from 'lodash-es'
import type CatalogDetails from '@/models/catalogDetails'
import type MyArticle from '@/models/myArticle'
import SimpleFavoriteTag from '@/models/simpleFavoriteTag'
import utils from '@/services/utils'
import { ArticleCrd } from '@/models/articleDeliveryDate'
import { AttributeType } from '@/models/catalogAttribute'
import { Segmentation } from '@/models/articleSegmentation'
import { requestConstants } from '@/models/constants'

function getArticlePropertyInfo(property: string, myAttributes: { [property: string]: IMyAttribute }, priceGroupsLabel: { wholesalePrice: string, retailPrice: string, outletPrice: string, orderPrice: string }, t: ComposerTranslation, configuration?: string) {
  let info: { type?: AttributeType, displayLabel: string, SystemName: string } | null = {
    type: myAttributes[property] && myAttributes[property].AttributeType ? myAttributes[property].AttributeType : undefined,
    displayLabel: myAttributes[property] ? myAttributes[property].DisplayName : getCustomArticlePropertyDisplayValue(property, priceGroupsLabel, t),
    SystemName: myAttributes[property] ? myAttributes[property].SystemName : property,
  }
  if (property === '_OrderPrice') {
    info.type = AttributeType.Decimal
  }
  else if (property === '_Segmentation') {
    if (myAttributes._Segmentations) {
      info.type = myAttributes._Segmentations.AttributeType
      info.displayLabel = myAttributes._Segmentations.DisplayName
      info.SystemName = myAttributes._Segmentations.SystemName
    }
    else {
      info.type = AttributeType.MultiValue
    }
  }
  else if (property === '_Color') {
    info.type = AttributeType.MultiValue
  }
  else if (!myAttributes?.hasOwnProperty(property)) {
    const config = configuration ? ` T1S-${configuration}` : ''
    console.warn(`Invalid attribute (${property}) in configuration${config}`)
    info = null
  }
  return info
}

function getArticlePropertyValue(article: MyArticle, property: string, myAttributes: { [property: string]: IMyAttribute }, activeCatalog: CatalogDetails, indexedLinkedCatalogs: { [catalogCode: number]: CatalogDetails }, t: ComposerTranslation, blankValue: string, articleSeasonValue?: string, indexedSeasonsInfo?: Record<string, { displayValue: string, actualValue: string | null }>) {
  const propertyValues: Array<IArticlePropertyInfo> = []
  const articlePropertyValues = article[property]

  const propertyType: AttributeType = myAttributes[property] ? myAttributes[property].AttributeType : AttributeType.Nvarchar
  // if attribute value is a list
  if (Array.isArray(articlePropertyValues)) {
    let propertyValue = {} as IArticlePropertyInfo
    articlePropertyValues.forEach((value) => {
      propertyValue = {} as IArticlePropertyInfo
      if (value instanceof SimpleFavoriteTag) {
        propertyValue.displayValue = value.Tag.toString().trim()
        propertyValue.unFormattedValue = value.Tag.toString().trim()
        propertyValue.lowerStringOrNull = value.Tag.toString().trim().toLowerCase()
        propertyValue.filterValue = value.Id
        propertyValues.push(propertyValue)
      }
      else if (value instanceof Segmentation) {
        if (activeCatalog._IndexedCatalogSegmentation[value.Id]) {
          propertyValue.displayValue = activeCatalog._IndexedCatalogSegmentation[value.Id].Name.toString().trim()
          propertyValue.unFormattedValue = activeCatalog._IndexedCatalogSegmentation[value.Id].Name.toString().trim()
          propertyValue.lowerStringOrNull = activeCatalog._IndexedCatalogSegmentation[value.Id].Name.toString().trim().toLowerCase()
          propertyValue.filterValue = value.Id
          propertyValues.push(propertyValue)
        }
      }
      else if (value instanceof ArticleCrd) {
        if (activeCatalog._IndexedCatalogCRD[value.Id]) {
          propertyValue.displayValue = utils.formatDate(activeCatalog._IndexedCatalogCRD[value.Id].CustomerRequiredDate)
          propertyValue.unFormattedValue = activeCatalog._IndexedCatalogCRD[value.Id].CustomerRequiredDate
          propertyValue.lowerStringOrNull = activeCatalog._IndexedCatalogCRD[value.Id].CustomerRequiredDate
          propertyValue.filterValue = value.Id
          propertyValues.push(propertyValue)
        }
      }
      else {
        propertyValue.displayValue = String(value).trim()
        propertyValue.unFormattedValue = String(value).trim()
        propertyValue.lowerStringOrNull = String(value).trim().toLowerCase()
        propertyValue.filterValue = String(value)
        propertyValues.push(propertyValue)
      }
    })

    if (propertyValues.length === 0) {
      propertyValue.displayValue = blankValue
      propertyValue.unFormattedValue = blankValue
      propertyValue.lowerStringOrNull = blankValue.trim().toLowerCase()
      propertyValue.filterValue = null
      propertyValues.push(propertyValue)
    }
  }
  else {
    let propertyValue: IArticlePropertyInfo = {} as IArticlePropertyInfo

    if ((propertyType === AttributeType.Calc && myAttributes[property].calcTypeDataType === 'multivalue')) {
      if (articlePropertyValues != null && articlePropertyValues.toString().trim().length) {
        propertyValue = {} as IArticlePropertyInfo
        (articlePropertyValues as string).split(',').forEach((value) => {
          propertyValue.displayValue = String(value).trim()
          propertyValue.unFormattedValue = String(value).trim()
          propertyValue.lowerStringOrNull = String(value).trim().toLowerCase()
          propertyValue.filterValue = String(value)
          propertyValues.push(propertyValue)
        })
      }
      else {
        propertyValue.displayValue = blankValue
        propertyValue.unFormattedValue = blankValue
        propertyValue.lowerStringOrNull = blankValue.trim().toLowerCase()
        propertyValue.filterValue = null
        propertyValues.push(propertyValue)
      }
    }
    else if (property === 'Status') { // if attribute is status
      let articleStatus = articlePropertyValues && articlePropertyValues === 1 ? t('general.active') : t('general.inactive')
      if (activeCatalog!.DataSourceTypeId === 3) {
        switch (articlePropertyValues) {
          case 1:
            articleStatus = t('general.active')
            break
          case 0:
            articleStatus = t('general.inactive')
            break
          case 2:
            articleStatus = t('general.notAssorted')
            break
          case 3:
            articleStatus = t('general.globallyDropped')
            break
          default:
            articleStatus = articlePropertyValues && articlePropertyValues === 1 ? t('general.active') : t('general.inactive')
            break
        }
      }
      propertyValue.displayValue = articleStatus
      propertyValue.unFormattedValue = articlePropertyValues as number
      propertyValue.lowerStringOrNull = articlePropertyValues!.toString().trim().toLowerCase()
      propertyValue.filterValue = articlePropertyValues as number
      propertyValues.push(propertyValue)
    }
    else if (property === '_RequestSource') { // if attribute is _RequestSource
      let requestSource = '[Blank]'
      switch (articlePropertyValues) {
        case requestConstants.requestSources.new:
          requestSource = t('requests.new')
          break
        case requestConstants.requestSources.similar:
          requestSource = t('requests.similar')
          break
        case requestConstants.requestSources.carryover:
          requestSource = t('requests.carryover')
          break
        case requestConstants.requestSources.similarStyle:
          requestSource = t('requests.similarStyle')
          break
      }
      propertyValue.displayValue = requestSource
      propertyValue.unFormattedValue = articlePropertyValues as number
      propertyValue.lowerStringOrNull = articlePropertyValues!.toString().trim().toLowerCase()
      propertyValue.filterValue = articlePropertyValues as number
      propertyValues.push(propertyValue)
    }
    else if (property === '_RequestState') { // if attribute is _RequestState
      let requestStatus = '[Blank]'
      switch (articlePropertyValues) {
        case requestConstants.requestStates.new:
          requestStatus = t('requests.new')
          break
        case requestConstants.requestStates.approve:
          requestStatus = t('requests.approve')
          break
        case requestConstants.requestStates.reject:
          requestStatus = t('requests.reject')
          break
        case requestConstants.requestStates.draft:
          requestStatus = t('requests.draft')
          break
        case requestConstants.requestStates.confirm:
          requestStatus = t('requests.confirm')
          break
      }
      propertyValue.displayValue = requestStatus
      propertyValue.unFormattedValue = articlePropertyValues as number
      propertyValue.lowerStringOrNull = articlePropertyValues!.toString().trim().toLowerCase()
      propertyValue.filterValue = articlePropertyValues as number
      propertyValues.push(propertyValue)
    }
    else if (propertyType === AttributeType.Date || propertyType === AttributeType.DateTime || propertyType === AttributeType.DateOption) {
      propertyValue.displayValue = articlePropertyValues ? utils.formatDate(articlePropertyValues) : blankValue
      propertyValue.unFormattedValue = (articlePropertyValues || blankValue) as string
      propertyValue.lowerStringOrNull = (articlePropertyValues || blankValue) as string
      propertyValue.filterValue = articlePropertyValues != null && articlePropertyValues.toString().trim().length ? articlePropertyValues as string : null
      propertyValues.push(propertyValue)
    }
    else if (property === '_Seasons') {
      if (indexedSeasonsInfo == null) {
        indexedSeasonsInfo = getSeasonsInfo(activeCatalog, indexedLinkedCatalogs, blankValue)
      }
      if (articleSeasonValue == null) {
        articleSeasonValue = getArticleSeasonValue(article, activeCatalog, indexedLinkedCatalogs)
      }
      const seasonInfo = indexedSeasonsInfo[`${articleSeasonValue}${article.CatalogCode}`]
      propertyValue.displayValue = seasonInfo.displayValue
      propertyValue.unFormattedValue = seasonInfo.actualValue || blankValue
      propertyValue.lowerStringOrNull = seasonInfo.actualValue != null && seasonInfo.actualValue.toString().trim().length ? seasonInfo.actualValue.toString().trim().toLowerCase() : null
      propertyValue.filterValue = article.CatalogCode
      propertyValues.push(propertyValue)
    }
    else if (property === '_Segmentations') {
      if (articlePropertyValues !== null && articlePropertyValues.toString().trim().length && !isEmpty(articlePropertyValues)) {
        Object.keys(articlePropertyValues).forEach((key) => {
          const value = articlePropertyValues[key]

          if (value instanceof Segmentation) {
            if (activeCatalog._IndexedCatalogSegmentation[value.Id]) {
              const propertyValue: IArticlePropertyInfo = {} as IArticlePropertyInfo

              propertyValue.displayValue = activeCatalog._IndexedCatalogSegmentation[value.Id].Name.toString().trim()
              propertyValue.unFormattedValue = activeCatalog._IndexedCatalogSegmentation[value.Id].Name.toString().trim()
              propertyValue.lowerStringOrNull = activeCatalog._IndexedCatalogSegmentation[value.Id].Name.toString().trim().toLowerCase()
              propertyValue.filterValue = value.Id

              propertyValues.push(propertyValue)
            }
          }
        })
      }
      else {
        const propertyValue: IArticlePropertyInfo = {} as IArticlePropertyInfo
        propertyValue.displayValue = blankValue
        propertyValue.unFormattedValue = blankValue
        propertyValue.lowerStringOrNull = blankValue.trim().toLowerCase()
        propertyValue.filterValue = null
        propertyValues.push(propertyValue)
      }
    }
    else if (propertyType === AttributeType.Bool) {
      let articleStatus = ''
      if (articlePropertyValues === 1 || articlePropertyValues === true) {
        articleStatus = t('filter.true') // True or 1
      }
      else if (!utils.isDefined(articlePropertyValues) || articlePropertyValues === 0 || articlePropertyValues === false) {
        articleStatus = t('filter.false') // False or 0
      }

      propertyValue.displayValue = articleStatus
      propertyValue.unFormattedValue = articlePropertyValues as string
      propertyValue.lowerStringOrNull = articlePropertyValues != null && articlePropertyValues.toString().trim().length ? articlePropertyValues.toString().trim().toLowerCase() : null
      propertyValue.filterValue = articlePropertyValues as number
      propertyValues.push(propertyValue)
    }
    else { // attribute with string value
      const value = article[property] != null && article[property]!.toString().trim().length ? article[property] : blankValue
      propertyValue.displayValue = String(value).trim()
      propertyValue.unFormattedValue = String(value).trim()
      propertyValue.lowerStringOrNull = article[property] != null && article[property]!.toString().trim().length ? article[property]!.toString().trim().toLowerCase() : null
      propertyValue.filterValue = article[property] != null && article[property]!.toString().trim().length ? article[property]!.toString() : null
      propertyValues.push(propertyValue)
    }
  }
  return propertyValues
}

function getArticleSeasonValue(article: MyArticle, activeCatalog: CatalogDetails, indexedLinkedCatalogs: { [catalogCode: number]: CatalogDetails }) {
  if (article.CatalogCode === activeCatalog.CatalogCode) {
    return activeCatalog.Season
  }
  else {
    return indexedLinkedCatalogs[article.CatalogCode].Season
  }
}

function getSeasonsInfo(activeCatalog: CatalogDetails, indexedLinkedCatalogs: { [catalogCode: number]: CatalogDetails }, blankValue: string) {
  const indexedCatalogs = { [activeCatalog.CatalogCode]: activeCatalog, ...indexedLinkedCatalogs }
  const indexedSeasons: Record<string, { displayValue: string, actualValue: string | null }> = {}

  const indexedSeasonValueCount = {}
  for (const catalogCode in indexedCatalogs) {
    const catalog = indexedCatalogs[catalogCode]
    const seasonValue = catalog.Season != null && catalog.Season.toString().trim() !== '' ? catalog.Season : blankValue
    if (!indexedSeasonValueCount.hasOwnProperty(seasonValue)) {
      indexedSeasonValueCount[seasonValue] = 0
    }
    indexedSeasonValueCount[seasonValue]++
  }

  for (const catalogCode in indexedCatalogs) {
    const catalog = indexedCatalogs[catalogCode]
    const seasonValue = catalog.Season != null && catalog.Season.toString().trim() !== '' ? catalog.Season : blankValue
    indexedSeasons[`${seasonValue}${catalog.CatalogCode}`] = {
      displayValue: indexedSeasonValueCount[seasonValue] <= 1 ? seasonValue : `${seasonValue} (${catalog.CatalogCode})`,
      actualValue: catalog.Season,
    }
  }
  return indexedSeasons
}

function getCustomArticlePropertyDisplayValue(customProperty: string, priceGroupsLabel: { wholesalePrice: string, retailPrice: string, outletPrice: string, orderPrice: string }, t: ComposerTranslation) {
  let displayLabel = customProperty
  if (customProperty === '_OrderPrice') {
    displayLabel = priceGroupsLabel.orderPrice
  }
  else if (customProperty === '_Segmentation') {
    displayLabel = t('general.segmentations')
  }
  else if (customProperty === '_Color') {
    displayLabel = t('general.silhouetteColor')
  }
  return displayLabel
}

// a function that returns value that is going to be used for custom sort
function getCustomSortValueListForComparer(valuesType: AttributeType, customSortValueList: Array<any>) {
  let result: Array<any> = []
  if (valuesType === AttributeType.Int || valuesType === AttributeType.Decimal) {
    customSortValueList.forEach((value) => {
      // result.push(value.toString())
      if (utils.isNumberOrNumberString(value)) {
        result.push(Number(value))
      }
      else {
        result.push('[Blank]')
      }
    })
  }
  else if (valuesType === AttributeType.Date || valuesType === AttributeType.DateTime || valuesType === AttributeType.DateOption) {
    customSortValueList.forEach((value) => {
      if (!(value === 0 || value == null || value === 'null' || value.toString().trim() === '') && utils.validateDate(value)) { // this is based on how the custom sort (formatDate), and date validation(validateDate) is currently developed
        const dateValue = new Date(value)
        dateValue.setHours(0, 0, 0, 0)
        result.push(utils.formatDate(dateValue))
      }
      else {
        result.push('[Blank]')
      }
    })
  }
  else {
    result = customSortValueList.map(value => value.toString().trim().toLowerCase())
  }
  return result
}

function customSortComparer(displayValueA: any, displayValueB: any, valuesType: AttributeType, sortDirectionMultiple: 1 | -1, customSortValueList: Array<any> = []) {
  let aValueForCustomSort: string | number // this can hold [blank] string value where bDateValue will always have date
  let bValueForCustomSort: string | number // this can hold [blank] string value where bDateValue will always have date
  const valueA = _getArticleValueForSort(displayValueA, valuesType)
  const valueB = _getArticleValueForSort(displayValueB, valuesType)
  if (valuesType === AttributeType.Int || valuesType === AttributeType.Decimal) {
    aValueForCustomSort = utils.isNumberOrNumberString(displayValueA) ? Number(displayValueA) : '[Blank]' // assign blank for invalid date as this is what will be added in configuration
    bValueForCustomSort = utils.isNumberOrNumberString(displayValueB) ? Number(displayValueB) : '[Blank]' // assign blank for invalid date as this is what will be added in configuration
  }
  else if (valuesType === AttributeType.DateTime || valuesType === AttributeType.Date || valuesType === AttributeType.DateOption) {
    if (!(displayValueA === 0 || displayValueA == null || displayValueA === 'null' || displayValueA.toString().trim() === '') && utils.validateDate(displayValueA)) { // this is based on how the custom sort (formatDate), and date validation(validateDate) is currently developed
      aValueForCustomSort = utils.formatDate(clone(valueA).setHours(0, 0, 0, 0))
    }
    else {
      aValueForCustomSort = '[Blank]' // assign blank for invalid date as this is what that will be added in configuration
    }
    if (!(displayValueB === 0 || displayValueB == null || displayValueB === 'null' || displayValueB.toString().trim() === '') && utils.validateDate(displayValueB)) { // this is based on how the custom sort (formatDate), and date validation(validateDate) is currently developed
      bValueForCustomSort = utils.formatDate(clone(valueB).setHours(0, 0, 0, 0))
    }
    else {
      bValueForCustomSort = '[Blank]' // assign blank for invalid date as this is what that will be added in configuration
    }
  }
  else {
    aValueForCustomSort = utils.isDefined(displayValueA) ? displayValueA.toString().trim().toLowerCase() : '[Blank]' // assign blank for invalid date as this is what will be added in configuration
    bValueForCustomSort = utils.isDefined(displayValueB) ? displayValueB.toString().trim().toLowerCase() : '[Blank]' // assign blank for invalid date as this is what will be added in configuration
  }

  if (customSortValueList.includes(aValueForCustomSort) && customSortValueList.includes(bValueForCustomSort)) {
    return customSortValueList.indexOf(aValueForCustomSort) - customSortValueList.indexOf(bValueForCustomSort)
  }
  else if (customSortValueList.includes(aValueForCustomSort)) {
    return -1
  }
  else if (customSortValueList.includes(bValueForCustomSort)) {
    return 1
  }
  else {
    if (valueA < valueB) {
      return sortDirectionMultiple * -1
    }
    else if (valueA > valueB) {
      return sortDirectionMultiple * 1
    }
    else {
      return 0
    }
  }
}

function sortArticleComparer(displayValueA: any, displayValueB: any, valuesType: AttributeType, sortDirectionMultiple: 1 | -1, customSortValueList: Array<any> = []) {
  if (customSortValueList.length) {
    return customSortComparer(displayValueA, displayValueB, valuesType, sortDirectionMultiple, customSortValueList)
  }
  else {
    const valueA = _getArticleValueForSort(displayValueA, valuesType)
    const valueB = _getArticleValueForSort(displayValueB, valuesType)
    if (valueA < valueB) {
      return sortDirectionMultiple * -1
    }
    else if (valueA > valueB) {
      return sortDirectionMultiple * 1
    }
    else {
      return 0
    }
  }
}

// sort articles in place based on sortBy or customSortArticlePropertyAndValues
function sortArticles(articles: Array<MyArticle>, sortBy: string | undefined, customSortArticlePropertyAndValues: Record<string, string[]>, myAttributes: Record<string, IMyAttribute>, activeCatalog: CatalogDetails) {
  let sortDirectionMultiple: 1 | -1 = 1 // ascending
  let customSortValueList: Array<any> = []
  let propertyType = AttributeType.Nvarchar
  let sortProperty = sortBy

  // if custom sort is applied, first apply the custom sort and values missing in custom sort value list will be sorted by sortDirection
  if (!isEmpty(customSortArticlePropertyAndValues)) {
    sortProperty = Object.keys(customSortArticlePropertyAndValues)[0]
    customSortValueList = customSortArticlePropertyAndValues[sortProperty]
  }

  if (sortProperty != null && sortProperty.toString().trim().length) { // when no sort option is selected or default sort option is selected
    propertyType = myAttributes[sortProperty]?.AttributeType || AttributeType.Nvarchar // TODO: if sort by is an object we do not need to do this lookup (later this can be problem if sort option is not part of myAttributes)
    const sortOption = activeCatalog.Config.ArticlesSortProperties.find(sortOption => sortOption.articleProperty === sortProperty)
    if (sortOption && sortOption.sortDirection) { // take sort direction from configuration
      sortDirectionMultiple = sortOption.sortDirection === 'descending' ? -1 : 1
    }
    articles.sort((a, b) => sortArticleComparer(a[sortProperty!], b[sortProperty!], propertyType, sortDirectionMultiple, customSortValueList))
  }
}

// get sort info (sort direction and customSortValueList for colum/row divider)
function getPivotSortInfo(rowDividerOrColumnDividerAttributeSystemName: string, attributeType: AttributeType, sortBy: string | undefined, customSortArticlePropertyAndValues: Record<string, string[]>, articlesSortPropertiesConfig: Array<{
  articleProperty: string
  customSortValueList?: (string | number)[]
  sortDirection?: string
}>, groupByAttributesConfig: Record<string, {
  groupBySortDirection?: string
  customSortValueList?: string[]
}>) {
  // pivotSortInfo will be taken from selected sort option if it matches the row/column divider (rowDividerOrColumnDividerAttributeSystemName), if sort option is not selected or it's default then the values will be taken from GroupByAttributes configuration
  const pivotSortInfo = {
    sortDirection: 'ascending',
    customSortValueList: [] as Array<string | number>,
  }

  if (rowDividerOrColumnDividerAttributeSystemName != null && rowDividerOrColumnDividerAttributeSystemName.toString().trim().length) {
    // initialize sort direction and customSortValueList from GroupByAttributes configuration
    pivotSortInfo.sortDirection = groupByAttributesConfig[rowDividerOrColumnDividerAttributeSystemName]?.groupBySortDirection || 'ascending' // default sort direction for GroupByAttributes is ascending
    pivotSortInfo.customSortValueList = getCustomSortValueListForComparer(attributeType, groupByAttributesConfig[rowDividerOrColumnDividerAttributeSystemName]?.customSortValueList || [])

    // if user apply sort on any property except default and custom sort then take the sort direction and customSortValueList values for column/row divider from sort option only if sortBy property match with column/row divider (rowDividerOrColumnDividerAttributeSystemName)
    if (sortBy != null && sortBy.toString().trim().length && sortBy !== '_customSort' && sortBy === rowDividerOrColumnDividerAttributeSystemName) {
      const configuredSortOption = articlesSortPropertiesConfig.find(configuredArticlesSortOption => configuredArticlesSortOption.articleProperty === sortBy)
      if (configuredSortOption) {
        pivotSortInfo.sortDirection = configuredSortOption.sortDirection && configuredSortOption.sortDirection.length ? configuredSortOption.sortDirection : 'ascending' // default sort direction for configuredSortOption is ascending
        pivotSortInfo.customSortValueList = getCustomSortValueListForComparer(attributeType, configuredSortOption.customSortValueList || [])
      }
    }

    // if user apply custom sort then take the sort direction for column/row divider from sort option and customSortValueList values from customSortArticlePropertyAndValues which is values selected by user only if sortBy property match with column/row divider (rowDividerOrColumnDividerAttributeSystemName)
    if (sortBy === '_customSort') {
      if (!isEmpty(customSortArticlePropertyAndValues)) { // if user selects custom sort but did not select an attribute then consider default and take values from GroupByAttributes
        const customSortProperty = Object.keys(customSortArticlePropertyAndValues)[0]
        // if custom sort property matches column/row divider (rowDividerOrColumnDividerAttributeSystemName) then update sort direction and customSortValueList
        if (customSortProperty === rowDividerOrColumnDividerAttributeSystemName) {
          pivotSortInfo.customSortValueList = getCustomSortValueListForComparer(attributeType, customSortArticlePropertyAndValues[customSortProperty])
          const configuredSortOption = articlesSortPropertiesConfig.find(configuredArticlesSortOption => configuredArticlesSortOption.articleProperty === customSortProperty)
          if (configuredSortOption) {
            pivotSortInfo.sortDirection = configuredSortOption.sortDirection && configuredSortOption.sortDirection.length ? configuredSortOption.sortDirection : 'ascending' // default sort direction is ascending
          }
        }
      }
    }
  }
  return pivotSortInfo
}

function _getArticleValueForSort(value: any, valuesType: AttributeType): any {
  if (valuesType === AttributeType.Int || valuesType === AttributeType.Decimal) {
    return utils.isNumberOrNumberString(value) ? Number(value) : 0
  }
  else if (valuesType === AttributeType.Date || valuesType === AttributeType.DateTime || valuesType === AttributeType.DateOption) {
    const dateValue = new Date(value)
    return utils.isDate(dateValue) ? dateValue : new Date(0)
  }
  else {
    return utils.isDefined(value) ? value.toString().toLowerCase() : ''
  }
}

function getArticlePropertyRenderValue(article: MyArticle, property: string, type: AttributeType, translatedValues?: {
  active: string
  inactive: string
  yes: string
  no: string
}) {
  translatedValues = Object.assign({
    active: 'Active',
    inactive: 'Inactive',
    yes: 'Yes',
    no: 'No',
  }, translatedValues ?? {})
  const articlePropertyValue = article[property]
  if (isNull(articlePropertyValue) || isUndefined(articlePropertyValue)) {
    return articlePropertyValue
  }
  switch (type) {
    case AttributeType.ArticleNumber:
    case AttributeType.Calc:
    case AttributeType.LinkedCatalogArticleNumber:
    case AttributeType.Nvarchar:
    case AttributeType.SellerModel:
    case AttributeType.SizeScale:
    case AttributeType.MasterSizeScale:
      return articlePropertyValue
    case AttributeType.Int:
      return articlePropertyValue.toLocaleString()
    case AttributeType.Decimal:
      return articlePropertyValue.toLocaleString(undefined, { minimumFractionDigits: 2 })
    case AttributeType.Date:
    case AttributeType.DateOption:
      return new Date(articlePropertyValue as string).toLocaleDateString()
    case AttributeType.DateTime:
      return new Date(articlePropertyValue as string).toLocaleString(undefined, { hour: 'numeric', minute: 'numeric', second: 'numeric' })
    case AttributeType.MultiValue:
      return (articlePropertyValue as Array<string>).join('|')
    case AttributeType.Status:
      return articlePropertyValue ? translatedValues.active : translatedValues.inactive
    case AttributeType.Bool:
      if (property === 'Status') {
        return articlePropertyValue ? translatedValues.active : translatedValues.inactive
      }
      else {
        return articlePropertyValue ? translatedValues.yes : translatedValues.no
      }
    case AttributeType.ColorPalette:
      return articlePropertyValue.toString()
    default:
      return null
  }
}

export {
  customSortComparer,
  getArticlePropertyInfo,
  getArticlePropertyRenderValue,
  getArticlePropertyValue,
  getArticleSeasonValue,
  getCustomArticlePropertyDisplayValue,
  getCustomSortValueListForComparer,
  getPivotSortInfo,
  getSeasonsInfo,
  sortArticleComparer,
  sortArticles,
}
