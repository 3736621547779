<template class="container relative flex flex-col w-full h-full overflow-y-auto">
  <tx-dialog
    v-model="visible" :title="t('requests.addAttachments')"
    width="600px"
    height="460px"
    show-ok-cancel
    :ok-state="loading || isSaveDisabled ? 'disabled' : 'enabled'"
    confirm-text="general.add"
    @click="doCancel()" @ok="addAttachments()"
  >
    <div class="relative w-full h-full overflow-x-hidden">
      <loader v-if="loading" />
      <div v-else class="container flex flex-col w-full h-full">
        <div class="px-10 mt-4 alerts">
          <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        </div>
        <div class="h-full">
          <div class="flex w-full">
            <div class="w-full">
              <div class="w-full h-3 pb-4 overflow-hidden text-xs leading-3 tracking-wide uppercase label whitespace-nowrap text-ellipsis">
                {{ t('general.fileList') }}
              </div>
              <upload-attachment
                :key="attachmentsKey"
                v-model="form.Attachments"
                :restricted-formats="requestConstants.allowedRequestAttachmentsTypes"
                :multiple="true"
                :show-list="false"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useUserStore } from '@/store/userData'
import useErrorMessage from '@/shared/composables/errorMessage'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import Loader from '@/shared/components/Loader.vue'
import { addRequestAttachments } from '@/api/t1/request'
import UploadAttachment from '@/shared/components/UploadAttachment.vue'
import type RequestModel from '@/models/request'
import { requestConstants } from '@/models/constants'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'upload', item: RequestModel): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const form = reactive<Record<string, any>>({})
const visible = ref(false)
const loading = ref(false)
const currentRequest = ref<RequestModel>()
const attachmentsKey = ref(0) // to rerender the uploaad attachemnt component after reset

const isSaveDisabled = computed(() => !form.Attachments || (form.Attachments && form.Attachments.length === 0))

async function showDialog(request: RequestModel) {
  reset()
  loading.value = true
  currentRequest.value = request
  loading.value = false
}

function doCancel() {
  visible.value = false
  emit('cancel')
}

async function addAttachments() {
  loading.value = true
  try {
    if (userStore.activeCatalog) {
      await addRequestAttachments(userStore.activeCatalog!.CatalogCode, currentRequest.value!.Id, form.Attachments)
        .then(async () => {
          await userStore.doLoadData(['Requests'])
          emit('upload', currentRequest.value!)
        })
        .catch ((error) => {
          console.error(error)
          errorMessage.value = t('general.unexpectedError')
        })
    }
  }
  finally {
    loading.value = false
    visible.value = false
  }
}

function reset() {
  loading.value = false
  visible.value = true
  attachmentsKey.value++
  form.Attachments = []
  errorMessage.value = ''
}

defineExpose({
  showDialog,
})
</script>
