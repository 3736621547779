import type { AccessibleCatalogModel, AccountDetailsModel, CatalogPermissionModel, IProfileSettings, NotificationSubscribeTypeModel, UserProfileModel, UserRoleModel } from '@/api/t1/model/userModel'
import utils from '@/services/utils'

// change it to UserProfile
export default class UserProfile {
  Id: number
  AccountId: number
  AccountName: string
  FirstName: string
  LastName: string
  Email: string
  Phone: string | null
  CountryCode: string | null
  Country: string | null
  City: string | null
  Designation: string | null
  Department: string | null
  UserName: string
  Gender: string | null
  DOB: Date | null
  Nationality: string | null
  Address: string | null
  Website: string | null
  RestrictFullRange: number | null
  Status: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date

  Settings: IProfileSettings
  AccountDetails: AccountDetailsModel
  AccessibleCatalogs: AccessibleCatalogModel[]
  NotificationSubscribeTypes: NotificationSubscribeTypeModel[]
  Roles: UserRoleModel[]

  Permissions: Map<string, CatalogPermissionModel>
  catalogCode: number

  get initials() {
    return this.FirstName.substring(0, 1) + this.LastName.substring(0, 1)
  }

  get fullName() {
    return `${this.FirstName} ${this.LastName}`
  }

  constructor(userProfileModel: UserProfileModel, catalogCode: number) {
    this.Id = userProfileModel.Id
    this.AccountId = userProfileModel.AccountId
    this.AccountName = userProfileModel.AccountName
    this.FirstName = userProfileModel.FirstName
    this.LastName = userProfileModel.LastName
    this.Email = userProfileModel.Email
    this.Phone = userProfileModel.Phone
    this.CountryCode = userProfileModel.CountryCode
    this.Country = userProfileModel.Country
    this.City = userProfileModel.City
    this.Designation = userProfileModel.Designation
    this.Department = userProfileModel.Department
    this.UserName = userProfileModel.UserName
    this.Gender = userProfileModel.Gender
    this.DOB = userProfileModel.DOB
    this.Nationality = userProfileModel.Nationality
    this.Address = userProfileModel.Address
    this.Website = userProfileModel.Website
    this.RestrictFullRange = userProfileModel.RestrictFullRange
    this.Status = userProfileModel.Status
    this.CreatedBy = userProfileModel.CreatedBy
    this.UpdatedBy = userProfileModel.UpdatedBy
    this.CreatedDate = userProfileModel.CreatedDate
    this.UpdatedDate = userProfileModel.UpdatedDate

    this.Settings = typeof userProfileModel.Settings === 'string' ? utils.tryParse(userProfileModel.Settings) : userProfileModel.Settings
    this.AccountDetails = userProfileModel.AccountDetails
    this.AccessibleCatalogs = userProfileModel.AccessibleCatalogs
    this.NotificationSubscribeTypes = userProfileModel.NotificationSubscribeTypes
    // trim the role name
    this.Roles = userProfileModel.Roles

    this.Permissions = new Map<string, CatalogPermissionModel>(userProfileModel.Permissions.map(p => [p.RouteName, p]))
    this.catalogCode = catalogCode
  }

  isValidPrivilege(key: string) {
    const permission = this.Permissions.get(key)
    return utils.isDefined(permission) && (permission.Catalogs.length === 0 || permission.Catalogs.includes(this.catalogCode))
  }
}
