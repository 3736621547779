<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 171.863 101.881">
    <g transform="translate(-22.254 -56.183)">
      <rect width="156.872" height="101.881" rx="10" transform="translate(37.245 56.183)" style="fill:#fff" />
      <circle cx="2" cy="2" r="2" transform="translate(45 144)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(72 144)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(100 144)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(127 144)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(155 144)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(182 144)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(45 117)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(45 90)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(45 63)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(72 117)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(72 90)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(72 63)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(100 117)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(100 90)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(100 63)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(127 117)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(127 90)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(127 63)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(155 117)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(155 90)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(155 63)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(182 117)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(182 90)" style="fill:#c8c9c9" />
      <circle cx="2" cy="2" r="2" transform="translate(182 63)" style="fill:#c8c9c9" />
      <path d="M-750.374 43.967a2.59 2.59 0 0 1 .337 2.351l-20.213 58.434a2.585 2.585 0 0 1-2.442 1.741H-790.8a2.582 2.582 0 0 1-2.449-1.767l-7.6-22.749-7.6 22.749a2.583 2.583 0 0 1-2.45 1.767H-829a2.584 2.584 0 0 1-2.443-1.741l-20.22-58.434a2.59 2.59 0 0 1 .337-2.351 2.581 2.581 0 0 1 2.106-1.089h20.711a2.584 2.584 0 0 1 2.5 1.919l6.823 25.518 7.677-25.592a2.59 2.59 0 0 1 2.476-1.844h16.25a2.58 2.58 0 0 1 2.469 1.835l7.792 25.656 6.836-25.572a2.582 2.582 0 0 1 2.5-1.919h20.711a2.58 2.58 0 0 1 2.101 1.088z" transform="translate(875.089 23.688)" style="fill:#00a6f0" />
      <path d="M102.396 131.213H84.289a3.605 3.605 0 0 1-3.427-2.474l-6.619-19.823-6.62 19.824a3.6 3.6 0 0 1-3.426 2.472H46.09a3.622 3.622 0 0 1-3.414-2.432L22.455 70.344a3.628 3.628 0 0 1 .471-3.288 3.615 3.615 0 0 1 2.944-1.523h20.711a3.622 3.622 0 0 1 3.492 2.684l5.885 22.011 6.635-22.115a3.642 3.642 0 0 1 3.462-2.58h16.25a3.592 3.592 0 0 1 3.455 2.568l6.743 22.2 5.905-22.087a3.62 3.62 0 0 1 3.491-2.685h20.71a3.615 3.615 0 0 1 2.946 1.524 3.629 3.629 0 0 1 .469 3.289l-20.212 58.432a3.624 3.624 0 0 1-3.416 2.439zm-28.153-28.8 8.574 25.673a1.548 1.548 0 0 0 1.472 1.062h18.107a1.562 1.562 0 0 0 1.471-1.049l20.212-58.432a1.56 1.56 0 0 0-.2-1.414 1.554 1.554 0 0 0-1.267-.654h-20.71a1.556 1.556 0 0 0-1.5 1.154l-7.777 29.055-8.841-29.106a1.542 1.542 0 0 0-1.484-1.1H66.05a1.568 1.568 0 0 0-1.49 1.11L55.84 97.78l-7.76-29.024a1.558 1.558 0 0 0-1.5-1.154H25.869a1.551 1.551 0 0 0-1.266.654 1.557 1.557 0 0 0-.2 1.414l20.22 58.435a1.56 1.56 0 0 0 1.47 1.047H64.2a1.547 1.547 0 0 0 1.472-1.06z" style="fill:#fff" />
    </g>
  </svg>
</template>
