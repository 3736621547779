import { fabric } from 'fabric'
import { v4 as guid } from 'uuid'
import type { Subscription } from 'dexie'
import { liveQuery } from 'dexie'
import { isArray, isString, pick } from 'lodash-es'
import WbTextBox from './textBox'
import WbArticleImage from './articleImage'
import WbArticleDetails from './articleDetails'
import Utility from './utility'
import { whiteboardConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import appConfig from '@/services/appConfig'
import type MyArticle from '@/models/myArticle'
import { FilterCriteria } from '@/models/filterCriteria'

interface IWbDynamicOptions extends fabric.IRectOptions {
  id?: string
  filterJson?: string[]
  groupBy?: string[]
  visibleArticleIds?: number[]
  lock?: boolean
  attributes?: string[]
  attributesSeparator?: string
  attributePlaceHolder?: string
  preventUnlock?: boolean
}

export default class WbDynamic extends fabric.Rect implements IWbObject {
  public id: string
  public type = whiteboardConstants.objectTypes.dynamic
  public lock: boolean
  public preventUnlock: boolean
  public connectable: boolean = false
  public filterJson?: string[]
  public visibleArticleIds?: number[]
  public attributes?: string[]
  public attributesSeparator?: string
  public attributePlaceHolder?: string
  public imageSize = 100
  public editableProps: Record<string, IWbObjectProp> = {
    attributes: { name: 'attributes', type: 'attributes' },
    size: { name: 'size', type: 'size' },
    lock: { name: 'Lock', type: 'lock' },
    addDiscussion: { name: 'add comment', type: 'addDiscussion' },
  }

  public groupBy?: string[] = []

  public actions: Record<string, IWObjectActions> = {
    editSettings: { action: 'editDynamicObjectSettings', label: 'Edit Settings', faicon: 'fa-light fa-gear', showInSubMenu: true },
    selectSimilar: { action: 'selectSimilar', label: 'Select Similar', faicon: 'fa-light fa-check-double', showInSubMenu: true },
    delete: { action: 'delete', label: 'Remove', faicon: 'fa-light fa-trash-can', showInSubMenu: true },
  }

  public subscription?: Subscription
  public groups: Record<string, number[]> = {}
  public articleObjs: Record<number, { img?: WbArticleImage, details?: WbArticleDetails }> = {}
  public articles: MyArticle[] = []
  public groupObjs: Record<string, WbTextBox> = {}

  public _isMoving = false
  public _isOverflow = false
  public _isSelected = false
  public _isFirstTimeLoading = false
  public _isLoading = false

  private overflowMessage = ''

  constructor(opt?: IWbDynamicOptions) {
    super(opt || { lockRotation: true })
    this.id = opt?.id || guid()
    // this.text = text
    // this.textAlign = opt?.textAlign || 'center'
    // this.font = opt?.font || 'Helvetica'
    // this.fontSize = opt?.fontSize || -1
    // this.textColor = opt?.textColor || '#000000'
    // this.bold = opt?.bold || false
    // this.italic = opt?.italic || false
    this.attributes = opt?.attributes?.length ? opt.attributes : ['ArticleNumber', 'ArticleName']
    this.attributesSeparator = opt?.attributesSeparator || '\n'
    this.attributePlaceHolder = opt?.attributePlaceHolder || ''
    this.lock = opt?.lock || false
    this.hasRotatingPoint = false
    this.lockRotation = true
    // this.shadow = new fabric.Shadow('rgba(0,0,0,0.2) 2px 2px 10px')
    // this.connectable = true
    this.visibleArticleIds = opt?.visibleArticleIds || []
    this.subscription = undefined

    this.overflowMessage = 'Not all items are visible, resize frame'
    this.objectCaching = false
    this.groupBy = opt?.groupBy || []
    this.preventUnlock = opt?.preventUnlock || false

    this.applyFilter()

    Utility.makeObjectSelectableByBorderWhenLocked(this)

    this.on('added', () => {
      console.log('Object added')
    })

    this.on('removed', () => {
      Object.values(this.groupObjs).forEach((groupValue) => {
        this.canvas?.remove(groupValue)
      })
      Object.values(this.articleObjs).forEach((articleObj) => {
        if (articleObj.details) { this.canvas?.remove(articleObj.details) }
        if (articleObj.img) { this.canvas?.remove(articleObj.img) }
      })

      if (this.subscription && !this.subscription.closed) {
        this.subscription.unsubscribe()
      }
    })

    this.on('moving', () => {
      this._isMoving = true
      Object.values(this.groupObjs).forEach(obj => obj.visible = false)
      Object.values(this.articleObjs).forEach((obj) => {
        if (obj.details) { obj.details.visible = false }
        if (obj.img) { obj.img.visible = false }
      })
    })

    this.on('mouseup', () => {
      if (this._isMoving) {
        this._isMoving = false
        this.backgroundColor = 'transparent'
        this.recalcObjectsPosition()
      }
    })

    this.on('selected', () => {
      this._isSelected = true
    })

    this.on('deselected', () => {
      this._isSelected = false
    })

    this.on('scaling', () => {
      this.width = this.width! * this.scaleX!
      this.height = this.height! * this.scaleY!
      this.scaleX = 1
      this.scaleY = 1
      this.recalcObjectsPosition()
    })

    this.setLock(this.lock)
  }

  getFilter() {
    return this.filterJson?.map(v => new FilterCriteria(JSON.parse(v))) || []
  }

  applyFilter() {
    const filter = this.getFilter()
    if (!filter || !isArray(filter) || filter.length === 0) { return }
    const userStore = useUserStore()
    this.doLoading(true)

    if (this.subscription && !this.subscription.closed) {
      this.subscription.unsubscribe()
    }

    const observable = liveQuery(async () => await appConfig.DB!.getArticlesByCriteria(userStore.activeCatalog!, userStore.myAttributes!, filter, true, userStore.currentUsername, userStore.currentCustomer, userStore.currentCustomerSegmentations))
    const subscription = observable.subscribe(async (articles) => {
      this._isFirstTimeLoading = false
      appConfig.DB!.buildMyArticles(articles, userStore.activeCatalog!, userStore.linkedCatalogDetails, userStore.myAttributes!, userStore.currentUsername, userStore.priceGroups.retail, userStore.priceGroups.wholesale, userStore.priceGroups.outlet, true).then((myArticles) => {
        this.updateArticles(myArticles)
        this.rebuildGroups()
      })
    })
    this.subscription = subscription
  }

  updateArticles(articles: MyArticle[]) {
    // List of articles to add
    const userStore = useUserStore()
    const articlesToAdd = articles.filter(article => !this.articleObjs[article.Id])
    const articlesToRemove = Object.keys(this.articleObjs).filter(articleId => !articles.find(article => article.Id === Number.parseInt(articleId)))

    this.articles = articles

    articlesToRemove.forEach((articleId) => {
      const art = this.articleObjs[Number.parseInt(articleId)]
      if (art.details) { this.canvas?.remove(art.details) }
      if (art.img) { this.canvas?.remove(art.img) }
      delete this.articleObjs[Number.parseInt(articleId)]
    })

    const detailsPromises: Promise<WbArticleDetails>[] = []
    const imagesPromises: Promise<WbArticleImage>[] = []
    articlesToAdd.forEach((article) => {
      this.articleObjs[article.Id] = { img: undefined, details: undefined }
      detailsPromises.push(WbArticleDetails.loadArticleDetails(article, userStore.myAttributes, { left: 0, top: 0, width: this.imageSize, showLabels: false, visible: false, lock: true, preventUnlock: true, localOnly: true, attributes: this.attributes, fontSize: this.imageSize === 50 ? 4 : this.imageSize === 100 ? 14 : 20 }).then(details => this.articleObjs[article.Id].details = details)) // top + newArticleObject.height - (52 * 1.35) }))
      imagesPromises.push(WbArticleImage.loadArticleImage(article, 500, 500, { top: 0, left: 0, catalogCode: article.CatalogCode, articleId: article.Id, objectId: article.CatalogArticleId, isRequest: article._IsRequestArticle, visible: false, lock: true, preventUnlock: true, localOnly: true }).then(img => this.articleObjs[article.Id].img = img))
    })

    this.canvas!.renderOnAddRemove = true
    Promise.all(detailsPromises).then((detailsObjs) => {
      this.canvas!.renderOnAddRemove = false
      detailsObjs.forEach((obj) => {
        obj.width = this.imageSize
        obj.textAlign = 'center'
        obj.visible = false
        this.canvas?.add(obj)
      })

      Promise.all(imagesPromises).then((imagesObjs) => {
        imagesObjs.forEach((obj) => {
          obj.scale(this.imageSize * 0.002)
          this.canvas?.add(obj)
        })
        this.recalcObjectsPosition()
        this._isFirstTimeLoading = false
        this.doLoading(false)
        this.canvas!.renderOnAddRemove = true
      })
    })
  }

  rebuildGroups() {
    this.groups = {}
    this.canvas!.renderOnAddRemove = false
    this.articles.forEach((article) => {
      const groupValue = this.groupBy?.map(itm => article[itm]?.toString() || '(Blank)').join(' - ') || '(Blank)'
      if (!this.groups[groupValue]) {
        this.groups[groupValue] = []
      }
      this.groups[groupValue].push(article.Id)
    })

    Object.keys(this.groups).forEach((groupValue) => {
      if (!this.groupObjs[groupValue]) {
        this.groupObjs[groupValue] = new WbTextBox(groupValue, { selectable: false, lock: true, evented: false, fontFamily: 'Arial', fontSize: 25, backgroundColor: '#1484f8', fill: '#ffffff', localOnly: true })
        this.canvas?.add(this.groupObjs[groupValue])
      }
    })

    Object.keys(this.groupObjs).forEach((groupValue) => {
      if (!this.groups[groupValue]) {
        this.canvas?.remove(this.groupObjs[groupValue])
        delete this.groupObjs[groupValue]
      }
    })
  }

  recalcObjectsPosition() {
    const padding = 10
    const spaceBetweenObj = 5
    const spaceBetweenGroups = 50

    const rectLeftTop = this.getPointByOrigin('left', 'top')

    let currentLeft = padding
    let currentTop = padding

    this._isOverflow = false

    const grps = Object.keys(this.groups)
    for (let i = 0; i < grps.length; i++) {
      const groupValue = grps[i]

      // Add header group
      const groupObj = this.groupObjs[groupValue]
      groupObj.visible = true
      groupObj.left = rectLeftTop.x + padding
      groupObj.top = rectLeftTop.y + currentTop
      groupObj.width = this.width! - padding * 2
      groupObj.setCoords()

      currentLeft = padding
      currentTop += groupObj.height! + spaceBetweenObj

      if (groupObj.top + groupObj.height! + padding * 2 > rectLeftTop.y + this.height!) {
        // No space for this obj. Hide it
        groupObj.visible = false
        this._isOverflow = true
      }

      let highestLabel = 0
      this.groups[groupValue].forEach((artId) => {
        highestLabel = Math.max(highestLabel, this.articleObjs[artId].details?.height || 0)
      })

      // Add articles
      for (let j = 0; j < this.groups[groupValue].length; j++) {
        const articleId = this.groups[groupValue][j]
        const articleObj = this.articleObjs[articleId].img
        const detailsObj = this.articleObjs[articleId].details

        if (articleObj) {
          articleObj.visible = true
          articleObj.left = rectLeftTop.x + currentLeft
          articleObj.top = rectLeftTop.y + currentTop
          if (articleObj.left + this.imageSize + padding * 2 > rectLeftTop.x + this.width!) {
            currentLeft = padding
            currentTop += this.imageSize + highestLabel + spaceBetweenObj
            articleObj.left = rectLeftTop.x + currentLeft
            articleObj.top = rectLeftTop.y + currentTop
          }
          if (articleObj.top + this.imageSize + padding * 2 > rectLeftTop.y + this.height!) {
            // No space for this obj. Hide it
            articleObj.visible = false
            this._isOverflow = true
          }
          // articleObj.dirty = true
          articleObj.setCoords()
        }

        if (detailsObj) {
          detailsObj.visible = true
          detailsObj.left = rectLeftTop.x + currentLeft
          detailsObj.top = rectLeftTop.y + currentTop + this.imageSize
          if (detailsObj.top! + detailsObj.height! + padding * 2 > rectLeftTop.y + this.height!) {
            // No space for this obj. Hide it
            detailsObj.visible = false
            this._isOverflow = true
          }
          // detailsObj.dirty = true
          detailsObj.setCoords()
        }

        currentLeft += this.imageSize + spaceBetweenObj
      }
      currentTop += spaceBetweenGroups + highestLabel + this.imageSize
    }
    this.canvas?.requestRenderAll()
  }

  rescaleImages() {
    Object.values(this.articleObjs).forEach((art) => {
      if (art.img) {
        art.img.scale(this.imageSize * 0.002)
      }
      if (art.details) {
        art.details.set('fontSize', this.imageSize === 50 ? 4 : this.imageSize === 100 ? 14 : 20)
        art.details.set('width', this.imageSize)
      }
    })
    this.recalcObjectsPosition()
  }

  resetAttributes() {
    const p: Promise<void>[] = []
    this.doLoading(true)
    Object.values(this.articleObjs).forEach((art) => {
      if (art.details) {
        p.push(art.details.setProp('attributes', { attributes: this.attributes }, true))
      }
    })
    Promise.all(p).then(() => {
      this.doLoading(false)
      this.recalcObjectsPosition()
    })
  }

  doLoading(loading: boolean) {
    this._isLoading = loading
    if (loading) {
      Object.values(this.articleObjs).forEach((itm) => {
        if (itm.details) { itm.details.visible = false }
        if (itm.img) { itm.img.visible = false }
      })
    }
  }

  setProp(prop: string, value: any) {
    switch (prop) {
      case 'size': {
        let size = 100
        switch (value.size) {
          case 'S':
            size = 50
            break
          case 'M':
            size = 100
            break
          case 'L':
            size = 200
            break
        }
        this.imageSize = size
        this.rescaleImages()
        break
      }
      case 'attributes':
        this.attributes = value.attributes
        this.resetAttributes()
        break
      case 'lock':
        this.set('lock', value.lock)
        this.setLock(value.lock)
        break
      case 'filter':
        this.set('filterJson', value.map(itm => isString(itm) ? itm : itm.toJSON()))
        this.applyFilter()
        break
      case 'groupBy':
        this.set('groupBy', value)
        this.rebuildGroups()
        break
      default:
        console.warn('Attempting to set unsupported WbObjectProp', prop, value)
        return
    }
    this.dirty = true
    this.canvas?.requestRenderAll()
    this.canvas?.fire('object:modified', { target: this })
  }

  getProp(prop: string) {
    const result: any = {}
    switch (prop) {
      case 'size':
        result.size = this.imageSize === 50
          ? 'S'
          : this.imageSize === 100
            ? 'M'
            : this.imageSize === 200
              ? 'L'
              : 'C'
        break
      case 'attributes':
        result.attributes = this.attributes
        break
      case 'lock':
        result.lock = this.lock
        break
      case 'filter':
        result.filter = this.filterJson?.map(itm => new FilterCriteria(JSON.parse(itm))) || '[]'
        break
      case 'groupBy':
        result.groupBy = this.groupBy
        break
      default:
        console.warn('Attempting to get unsupported WbObjectProp', prop)
    }
    return result
  }

  setLock(lock: boolean) {
    this.set('lockMovementX', lock)
    this.set('lockMovementY', lock)
    this.set('lockScalingFlip', lock)
    this.set('lockScalingX', lock)
    this.set('lockScalingY', lock)
    this.set('hasControls', !lock)
    this.set('lockRotation', true)
    this.set('hasRotatingPoint', false)
  }

  override toObject() {
    const propsToPluck = [
      'id',
      'groupBy',
      'filterJson',
      'preventUnlock',
      'angle',
      'backgroundColor',
      'attributes',
      'fill',
      'height',
      'width',
      'left',
      'top',
      'scaleX',
      'scaleY',
      'opacity',
      'version',
      'lock',
      'type',
    ]
    return pick(this, propsToPluck)
  }

  static fromObject(object: WbDynamic, callback?: Function) {
    return fabric.Object._fromObject(whiteboardConstants.objectTypes.dynamic, object, callback)
  }

  override _render(ctx: CanvasRenderingContext2D): void {
    // Draw rectangle
    super._render(ctx)
    if (this._isLoading) {
      ctx.fillStyle = 'lightgrey'
      ctx.fillRect(-this.width! / 2, -this.height! / 2, this.width!, this.height!)
    }

    if (this._isSelected && this._isOverflow) {
      ctx.lineWidth = 3
      ctx.strokeStyle = 'red'
      ctx.strokeRect(-this.width! / 2, -this.height! / 2, this.width!, this.height!)
    }
    // console.log('rendering')
    // if (this._isOverflow && this._isSelected) {

    //   ctx.font = '20px Helvetica'
    //   const measure = ctx.measureText(this.overflowMessage)
    //   ctx.fillStyle = '#1484f8'
    //   ctx.fillRect(this.width! / 2 - measure.width, -this.height! / 2, measure.width, 30)
    //   ctx.fillStyle = '#FFFFFF'
    //   ctx.fillText(this.overflowMessage, this.width! / 2 - measure.width, -this.height! / 2 + 20)
    // }
  }
}

const f: any = fabric
f.WbDynamic = WbDynamic
