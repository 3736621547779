<template>
  <div class="w-full">
    <div v-if="label.length > 0" class="flex">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase" v-text="label" />
      <p v-if="restrictedFormats.length > 0" class="ml-2 text-xs italic text-gray-600">
        {{ allowedFileFormats }}
      </p>
    </div>
    <div
      v-if="showList" ref="refUploadFile" :class="[isArticleForm ? 'h-12 border rounded p-2 text-sm overflow-y-auto' : 'mt-1 overflow-y-auto border rounded cursor-pointer h-28', { 'flex items-center justify-center': validFiles.length <= 0 },
                                                   disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:border-blue-200']" :disabled="disabled"
    >
      <ul v-if="validFiles.length > 0" class="max-h-40 overflow-y-auto">
        <li v-for="(file, index) in validFiles" :key="index" class="flex">
          <div class="p-1 border">
            {{ index + 1 }}
          </div>
          <div class="flex-1 p-1 border">
            {{ file.name }}
          </div>
          <p class="ml-2 text-sm text-gray-500 flex items-center">
            {{ uploadProgress[file.uniqueIdentifier] || 0 }}%
          </p>
          <div v-if="file.error" v-t="'general.fileUploadError'" class="p-1 text-red-400 border" />
          <tx-button class="p-1 border" type="icon" faicon="fa-light fa-close" @click.stop="onRemoveFile(file.uniqueIdentifier)" />
        </li>
      </ul>
      <p v-else v-t="'general.browseOrDropFileHere'" class="text-xs text-center text-gray-500" />
    </div>

    <!-- show list seprate -->
    <div v-else class="flex gap-4">
      <div
        ref="refUploadFile" class="w-1/2 border-2 border-gray-300 rounded-lg flex flex-col items-center justify-center p-4 cursor-pointer hover:border-blue-200"
        :class="disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer hover:border-blue-200'" :disabled="disabled"
      >
        <p class="text-s text-center text-gray-500">
          {{ t('general.browseOrDropFileHere') }}
        </p>
      </div>
      <div class="w-full border rounded-lg p-4 overflow-y-auto h-60">
        <ul v-if="validFiles.length > 0">
          <li v-for="(file, index) in validFiles" :key="index" class="flex items-center py-2">
            <div class="flex-1">
              <p class="text-sm">
                {{ file.name }}
              </p>
              <div class="relative w-full h-2 bg-gray-200 rounded-full">
                <div
                  class="absolute top-0 left-0 h-2 bg-blue-500 rounded-full"
                  :style="{ width: `${uploadProgress[file.uniqueIdentifier]}%` }"
                />
              </div>
            </div>
            <p class="ml-2 text-sm text-gray-500 flex items-center">
              {{ uploadProgress[file.uniqueIdentifier] || 0 }}%
            </p>
            <tx-button class="ml-2 text-gray-500" type="icon" faicon="fa-light fa-close" @click.stop="onRemoveFile(file.uniqueIdentifier)" />
          </li>
        </ul>
        <p v-else class="text-xs text-center text-gray-500">
          {{ t('general.noFilesUploaded') }}
        </p>
      </div>
    </div>
    <div v-if="hasError">
      <p v-if="localError.length > 0" class="text-xs italic text-red-500">
        {{ localError }}
      </p>
      <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500 overflow-auto">
        {{ error.$message }}
      </p>
    </div>
    <div v-if="isUploading" class="flex items-center justify-center">
      <div class="spinner" />
      <span class="ml-2 text-sm">Uploading files...</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FlowFile } from '@flowjs/flow.js'
import Flow from '@flowjs/flow.js'
import { useI18n } from 'vue-i18n'
import type { ErrorObject } from '@vuelidate/core'
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'
import TxButton from '@/shared/components/TxButton.vue'
import auth from '@/services/auth'
import Net from '@/services/net'
import utils from '@/services/utils'

interface IProps {
  label?: string
  errors?: ErrorObject[]
  restrictedFormats?: string[]
  multiple?: boolean
  isArticleForm?: boolean
  showList?: boolean // to show the uploaded files as seprate list
  disabled?: boolean
  clearSelection?: boolean
}
const props = withDefaults(defineProps<IProps>(), { label: '', errors: () => [] as ErrorObject[], restrictedFormats: () => [] as string[], multiple: false, isArticleForm: false, showList: true, disabled: false, clearSelection: false })

const emit = defineEmits<{
  (e: 'update:modelValue', fileId: string | string[]): void
  (e: 'onUpload', file: FlowFile, fileId: string): void
  (e: 'onRemove', file: FlowFile): void
  (e: 'filesUploading', status: boolean): void
}>()

const { t } = useI18n()

const refUploadFile = ref<HTMLDivElement>()
const flow = new Flow({
  target: `${Net.t1.defaults.baseURL}/UploadFileChunk`,
  headers: { Authorization: `Bearer ${auth.getLocalToken()}` },
  speedSmoothingFactor: 0.02,
  chunkSize: 5 * 1024 * 1024,
  singleFile: !props.multiple,
})
const localError = ref('')
const validFiles = ref<FlowFile[]>([])
let selectedFileMap: Record<string, string> = {}
const isUploading = ref(false)
const uploadProgress = ref<Record<string, number>>({})

const allowedFileFormats = computed(() => t('general.allowedFileFormats', { fileFormats: props.restrictedFormats.join(', ') }))

const hasError = computed(() => utils.isValidStringValue(localError.value) || props.errors.length)

function validateFile(file: FlowFile) {
  if (utils.isDefined(file) && !file.name.match(new RegExp(`.(${props.restrictedFormats.join('|')})$`))) {
    return false
  }
  else {
    return true
  }
}

function onFilesAdded(files: FlowFile[]) {
  localError.value = ''
  files.forEach((file) => {
    const fileFetched = file
    fileFetched.name = fileFetched.name.replace(/[\u00A0\u1680\u200B\u180E\u2000-\u2009\u200A\u202F\u205F\u3000]/g, ' ')
    fileFetched.uniqueIdentifier = fileFetched.name.replace(/[\u00A0\u1680\u200B\u180E\u2000-\u2009\u200A\u202F\u205F\u3000]/g, ' ')
    fileFetched.relativePath = fileFetched.name.replace(/[\u00A0\u1680\u200B\u180E\u2000-\u2009\u200A\u202F\u205F\u3000]/g, ' ')
    if (validateFile(fileFetched)) {
      if (props.multiple) {
        validFiles.value.push(fileFetched)
      }
      else {
        validFiles.value[0] = fileFetched
      }
    }
    else {
      localError.value = t('general.unsupportedFile', { fileName: file.name })
      if (!props.isArticleForm) { // in article form we should not make the compoenent invalid
        return false
      }
    }
  })
}

function onFileSubmitted(files: FlowFile[]) {
  if (files.length) {
    isUploading.value = true
    emit('filesUploading', true)
    files.forEach((file) => {
      if (utils.isDefined(validFiles.value.find(f => f.uniqueIdentifier === file.uniqueIdentifier))) {
        flow.upload()
      }
      else {
        flow.removeFile(file)
        isUploading.value = false
        emit('filesUploading', false)
      }
    })
  }
}

flow.on('fileProgress', (file: FlowFile) => {
  const progress = Math.floor((file.progress() || 0) * 100)
  uploadProgress.value[file.uniqueIdentifier] = progress
})

function onFileSuccess(file: FlowFile, message: string) {
  const fileId: string = message.replace(/['"]+/g, '')
  const uniqueIdentifier: string = file.uniqueIdentifier
  uploadProgress.value[uniqueIdentifier] = 100
  if (props.multiple) {
    selectedFileMap[uniqueIdentifier] = fileId
    emit('update:modelValue', Object.values(selectedFileMap))
  }
  else {
    selectedFileMap = { uniqueIdentifier: fileId }
    emit('update:modelValue', fileId)
  }
  emit('onUpload', file, fileId)
  if (Object.values(uploadProgress.value).every(progress => progress === 100)) {
    isUploading.value = false
    emit('filesUploading', false)
  }
}

function onFileError(file: FlowFile, message: string) {
  console.error(file, message)
  localError.value = message
}

function onRemoveFile(fileKey: string) {
  const flowFile = flow.files.find(file => file.uniqueIdentifier === fileKey)
  const fileIndex = validFiles.value.findIndex(file => file.uniqueIdentifier === fileKey)
  if (utils.isDefined(flowFile) && fileIndex !== -1) {
    delete selectedFileMap[flowFile.uniqueIdentifier]
    validFiles.value.splice(fileIndex, 1)
    flow.removeFile(flowFile)
    emit('update:modelValue', props.multiple ? Object.values(selectedFileMap) : '')
    emit('onRemove', flowFile)
  }
}

watch(() => props.clearSelection, (newValue) => {
  if (newValue) {
    validFiles.value = []
    uploadProgress.value = {}
    selectedFileMap = {}
    flow.files.forEach(file => flow.removeFile(file))
    localError.value = ''
  }
})

onMounted(() => {
  if (!props.disabled && utils.isDefined(refUploadFile.value)) {
    flow.assignBrowse([refUploadFile.value])
    flow.assignDrop([refUploadFile.value])
  }
  flow.on('filesAdded', onFilesAdded)
  flow.on('filesSubmitted', onFileSubmitted)
  flow.on('fileSuccess', onFileSuccess)
  flow.on('fileError', onFileError)
})

onUnmounted(() => {
  flow.off('filesAdded')
  flow.off('filesSubmitted')
  flow.off('fileSuccess')
  flow.off('fileError')
})
</script>
