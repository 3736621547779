export default class Job {
  Id: string
  CatalogCode: number
  Type: 'exportPPT' | 'exportPDF' | 'Articles' | 'editSizes' | 'assortArticles' | 'importArticles' | 'updateViaImport' | 'updateViaImportModel' | 'importByLicensee' | 'setSizesByLicenseeModelNumber' | 'setPricesByLicenseeModelNumber' | 'editRequestBulk'
  Status: string
  DownloadUrl?: string
  UserId: number
  CreatedDate: Date

  constructor(obj: Job) {
    this.Id = obj.Id.toString()
    this.CatalogCode = obj.CatalogCode
    this.Type = obj.Type
    this.Status = obj.Status.toString().toLowerCase()
    this.DownloadUrl = obj.DownloadUrl ? obj.DownloadUrl : ''
    this.UserId = obj.UserId
    this.CreatedDate = new Date(obj.CreatedDate)
  }
}
