<template>
  <div class="flex flex-row items-center h-full">
    <div class="sticky left-0 flex items-center h-full pl-[3px] pr-[31px] bg-transparent">
      <tx-button type="plainIcon" icon-size="16px" faicon="fa-light fa-chevron-left" @click="exitEditing" />
    </div>
    <div class="flex flex-col items-center text-xs flex-nowrap">
      <!-- Delivery Date (conditional) -->
      <span>{{ crdId != null ? activeCatalog._IndexedCatalogCRD[crdId].Description : '' }}</span>
      <image-loader
        class="cursor-pointer" :style="{ width: '50px', height: '50px' }"
        :context="activeCatalog.DuneContext" :context-key="activeCatalog.ContextKey"
        :article-number="orderline.Article.ArticleNumber" :size="50"
        @click="onArticleClick(orderline.Article)"
      />
    </div>
    <!-- FORM ITEMS -->
    <div class="h-full mx-8 overflow-hidden">
      <!-- TODO: use form editor -->
      <div class="flex items-center flex-grow h-full overflow-x-auto overflow-y-hidden">
        <div
          v-for="(attribute, index) in visibleAttributes" :key="attribute.SystemName"
          class="flex flex-col items-center w-24 h-full mx-2 flex-nowrap shrink-0" :class="[[AttributeType.Date, AttributeType.DateOption, AttributeType.DateTime].includes(attribute.AttributeType) ? 'w-56 mr-10' : '']"
        >
          <span class="text-xs py-2">{{ attribute.DisplayName }}</span>
          <form-editor
            ref="attributeEditorRef"
            v-bind="deliveryDateProps"
            v-model="formModel[attribute.SystemName]"
            :show-label="false"
            :form="formModel"
            :attribute="attribute"
            :disabled="attribute.ReadOnly"
            :required="attribute.IsRequired"
            :show-update-seasonless-attribute-warning="false"
            :errors="v$[attribute.SystemName]?.$errors"
            @blur="v$[attribute.SystemName].$touch"
            @change="v$[attribute.SystemName].$touch"
            @click="setFocusOnSizeInput(index)"
          />
        </div>
      </div>
      <!-- TODO: size curve -->
    </div>
    <tx-button class="mr-8 rounded shrink-0" width="90px" height="30px" type="confirm" :text="t('general.apply')" :disabled="!visibleAttributes.length || !isOrderEditable || !isCrdEditable || v$.$invalid" @click="updateOrderline" />
    <tx-button v-if="isReplicateAllowed" class="mr-8 rounded shrink-0" width="90px" height="30px" type="confirm" :text="t('general.replicateQty')" :disabled="!visibleAttributes.length || !isOrderEditable || !isCrdEditable || v$.$invalid" @click="replicateQuantity" />
  </div>
</template>

<script lang='ts' setup>
import useVuelidate from '@vuelidate/core'
import { computed, nextTick, ref, toRef, watch } from 'vue'
import { helpers, minValue, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import type CatalogDetails from '@/models/catalogDetails'
import FormEditor from '@/shared/components/FormEditor.vue'
import ImageLoader from '@/shared/components/ImageLoader.vue'
import type MyArticle from '@/models/myArticle'
import Order from '@/models/order'
import type Orderline from '@/models/orderline'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'
import { appConstants } from '@/models/constants'
import { useOrdersStore } from '@/store/orders'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  activeCatalog: CatalogDetails
  crdId: number | null
  isCrdEditable: boolean
  isOrderEditable: boolean
  isOrderlineEditable: boolean
  orderline: Orderline
  visible: boolean
}>()

const emit = defineEmits<{
  (e: 'exitEditing'): void
  (e: 'articleClick', article: MyArticle): void
  (e: 'replicateQty', replicatedSizeInfo: Record<string, number>, bannerInfoMessage: string, articleId: number, crdId: number): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const ordersStore = useOrdersStore()

const currentOrder = toRef(ordersStore, 'currentOrder')
const attributeEditorRef = ref<InstanceType<typeof FormEditor> | null>(null)
const formModel = ref<Record<string, number | string>>({})
const deliveryDateProps = ref<Record<string, any>>({})
const replicatedSizeInfo = ref<Record<string, number>>({})

const isCustomerRequiredDateValidatorAvailable = computed(() => Order.isCustomerRequiredDateValidatorAvailable(userStore.activeCatalog!))
const isReplicateAllowed = computed(() => {
  return props.isOrderEditable && userStore.activeCatalog && !userStore.activeCatalog.IsStockApply && !userStore.activeCatalog.OrderBySizeCurve
})
const visibleAttributes = computed(() => {
  const visibleAttributes: Array<IFormEditorAttribute> = []
  if (props.crdId != null && props.visible) {
    if (isCustomerRequiredDateValidatorAvailable.value) {
      const templateAttributeClone = Object.assign({}, appConstants.staticFormFieldTemplate, {
        Creatable: true,
        SystemName: 'DeliveryDate',
        DisplayName: 'Customer Required Date',
        AttributeType: AttributeType.Date,
        IsRequired: true,
        IsModelLevel: false,
        ReadOnly: !props.isOrderEditable || !props.isCrdEditable,
      })
      visibleAttributes.push(templateAttributeClone)
    }

    const sortedSizes = utils.sort([...props.orderline.Article._Sizes], ['SortOrder', 'SizeIndex'])
    sortedSizes.forEach((size) => {
      const orderlineSize = props.orderline.IndexedCRD[props.crdId!]?.IndexedSizes.hasOwnProperty(size.Id) ? props.orderline.IndexedCRD[props.crdId!].IndexedSizes[size.Id] : null
      if ((size.Status && props.isOrderlineEditable) || (orderlineSize != null && orderlineSize.Quantity > 0)) {
        const templateAttributeClone = Object.assign({}, appConstants.staticFormFieldTemplate, {
          Creatable: true,
          SystemName: size.Id,
          DisplayName: size.SizeName,
          IsModelLevel: false,
          minValue: 0,
          ReadOnly: !size.Status || !props.isOrderEditable || !props.isCrdEditable, // TODO: check for CRD status, if assigned to orderline probably from orderline otherwise check the status on catalog, article and its availability
        })
        visibleAttributes.push(templateAttributeClone)
      }
    })
  }
  return visibleAttributes
})

const validations = computed(() => {
  const result: Record<string, any> = {}
  visibleAttributes.value.forEach((attribute) => {
    result[attribute.SystemName] = {}
    if (attribute.IsRequired) {
      result[attribute.SystemName].required = helpers.withMessage(t('validations.required', { property: attribute.DisplayName }), required)
    }
    if (utils.isDefined(attribute.minValue)) {
      result[attribute.SystemName].minValue = helpers.withMessage(t('validations.minValue', { property: attribute.DisplayName, min: attribute.minValue }), minValue(attribute.minValue))
    }
  })
  return result
})
const v$ = useVuelidate(validations, formModel)

function init() {
  visibleAttributes.value.forEach((visibleAttribute) => {
    if (visibleAttribute.AttributeType === AttributeType.Int) {
      // sizes
      const sizeId = visibleAttribute.SystemName
      const orderlineSize = props.orderline.IndexedCRD[props.crdId!]?.IndexedSizes.hasOwnProperty(sizeId) ? props.orderline.IndexedCRD[props.crdId!].IndexedSizes[sizeId] : null
      formModel.value[visibleAttribute.SystemName] = 0
      if (orderlineSize != null) {
        formModel.value[visibleAttribute.SystemName] = orderlineSize.Quantity
      }
    }
    else if (visibleAttribute.SystemName === 'DeliveryDate') {
      // delivery date
      formModel.value[visibleAttribute.SystemName] = ''
      deliveryDateProps.value.teleport = true
      if (isCustomerRequiredDateValidatorAvailable.value) {
        let customerRequiredDateValidation = {} as { relation: string, numberOfDays: number }
        if (userStore.activeCatalog!.CustomerRequiredDateValidation != null) { // CustomerRequiredDateValidation will not be null in this function
          try {
            customerRequiredDateValidation = JSON.parse(userStore.activeCatalog!.CustomerRequiredDateValidation)
          }
          catch (error) {
            console.warn(error)
          }
        }
        // setup validation for delivery date field
        if (props.orderline.IndexedCRD.hasOwnProperty(props.crdId!)) {
          formModel.value[visibleAttribute.SystemName] = props.orderline.IndexedCRD[props.crdId!]?.DeliveryDate as string
        }
        else {
          const crd = userStore.activeCatalog!._IndexedCatalogCRD[props.crdId!]
          const currentCrdDateObject = new Date(crd.CustomerRequiredDate)
          currentCrdDateObject.setHours(0, 0, 0)
          if (utils.validateCondition(currentCrdDateObject.getTime(), currentCrdDateObject.getTime(), customerRequiredDateValidation.relation)) {
            formModel.value[visibleAttribute.SystemName] = crd.CustomerRequiredDate
          }
        }
        setupValidationForDeliveryDatePicker(customerRequiredDateValidation)
      }
    }
  })
  nextTick(() => {
    setFocusOnSizeInput(1)
  })
}

function setupValidationForDeliveryDatePicker(customerRequiredDateValidation: { relation: string, numberOfDays: number }) {
  const crd = userStore.activeCatalog!._IndexedCatalogCRD[props.crdId!] // if visibleAttributes has length then props.crdId is not null
  const currentCrdDateObject = new Date(crd.CustomerRequiredDate)
  currentCrdDateObject.setHours(0, 0, 0)
  let deliveryDateValidationStartTime: number | null = null
  let deliveryDateValidationEndTime: number | null = null
  if (customerRequiredDateValidation.hasOwnProperty('numberOfDays') && customerRequiredDateValidation.numberOfDays > 0) {
    let numberOfDays = customerRequiredDateValidation.numberOfDays
    // following comment brought form T1S code base
    // Related to bug #33790, we have to remove 1 day for '>=' and '<='
    if (customerRequiredDateValidation.hasOwnProperty('relation') && (customerRequiredDateValidation.relation === '<=' || customerRequiredDateValidation.relation === '>=')) {
      numberOfDays -= 1
    }
    deliveryDateValidationStartTime = currentCrdDateObject.getTime() - numberOfDays * 24 * 60 * 60 * 1000
    deliveryDateValidationEndTime = currentCrdDateObject.getTime() + numberOfDays * 24 * 60 * 60 * 1000
  }

  deliveryDateProps.value['disabled-dates'] = (date) => {
    const currentDate = new Date(date).getTime()
    if (!customerRequiredDateValidation.hasOwnProperty('relation') || !customerRequiredDateValidation.hasOwnProperty('numberOfDays') || customerRequiredDateValidation.numberOfDays <= 0
      || deliveryDateValidationStartTime == null || deliveryDateValidationEndTime == null) {
      return false
    }
    if (utils.validateCondition(currentDate, currentCrdDateObject.getTime(), customerRequiredDateValidation.relation)) {
      if ((currentDate < deliveryDateValidationEndTime && currentDate > deliveryDateValidationStartTime) || currentDate === deliveryDateValidationEndTime || currentDate === deliveryDateValidationStartTime) {
        return false
      }
      else {
        return true
      }
    }
    else {
      return true
    }
  }
  deliveryDateProps.value['custom-date-format'] = 'dd MMM yyyy'
}

function exitEditing() {
  emit('exitEditing')
}

function updateOrderline() {
  const sizesWithQuantity: Array<{ sizeId: number, quantity: number }> = []
  visibleAttributes.value.forEach((visibleAttribute) => {
    if (visibleAttribute.AttributeType === AttributeType.Int && !visibleAttribute.ReadOnly) { // user is able to update only active sizes
      sizesWithQuantity.push({ sizeId: Number.parseInt(visibleAttribute.SystemName), quantity: formModel.value[visibleAttribute.SystemName] as number ?? 0 })
    }
    else if (visibleAttribute.SystemName === 'DeliveryDate') {
      props.orderline.setDeliveryDate(props.crdId!, formModel.value[visibleAttribute.SystemName] as string)
    }
  })
  props.orderline.setQuantity(currentOrder.value!, props.crdId!, sizesWithQuantity)
  exitEditing()
}
function replicateQuantity() {
  replicatedSizeInfo.value = {}
  visibleAttributes.value.forEach((visibleAttribute) => {
    if (visibleAttribute.AttributeType === AttributeType.Int && !visibleAttribute.ReadOnly && formModel.value[visibleAttribute.SystemName] as number !== 0) { // user is able to update only active sizes
      replicatedSizeInfo.value[visibleAttribute.DisplayName.toLowerCase()] = formModel.value[visibleAttribute.SystemName] as number
    }
  })
  const sortedSizes = utils.sort([...props.orderline.Article._Sizes], ['SortOrder', 'SizeIndex'])
  let bannerInfoMessage = t('orders.alerts.replicateMessage')
  if (Object.keys(replicatedSizeInfo.value).length) {
    let validCount = 0

    sortedSizes.forEach((size) => {
      if (replicatedSizeInfo.value[size.SizeName.toLowerCase()]) {
        if (validCount !== 0) {
          bannerInfoMessage += ', '
        }
        validCount++
        bannerInfoMessage += ` ${size.SizeName}: ${replicatedSizeInfo.value[size.SizeName.toLowerCase()]}`
      }
    })
  }
  emit('replicateQty', replicatedSizeInfo.value, bannerInfoMessage, props.orderline.Article.Id, props.crdId!)
}
function setFocusOnSizeInput(index: number) {
  if (attributeEditorRef.value && attributeEditorRef.value[index]) {
    attributeEditorRef.value[index].controlRef?.focus()
  }
}

function onArticleClick(article: MyArticle) {
  emit('articleClick', article)
}

function reset() {
  formModel.value = {}
  deliveryDateProps.value = {}
}

watch(() => props.visible, (visible) => {
  if (visible) {
    init()
  }
  else {
    reset()
  }
})
</script>
