import type { IFilterCriteria } from './filterCriteria'
import type { ISettingItem } from '@/modules/articleDetails/components/SettingsDropdown.vue'

export default class UserPreferences {
  userId: number
  targetSummary: { filterCriteria: IFilterCriteria[] }
  modelDetails: { settings: Record<number, ISettingItem> }

  constructor(userPreferences: UserPreferences) {
    this.userId = userPreferences.userId
    this.targetSummary = userPreferences.targetSummary
    this.modelDetails = userPreferences.modelDetails
  }
}
