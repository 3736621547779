<template>
  <tx-dialog
    :model-value="visible"
    :title="t('requests.viewAttachments.attachmentPreview')"
    width="600px"
    height="500px"
    :loading="loading"
    @cancel="closeDialog"
  >
    <div class="overflow-hidden flex justify-center items-center h-full">
      <div v-if="requestAttachment && requestAttachment.FilePath">
        <div class="flex flex-col items-centerf">
          <img :src="requestAttachment.FilePath" class="h-auto max-w-full" alt="Attachment">
        </div>
      </div>
    </div>

    <template #footer>
      <div class="flex justify-center items-center border-t pt-4 pb-4">
        <tx-button
          v-if="hasDeleteAttachmentPrivilege && currentRequest
            && (currentRequest.State === requestConstants.requestStates.draft || currentRequest.State === requestConstants.requestStates.new)
            && currentRequest?.CreatedBy === userStore.userProfile.Id
            && currentRequest.CatalogCode === userStore.activeCatalog?.CatalogCode
            && userStore.activeCatalog?.AcceptRequests"
          type="danger"
          :disabled="!utils.isDefined(requestAttachment) || requestAttachment.CreatedBy !== userStore.userProfile.Id"
          :text="t('general.delete')" faicon="fa-light fa-trash-can" width="120px"
          :is-request="true"
          class="mr-2"
          @click="deleteAttachment"
        />
        <tx-button
          type="confirm"
          :text="t('requests.viewAttachments.download')" faicon="fa-light fa-download" width="120px"
          :is-request="true"
          @click="downloadAttachment"
        />
      </div>
    </template>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import axios from 'axios'
import TxDialog from '@/shared/components/TxDialog.vue'
import type MyArticle from '@/models/myArticle'
import { useUserStore } from '@/store/userData'
import type { IAttachmentModel } from '@/api/t1/model/requestModel'
import TxButton from '@/shared/components/TxButton.vue'
import utils from '@/services/utils'
import type RequestModel from '@/models/request'
import { privileges, requestConstants } from '@/models/constants'
import { deleteRequestAttachment } from '@/api/t1/request'
import { useNotificationStore } from '@/store/notification'

const emit = defineEmits<{
  (e: 'cancel'): void
  (e: 'confirm', article: MyArticle, asset: string): void
  (e: 'delete', item: RequestModel): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const visible = ref(false)
const loading = ref(false)
const requestAttachment = ref<IAttachmentModel>()
const currentRequest = ref<RequestModel>()

const hasDeleteAttachmentPrivilege = computed(() => userStore.userProfile.isValidPrivilege(privileges.request.deleteAttachment))

function showDialog(attachment: IAttachmentModel, request: RequestModel) {
  if (attachment && attachment.FilePath) {
    visible.value = true
    requestAttachment.value = attachment
    currentRequest.value = request
  }
}

function closeDialog() {
  loading.value = false
  visible.value = false
}

async function deleteAttachment() {
  loading.value = true
  await deleteRequestAttachment(userStore.activeCatalog!.CatalogCode, currentRequest.value!.Id, [{ Id: requestAttachment.value!.Id, Status: 0 }])
  notificationStore.addNotification({ message: t('requests.viewAttachments.attachmentDeletedSuccessfully'), type: 'Success' })
  emit('delete', currentRequest.value!)
  closeDialog()
}

function downloadAttachment() {
  if (requestAttachment.value && utils.isValidStringValue(requestAttachment.value.FilePath)) {
    axios({
      url: requestAttachment.value.FilePath,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      closeDialog()
      const href = URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', requestAttachment.value!.FileName)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
}

defineExpose({
  showDialog,
})
</script>
