<template>
  <div class="flex h-12 rounded-lg bg-white shadow-toolbar">
    <div class="pr-3 m-2 leading-8 border-r text-primary-500 text-md border-blue-50">
      {{ userStore.activeCatalog?.Season }}
    </div>
    <div v-tooltip="{ text: name, theme: { placement: 'bottom' } }" class="relative m-2 pr-4 border-r border-blue-50 min-w-[250px] max-w-[400px]">
      <p class="pb-1 text-xxs" v-text="t('whiteboard.titlebar.viewing')" />
      <p class="z-1000 block text-sm text-primary-500 truncate whitespace-nowrap overflow-hidden text-ellipsis" v-text="name" />
    </div>
    <div class="p-1 m-1 w-[140px]">
      <tx-button
        v-tooltip="'Undo [Ctrl + Z]'" type="icon" faicon="fa-light fa-rotate-left"
        :disabled="!(whiteboard && whiteboard.history.value.length > whiteboard.historyPointer.value) || isReadOnly" @click="onUndo"
      />
      <tx-button
        v-tooltip="'Redo [Ctrl + Shift + Z]'" type="icon" faicon="fa-light fa-rotate-right"
        :disabled="!(whiteboard && whiteboard.historyPointer.value > 0) || isReadOnly" @click="onRedo"
      />
      <tx-button v-tooltip="'Comments'" type="icon" faicon="fa-light fa-comments" :disabled="isReadOnly" @click="toggleComments" />
      <tx-button v-tooltip="'Settings'" type="icon" faicon="fa-light fa-gear" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed } from 'vue'
import type Whiteboard from '../services/whiteboard'
import TxButton from '@/shared/components/TxButton.vue'
import { useUserStore } from '@/store/userData'
import { whiteboardConstants } from '@/models/constants'

interface IProps {
  name?: string
  whiteboard?: Whiteboard
  permission: WhiteboardUserPermissionType
}

const props = withDefaults(defineProps<IProps>(), { name: '' })
const emit = defineEmits<{
  (e: 'toggleComments'): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const isReadOnly = computed(() => props.permission === whiteboardConstants.userPermissions.read)

function onUndo() {
  if (props.whiteboard) {
    props.whiteboard.undo()
  }
}

function onRedo() {
  if (props.whiteboard) {
    props.whiteboard.redo()
  }
}

function toggleComments() {
  emit('toggleComments')
}
</script>
