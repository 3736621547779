<template>
  <tx-dialog
    v-model="visible" :title="dialogTitle" width="450px" show-ok-cancel
    :ok-state="v$.$invalid ? 'disabled' : 'enabled'" :loading="loading"
    confirm-text="general.save" @ok="doSave()"
  >
    <div class="w-full h-full">
      <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
      <div v-if="errorMessage && showActivateActions" class="flex justify-between">
        <tx-button type="danger" :text="t('whiteboard.manageDialog.activateAndNew')" @click="doActivate(true)" />
        <tx-button :text="t('whiteboard.manageDialog.activate')" @click="doActivate()" />
      </div>
      <div class="w-full h-full">
        <div class="w-full my-2">
          <tx-select
            v-model="form.folder" :label="t('whiteboard.manageDialog.folder')" clearable
            :data="parentFolders" value-prop="key" display-prop="label"
          />
        </div>
        <div class="w-full my-2">
          <tx-input v-model.trim="form.name" :label="t('whiteboard.manageDialog.name')" required />
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import useVuelidate from '@vuelidate/core'
import { createI18nMessage, helpers, maxLength, minLength, required } from '@vuelidate/validators'
import { computed, reactive, ref } from 'vue'
import useErrorMessage from '@/shared/composables/errorMessage'
import TxDialog from '@/shared/components/TxDialog.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import TxButton from '@/shared/components/TxButton.vue'
import type Whiteboard from '@/models/whiteboard'

const emit = defineEmits<{
  (e: 'save', folder: string, name: string, actionType: ManageActionType, existingWhiteboard?: Whiteboard): void
  (e: 'activate', overwrite: boolean, sameNameId: number): void
}>()

export type ManageActionType = 'create' | 'update' | 'duplicate'

const { t } = useI18n()
const { errorMessage, hasError } = useErrorMessage()
const withI18nMessage = createI18nMessage({ t })

const visible = ref(false)
const loading = ref(false)
const showActivateActions = ref(false)
const form = reactive({
  folder: '',
  name: '',
})
const parentFolders = ref<IKeyLabel<string>[]>([])
const actionType = ref<ManageActionType>('create')
const existingWhiteboard = ref<Whiteboard>()
const sameNameWhiteboardId = ref<number>()

const dialogTitle = computed(() => actionType.value === 'update'
  ? t('whiteboard.manageDialog.editTitle')
  : actionType.value === 'duplicate'
    ? t('whiteboard.manageDialog.duplicateTitle')
    : t('whiteboard.manageDialog.newTitle'))

const rules = computed(() => {
  const result: Record<string, any> = {}
  if (visible.value) {
    result.name = {
      required: helpers.withMessage(t('validations.required', { property: t('whiteboard.list.item.name') }), required),
      minLength: withI18nMessage(minLength(1)),
      maxLength: withI18nMessage(maxLength(250)),
    }
  }
  return result
})
const v$ = useVuelidate(rules, form)

function showDialog(folders: IKeyLabel<string>[], action: ManageActionType, whiteboard: Whiteboard | undefined = undefined) {
  parentFolders.value = folders
  reset()
  actionType.value = action
  existingWhiteboard.value = whiteboard
  if (whiteboard) {
    form.folder = whiteboard.FolderId ?? ''
    if (action === 'update') {
      form.name = whiteboard.Name
    }
  }
}

function closeDialog() {
  visible.value = false
}

async function doSave() {
  errorMessage.value = ''
  if (!(await v$.value.$validate())) {
    errorMessage.value = t('validations.formInvalid')
  }
  loading.value = true
  emit('save', form.folder, form.name, actionType.value, existingWhiteboard.value)
}

function doActivate(overwrite = false) {
  if (sameNameWhiteboardId.value) {
    loading.value = true
    emit('activate', overwrite, sameNameWhiteboardId.value)
  }
}

function reset() {
  loading.value = false
  visible.value = true
  showActivateActions.value = false
  form.folder = ''
  form.name = ''
}

function setLoading(val: boolean) {
  loading.value = val
}

function setErrorMessage(message: string) {
  errorMessage.value = message
}

function setupShowActivateActions(val: boolean, sameNameId: number) {
  showActivateActions.value = val
  sameNameWhiteboardId.value = sameNameId
}

defineExpose({
  showDialog,
  closeDialog,
  setErrorMessage,
  setLoading,
  setupShowActivateActions,
})
</script>
