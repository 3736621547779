import { DB } from './db'
import type { FilterCriteria } from '@/models/filterCriteria'
import appInsightsInstance from '@/applicationInsights'

export class AppConfig {
  AuthUrl: string
  T1URL: string
  T1v2URL: string
  T1Env: string
  ClientCode: string
  DunesUrl: string
  AssetsUrl: string
  ShareDbUrl: string
  WhiteboardUrl: string
  FileServerUrl: string
  I18nLocale: 'en' | 'enCustom'
  stateLockingExternalChangeManagementURL: string
  stateLockingExternalChangeManagementURLForModelName: string
  stateLockingExternalChangeManagementURLForPrices: string
  stateLockingExternalChangeManagementURLForSizeScale: string
  requestNotificationInterval: number
  streamArticleInterval: number
  DB?: DB

  constructor() {
    this.AssetsUrl = ''
    this.AuthUrl = ''
    this.ClientCode = ''
    this.DunesUrl = ''
    this.T1Env = ''
    this.T1v2URL = ''
    this.T1URL = ''
    this.ShareDbUrl = ''
    this.WhiteboardUrl = ''
    this.FileServerUrl = ''
    this.I18nLocale = 'en'
    this.stateLockingExternalChangeManagementURL = ''
    this.stateLockingExternalChangeManagementURLForModelName = ''
    this.stateLockingExternalChangeManagementURLForPrices = ''
    this.stateLockingExternalChangeManagementURLForSizeScale = ''
    this.requestNotificationInterval = 600000
    this.streamArticleInterval = 300000
  }

  init(config: AppConfig) {
    this.AssetsUrl = config.AssetsUrl
    this.AuthUrl = config.AuthUrl
    this.ClientCode = config.ClientCode
    this.DunesUrl = config.DunesUrl
    this.T1Env = config.T1Env
    this.T1v2URL = config.T1v2URL
    this.T1URL = config.T1URL
    this.ShareDbUrl = config.ShareDbUrl
    this.WhiteboardUrl = config.WhiteboardUrl
    this.FileServerUrl = config.FileServerUrl
    this.I18nLocale = config.I18nLocale
    this.stateLockingExternalChangeManagementURL = config.stateLockingExternalChangeManagementURL
    this.stateLockingExternalChangeManagementURLForModelName = config.stateLockingExternalChangeManagementURLForModelName
    this.stateLockingExternalChangeManagementURLForPrices = config.stateLockingExternalChangeManagementURLForPrices
    this.stateLockingExternalChangeManagementURLForSizeScale = config.stateLockingExternalChangeManagementURLForSizeScale
    this.requestNotificationInterval = config.requestNotificationInterval || 600000
    this.streamArticleInterval = config.streamArticleInterval || 300000
    this.DB = new DB(this.T1Env)
  }

  setApplicationInsights(eventName: string, loadTime: number, catalogCode: number, userName: string, criteria: FilterCriteria[]) {
    appInsightsInstance.then(async (appInsights) => {
      if (appInsights && appInsights !== null) {
        appInsights.context.user.setAuthenticatedUserContext(userName)
        const buildDate = new Date('__DATE__').toLocaleDateString()
        const criteriaValue = JSON.stringify(criteria)
        appInsights.trackMetric({ name: `${eventName}`, average: loadTime }, { catalogCode: catalogCode.toString(), envCode: this.T1Env, build: buildDate, criteria: criteriaValue })
      }
    })
  }
}

export default new AppConfig()
