export default class Whiteboard {
  Id: number
  CatalogCode: number
  Name: string
  FolderId?: string
  FolderName?: string
  CreatedByEmail: string
  CreatedByName: string
  CreatedByUserName: string
  Status: number
  CreatedBy: number
  UpdatedBy: number
  CreatedDate: Date
  UpdatedDate: Date

  constructor(CatalogCode: number, obj: any) {
    this.Id = obj.Id
    this.CatalogCode = CatalogCode
    this.Name = obj.Name
    this.FolderId = obj.FolderId
    this.FolderName = obj.FolderName
    this.CreatedByEmail = obj.CreatedByEmail
    this.CreatedByName = obj.CreatedByName
    this.CreatedByUserName = obj.CreatedByUserName
    this.Status = obj.Status
    this.CreatedBy = obj.CreatedBy
    this.UpdatedBy = obj.UpdatedBy
    this.CreatedDate = new Date(obj.CreatedDate)
    this.UpdatedDate = new Date(obj.UpdatedDate)
  }
}
