import type { CreateRequestModel, RequestHistoryModel, RequestModel, RequestObjectModel, RequestReasonsModel, UpdateRequestModel, UpdateRequestStateModel, UpdateRequestStatusModel } from './model/requestModel'
import { backgroundJobConstants, requestConstants } from '@/models/constants'
import type { RequestDetailsModel } from '@/models/request'
import Net from '@/services/net'
import utils from '@/services/utils'

/**
 * Get list of requests
 * @param catalogCode
 * @param extendedResults
 * @param objectId objectId
 * @param u toISOString date
 * @returns ArticleModel[]
 */
export function getRequests(catalogCode: number, extendedResults?: boolean, objectId?: string, u?: string) {
  const params = new URLSearchParams()
  params.set('requestversion', requestConstants.requestVersion.toString())
  if (utils.isDefined(extendedResults)) {
    params.set('extendedresults', extendedResults.toString())
  }
  if (utils.isDefined(objectId)) {
    params.set('ObjectId', objectId)
  }
  if (utils.isDefined(u)) {
    params.set('u', u)
  }
  return Net.t1.get<RequestModel[]>(`/catalogs/${catalogCode}/requests?${params.toString()}`)
}

/**
 * Get list of request objects
 * @param catalogCode
 * @param objectId objectId
 * @param extendedResults extendedResults boolean
 * @param u toISOString date
 * @returns ArticleModel[]
 */
export function getRequestObjects(catalogCode: number, objectId?: string, extendedResults?: boolean, u?: string) {
  const params = new URLSearchParams()
  if (utils.isDefined(objectId)) {
    params.set('ObjectId', objectId)
  }
  if (utils.isDefined(extendedResults)) {
    params.set('extendedResults', extendedResults.toString())
  }
  if (utils.isDefined(u)) {
    params.set('u', u)
  }
  return Net.t1.get<RequestObjectModel[]>(`/catalogs/${catalogCode}/requests/objects?${params.toString()}`)
}

export function createRequestBulk(catalogCode: number, requestObject: CreateRequestModel[], copies?: number) {
  const params = new URLSearchParams()
  if (copies) {
    params.set('copies', copies.toString())
  }
  return Net.t1.post<RequestModel[]>(`/catalogs/${catalogCode}/requests/createbulk?${params.toString()}`, requestObject)
}

export function createRequest(catalogCode: number, requestObject: CreateRequestModel) {
  return Net.t1.post<RequestModel>(`/catalogs/${catalogCode}/requests`, requestObject)
}

export function getRequestHistory(catalogCode: number, requestId: number) {
  return Net.t1.get<RequestHistoryModel[]>(`/catalogs/${catalogCode}/requests/${requestId}/history`)
}

export function getRequestReasons(catalogCode: number) {
  return Net.t1.get<RequestReasonsModel[]>(`/catalogs/${catalogCode}/requests/reasons`)
}

export function updateRequestStatuses(catalogCode: number, payload: UpdateRequestStatusModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/requests/status`, payload)
}

export function approveRequests(catalogCode: number, payload: UpdateRequestStateModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/requests/approve`, payload)
}

export function rejectRequests(catalogCode: number, payload: UpdateRequestStateModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/requests/reject`, payload)
}

export function getRequestDetails(catalogCode: number, requestId: number) {
  return Net.t1.get<RequestDetailsModel[]>(`/catalogs/${catalogCode}/requests/${requestId}`)
}

export function addRequestAttachments(catalogCode: number, requestId: number, payload: string[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/requests/${requestId}/attachments`, payload)
}

export function deleteRequestAttachment(catalogCode: number, requestId: number, payload: UpdateRequestStatusModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/requests/${requestId}/attachments/status`, payload)
}

export function updateRequest(catalogCode: number, requestId: number, payload: UpdateRequestModel) {
  return Net.t1.put(`/catalogs/${catalogCode}/requests/${requestId}/update`, payload)
}

export function editRequestBulkBgJob(requestObject: Record<string, any>) {
  const params = new URLSearchParams()
  params.set('sourceapplication', backgroundJobConstants.sourceApplication)
  params.set('sourceaction', backgroundJobConstants.sourceAction.editRequestBulk)
  return Net.t1.put(`/requests/updateBulk?${params.toString()}`, requestObject)
}
