import type { CreateUpdateWhiteboardModel, SendMentionNotificationsModel, SharedUserGroupModel, SharedUserModel, UpdateWhiteboardsStatusModel, WhiteboardDetailsModel, WhiteboardItemModel, WhiteboardListModel } from './model/whiteboardModel'
import utils from '@/services/utils'
import Net from '@/services/net'

export function getMyWhiteboards(catalogCode: number, whiteboardType?: 'my' | 'shared', u?: string) {
  const params = new URLSearchParams()
  if (utils.isDefined(whiteboardType)) {
    params.set('whiteboardType', whiteboardType)
  }
  if (utils.isDefined(u)) {
    params.set('u', u)
  }
  return Net.t1.get<WhiteboardListModel[]>(`/catalogs/${catalogCode}/mywhiteboards?${params.toString()}`)
}

export function getWhiteboardDetails(catalogCode: number, whiteboardId: number) {
  return Net.t1.get<WhiteboardDetailsModel>(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}`)
}

export function createWhiteboard(catalogCode: number, payload: CreateUpdateWhiteboardModel) {
  return Net.t1.post<WhiteboardItemModel>(`/catalogs/${catalogCode}/whiteboards`, payload)
}

export function updateWhiteboard(catalogCode: number, whiteboardId: number, payload: CreateUpdateWhiteboardModel) {
  return Net.t1.put<WhiteboardDetailsModel>(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}`, payload)
}

export function updateWhiteboardFolder(catalogCode: number, folderId: string, folderName: string) {
  return Net.t1.put<WhiteboardItemModel[]>(`/catalogs/${catalogCode}/whiteboards/folders/${folderId}`, { FolderName: folderName })
}

export function updateWhiteboardsStatus(catalogCode: number, requestObject: UpdateWhiteboardsStatusModel[]) {
  return Net.t1.put(`/catalogs/${catalogCode}/whiteboards/status`, requestObject)
}

export function shareWhiteboard(catalogCode: number, whiteboardId: number, sharedUsers: SharedUserModel[] = [], sharedUserGroups: SharedUserGroupModel[] = []) {
  return Net.t1.put(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}/share`, { SharedUsers: sharedUsers, SharedUsersGroups: sharedUserGroups })
}

export function sendMentionNotifications(catalogCode: number, whiteboardId: number, payload: SendMentionNotificationsModel) {
  return Net.t1.post(`/catalogs/${catalogCode}/whiteboards/${whiteboardId}/tags/notifications`, payload)
}
