<template>
  <div class="container relative flex flex-col w-full h-full overflow-hidden">
    <!-- HEADER -->
    <tx-form-header class="flex flex-col mx-2 my-[30px] grow-0 shrink-0 justify-center header" :title="t('orders.importOrder.title')" :show-header="true" />
    <!-- BODY -->
    <div class="relative flex-auto mt-4 overflow-auto body">
      <div class="flex flex-col w-full px-10 overflow-hidden">
        <div v-if="currentStep === steps.uploadFile || currentStep === steps.selectSheet || currentStep === steps.columnsMapping || currentStep === steps.validations">
          <file-upload
            v-model="formModel.file" file-format-hint="Excel (xlsx)"
            accept-format=".xlsx" @change="onFileChange"
          />
        </div>
        <!-- SELECT SHEET STEP -->
        <div v-show="currentStep === steps.selectSheet || currentStep === steps.columnsMapping || currentStep === steps.validations" class="flex flex-col flex-nowrap">
          <div class="mt-4">
            <label class="text-xs tracking-wide uppercase label required" v-text="t('orders.importOrder.mapping.sheet')" />
            <tx-select
              v-if="excelWorkbook" v-model="formModel.sheetId" class="col-span-3" :data="excelWorkbook.worksheets" :errors="v$.sheetId?.$errors"
              value-prop="id" display-prop="name" :sort-list="false" @change="onSheetChange"
            />
          </div>
          <!-- Implement stock drop down when stock is implemented -->
        </div>
        <!-- COLUMN MAPPING STEP -->
        <div v-show="currentStep === steps.columnsMapping || currentStep === steps.validations" class="flex flex-col flex-nowrap">
          <template v-for="mapping in columnsMappingFields" :key="mapping.name">
            <label class="text-xs tracking-wide uppercase label mt-4" :class="{ required: mapping.required }" v-text="t(mapping.label)" />
            <tx-select
              v-model="formModel.mapping[mapping.name]" class="col-span-3" :data="excelColumns" :errors="v$[mapping.name]?.$errors"
              :sort-list="false" clearable filterable @change="onMappingChange(mapping.name)"
            />
          </template>
          <label class="text-xs tracking-wide uppercase label required mt-4" v-text="t('orders.importOrder.fields.selectOption')" />
          <div class="grid grid-cols-3 col-span-3 gap-4">
            <label><input v-model="formModel.option" type="radio" value="newOrder" @change="onOptionChange()"><span class="ml-1">{{ t('orders.importOrder.fields.newOrder') }}</span></label>
            <label><input v-model="formModel.option" type="radio" value="overwriteExistingOrder" @change="onOptionChange()"><span class="ml-1">{{ t('orders.importOrder.fields.overwriteExistingOrders') }}</span></label>
          </div>
        </div>
        <!-- VALIDATION STEP -->
        <div v-show="currentStep === steps.validations" class="flex flex-col flex-nowrap mt-4">
          <loader v-if="isValidating" />
          <div v-if="!isValidating">
            <div v-if="!validationModel.noOrderToImport" class="text-sm">
              <div>
                <span>{{ t('orders.importOrder.labels.totalValidRows') }}</span>
                <span>{{ validationModel.validData.length }}</span>
              </div>
              <div>
                <span>{{ t('orders.importOrder.labels.totalInvalidRows') }}</span>
                <span>{{ validationModel.totalInvalidRows }}</span>
              </div>
              <div v-if="validationModel.emptyOrdersColumns.length && validationModel.totalInvalidRows === 0" class="fs12 ml-5">
                <div>
                  <span>*</span>
                  <span v-if="formModel.option === 'newOrder'">{{ t('orders.importOrder.validations.newOrderEmptyOrderColumn', { columns: validationModel.emptyOrdersColumns.join(', ') }) }}</span>
                  <span v-else>{{ t('orders.importOrder.validations.overwriteOrderEmptyOrderColumn', { columns: validationModel.emptyOrdersColumns.join(', ') }) }} </span>
                </div>
              </div>
              <div v-if="validationModel.totalInvalidRows !== 0">
                <div>
                  {{ t('orders.importOrder.labels.importValidationFailedMessage') }}
                </div>
                <div class="mt-2">
                  <tx-button type="cancel" :text="t('orders.importOrder.labels.getResult')" @click="getImportDataResult" />
                </div>
                <div v-if="validationModel.validData.length > 0" class="mt-4">
                  <tx-checkbox id="tx-skip-invalid-rows-order" v-model="validationModel.skipInvalidRows" :label="t('orders.importOrder.labels.skipInvalidRows')" />
                </div>
              </div>
            </div>
            <div v-else>
              {{ t('orders.importOrder.validations.noOrderWithValidQuantity') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- FOOTER -->
    <tx-form-footer
      class="flex flex-row justify-end flex-shrink-0 flex-nowrap"
      :primary-text="currentStep === steps.validations ? t('general.import') : t('general.next')" :secondary-text="t('general.back')" :secondary-disabled="currentStep === steps.uploadFile ? true : false" :primary-loading="loading"
      :primary-disabled="v$.$invalid || (currentStep === steps.validations && validationModel.totalInvalidRows !== 0 && (!validationModel.skipInvalidRows || validationModel.validData.length === 0) || validationModel.noOrderToImport)" @primary-click="changeStep(1)" @secondary-click="changeStep(-1)"
    />
  </div>
</template>

<script lang='ts' setup>
import ExcelJS from 'exceljs'
import useVuelidate from '@vuelidate/core'
import { computed, inject, onMounted, reactive, ref } from 'vue'
import { createI18nMessage, required } from '@vuelidate/validators'
import { useI18n } from 'vue-i18n'
import { cloneDeep, isEmpty, isFunction } from 'lodash-es'
import FileUpload from '@/shared/components/FileUpload.vue'
import TxButton from '@/shared/components/TxButton.vue'
import TxSelect from '@/shared/components/TxSelect.vue'
import Loader from '@/shared/components/Loader.vue'
import TxCheckbox from '@/shared/components/TxCheckbox.vue'
import type CustomerLocation from '@/models/CustomerLocation'
import type MyArticle from '@/models/myArticle'
import Order from '@/models/order'
import TxFormFooter from '@/shared/components/forms/TxFormFooter.vue'
import TxFormHeader from '@/shared/components/forms/TxFormHeader.vue'
import utils from '@/services/utils'
import { ordersConstants } from '@/models/constants'
import { useUserStore } from '@/store/userData'
import { useNotificationStore } from '@/store/notification'
import appConfig from '@/services/appConfig'
import type LinkedCustomer from '@/models/linkedCustomer'
import type { Segmentation } from '@/models/customerSegmentation'

interface IExcelMapping {
  name: string
  column: number
  type: 'text' | 'date' | 'number' | 'int'
  label: string
  required: boolean
  autoMap?: string[]
  extras?: Record<string, any>
  validator?: (cellValue: string, columnLabel: string, extraParam1?: string, extraParam3?: string, extraParam4?: string, extraParam5?: boolean) => string | undefined
  validationPattern?: string
  invalidPatternMessage?: string
  isOrderField: boolean
}
interface IForm {
  mapping: Record<string, any>
  file: File | null
  sheetId: number
  option: 'newOrder' | 'overwriteExistingOrder'
}
interface IProps {
  indexedCustomers: Record<number, LinkedCustomer>
  indexedLocations: Record<number, CustomerLocation>
  indexedOrders: Record<string, number>
  indexedArticles: Record<string, MyArticle>
  orders: Array<Order>
}
const props = withDefaults(defineProps<IProps>(), {})

const emit = defineEmits<{
  (e: 'cancel'): void
}>()
const { t } = useI18n()
const userStore = useUserStore()
const notificationStore = useNotificationStore()
const withI18nMessage = createI18nMessage({ t })

const { addNewlyCreatedOrderToOrdersList } = inject('ordersProvide', { addNewlyCreatedOrderToOrdersList: (_order: Order) => null,
})
const initialFormState: IForm = {
  option: 'newOrder',
  mapping: {},
  file: null,
  sheetId: -1,
}

const formModel = reactive<IForm>(cloneDeep(initialFormState))

const excelColumns = ref([] as string[])
const validationModel = reactive<Record<string, any>>({
  noOrderToImport: false,
  validData: [] as any[],
  totalInvalidRows: 0,
  emptyOrdersColumns: [] as any[],
  emptyOrdersMap: {},
  skipInvalidRows: false,
})
const constraints = {
  uniqueConstraints: [{ name: 'constraints1', fields: ['ArticleNumber', 'CRD', 'Size'] }],
}
const currentStep = ref(0)
const loading = ref(false)
const excelWorkbook = ref<ExcelJS.Workbook>()
const steps = {
  uploadFile: 0,
  selectSheet: 1,
  columnsMapping: 2,
  validations: 3,
}
const columnsMappingFields: IExcelMapping[] = [{
  name: 'ArticleNumber',
  column: -1,
  type: 'text',
  label: 'orders.importOrder.mapping.articleNumber',
  required: true,
  autoMap: ['article', 'articlenumber', 'article number', 'colorway number', 'colorwaynumber', 'artnbr', 'style color code'],
  isOrderField: false,
  validator: validateArticle,
} as IExcelMapping, {
  name: 'Size',
  column: -1,
  required: true,
  label: 'orders.importOrder.mapping.size',
  type: 'text',
  autoMap: ['size'],
  isOrderField: false,
  validator: validateSize,
} as IExcelMapping, {
  name: 'CRD',
  column: -1,
  label: 'orders.importOrder.mapping.deliveryDateDescription',
  required: true,
  type: 'text',
  autoMap: ['crd', 'delivery date description'],
  isOrderField: false,
  validator: validateDeliveryDate,
} as IExcelMapping]
const columnsMappingStaticFieldForCustomerRequiredDate: IExcelMapping = {
  name: 'DeliveryDate',
  label: 'Customer Required Date',
  required: true,
  type: 'date',
  autoMap: ['delivery date'],
  column: -1,
  isOrderField: false,
  validator: validateCustomerRequiredDate,
}

const rules = computed(() => {
  const result: Record<string, any> = {
    file: {},
    sheetId: -1,
    mapping: {},
  }
  if (currentStep.value === steps.uploadFile) {
    result.file = { required: withI18nMessage(required) }
  }
  if (currentStep.value === steps.selectSheet) {
    result.sheetId = { required: withI18nMessage(required) }
  }
  if (currentStep.value === steps.columnsMapping) {
    for (const map of columnsMappingFields) {
      if (map.required) {
        result.mapping[map.name] = { required: withI18nMessage(required) }
      }
    }
  }
  return result
})
const v$ = useVuelidate(rules, formModel)

const isCustomerRequiredDateValidatorAvailable = computed(() => Order.isCustomerRequiredDateValidatorAvailable(userStore.activeCatalog!))
const isValidating = ref(false)
const catalogCRDDescriptionMap = computed (() => {
  const crdMap = {}
  if (userStore.activeCatalog) {
    userStore.activeCatalog.CatalogCRDList.forEach((crd) => {
      crdMap[crd.Description] = crd
    })
  }
  return crdMap
})

function getExcelColumns() {
  const cols: string[] = []
  if (excelWorkbook.value && formModel.sheetId && formModel.sheetId !== -1) {
    const worksheet = excelWorkbook.value.getWorksheet(formModel.sheetId)
    if (worksheet) {
      for (let i = 1; i <= worksheet.columnCount; i++) {
        const firstRowCellValue = worksheet.getCell(`${utils.numberToExcelColumnName(i)}1`).value
        if (!firstRowCellValue || firstRowCellValue.toString().trim() === '') {
          const cell = worksheet.getCell(`${utils.numberToExcelColumnName(i)}3`)
          if (cell && cell.value) {
            cols.push(cell.value.toString())
          }
        }
        else {
          const secondRowCellValue = worksheet.getCell(`${utils.numberToExcelColumnName(i)}2`).value
          if (utils.isDefined(firstRowCellValue) && firstRowCellValue.toString().trim() !== '' && firstRowCellValue.toString().toLowerCase().trim() !== 'status'
            && utils.isDefined(secondRowCellValue) && secondRowCellValue.toString().trim() !== '') {
            const customerLocation = { customerNumber: '', locationCode: '', orderReference: '' }
            const customerNumberMatch = firstRowCellValue.toString().match(/\(([^()]*)\)$/)
            customerLocation.customerNumber = utils.isDefined(customerNumberMatch) && utils.isDefined(customerNumberMatch[1]) ? customerNumberMatch[1] : ''
            const locationCodeMatch = secondRowCellValue.toString().match(/\(([^()]*)\)$/)
            customerLocation.locationCode = utils.isDefined(locationCodeMatch) && utils.isDefined(locationCodeMatch[1]) ? locationCodeMatch[1] : ''
            const orderRefCellValue = worksheet.getCell(`${utils.numberToExcelColumnName(i)}3`).value
            if (orderRefCellValue && orderRefCellValue.toString().trim() !== '') {
              customerLocation.orderReference = orderRefCellValue.toString()
              cols.push(`${customerLocation.customerNumber} | ${customerLocation.locationCode} | ${customerLocation.orderReference}`.toString())
            }
            else {
              cols.push(`${customerLocation.customerNumber} | ${customerLocation.locationCode}`.toString())
            }
          }
        }
      }
    }
  }
  return cols
}
function setMappingBasedOnAutoMap(cols: string[]) {
  for (const itm of columnsMappingFields) {
    const mapped = cols.find(c => itm.autoMap && itm.autoMap.includes(c.toLowerCase()))
    formModel.mapping[itm.name] = mapped || null
  }
}
function getOrderFields() {
  const cusLocations: IExcelMapping[] = []
  if (excelWorkbook.value && formModel.sheetId && formModel.sheetId !== -1) {
    const worksheet = excelWorkbook.value.getWorksheet(formModel.sheetId)
    if (worksheet) {
      for (let i = 1; i <= worksheet.columnCount; i++) {
        const firstRowCellValue = worksheet.getCell(`${utils.numberToExcelColumnName(i)}1`).value
        const secondRowCellValue = worksheet.getCell(`${utils.numberToExcelColumnName(i)}2`).value
        if (utils.isDefined(firstRowCellValue) && firstRowCellValue.toString().trim() !== '' && firstRowCellValue.toString().toLowerCase().trim() !== 'status'
          && utils.isDefined(secondRowCellValue) && secondRowCellValue.toString().trim() !== '') {
          const customerLocation = { customerNumber: '', locationCode: '', orderReference: '' }
          const customerNumberMatch = firstRowCellValue.toString().match(/\(([^()]*)\)$/)
          customerLocation.customerNumber = utils.isDefined(customerNumberMatch) && utils.isDefined(customerNumberMatch[1]) ? customerNumberMatch[1] : ''
          const locationCodeMatch = secondRowCellValue.toString().match(/\(([^()]*)\)$/)
          customerLocation.locationCode = utils.isDefined(locationCodeMatch) && utils.isDefined(locationCodeMatch[1]) ? locationCodeMatch[1] : ''
          const orderRefCellValue = worksheet.getCell(`${utils.numberToExcelColumnName(i)}3`).value
          if (orderRefCellValue && orderRefCellValue.toString().trim() !== '') {
            customerLocation.orderReference = orderRefCellValue.toString()
          }
          const field: IExcelMapping = {
            name: i.toString(),
            label: 'customerLocation',
            column: i,
            required: false,
            extras: customerLocation, // will contains locationCode, CustomerNumber and OrderReference (optional)
            type: 'number',
            validationPattern: '^[0-9]\\d*$',
            invalidPatternMessage: 'Value should be positive number',
            isOrderField: true,
          }
          cusLocations.push(field)
        }
      }
    }
  }
  return cusLocations
}
async function changeStep(step) {
  if (step === -1 && currentStep.value !== steps.uploadFile) {
    currentStep.value--
  }
  else if (currentStep.value <= Object.keys(steps).length - 1) {
    if (currentStep.value === steps.selectSheet) {
      if (excelWorkbook.value) {
        excelColumns.value = getExcelColumns()
        setMappingBasedOnAutoMap(excelColumns.value)
      }
    }
    else if (currentStep.value === steps.columnsMapping) {
      isValidating.value = true
      const orderFields: IExcelMapping[] = getOrderFields()
      if (excelWorkbook.value) {
        const worksheet = excelWorkbook.value.getWorksheet(formModel.sheetId)
        if (worksheet) {
          validateData(worksheet, columnsMappingFields, orderFields, excelColumns.value, formModel.mapping, formModel.option, constraints)
          validationModel.emptyOrdersColumns = Object.entries(validationModel.emptyOrdersMap).reduce((acu, cur) => {
            if (cur[1]) {
              acu.push(utils.numberToExcelColumnName(Number(cur[0])))
            }
            return acu
          }, [] as any)
          if (validationModel.emptyOrdersColumns.length === Object.keys(validationModel.emptyOrdersMap).length && worksheet.rowCount > 3) {
            validationModel.noOrderToImport = true
          }
          else {
            validationModel.noOrderToImport = false
          }
          isValidating.value = false
        }
      }
    }
    else if (currentStep.value === steps.validations) {
      const orderFields: IExcelMapping[] = getOrderFields()
      loading.value = true
      const result = await doImportOrders(validationModel.validData, validationModel.emptyOrdersMap, orderFields, formModel.option)
      const emptyOrderColumns = Object.values(validationModel.emptyOrdersMap).filter(value => value).length
      loading.value = false
      emit('cancel')
      if (result.successImport.length !== 0 || result.failedImport.length !== orderFields.length - emptyOrderColumns) {
        notificationStore.addNotification({ message: t('orders.importOrder.validations.importSuccessful'), type: 'Success' })
        if (result.failedImport.length) {
          notificationStore.addNotification({ message: t('orders.importOrder.validations.orderCreatedPartially'), type: 'Alert' })
        }
      }
      else {
        notificationStore.addNotification({ message: t('orders.importOrder.validations.noOrderCreated'), type: 'Warning' })
      }
    }
    if (currentStep.value !== Object.keys(steps).length - 1) {
      currentStep.value++
    }
  }
}
function onFileChange(file: File | null) {
  formModel.sheetId = -1
  formModel.mapping = {}
  excelColumns.value = []
  // if in validation state move it 1 step back and reset validation
  if (currentStep.value === steps.validations) {
    currentStep.value--
    validationModel.validData = []
    validationModel.totalInvalidRows = 0
  }
  if (file) {
    const reader = new FileReader()
    reader.onload = (event: ProgressEvent<FileReader>) => {
      if (event.target && event.target.result) {
        excelWorkbook.value = new ExcelJS.Workbook()
        excelWorkbook.value.xlsx.load(event.target.result as ArrayBuffer)
          .then((workbook) => {
            formModel.sheetId = workbook.worksheets.length > 0 ? workbook.worksheets[0].id : -1
            excelColumns.value = getExcelColumns()
            setMappingBasedOnAutoMap(excelColumns.value)
          })
      }
    }
    reader.readAsBinaryString(file)
  }
}
function onSheetChange() {
  // validate and clear below fields
  if (v$.value.sheetId) {
    v$.value.sheetId.$touch()
  }
  formModel.mapping = {}
  // reset sheetColumns
  excelColumns.value = []
  // if in validation state move it 1 step back and reset validation
  if (currentStep.value === steps.validations) {
    currentStep.value--
    validationModel.validData = []
    validationModel.totalInvalidRows = 0
  }
  excelColumns.value = getExcelColumns()
  setMappingBasedOnAutoMap(excelColumns.value)
}
function onMappingChange(mappingName) {
  if (v$.value[mappingName]) {
    v$.value[mappingName].$touch()
  }
  // if in validation state move it 1 step back and reset validation
  if (currentStep.value === steps.validations) {
    currentStep.value--
    validationModel.validData = []
    validationModel.totalInvalidRows = 0
  }
}
function onOptionChange() {
  // if in validation state move it 1 step back and reset validation
  if (currentStep.value === steps.validations) {
    currentStep.value--
    validationModel.validData = []
    validationModel.totalInvalidRows = 0
  }
}
function validateData(worksheet, columnMappingFields, orderFields, sheetColumns, mappingData, options, importConstraints) {
  // TODO : need to implement VAS, Size curve, msr , moq, multiple-off, stock changes when implemented
  validationModel.validData = []
  validationModel.totalInvalidRows = 0
  const constraints = {}
  const fieldToColumnLabelMap = {}
  let articleNumberColumnIndex = -1
  let CRDColumnIndex = -1
  const invalidRows: number[] = []
  const orderColumnsValidation = {
    customer: [] as string[],
    location: [] as string[],
    order: [] as string[],
  }
  const indexedCustomers = {}
  let mappedColumns: IExcelMapping[] = []
  columnMappingFields.forEach((columnMappingField) => {
    if (mappingData[columnMappingField.name] && sheetColumns.includes(mappingData[columnMappingField.name])) {
      mappedColumns.push(Object.assign({}, columnMappingField, {
        column: sheetColumns.indexOf(mappingData[columnMappingField.name]) + 1,
      }))
    }
  })
  mappedColumns = mappedColumns.concat(orderFields)
  const lastColumnIndex = worksheet.columnCount
  const statusColumnIndex = lastColumnIndex + 2
  // set Status column label
  worksheet.getCell(`${utils.numberToExcelColumnName(statusColumnIndex)}1`).value = t('general.status')
  // Load the unique constrains of the object
  if (
    utils.isDefined(importConstraints)
    && utils.isDefined(importConstraints.uniqueConstraints)
    && Array.isArray(importConstraints.uniqueConstraints)
  ) {
    importConstraints.uniqueConstraints.forEach((uniqueConstraint) => {
      constraints[uniqueConstraint.name] = {}
    })
  }

  // validate customer, location and order reference, init fieldToColumnLabelMap and find articleNumberColumnIndex, and CRDColumnIndex
  for (let i = 0; i < mappedColumns.length; i++) {
    if (mappedColumns[i].name.toString().toLowerCase() === 'articlenumber') {
      articleNumberColumnIndex = mappedColumns[i].column
    }
    if (mappedColumns[i].name.toString().toLowerCase() === 'crd') {
      CRDColumnIndex = mappedColumns[i].column
    }

    if (mappedColumns[i].column !== null && sheetColumns[mappedColumns[i].column - 1]) {
      // map of field name to an object containing label in import sheet as well as its index(column number)
      fieldToColumnLabelMap[mappedColumns[i].name] = { label: sheetColumns[mappedColumns[i].column - 1], column: mappedColumns[i].column }
    }
    // validate sheet format
    if (mappedColumns[i].isOrderField) { // order column
      validationModel.emptyOrdersMap[mappedColumns[i].name] = true
      // validationModel.allocationTracker[mappedColumns[i].field] = {}

      // validate first row of this column if it contains valid customer, second row if contains valid location and third row if it contains valid order reference in case of overwrite
      let message = ''

      const customerRowData = worksheet.getRow(1).values
      const matchCustomerNumber = customerRowData[mappedColumns[i].column].match(/\(([^()]*)\)$/)
      const customerNumber = utils.isDefined(matchCustomerNumber) && utils.isDefined(matchCustomerNumber[1]) ? matchCustomerNumber[1] : ''
      const customer = Object.values(props.indexedCustomers).find(customer => customer.CustomerNumber === customerNumber)
      const customerId = utils.isDefined(customer) ? customer.CustomerId : undefined
      // validate customer
      if (!utils.isDefined(customerId)
        || (utils.isDefined(userStore.currentCustomer) && customerId !== userStore.currentCustomer.CustomerId)
        || (!utils.isDefined(customer) || !customer.Status)
      ) {
        message = t('orders.importOrder.validations.invalidCustomer', { customerNumber, cell: `${utils.numberToExcelColumnName(mappedColumns[i].column)}1` })
        orderColumnsValidation.customer.push(message)
        continue
      }

      // init indexed customers used in import sheet
      if (!indexedCustomers.hasOwnProperty(customerNumber)) {
        indexedCustomers[customerNumber] = customer
      }

      const locationRowData = worksheet.getRow(2).values
      const matchLocationCode = locationRowData[mappedColumns[i].column].match(/\(([^()]*)\)$/)
      const locationCode = utils.isDefined(matchLocationCode) && utils.isDefined(matchLocationCode[1]) ? matchLocationCode[1] : ''
      const locationObject = Object.values(props.indexedLocations).find(location => location.Code === locationCode && location.CustomerId === customerId)
      const locationId = utils.isDefined(locationObject) ? locationObject.Id : undefined
      // validate location
      if (!utils.isDefined(locationId) || !utils.isDefined(locationObject) || !locationObject.Status) {
        message = t('orders.importOrder.validations.invalidLocation', { locationCode, cell: `${utils.numberToExcelColumnName(mappedColumns[i].column)}2`, customerNumber })
        orderColumnsValidation.location.push(message)
        continue
      }

      let orderReference = ''
      // validate order reference
      if (options === 'overwriteExistingOrder') {
        const orderReferenceRowData = worksheet.getRow(3).values
        orderReference = orderReferenceRowData[mappedColumns[i].column]
        if (utils.isDefined(orderReference) && props.indexedOrders[orderReference] && props.orders[props.indexedOrders[orderReference] - 1].CreatedBy !== userStore.userProfile.Id) {
          message = t('orders.importOrder.validations.doesNotHaveOwnership', { orderReference, cell: `${utils.numberToExcelColumnName(mappedColumns[i].column)}3`, customerNumber, locationCode })
          orderColumnsValidation.order.push(message)
          continue
        }
        if (!utils.isDefined(orderReference) || !props.indexedOrders[orderReference] || !props.orders[props.indexedOrders[orderReference] - 1].Status || props.orders[props.indexedOrders[orderReference] - 1].CustomerId !== customerId || props.orders[props.indexedOrders[orderReference] - 1].LocationId !== locationId) {
          message = t('orders.importOrder.validations.invalidOrderReference', { orderReference, cell: `${utils.numberToExcelColumnName(mappedColumns[i].column)}3`, customerNumber, locationCode })
          orderColumnsValidation.order.push(message)
          continue
        }
        else if (props.orders[props.indexedOrders[orderReference] - 1].OrderProcessStatusId !== null && ordersConstants.noneEditableProcessStatuses.includes(props.orders[props.indexedOrders[orderReference] - 1].OrderProcessStatusId!)) {
          message = t('orders.importOrder.validations.noneEditableOrder', { orderReference, cell: `${utils.numberToExcelColumnName(mappedColumns[i].column)}3` })
          orderColumnsValidation.order.push(message)
        }
      }
    }
  }
  if (orderColumnsValidation.location.length || orderColumnsValidation.customer.length) {
    if (orderColumnsValidation.location.length) {
      validationModel.totalInvalidRows++
    }
    if (orderColumnsValidation.customer.length) {
      validationModel.totalInvalidRows++
    }
    worksheet.getCell(`${utils.numberToExcelColumnName(statusColumnIndex)}2`).value = `${orderColumnsValidation.customer.join(', ')}, ${orderColumnsValidation.location.join(', ')}`
  }

  if (orderColumnsValidation.order.length) {
    validationModel.totalInvalidRows++
    worksheet.getCell(`${utils.numberToExcelColumnName(statusColumnIndex)}3`).value = orderColumnsValidation.order.join(', ')
  }

  let columnNameOfThirdRow = ''
  let previousRowArticleCrd = ''
  let deliveryDate = ''
  worksheet.eachRow((row, rowNumber) => {
    const rowData: Record<any, any> = {}
    const currentRowStatus: string[] = []
    let isValidRow = true
    let cellValue

    if (rowNumber === 3) {
      columnNameOfThirdRow = worksheet.getRow(3).getCell(1).value.toString().toLowerCase()
    }
    if (rowNumber > 3) {
      const currentRowArticleCrd = (row.values[articleNumberColumnIndex] ? row.values[articleNumberColumnIndex].toString().trim() : '') + (row.values[CRDColumnIndex] ? row.values[CRDColumnIndex].toString().trim() : '')
      const dynamicColumns = mappedColumns.filter(column => column.isOrderField)
      let considerRow = false
      for (let j = 0; j < dynamicColumns.length; j++) {
        if (row.values[dynamicColumns[j].column] && row.values[dynamicColumns[j].column].toString().trim() !== '') {
          considerRow = true
          break
        }
      }
      if (considerRow) {
        for (let i = 0; i < mappedColumns.length; i++) {
          let customerId: number | undefined
          if (mappedColumns[i].isOrderField && utils.isDefined(mappedColumns[i].extras)) {
            const customer = Object.values(props.indexedCustomers).find(customer => mappedColumns[i].extras!.customerNumber === customer.CustomerNumber)
            customerId = utils.isDefined(customer) ? customer.CustomerId : undefined
          }

          cellValue = undefined
          // if field is mapped to an excel column
          if (utils.isDefined(mappedColumns[i].column) && Number.isInteger(mappedColumns[i].column)) {
            // validate required field
            if (
              mappedColumns[i].required
              && (!utils.isDefined(row.values[mappedColumns[i].column])
              || row.values[mappedColumns[i].column].toString().trim() === '')
            ) {
              currentRowStatus.push(`${fieldToColumnLabelMap[mappedColumns[i].name].label
                    } at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].name].column)}${rowNumber}) is empty`)
              isValidRow = false
              continue
            }
            else if (
              utils.isDefined(mappedColumns[i].validationPattern)
              && mappedColumns[i].validationPattern!.length
              && utils.isDefined(row.values[mappedColumns[i].column]) && row.values[mappedColumns[i].column].toString().trim() !== ''// since field value is not mandatory do not check cell if no value is provided
              && !new RegExp(mappedColumns[i].validationPattern!).test(row.values[mappedColumns[i].column])
            ) {
              currentRowStatus.push(
                `${fieldToColumnLabelMap[mappedColumns[i].name].label
                       } at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].name].column)}${rowNumber}) does not match the required format ${
                       mappedColumns[i].invalidPatternMessage}` || '',
              )
              isValidRow = false
              continue
            }
            else if (
              utils.isDefined(mappedColumns[i])
              && utils.isDefined(mappedColumns[i].validator)
              && isFunction(mappedColumns[i].validator)
            ) {
              if (currentRowArticleCrd !== previousRowArticleCrd && mappedColumns[i].name === 'DeliveryDate') {
                deliveryDate = row.values[mappedColumns[i].column]
              }
              // pass the dependent name to the validator as customerRequiredDate is dependent on DeliveryDate
              const dependentColumn = mappedColumns[i].name === 'DeliveryDate' ? row.values[CRDColumnIndex] : null
              const error = mappedColumns[i].validator!(
                row.values[mappedColumns[i].column],
                      `${fieldToColumnLabelMap[mappedColumns[i].name].label} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].name].column)}${rowNumber})`,
                      row.values[articleNumberColumnIndex], /* this field is optional, use for validation entities that depend on article and will be undefined for exports that does not have article in column mapping */
                      dependentColumn,
                      deliveryDate,
                      currentRowArticleCrd === previousRowArticleCrd,
              )
              if (utils.isDefined(error) && error.toString().trim().length) {
                currentRowStatus.push(error)
                isValidRow = false
                continue
              }
            }
            switch (mappedColumns[i].type) {
              case 'int':
                if (
                  utils.isDefined(row.values[mappedColumns[i].column]) && row.values[mappedColumns[i].column].toString().trim() !== '' // do not check for empty cells
                  && !Number.isInteger(Number.parseInt(row.values[mappedColumns[i].column]))
                ) {
                  currentRowStatus.push(
                    `${fieldToColumnLabelMap[mappedColumns[i].name].label} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].name].column)}${rowNumber}) is not a valid integer`,
                  )
                  isValidRow = false
                  continue
                }
                else {
                  // since its optional (since control reached to this point) only convert cell value if it is defined
                  cellValue = utils.isDefined(row.values[mappedColumns[i].column]) && row.values[mappedColumns[i].column].toString().trim() !== ''
                    ? Number.parseInt(row.values[mappedColumns[i].column])
                    : row.values[mappedColumns[i].column]
                }
                break
              case 'number':
                if (
                  utils.isDefined(row.values[mappedColumns[i].column]) && row.values[mappedColumns[i].column].toString().trim() !== '' // do not check for empty cells
                  && Number.isNaN(Number.parseFloat(row.values[mappedColumns[i].column]))
                ) {
                  currentRowStatus.push(
                    `${fieldToColumnLabelMap[mappedColumns[i].name].label} at cell(${utils.numberToExcelColumnName(fieldToColumnLabelMap[mappedColumns[i].name].column)}${rowNumber}) is not a valid number`,
                  )
                  isValidRow = false
                  continue
                }
                else {
                  // since its optional (since control reached to this point) only convert cell value if it is defined
                  cellValue = utils.isDefined(row.values[mappedColumns[i].column]) && row.values[mappedColumns[i].column].toString().trim() !== ''
                    ? Number.parseFloat(row.values[mappedColumns[i].column])
                    : row.values[mappedColumns[i].column]
                }
                break
              default:
                cellValue = row.values[mappedColumns[i].column]
                break
            }
            if (customerId && mappedColumns[i].isOrderField) {
              // validate segmentations in case active customer is full range and only for for rows that having valid quantity (ignore rows that does not have quantities since same row can have quantity for multiple customers)
              // since we check for article segmentation if the row which is invalid but we need to skip the rows with invalid article
              const article = Object.values(props.indexedArticles).find(article => article.ArticleNumber === row.values[articleNumberColumnIndex])
              if (!utils.isDefined(userStore.currentCustomer) && utils.isDefined((article))) {
                // article is already being validated before this point and invalid articles wont reach till this check
                // customer has been validated
                if (props.indexedCustomers[customerId] && utils.isDefined(props.indexedCustomers[customerId].Segmentations) && props.indexedCustomers[customerId].Segmentations!.length) {
                  const isArticleSegmented = appConfig.DB!.isArticleSegmented(article._Segmentations, props.indexedCustomers[customerId].Segmentations!, userStore.activeCatalog!._IndexedCatalogSegmentation)
                  if (!isArticleSegmented) {
                    const message = `${t('orders.importOrder.validations.articleNotSegmented', { articleNumber: row.values[articleNumberColumnIndex], customerNumber: props.indexedCustomers[customerId].CustomerNumber })} and quantity needs to be removed`
                    currentRowStatus.push(message)
                    isValidRow = false
                    continue
                  }
                }
                else {
                  const message = `${t('orders.importOrder.validations.articleNotSegmented', { articleNumber: row.values[articleNumberColumnIndex], customerNumber: props.indexedCustomers[customerId].CustomerNumber })} and quantity needs to be removed`
                  currentRowStatus.push(message)
                  isValidRow = false
                  continue
                }
              }
            }
          }
          if (mappedColumns[i].isOrderField && utils.isDefined(cellValue) && cellValue !== 0 && cellValue.toString().trim() !== '') {
            if (validationModel.emptyOrdersMap[mappedColumns[i].name]) {
              validationModel.emptyOrdersMap[mappedColumns[i].name] = false
            }
          }
          rowData[mappedColumns[i].name] = cellValue
        }
      }
      // validate constraints
      /* only validate for valid rows since if either of article, DD or Size became invalid rowData wont be having any entry for them hence the value will be undefined and it will show error if 2 rows with invalid article or DD or size exist
              * invalid validation for article, DD and Size is already applied and adding isValidRow is optional to prevent confusing user in case of for eg if there are 2 rows with inactive size, those rows will be invalidated
              * saying Invalid row: Size contains invalid value but second row will also contain message for not having unique constraints since value for size for both rows will be undefined
              */
      if (considerRow && isValidRow && utils.isDefined(importConstraints) && utils.isDefined(importConstraints.uniqueConstraints)) {
        importConstraints.uniqueConstraints.forEach((constraint) => {
          const constraintValues: string[] = []
          const columnLabels: string[] = []
          for (let i = 0; i < constraint.fields.length; i++) {
            constraintValues.push(rowData[constraint.fields[i]])
            columnLabels.push(fieldToColumnLabelMap[constraint.fields[i]].label)
          }
          const constraintValueStr = constraintValues.join('//')
          if (!utils.isDefined(constraints[constraint.name][constraintValueStr])) {
            constraints[constraint.name][constraintValueStr] = 1
          }
          else {
            currentRowStatus.push(
                    `This row contains a duplicate record (must be unique on: ${columnLabels.join(', ')})`,
            )
            isValidRow = false
          }
        })
      }

      if (considerRow) {
        if (isValidRow) {
          validationModel.validData.push(rowData)
        }
        else {
          // condition to check if excel with article Images and row is empty - then ignore that row
          if ((columnNameOfThirdRow === 'images' && row.cellCount !== 1) || columnNameOfThirdRow !== 'images') {
            validationModel.totalInvalidRows++
            invalidRows.push(rowNumber)
            worksheet.getCell(`${utils.numberToExcelColumnName(statusColumnIndex)}${rowNumber}`).value
                    = `Invalid row: ${currentRowStatus.join(', ')}`
          }
        }
      }
      previousRowArticleCrd = currentRowArticleCrd
    }
  })
}
function validateArticle(articleNumber, columnLabel) {
  // TODO:fetch all in one go read whole excel file
  const article = Object.values(props.indexedArticles).find(article => article.ArticleNumber === articleNumber)
  const customerSegmentations: Segmentation[] = userStore.currentCustomerSegmentations && userStore.currentCustomerSegmentations.Segmentations && userStore.currentCustomerSegmentations.Segmentations.length ? userStore.currentCustomerSegmentations.Segmentations : []
  // Check for if article is request article as not allow to create order for these articles, check for article availability only if customer is not full range, for full range customer it is going to be validated per customer
  if (!utils.isDefined(article) || article.Status !== 1 || article._IsRequestArticle || (utils.isDefined(userStore.currentCustomer) && (isEmpty(article._Segmentations) || !customerSegmentations.length || (!isEmpty(article._Segmentations) && customerSegmentations.length && !appConfig.DB!.isArticleSegmented(article._Segmentations, customerSegmentations, userStore.activeCatalog!._IndexedCatalogSegmentation))))) {
    return `${columnLabel} is invalid, it may be inactive, non-segmented, or a request article.`
  }
}
function validateDeliveryDate(CRDDescription, columnLabel, articleNumber) {
  const article = Object.values(props.indexedArticles).find(article => article.ArticleNumber === articleNumber)
  const catalogCRD = catalogCRDDescriptionMap.value[CRDDescription]
  if (!utils.isDefined(catalogCRD) || !catalogCRD.Status || !catalogCRD.Availability) {
    return `${columnLabel} contains a value which is not available to catalog`
  }
  if (utils.isDefined(article)) {
    const articleCRD = article._DeliveryDates.find(deliveryDate => deliveryDate.CrdId === catalogCRD.Id)
    if (!articleCRD || !articleCRD.Status) {
      return `${columnLabel} contains a value which is not available for an article ${articleNumber}`
    }
    // check with article crd availability criteria if it is allowed
    const nowInMilliseconds = Date.now()
    if ((articleCRD.AvailabilityFrom != null && articleCRD.AvailabilityFrom.toString().trim() !== '' && nowInMilliseconds < new Date(articleCRD.AvailabilityFrom).getTime())
      || (articleCRD.AvailabilityTo != null && articleCRD.AvailabilityTo.toString().trim() !== '' && nowInMilliseconds > new Date(articleCRD.AvailabilityTo).getTime())) {
      return `${columnLabel} contains a value which is not available for article ${articleNumber}`
    }
  }
  // }
}
function validateCustomerRequiredDate(customerRequiredDate, columnLabel, articleNumber, CRDDescription, deliveryDate, isSameArticleCrd) {
  const article = Object.values(props.indexedArticles).find(article => article.ArticleNumber === articleNumber)
  const catalogCRD = catalogCRDDescriptionMap.value[CRDDescription]
  if (utils.isDefined(catalogCRD) && catalogCRD.Status && utils.isDefined(article) && isCustomerRequiredDateValidatorAvailable.value) {
    const articleCRD = article._DeliveryDates.find(deliveryDate => deliveryDate.CrdId === catalogCRD.Id)
    if (articleCRD && articleCRD.Status) {
      if (utils.isDefined(customerRequiredDate) && utils.validateDate(new Date(customerRequiredDate))) {
        const currentCustomerRequiredDateObject = new Date(customerRequiredDate)
        currentCustomerRequiredDateObject.setHours(0, 0, 0)
        const currentCustomerRequiredDate = currentCustomerRequiredDateObject.getTime()

        if (isSameArticleCrd) {
          // check if its same as DD or not
          const deliveryDateObject = new Date(deliveryDate)
          deliveryDateObject.setHours(0, 0, 0)
          const deliveryDateTime = deliveryDateObject.getTime()
          if (currentCustomerRequiredDate !== deliveryDateTime) {
            return `${columnLabel} should be same as ${deliveryDate}`
          }
          else {
            let customerRequiredDateValidation = {} as { relation: string, numberOfDays: number }
            if (userStore.activeCatalog!.CustomerRequiredDateValidation != null) { // CustomerRequiredDateValidation will not be null in this function
              try {
                customerRequiredDateValidation = JSON.parse(userStore.activeCatalog!.CustomerRequiredDateValidation)
              }
              catch (error) {
                console.warn(error)
              }
            }
            const catalogCustomerRequiredDate = new Date(catalogCRD.CustomerRequiredDate)
            catalogCustomerRequiredDate.setHours(0, 0, 0)

            if (utils.validateCondition(currentCustomerRequiredDate, catalogCustomerRequiredDate.getTime(), customerRequiredDateValidation.relation)) {
              if (customerRequiredDateValidation.hasOwnProperty('numberOfDays') && customerRequiredDateValidation.numberOfDays > 0) {
                const endTime = catalogCustomerRequiredDate.getTime() + customerRequiredDateValidation.numberOfDays * 24 * 60 * 60 * 1000
                const startTime = catalogCustomerRequiredDate.getTime() - customerRequiredDateValidation.numberOfDays * 24 * 60 * 60 * 1000
                if ((currentCustomerRequiredDate < endTime && currentCustomerRequiredDate > startTime) || currentCustomerRequiredDate === endTime || currentCustomerRequiredDate === startTime) {
                  return ''
                }
                else {
                  return `${columnLabel} is not valid Customer Required Date`
                }
              }
            }
            else {
              return `${columnLabel} is not valid Customer Required Date`
            }
          }
        }
        else {
          let customerRequiredDateValidation = {} as { relation: string, numberOfDays: number }
          if (userStore.activeCatalog!.CustomerRequiredDateValidation != null) { // CustomerRequiredDateValidation will not be null in this function
            try {
              customerRequiredDateValidation = JSON.parse(userStore.activeCatalog!.CustomerRequiredDateValidation)
            }
            catch (error) {
              console.warn(error)
            }
          }
          const catalogCustomerRequiredDate = new Date(catalogCRD.CustomerRequiredDate)
          catalogCustomerRequiredDate.setHours(0, 0, 0)
          if (utils.validateCondition(currentCustomerRequiredDate, catalogCustomerRequiredDate.getTime(), customerRequiredDateValidation.relation)) {
            if (customerRequiredDateValidation.hasOwnProperty('numberOfDays') && customerRequiredDateValidation.numberOfDays > 0) {
              const endTime = catalogCustomerRequiredDate.getTime() + customerRequiredDateValidation.numberOfDays * 24 * 60 * 60 * 1000
              const startTime = catalogCustomerRequiredDate.getTime() - customerRequiredDateValidation.numberOfDays * 24 * 60 * 60 * 1000
              if ((currentCustomerRequiredDate < endTime && currentCustomerRequiredDate > startTime) || currentCustomerRequiredDate === endTime || currentCustomerRequiredDate === startTime) {
                return ''
              }
              else {
                return `${columnLabel} is not valid Customer Required Date`
              }
            }
          }
          else {
            return `${columnLabel} is not valid Customer Required Date`
          }
        }
      }
      else {
        if (!isSameArticleCrd) {
          return `${columnLabel} should contain a valid Customer Required Date`
        }
      }
    }
  }
}
async function getImportDataResult() {
  if (excelWorkbook.value) {
    validationModel.isValidating = true
    const buffer = await excelWorkbook.value.xlsx.writeBuffer()
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.setAttribute('download', 'result.xlsx')
    link.click()
  }
}
function validateSize(sizeName, columnLabel, articleNumber) {
  const article = Object.values(props.indexedArticles).find(article => article.ArticleNumber === articleNumber)
  // since its a custom validator and we know we are already validating article no need for commented code, continue validation if article is valid
  // if(!utils.isDefined(article) || !article['Status'] || !article['_isSegmented']) {
  //   return `invalid article number ${articleNumber}`
  // } else {
  // can create map articleNumber|sizeName to find required size
  if (utils.isDefined(article)) {
    const size = Object.values(article._Sizes).find(articleSize => articleSize.SizeName === sizeName)
    if (!utils.isDefined(size) || !size.Status) {
      return `${columnLabel} contains invalid value`
    }
  }
  // }
}
async function doImportOrders(data, emptyOrdersMap, orderFields, importOption) {
  const failedImport: Order[] = []
  const successImport: Order[] = []
  if (data.length) {
    const indexedOrderPerColumn = {} // map of order column number to order
    orderFields.forEach((orderField) => {
      // sheet will be validated for location and customer so they should exist in catalogUserData
      const customer = Object.values(props.indexedCustomers).find(customer => customer.CustomerNumber === orderField.extras.customerNumber)
      const location = Object.values(props.indexedLocations).find(location => location.Code === orderField.extras.locationCode && location.CustomerNumber === orderField.extras.customerNumber)
      if (!emptyOrdersMap[orderField.field]) { // only if the order have at least one valid quantity
        if (importOption === 'newOrder') {
          const formData = {
            locationId: location!.Id,
            locationCode: location!.Code,
            locationName: location!.Name,
            customerReference: '',
            alias: '',
          }
          if (customer) {
            indexedOrderPerColumn[orderField.name] = new Order(customer, userStore.activeCatalog!, userStore.userProfile, null, formData)
          }
        }
        else if (importOption === 'overwriteExistingOrder') {
          const orderReference = orderField.extras.orderReference
          const existingOrder = props.orders[props.indexedOrders[orderReference] - 1]
          if (existingOrder) {
            existingOrder.resetOrderlines()
            indexedOrderPerColumn[orderField.name] = existingOrder
          }
          else {
            console.log('unable to load order!', orderReference)
            indexedOrderPerColumn[orderField.field] = null
          }
        }
      }
    })
    data.forEach((rowData) => {
    // NOTE: since sheet being validated for valid article, crd, size and quantity validation while assigning is skipped (can be validated at each stage)
      const article = Object.values(props.indexedArticles).find(article => article.ArticleNumber === rowData.ArticleNumber)
      const indexedSizeByName: Record<string, any> = Object.values(article!._Sizes).reduce((acu, size) => (acu[size.SizeName] = size) && acu, {})
      const sizeId = indexedSizeByName[rowData.Size].Id

      for (const columnNumber in indexedOrderPerColumn) {
        // if rowData contains quantity for activeMappedLocationField
        const order = indexedOrderPerColumn[columnNumber]
        // create orderline only for the location which has quantity specified
        if (utils.isDefined(order)) {
          order.fillOrder([article])
          if (utils.isDefined(rowData[columnNumber]) && rowData[columnNumber].toString().trim() !== '') {
            if (isCustomerRequiredDateValidatorAvailable.value) {
              order.IndexedOrderlines[article!.Id].setDeliveryDate(catalogCRDDescriptionMap[rowData.CRD].Id, new Date(rowData.DeliveryDate))
            }
            order.IndexedOrderlines[article!.Id].setQuantity(order, catalogCRDDescriptionMap.value[rowData.CRD].Id, [{ sizeId, quantity: rowData[columnNumber] }])
            // order.orderLineRecentUpdateDate = order.updatedDate
          }
        }
      }
    })

    for (const key in indexedOrderPerColumn) {
      const order = indexedOrderPerColumn[key]
      try {
        await order.saveDraft(null)
        await addNewlyCreatedOrderToOrdersList(order)
        successImport.push(order)
      }
      catch (error) {
        console.error(error)
        failedImport.push(order)
      }
    }
  }
  return { failedImport, successImport }
}
onMounted(() => {
  // need to implement the changes in below code when implement stock or size curve functionality
  if (isCustomerRequiredDateValidatorAvailable.value) {
    columnsMappingFields.push(columnsMappingStaticFieldForCustomerRequiredDate)
  }
})
</script>
