<template>
  <div v-if="localShow" class="flex p-4 mb-1 rounded-lg" :class="bgClasses" role="alert">
    <svg v-if="showIcon" class="flex-shrink-0 w-5 h-5" :class="textClasses" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
    </svg>
    <div class="ml-3 text-sm font-medium break-words w-full pr-6 overflow-y-auto max-h-40" :class="textClasses">
      <slot>
        {{ text }}
      </slot>
    </div>
    <button v-if="dismissible" type="button" class="ml-auto -mx-1.5 -my-1.5 rounded-lg focus:ring-2 p-1.5 inline-flex h-8 w-8" :class="dismissibleClasses" aria-label="Close" @click="hide">
      <span class="sr-only">Close</span>
      <svg class="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd" />
      </svg>
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue'

interface IProps {
  type?: 'info' | 'error' | 'success' | 'warning' | 'primary'
  show?: boolean
  text?: string
  showIcon?: boolean
  dismissible?: boolean
  timeout?: number
}
const props = withDefaults(defineProps<IProps>(), { type: 'primary', show: false, text: '', showIcon: true, dismissible: false, timeout: undefined })

const emit = defineEmits<{
  (e: 'update:show', val: boolean): void
  (e: 'shown'): void
  (e: 'hidden'): void
}>()

const localShow = ref(props.show)

const bgClasses = computed(() => {
  switch (props.type) {
    case 'info':
      return 'bg-gray-100 dark:bg-gray-700'
    case 'error':
      return 'bg-red-100 dark:bg-red-200'
    case 'success':
      return 'bg-green-100 dark:bg-green-200'
    case 'warning':
      return 'bg-yellow-100 dark:bg-yellow-200'
    case 'primary':
      return 'bg-blue-100 dark:bg-blue-200'
    default:
      return ''
  }
})

const textClasses = computed(() => {
  switch (props.type) {
    case 'info':
      return 'text-gray-700 dark:text-gray-300'
    case 'error':
      return 'text-red-700 dark:text-red-800'
    case 'success':
      return 'text-green-700 dark:text-green-800'
    case 'warning':
      return 'text-yellow-700 dark:text-yellow-800'
    case 'primary':
      return 'text-blue-700 dark:text-blue-800'
    default:
      return ''
  }
})

const dismissibleClasses = computed(() => {
  switch (props.type) {
    case 'info':
      return 'bg-gray-100 text-gray-500 focus:ring-gray-400 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-300 dark:hover:bg-gray-800 dark:hover:text-white'
    case 'error':
      return 'bg-red-100 text-red-500 focus:ring-red-400 hover:bg-red-200 dark:bg-red-200 dark:text-red-600 dark:hover:bg-red-300'
    case 'success':
      return 'bg-green-100 text-green-500 focus:ring-green-400 hover:bg-green-200 dark:bg-green-200 dark:text-green-600 dark:hover:bg-green-300'
    case 'warning':
      return 'bg-yellow-100 text-yellow-500 focus:ring-yellow-400 hover:bg-yellow-200 dark:bg-yellow-200 dark:text-yellow-600 dark:hover:bg-yellow-300'
    case 'primary':
      return 'bg-blue-100 text-blue-500 focus:ring-blue-400 hover:bg-blue-200 dark:bg-blue-200 dark:text-blue-600 dark:hover:bg-blue-300'
    default:
      return ''
  }
})

function initTimeout() {
  setTimeout(() => {
    hide()
  }, props.timeout)
}

function hide() {
  localShow.value = false
}

watch(() => props.show, (show: boolean) => localShow.value = show)
watch(() => localShow.value, (localShow: boolean) => {
  emit('update:show', localShow)
  if (localShow) {
    emit('shown')
    if (props.timeout) {
      initTimeout()
    }
  }
  else {
    emit('hidden')
  }
})

onMounted(() => {
  if (localShow.value && props.timeout) {
    initTimeout()
  }
})
</script>
