<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 152 220">
    <g fill="none" fill-rule="evenodd">
      <path d="M-2321-1223h4000V977h-4000z" />
      <path d="M77 166c-30.099 0-51.57-15.667-51.82-25.167-.631-24.013-.372-36.544 10.58-50 7.53-9.251 18.695-13.736 29.863-15.457C65.847 60 65.356 51 69.18 51c1.152 0 8.053-5.078 11.333 0 4.295 6.648 5.012 23.883 5.012 23.883S109.057 79.97 116.76 89.5c10.739 13.288 10.17 22.457 10.17 47.584C126.93 151.833 107.1 166 77 166z" fill="#E0F2FF" />
      <path d="M106.93 122.084c-.212 30.099.367 54.733-29.732 54.733-30.1 0-30.271-24.41-29.018-54.484-9.25-2-16-3.25-22.721-11.108 2.113-7.86 5.306-14.68 10.239-20.74 7.107-8.733 17.454-13.219 27.984-15.141 1.998 5.24 7.824 10.74 12.248 10.74 3.53 0 10-5 12.203-11.24 10.823 1.467 21.446 5.5 28.565 14.306 5.59 6.917 9.202 14.819 11.355 24.349-9.373 8.835-19.123 8.335-21.123 8.585" fill="#1F71AA" />
      <path d="M111.285 162H38.952l-2.873-47.88a2 2 0 0 1 1.997-2.12h75.999a2 2 0 0 1 1.99 2.198L111.285 162z" fill="#FFF" />
      <path d="M102 156c38-28 60-7 43 16-9.245 12.507-39.291 11.059-48 5-7.667-5.333-36.362 4.952-47.82 7.666-25.334 6-52.937-3.988-49.18-28.666 2.487-16.334 24-16 43-3 43.113 29.498 21 31 59 3z" fill="#1F71AA" />
      <path d="M22.258 188.276c8.66 4.074 26.788 9.337 42.893 13.615a1163.427 1163.427 0 0 0 30.773 7.706s5.095-8.071 6.167-11.271c-4.394-3.15-13.79-10.03-24.205-17.442-13.408-9.54-28.508-19.96-36.815-24.431-14.76-7.947-30.844-11.237-36.536-.665-5.691 10.57 2.555 25.35 17.723 32.488" fill="#CCE9FF" />
      <path d="M90.689 204.584c.106 3.589 10.842 11.35 24.082 13.624 7.83 1.346 14.539-3.695 14.79-7.275.352-5.013-6.956-10.178-13.2-12.114-5.89-1.825-11.23-2.53-16.228-1.851-5.513.748-9.5 5.734-9.444 7.617" fill="#1F71AA" />
      <path d="M65.15 201.89a1163.427 1163.427 0 0 0 30.774 7.707s2.435-5.314 3.506-8.514c-4.394-3.15-5.334.051-17.43-4.083-19.75-6.75-4.822-1.823-21-7-25-8-51.258-8.276-32 0 8.661 4.074 20.046 7.613 36.15 11.89z" fill="#A4CEEE" />
      <path d="M65.15 201.891a1163.427 1163.427 0 0 0 30.773 7.706c3.96 1.383 7.947.756 8.727-.926 2.036-4.395-1.086-7.596-4.487-10.705-4.395-3.149-11.862-9.67-22.278-17.082-3.312 8.84-8.229 17.321-12.735 21.007" fill="#237EBE" />
      <path d="M62.23 204.495c0 3.59-10.5 11.667-23.667 14.333-7.788 1.578-14.642-3.261-15-6.833-.5-5 6.652-10.38 12.834-12.5 5.833-2 11.15-2.862 16.166-2.333 5.534.584 9.667 5.45 9.667 7.333" fill="#1F71AA" />
      <path d="M130.146 186.162c-8.536 4.33-26.499 10.129-42.469 14.883-16.304 4.852-30.53 8.617-30.53 8.617s-3.55-7.162-4.717-10.33c3.5-3 11.702-11.19 21.893-18.906 13.12-9.934 27.903-20.797 36.073-25.514 14.518-8.382 30.497-12.147 36.5-1.75s-1.8 25.416-16.75 33" fill="#E0F2FF" />
      <path d="M87.677 201.044a1163.006 1163.006 0 0 1-30.53 8.617c-3.917 1.5-7.922.99-8.75-.667-2.167-4.333.859-7.626 4.166-10.833 4.3-3.279 11.569-10.019 21.76-17.736 3.574 8.736 8.74 17.07 13.354 20.62" fill="#2384C7" />
      <path d="M102.18 23.666C96.857 30.024 92.513 27 89.18 25c-3.233-1.94-2.465-.57-.667-8.666 1.334-6 12.834-8.668 17.334-16.334 2.666 2.5 2.333 16.5-3.667 23.666" fill="#2E7087" />
      <path d="M90.18 51c-3.708 7.417-23.931 8.401-29.333 3-2.667-2.666-5.667-6.708-5.667-15 0-8.293 5.479-15.078 12.667-17.666 4.166-1.5 20.157-5.883 23.333 1 4 8.666 3.5 19.665-1 28.665" fill="#176093" />
      <path d="M102.18 23.666C96.857 30.024 92.513 27 89.18 25c-3.233-1.94-2.465-.57-.667-8.666 1.334-6 12.834-8.668 17.334-16.334 2.666 2.5 2.333 16.5-3.667 23.666" fill="#176093" />
      <path d="M82.513 65c-6.667 19.666-17.333 7.334-19-3.666-1.765-11.65 6.635-12.894 13.833-10.335 7.5 2.667 9.057 2.526 5.167 14" fill="#B1D3F2" />
      <path d="M87.18 50c0 11.782-6.194 21.334-13.833 21.334-7.64 0-13.834-9.553-13.834-21.335 0-11.78 6.193-21.333 13.834-21.333 7.64 0 13.833 9.552 13.833 21.333" fill="#E0F2FF" />
      <path d="M89.18 38.084c-4.651 6.864-18.25 5-27 2.5-2.902-.83-4.75-1.75-7-1.585.389-8.283 5.479-15.078 12.667-17.666 4.166-1.5 18.583-6.25 20.833-.25 3.352 8.938 2.25 14.416.5 17" fill="#2384C7" />
      <path d="M81.575 139.833c0 3.405-2.462 6.167-5.5 6.167s-5.5-2.762-5.5-6.167 2.462-6.166 5.5-6.166 5.5 2.76 5.5 6.166" fill="#B1D3F2" />
    </g>
  </svg>
</template>
