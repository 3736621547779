import type { Static } from 'runtypes'
import { Array, Boolean, Dictionary, Literal, Nullish, Number, Optional, Record, String, Union } from 'runtypes'
import type CatalogConfiguration from './catalogConfiguration'
import type { UserRoleModel } from '@/api/t1/model/userModel'

const configSchemas = {
  GroupByAttributes: Dictionary(Record({
    groupBySortDirection: Optional(String),
    customSortValueList: Optional(Array(String)),
  })),

  ArticleThumbnailFlags: Array(Record({
    name: String,
    color: String,
    initials: String,
    value: String,
  })),

  ArticleDetailsFlags: Array(Record({
    name: String,
    color: String,
    initials: String,
    value: String,
  })),

  ArticlesSortProperties: Array(Record({
    articleProperty: String,
    sortDirection: Optional(String),
    customSortValueList: Optional(Array(Union(String, Number))),
  })),

  CarryoverArticleThumbnailDetailsWeb: Record({
    article: Record({
      large: Array(Record({ w: Number, h: Number, value: Optional(String), label: Optional(String), style: Optional(Array(String)) })),
    }),
  }),

  ThumbnailDetails: Record({
    article: Record({
      large: Array(Record({ w: Number, h: Number, value: Optional(String), label: Optional(String), style: Optional(Array(String)) })),
      medium: Array(Record({ w: Number, h: Number, value: Optional(String), label: Optional(String), style: Optional(Array(String)) })),
      small: Array(Record({ w: Number, h: Number, value: Optional(String), label: Optional(String), style: Optional(Array(String)) })),
    }),
    model: Record({
      large: Array(Record({ w: Number, h: Number, value: Optional(String), label: Optional(String), style: Optional(Array(String)) })),
      medium: Array(Record({ w: Number, h: Number, value: Optional(String), label: Optional(String), style: Optional(Array(String)) })),
      small: Array(Record({ w: Number, h: Number, value: Optional(String), label: Optional(String), style: Optional(Array(String)) })),
    }),
  }),

  FilterableAttributes: Array(String),
  HideCatalogTreeByDefault: Boolean,
  ShowPriceThousandsSeparated: Boolean,
  ArticleDetailsTemplate2: Array(Record({ width: Number, value: String, label: String, style: Optional(Array(String)) })),
  ArticleThumbnailShowFirstDeliveryDate: Boolean,
  CarryoverFormStyleDetailsTemplateWeb: Array(Record({ width: Number, value: String, label: String, style: Optional(Array(String)) })),
  PriceGroupsLabel: Record({
    wholesalePrice: String,
    retailPrice: String,
    outletPrice: String,
    orderPrice: String,
  }),

  DisableModules: Array(String),

  AllowArticleNameForUpdate: Boolean,

  // will allow to filter articles on delivery date with format as mmm-yyyy
  FilterDeliveryDateByMMMYYYYFormat: Boolean,

  // Specifies weather to allow filter articles by segmentation for full range customer
  AllowFullRangeCustomerToFilterBySegmentation: Boolean,

  // it if true it will enable the segmentation filter by customer
  AllowCustomerToFilterBySegmentation: Boolean,

  AllowDownloadImages: Boolean,
  DownloadArticleImageFormat: String,

  // specifies the file name expression when save article images
  SaveImagesFileName: String,

  WhiteboardImagePlaceholderOptions: Record({
    key: String,
    sortOrder: Number,
  }),
  // pre select the columns when export order form
  PreSelectedOrderFormColumns: Array(String),

  ArticleForm: Record({
    newModel: Optional(Record({
      modelForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      articleForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
    })),
    copyModel: Optional(Record({
      modelForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      articleForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
    })),
    childModel: Optional(Record({
      modelForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      articleForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
    })),
    carryoverModel: Optional(Record({
      modelForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      articleForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
    })),
    carryoverArticle: Optional(Record({
      modelForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean), sortOrder: Optional(Number) }))),
      articleForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean), sortOrder: Optional(Number) }))),
    })),
    updateArticles: Optional(Record({
      groups: Optional(Array(Record({ label: String, expanded: Optional(Boolean), attributes: Array(Record({ attribute: String, readOnly: Boolean, required: Boolean })) }))),
      attributes: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean }))),
    })),
  }),

  RequestForm: Record({
    copyModel: Optional(Record({
      modelForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      articleForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      requestAttributesForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean }))),
    })),
    carryoverModel: Optional(Record({
      modelForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      articleForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean, prePopulate: Optional(Boolean) }))),
      requestAttributesForm: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean }))),
    })),
    modifyAttributes: Optional(Dictionary(Array(String), String)),
  }),

  RequestAttributesVettingList: Dictionary(Array(String)),

  RequestArticleAttributes: Array(String),

  ModelDetailsForm: Record({
    ArticleProperties: Optional(Record({
      groups: Optional(Array(Record({ label: String, expanded: Optional(Boolean), attributes: Array(Record({ attribute: String, readOnly: Boolean, required: Boolean })) }))),
      attributes: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean }))),
    })),
    SeasonalModelProperties: Optional(Record({
      attributes: Optional(Array(Record({ attribute: String, readOnly: Boolean, required: Boolean }))),
    })),
  }),

  SilhouetteImagesCriteria: Record({}),
  EnableNewStyleDetails: Boolean,
  AssignColorRequiredSeasonlessArticleAttributes: Array(String),
  ArIndicatorAttribute: String,
  GhlIndicatorAttribute: String,
  BAndTIndicatorAttribute: String,
  GenerateFrameArticlesLimit: Number,
  GenerateSlideArticlesLimit: Number,
  EnableBrowseByModel: Boolean,
  BrowseByAttribute: Dictionary(Record({ sortDirection: Optional(String), customSortValueList: Optional(Array(Union(String, Number))) })),
  BrowseByAttributeThumbnailDetails: Dictionary(
    Array(Record({
      label: Optional(String),
      value: Optional(Union(Literal('$attributeDisplayName'), Literal('$attributeValue'), Literal('$articleImage'), Literal('$bucketAttribute'), Literal('$sku'), Literal('$styles'))),
      attr: Optional(String),
      style: Optional(Array(String)), // list of class name separated by space
      w: Optional(Number),
      h: Optional(Number),
      bg: Optional(String),
      margin: Optional(String),
      padding: Optional(String),
      maxHeight: Optional(String),
      overflow: Optional(String),
      borderRadius: Optional(String),
    })),
  ),
  BrowseByAttributeThumbnailDetailsT1SW: Union(Dictionary(Record({
    large: Array(Record({
      label: Optional(String),
      value: Optional(Union(Literal('$attributeDisplayName'), Literal('$attributeValue'), Literal('$articleImage'), Literal('$bucketAttribute'), Literal('$sku'), Literal('$styles'))),
      attr: Optional(String),
      style: Optional(Array(String)), // list of class name separated by space
      w: Optional(Number),
      h: Optional(Number),
      bg: Optional(String),
      margin: Optional(String),
      padding: Optional(String),
      maxHeight: Optional(String),
      overflow: Optional(String),
      borderRadius: Optional(String),
    })),
    medium: Array(Record({
      label: Optional(String),
      value: Optional(Union(Literal('$attributeDisplayName'), Literal('$attributeValue'), Literal('$articleImage'), Literal('$bucketAttribute'), Literal('$sku'), Literal('$styles'))),
      attr: Optional(String),
      style: Optional(Array(String)), // list of class name separated by space
      w: Optional(Number),
      h: Optional(Number),
      bg: Optional(String),
      margin: Optional(String),
      padding: Optional(String),
      maxHeight: Optional(String),
      overflow: Optional(String),
      borderRadius: Optional(String),
    })),
    small: Array(Record({
      label: Optional(String),
      value: Optional(Union(Literal('$attributeDisplayName'), Literal('$attributeValue'), Literal('$articleImage'), Literal('$bucketAttribute'), Literal('$sku'), Literal('$styles'))),
      attr: Optional(String),
      style: Optional(Array(String)), // list of class name separated by space
      w: Optional(Number),
      h: Optional(Number),
      bg: Optional(String),
      margin: Optional(String),
      padding: Optional(String),
      maxHeight: Optional(String),
      overflow: Optional(String),
      borderRadius: Optional(String),
    })),
  })), Nullish),
  BrowseByImageSort: Dictionary(Record({ sortBy: String, desc: Optional(Boolean) })),
  BrowseByAttributeThumbnailColor: Union(Record({
    conditions: Dictionary(String),
    attribute: String,
  }), Nullish),
  SkipCustomerSelection: Record({
    customerNumber: Optional(Number),
    wholesalePriceGroupId: Optional(Number),
    retailPriceGroupId: Optional(Number),
    outletPriceGroupId: Optional(Number),
  }),
  DefaultFilterAttributes: Array(String),
  ModelDetailsChipAttribute: String,
  ModelDetailsChipTooltipText: String,
  ExportDefaultImagePath: String,
  ExportTemplateArticlesLimit: Number,
  PassiveMOQ: Boolean,
  ArticlesMustHave: Record({}), // TODO: define schema once worked on orders related configuration
  ExportAllPriceGroups: Boolean,
  ExportExcelArticlesWithImageLimit: Number,
  NewestImageAssetKeyList: Array(String),
  MerchDefaultArticleDetailsAttributes: Array(String),
  MerchLabelAttributes: Optional(Array(String)),
  RetailWindowDefaultValueCriteria: Optional(Record({
    defaultRetailConfigurations: Optional(Array(Record({
      Period: Optional(Array(String)),
      RetailIntroMonth: Optional(String),
      RetailExitMonth: Optional(String),
      ArticleLifecycle: Optional(Array(String)),
    }))),
  })),
  ArticlesCustomSortOrder: Optional(Record({
    orderedAttributeSystemName: Array(String),
    sortOrder: Record({}),
  })),
  NumberOfDecimalsForPrices: Number,
  HardLaunchDateAttributeSystemName: String,
  UAStandardLaunchMonthAttribute: String,
  VLPTemplateArticleAttributesToDisplay: Array(String),
  WhiteboardLabelAttributes: Optional(Array(String)),
  ArticleSummaryReport: Array(Record({
    chartTitle: Optional(String),
    chartType: String,
    dataRange1: Record({ articleProperty: String }),
    dataRange2: Optional(Record({ articleProperty: Optional(String), formula: Optional(String) })),
    groupBySortDirection: Optional(String),
  })),
  AllowedMerchTemplates: Array(Number),
  WhiteboardColorCodingAttribute: Optional(Array(Record({
    attribute: String,
    value: String,
    color: String,
  }))),
  AllowedStatesForCreateArticle: Array(String),
  DefaultCreateArticleState: String,
}

export default class CatalogConfig {
  GroupByAttributes: Static<typeof configSchemas.GroupByAttributes> = {}
  ArticleThumbnailFlags: Static<typeof configSchemas.ArticleThumbnailFlags> = []
  ArticleDetailsFlags: Static<typeof configSchemas.ArticleDetailsFlags> = []
  ArticlesSortProperties: Static<typeof configSchemas.ArticlesSortProperties> = []
  ThumbnailDetails: Static<typeof configSchemas.ThumbnailDetails> = {
    article: {
      large: [
        { w: 138, h: 24, value: 'ArticleName', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-2'] },
        { w: 69, h: 17, value: '$retail', style: ['text-xs', 'font-bold', 'text-primary-500'] },
        { w: 69, h: 17, value: 'ArticleNumber', style: ['text-xs', 'text-right'] },
        { w: 78, h: 17, value: '$flags', style: ['mt-0.5'] },
        { w: 60, h: 17, value: '$datecol', style: ['text-xs', 'text-right', 'overflow-hidden'] },
      ],
      medium: [
        { w: 115, h: 17, value: 'ArticleName', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-1'] },
        { w: 57, h: 17, value: '$retail', style: ['text-xxs', 'font-bold', 'text-primary-500'] },
        { w: 58, h: 17, value: 'ArticleNumber', style: ['text-xxs', 'text-right'] },
        { w: 115, h: 11, value: '$flags' },
      ],
      small: [
        { w: 85, h: 24, value: 'ArticleName', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-1'] },
        { w: 32, h: 14, value: '$retail.nc', style: ['text-tiny', 'text-primary-500'] },
        { w: 53, h: 14, value: 'ArticleNumber', style: ['text-tiny', 'text-right'] },
      ],
    },
    model: {
      large: [
        { w: 138, h: 36, value: 'ArticleName', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-2'] },
        { w: 69, h: 17, value: '$retail', style: ['text-xs', 'font-bold', 'text-primary-500'] },
        { w: 69, h: 17, value: 'ModelNumber', style: ['text-xs'] },
        { w: 86, h: 17, value: '$flags' },
        { w: 52, h: 17, value: '$datecol', style: ['text-xs', 'overflow-hidden'] },
      ],
      medium: [
        { w: 115, h: 17, value: 'ArticleName', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-1'] },
        { w: 60, h: 17, value: '$retail', style: ['text-xxs', 'font-bold', 'text-primary-500'] },
        { w: 55, h: 17, value: 'ModelNumber', style: ['text-xxs'] },
        { w: 115, h: 11, value: '$flags' },
      ],
      small: [
        { w: 85, h: 27, value: 'ArticleName', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-1'] },
        { w: 36, h: 14, value: '$retail', style: ['text-tiny', 'text-primary-500'] },
        { w: 49, h: 14, value: 'ModelNumber', style: ['text-tiny'] },
      ],
    },
  }

  CarryoverArticleThumbnailDetailsWeb: Static<typeof configSchemas.CarryoverArticleThumbnailDetailsWeb> = {
    article: {
      large: [
        { w: 138, h: 24, value: 'ArticleName', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-2'] },
        { w: 138, h: 24, value: 'Period', style: ['text-xs', 'font-semibold', 'leading-4', 'line-clamp-2'] },
        { w: 69, h: 17, value: '$retail', style: ['text-xs', 'font-bold', 'text-primary-500'] },
        { w: 69, h: 17, value: 'ArticleNumber', style: ['text-xs', 'text-right'] },

      ],
    },
  }

  HideCatalogTreeByDefault: Static<typeof configSchemas.HideCatalogTreeByDefault> = false
  FilterableAttributes: Static<typeof configSchemas.FilterableAttributes> = []
  ShowPriceThousandsSeparated: Static<typeof configSchemas.ShowPriceThousandsSeparated> = false
  CarryoverFormStyleDetailsTemplateWeb: Static<typeof configSchemas.CarryoverFormStyleDetailsTemplateWeb> = [
    // { label: 'Merch Color Brief Comments Colorway Level', width: 1, value: 'Merch_Color_Brief_Comments_Colorway_Level' }
  ]

  ArticleDetailsTemplate2: Static<typeof configSchemas.ArticleDetailsTemplate2> = [
    { label: 'Merch Color Brief Comments Colorway Level', width: 1, value: 'Merch_Color_Brief_Comments_Colorway_Level' },
    { label: 'Color Comments', width: 1, value: 'Color_Comments' },
    { label: 'Colorway', width: 0.5, value: 'Colorway' },
    { label: 'Period', width: 0.5, value: 'Period' },
    { label: 'HPS', width: 0.5, value: 'HPS' },
    { label: 'Gender', width: 0.5, value: 'Gender' },
    { label: 'Retail Price', width: 0.5, value: '$retail' },
    { label: 'Wholesale Price', width: 0.5, value: '$wholesale' },
    { label: 'Start Ship', width: 0.5, value: 'ShipmentStartDate' },
    { label: 'End Ship', width: 0.5, value: 'ShipmentEndDate' },
    { label: 'Segmentation', width: 1, value: '$seg' },
  ]

  ArticleThumbnailShowFirstDeliveryDate: Static<typeof configSchemas.ArticleThumbnailShowFirstDeliveryDate> = false
  PriceGroupsLabel: Static<typeof configSchemas.PriceGroupsLabel> = {
    wholesalePrice: 'Wholesale price',
    retailPrice: 'Retail price',
    outletPrice: 'Outlet price',
    orderPrice: 'Order Price',
  }

  DisableModules: Static<typeof configSchemas.DisableModules> = []
  PreSelectedOrderFormColumns: Static<typeof configSchemas.PreSelectedOrderFormColumns> = []
  AllowArticleNameForUpdate: Static<typeof configSchemas.AllowArticleNameForUpdate> = false
  FilterDeliveryDateByMMMYYYYFormat: Static<typeof configSchemas.FilterDeliveryDateByMMMYYYYFormat> = false
  AllowFullRangeCustomerToFilterBySegmentation: Static<typeof configSchemas.AllowFullRangeCustomerToFilterBySegmentation> = false
  AllowCustomerToFilterBySegmentation: Static<typeof configSchemas.AllowCustomerToFilterBySegmentation> = false
  AllowDownloadImages: Static<typeof configSchemas.AllowDownloadImages> = true
  DownloadArticleImageFormat: Static<typeof configSchemas.DownloadArticleImageFormat> = 'png'
  SaveImagesFileName: Static<typeof configSchemas.SaveImagesFileName> = ''
  WhiteboardImagePlaceholderOptions: Static<typeof configSchemas.WhiteboardImagePlaceholderOptions> = {
    key: 'TEMP',
    sortOrder: 1000,
  }

  ArticleForm: Static<typeof configSchemas.ArticleForm> = {}
  RequestForm: Static<typeof configSchemas.RequestForm> = {}
  RequestAttributesVettingList: Static<typeof configSchemas.RequestAttributesVettingList> = {}
  RequestArticleAttributes: Static<typeof configSchemas.RequestArticleAttributes> = []
  BrowseByAttribute: Static<typeof configSchemas.BrowseByAttribute> = {}
  BrowseByAttributeThumbnailDetails: Static<typeof configSchemas.BrowseByAttributeThumbnailDetails> = {}
  BrowseByAttributeThumbnailDetailsT1SW: Static<typeof configSchemas.BrowseByAttributeThumbnailDetailsT1SW> = null
  BrowseByImageSort: Static<typeof configSchemas.BrowseByImageSort> = {}
  BrowseByAttributeThumbnailColor: Static<typeof configSchemas.BrowseByAttributeThumbnailColor> = null
  ModelDetailsForm: Static<typeof configSchemas.ModelDetailsForm> = {}
  EnableNewStyleDetails: Static<typeof configSchemas.EnableNewStyleDetails> = false
  AssignColorRequiredSeasonlessArticleAttributes: Static<typeof configSchemas.AssignColorRequiredSeasonlessArticleAttributes> = []
  ArIndicatorAttribute: Static<typeof configSchemas.ArIndicatorAttribute> = ''
  GhlIndicatorAttribute: Static<typeof configSchemas.GhlIndicatorAttribute> = ''
  BAndTIndicatorAttribute: Static<typeof configSchemas.BAndTIndicatorAttribute> = ''
  GenerateFrameArticlesLimit: Static<typeof configSchemas.GenerateFrameArticlesLimit> = 500
  GenerateSlideArticlesLimit: Static<typeof configSchemas.GenerateSlideArticlesLimit> = 500
  EnableBrowseByModel: Static<typeof configSchemas.EnableBrowseByModel> = false
  SkipCustomerSelection: Static<typeof configSchemas.SkipCustomerSelection> = {}
  DefaultFilterAttributes: Static<typeof configSchemas.DefaultFilterAttributes> = []
  ModelDetailsChipAttribute: Static<typeof configSchemas.ModelDetailsChipAttribute> = ''
  ModelDetailsChipTooltipText: Static<typeof configSchemas.ModelDetailsChipTooltipText> = ''
  ExportDefaultImagePath: Static<typeof configSchemas.ExportDefaultImagePath> = 'https://t1qa.blob.core.windows.net/dunes-images/noimg.png'
  ExportTemplateArticlesLimit: Static<typeof configSchemas.ExportTemplateArticlesLimit> = 5000
  SilhouetteImagesCriteria: Static<typeof configSchemas.SilhouetteImagesCriteria> = {}
  PassiveMOQ: Static<typeof configSchemas.PassiveMOQ> = false
  ArticlesMustHave: Static<typeof configSchemas.ArticlesMustHave> = {}
  ExportAllPriceGroups: Static<typeof configSchemas.ExportAllPriceGroups> = true
  ExportExcelArticlesWithImageLimit: Static<typeof configSchemas.ExportExcelArticlesWithImageLimit> = 3000
  NewestImageAssetKeyList: Static<typeof configSchemas.NewestImageAssetKeyList> = []
  MerchDefaultArticleDetailsAttributes: Static<typeof configSchemas.MerchDefaultArticleDetailsAttributes> = []
  MerchLabelAttributes: Static<typeof configSchemas.MerchLabelAttributes> = undefined
  RetailWindowDefaultValueCriteria: Static<typeof configSchemas.RetailWindowDefaultValueCriteria> = {}
  ArticlesCustomSortOrder: Static<typeof configSchemas.ArticlesCustomSortOrder> = {
    sortOrder: {},
    orderedAttributeSystemName: [],
  }

  NumberOfDecimalsForPrices: Static<typeof configSchemas.NumberOfDecimalsForPrices> = 2
  HardLaunchDateAttributeSystemName: Static<typeof configSchemas.HardLaunchDateAttributeSystemName> = ''
  UAStandardLaunchMonthAttribute: Static<typeof configSchemas.UAStandardLaunchMonthAttribute> = ''
  VLPTemplateArticleAttributesToDisplay: Static<typeof configSchemas.VLPTemplateArticleAttributesToDisplay> = []
  WhiteboardLabelAttributes: Static<typeof configSchemas.WhiteboardLabelAttributes> = undefined
  ArticleSummaryReport: Static<typeof configSchemas.ArticleSummaryReport> = []
  AllowedMerchTemplates: Static<typeof configSchemas.AllowedMerchTemplates> = [1, 2]
  WhiteboardColorCodingAttribute: Static<typeof configSchemas.WhiteboardColorCodingAttribute> = []
  AllowedStatesForCreateArticle: Static<typeof configSchemas.AllowedStatesForCreateArticle> = []
  DefaultCreateArticleState: Static<typeof configSchemas.DefaultCreateArticleState> = ''

  constructor(configs: CatalogConfiguration[], userRoles: UserRoleModel[]) {
    const userRolesSet = new Set()
    userRoles.forEach((role) => {
      if (role.Status) {
        userRolesSet.add(role.Role.toLowerCase())
      }
    })
    const roleBasedMatchedFirstConfig = new Set()
    configs.forEach((config) => {
      if (!config.Name.toLowerCase().startsWith('t1s-')) { return }
      const match = config.Name.match('T1S-(.[^.]+).?(.[^.]*)?')
      if (match && configSchemas.hasOwnProperty(match[1]) && this.hasOwnProperty(match[1])) {
        if (match[2] != null && userRolesSet.has(match[2].toLowerCase()) && !roleBasedMatchedFirstConfig.has(match[1])) { // configuration is role based and there is no other role based configuration added
          LoadValueOrDefault(config, this, match[1])
          roleBasedMatchedFirstConfig.add(match[1])
        }
        else if (match[2] == null && !roleBasedMatchedFirstConfig.has(match[1])) {
          LoadValueOrDefault(config, this, match[1])
        }
      }
    })
  }
}

function LoadValueOrDefault(config: CatalogConfiguration, catalogConfig: CatalogConfig, name: string) {
  let configValue: any
  try {
    configValue = JSON.parse(config.Value)
  }
  catch (error) {
    configValue = config.Value
  }
  try {
    const configSchema = configSchemas[name]
    let val = configValue
    if (configSchema.tag === 'boolean') {
      val = !!configValue
    }
    val = configSchema.check(val)

    catalogConfig[name] = val
  }
  catch (error) {
    console.warn('Invalid configuration for', name, configValue)
    console.error(error)
  }
  return catalogConfig
}
