<template>
  <tx-dialog
    v-model="dialogVisible" :title="t('shareUserDialog.title')" width="1000px" height="600px" show-ok-cancel :ok-state="loading ? 'loading' : 'enabled'"
    @opened="onOpen" @cancel="dialogVisible = false" @ok="onShare"
  >
    <div class="container relative min-h-15">
      <loader v-if="loading" />
      <div v-else class="body">
        <tx-alert :show="hasError" type="error" :text="errorMessage" dismissible />
        <p class="text-base">
          {{ t('shareUserDialog.placeholder') }}
        </p>
        <div class="flex">
          <!-- Available -->
          <div class="w-1/2 mr-1 border rounded-md">
            <div class="h-10 text-sm text-center bg-gray-200">
              <tx-input v-model="filter" :placeholder="t('shareUserDialog.filter')" />
            </div>
            <ul tabindex="-1" role="listbox" class="w-full overflow-y-auto leading-10 bg-white h-96">
              <li
                v-for="(accessibleItem) in availableAccessibleItems" :key="`${accessibleItem.type}${accessibleItem.id}`" tabindex="0"
                class="relative flex items-center h-10 cursor-pointer hover:bg-active"
                @click="onAccessibleItemSelected(accessibleItem)"
              >
                <div class="flex flex-1 m-1 text-sm border-b border-grey">
                  <div class="w-full text-left text-primary-500 line-clamp-1">
                    {{ accessibleItem.name }}
                  </div>
                  <div class="w-full text-xs text-right line-clamp-1">
                    {{ accessibleItem.subTitle }}
                  </div>
                </div>
              </li>
            </ul>
            <div class="h-10 p-1 pr-4 bg-gray-200">
              <tx-button type="text" :text="t('shareUserDialog.addAll')" height="32px" @click="onAddAll" />
            </div>
          </div>

          <!-- Selected -->
          <div class="w-1/2 ml-1 border rounded-md">
            <div class="flex items-center h-10 text-sm bg-gray-200">
              <p class="w-full text-center">
                {{ t('shareUserDialog.nSelectedUsers', modelValue.length) }}
              </p>
            </div>
            <ul tabindex="-1" role="listbox" class="w-full overflow-y-auto leading-10 h-96">
              <li
                v-for="(accessibleItem, index) in modelValue" :key="accessibleItem.id" tabindex="0"
                class="relative flex items-center h-10 cursor-pointer hover:bg-active"
                @click="onSelectedAccessibleItemSelected(accessibleItem, index)"
              >
                <div class="flex flex-1 m-1 text-sm border-b border-grey">
                  <div class="w-1/3 text-left text-primary-500 line-clamp-1">
                    {{ accessibleItem.name }}
                  </div>
                  <div class="w-1/3 text-xs text-right line-clamp-1 pr-2 truncate">
                    {{ accessibleItem.subTitle }}
                  </div>
                  <div class="w-1/3 min-w-30 text-xs text-left border-none bg-transparent h-0" @click.stop>
                    <tx-select
                      v-model="accessibleItem.permission"
                      class="border-0 outline-none appearance-none bg-transparent cursor-pointer p-0 relative h-4"
                      :data="permissionOptions"
                      value-prop="value"
                      display-prop="label"
                    />
                  </div>
                </div>
              </li>
            </ul>
            <div class="h-10 p-1 pr-4 bg-gray-200">
              <tx-button type="text" :text="t('shareUserDialog.removeAll')" height="32px" @click="onRemoveAll" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </tx-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { computed, ref } from 'vue'
import TxDialog from '@/shared/components/TxDialog.vue'
import Loader from '@/shared/components/Loader.vue'
import TxAlert from '@/shared/components/TxAlert.vue'
import TxInput from '@/shared/components/TxInput.vue'
import TxButton from '@/shared/components/TxButton.vue'
import type { AccessibleUserGroupModel, AccessibleUserModel } from '@/api/t1/model/userModel'
import { getAccessibleUsers, getAccessibleUsersGroups } from '@/api/t1/user'
import utils from '@/services/utils'
import useErrorMessage from '@/shared/composables/errorMessage'
import { useUserStore } from '@/store/userData'
import TxSelect from '@/shared/components/TxSelect.vue'
import { whiteboardConstants } from '@/models/constants'

export interface IAccessibleItem {
  id: number
  type: AccessibleItemType
  name: string
  subTitle: string
  permission: WhiteboardUserPermissionType
}

interface IProps {
  modelValue?: IAccessibleItem[]
}
const props = withDefaults(defineProps<IProps>(), { modelValue: () => [] as IAccessibleItem[] })

const emit = defineEmits<{
  (e: 'update:modelValue', items: IAccessibleItem[]): void
  (e: 'share', items: IAccessibleItem[]): void
}>()

const { t } = useI18n()
const userStore = useUserStore()
const { errorMessage, hasError } = useErrorMessage()

const dialogVisible = ref(false)
const loading = ref(false)
const filter = ref('')
const accessibleItems = ref<IAccessibleItem[]>([])
const permissionOptions = ref([
  { value: 'Read', label: t('shareUserDialog.viewOnly') },
  { value: 'Write', label: t('shareUserDialog.edit') },
  { value: 'ReadAndComment', label: t('shareUserDialog.viewAndComment') },
])

const selectedItems = computed({
  get: () => props.modelValue,
  set: modelValue => emit('update:modelValue', modelValue),
})

const availableAccessibleItems = computed(() => {
  const selectedItemIds = new Set(props.modelValue.map(x => x.id))
  if (utils.isValidStringValue(filter.value)) {
    return accessibleItems.value.filter(x => !selectedItemIds.has(x.id) && (x.name.toLowerCase().includes(filter.value.toLowerCase()) || x.subTitle.toLowerCase().includes(filter.value.toLowerCase())))
  }
  return accessibleItems.value.filter(x => !selectedItemIds.has(x.id))
})

function onOpen() {
  accessibleItems.value = []
  if (userStore.activeCatalog) {
    const catalogCode = userStore.activeCatalog.CatalogCode
    const customerId = userStore.currentCustomer ? userStore.currentCustomer.CustomerId : undefined

    const promises = [getAccessibleUsers(catalogCode, customerId), getAccessibleUsersGroups()]

    loading.value = true
    Promise.all(promises)
      .then(async (responses) => {
        for (const userGroup of responses[1].data as AccessibleUserGroupModel[]) {
          if (userGroup.Status) {
            const permission = userGroup.Write === 1
              ? whiteboardConstants.userPermissions.write as WhiteboardUserPermissionType
              : userGroup.Read === 1
                ? whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType
                : userGroup.ReadAndComment === 1
                  ? whiteboardConstants.userPermissions.readAndComment as WhiteboardUserPermissionType
                  : whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType // default
            accessibleItems.value.push({
              id: userGroup.Id,
              type: 'group',
              name: userGroup.Name,
              subTitle: userGroup.AccountName,
              permission,
            })
          }
        }
        for (const user of responses[0].data as AccessibleUserModel[]) {
          if (user.IsAccessible && user.Status) {
            const permission = user.Write === 1
              ? whiteboardConstants.userPermissions.write as WhiteboardUserPermissionType
              : user.Read === 1
                ? whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType
                : user.ReadAndComment === 1
                  ? whiteboardConstants.userPermissions.readAndComment as WhiteboardUserPermissionType
                  : whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType // default
            accessibleItems.value.push({
              id: user.Id,
              type: 'user',
              name: `${user.FirstName} ${user.LastName}`,
              subTitle: user.UserName,
              permission,
            })
          }
        }
      })
      .catch((e) => {
        console.error(e)
        errorMessage.value = t('general.unexpectedError')
      })
      .finally(() => {
        loading.value = false
      })
  }
}

function onAccessibleItemSelected(accessibleItem: IAccessibleItem) {
  accessibleItem.permission = whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType // default
  selectedItems.value.push(accessibleItem)
  emit('update:modelValue', selectedItems.value)
}

function onSelectedAccessibleItemSelected(accessibleItem: IAccessibleItem, index: number) {
  selectedItems.value.splice(index, 1)
  emit('update:modelValue', selectedItems.value)
}

function onAddAll() {
  const itemsWithPermissions = accessibleItems.value.map(item => ({
    ...item,
    permission: whiteboardConstants.userPermissions.read as WhiteboardUserPermissionType, // default
  }))
  emit('update:modelValue', itemsWithPermissions)
}

function onRemoveAll() {
  emit('update:modelValue', [])
}

function onShare() {
  emit('share', props.modelValue)
}

function open() {
  dialogVisible.value = true
}

function close() {
  dialogVisible.value = false
}

defineExpose({
  open,
  close,
  loading,
  errorMessage,
})
</script>
