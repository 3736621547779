<template>
  <div class="w-full" tabindex="0">
    <div v-if="label.length > 0" class="flex">
      <label v-if="label.length > 0" class="text-xs tracking-wide uppercase label" :class="{ required }" v-text="label" />
      <label v-if="multipleLimit > 0" class="ml-1 text-xs tracking-wide uppercase label" v-text="`(Limit to ${multipleLimit})`" />
      <label v-if="loading" class="ml-1 text-xs tracking-wide uppercase label" v-text="'(Loading...)'" />
    </div>

    <div
      ref="refSelect" class="block w-full min-h-9"
      :class="{ 'bg-gray-200 rounded': disabled, 'border-red-300 bg-red-50 placeholder-red-200 text-red-900': hasError, 'bg-white': !disabled }"
    >
      <div
        class="cursor-pointer flex relative items-center w-full min-h-9 h-full mt-0 px-0.5 border border-form rounded shadow-input focus:border-primary text-base text-default"
        aria-haspopup="listbox" aria-expanded="true" aria-labelledby="listbox-label" @click="doOpenDropdown"
      >
        <div v-if="$slots.prefix" class="w-5 shrink-0">
          <slot name="prefix" />
        </div>
        <template v-if="hasValue">
          <div v-if="!multipleValues" class="pl-2 truncate grow" v-text="displayModelValue" />
          <template v-else>
            <div
              v-for="keyVal in modelValues" :key="keyVal" class="block p-1 m-1 text-xs truncate border rounded-md"
              @click.stop="doSelect(keyVal)" v-text="displayValues[keyVal]"
            />
          </template>
        </template>
        <div v-else class="truncate grow text-grey" :style="placeholderStyle" v-text="placeholder" />
        <!-- </div> -->
        <div class="inset-y-0 right-0 flex items-center justify-end w-4 h-full mr-2 pointer-events-none shrink-0 grow">
          <font-awesome-icon icon="fa-light fa-chevron-down" />
        </div>
      </div>
    </div>
    <div v-if="hasError">
      <p v-for="error in errors" :key="error.$uid" class="text-xs italic text-red-500 overflow-auto ">
        {{ error.$message }}
      </p>
    </div>
    <div
      v-show="isOpen" ref="refDropdown"
      class="max-w-lg py-1 overflow-x-hidden text-base bg-white shadow-dropdown max-h-56 overflow-y-overlay z-dropdown focus:outline-none"
    >
      <div v-if="clearable || multipleValues" class="flex justify-between py-2 pl-3 pr-3">
        <tx-button v-if="multipleValues" type="text" :text="t('general.selectAll')" @click="doSelectAll()" />
        <tx-button v-if="clearable" type="text" :text="t('general.reset')" @click="doReset()" />
      </div>
      <tx-input
        v-if="filterable" ref="refFilter" v-model="filterText" clearable faicon="fa-light fa-magnifying-glass" class="pb-1 mx-3"
        :placeholder="t('general.search')"
      />
      <ul tabindex="-1" role="listbox" aria-labelledby="listbox-label" aria-activedescendant="listbox-item-3" class="leading-9">
        <!-- Add "None" option for non-integer attribute types with non required nonmultivalue vetting list -->
        <li
          v-if="showNone && isAttributeEditor && !multipleValues && !required && attributeType !== AttributeType.Int" id="listbox-item-none" tabindex="0" role="option"
          class="relative flex items-center pl-2 pr-3 text-gray-900 cursor-pointer select-none h-7 hover:bg-[#ecf5ff] hover:text-[#66b1ff] focus:outline-none focus:bg-grey-light"
          @click="doSelect('')"
        >
          <div class="pl-2 truncate grow" :class="{ 'text-primary-500': isSelected('') }">
            {{ t('general.none') }}
          </div>
        </li>

        <!-- Add "0" option for integer attribute types with non required nonmultivalue vetting list -->
        <li
          v-if="isAttributeEditor && !multipleValues && !required && attributeType === AttributeType.Int" id="listbox-item-0" tabindex="0" role="option" class="relative flex items-center pl-2 pr-3 text-gray-900 cursor-pointer select-none h-7 hover:bg-[#ecf5ff] hover:text-[#66b1ff] focus:outline-none focus:bg-grey-light"
          @click="doSelect(0)"
        >
          <div class="pl-2 truncate grow" :class="{ 'text-primary-500': isSelected(0) }">
            {{ t('general.zero') }}
          </div>
        </li>

        <li
          v-for="(d, index) in filteredData" :id="`listbox-item-${index}`" :key="hasValueProp ? d[valueProp] : (hasValueKey ? d[valueKey!] : d)" :tabindex="isAttributeEditor ? 1 : 0"
          role="option" class="relative flex flex-col pl-2 pr-3"
        >
          <div
            class="flex items-center text-gray-900 select-none"
            :class="{ 'cursor-pointer hover:bg-[#ecf5ff] hover:text-[#66b1ff] focus:outline-none focus:bg-grey-light': !d.children }"
            @click="d.children ? undefined : doSelect(hasValueProp ? d[valueProp] : d)"
          >
            <font-awesome-icon v-if="multipleValues && isSelected(d)" class="w-4 h-4 m-auto text-primary-500 shrink-0" icon="fa-light fa-check" />
            <div class="pl-2 truncate grow" :class="{ 'text-primary-500': isSelected(d) }">
              {{ hasDisplayProp ? d[displayProp] : d }}
            </div>
            <div
              v-if="hasSecondaryDisplayProp" class="truncate pl-0.5"
              :class="{ 'text-primary-500 font-bold': isSelected(d) }" v-text="d[secondaryDisplayProp]"
            />
          </div>
          <div
            v-for="child in d.children" :key="hasValueProp ? child[valueProp] : (hasValueKey ? child[valueKey!] : child)"
            role="option" class="flex items-center hover:bg-[#ecf5ff] hover:text-[#66b1ff] focus:outline-none focus:bg-grey-light"
            @click="doSelect(hasValueProp ? child[valueProp] : child)"
          >
            <font-awesome-icon v-if="multipleValues && isSelected(child)" class="w-4 h-4 m-auto text-primary-500 shrink-0" icon="fa-light fa-check" />
            <div class="pl-4 truncate grow" :class="{ 'text-primary-500': isSelected(child) }">
              {{ hasDisplayProp ? child[displayProp] : child }}
            </div>
            <div
              v-if="hasSecondaryDisplayProp" class="truncate pl-0.5"
              :class="{ 'text-primary-500 font-bold': isSelected(child) }" v-text="child[secondaryDisplayProp]"
            />
          </div>
        </li>
        <li v-if="filteredData.length === 0">
          <div class="flex items-center justify-center space-x-3">
            <!-- Selected: "font-semibold", Not Selected: "font-normal" -->
            <span class="block italic truncate text-dark-grey" v-text="t('general.emptyList')" />
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
import { isNull, isUndefined } from 'lodash-es'
import { useI18n } from 'vue-i18n'
import type { Instance } from '@popperjs/core'
import { createPopper } from '@popperjs/core'
import { onClickOutside } from '@vueuse/core'
import type { ErrorObject } from '@vuelidate/core'
import { computed, nextTick, onMounted, onUnmounted, ref } from 'vue'
import TxButton from './TxButton.vue'
import TxInput from './TxInput.vue'
import { sameWidthModifier } from '@/services/utils'
import { AttributeType } from '@/models/catalogAttribute'

interface IProps {
  label?: string
  errors?: ErrorObject[]
  data?: any[]
  modelValue?: any
  valueProp?: string
  displayProp?: string
  secondaryDisplayProp?: string
  placeholder?: string
  filterable?: boolean
  multipleValues?: boolean
  multipleLimit?: number
  clearable?: boolean
  disabled?: boolean
  required?: boolean
  sortList?: boolean
  showOne?: boolean
  loading?: boolean
  showNone?: boolean
  /**
   * in order to have modelValue an object valueKey is required
   * this can not work along side multipleValues
   * priority will be given to valueProp, if valueProp is defined the modelValue will be a string, if valueProp is missing and valueKey is defined modelValue will be an object if data is object
   */
  valueKey?: string
  isAttributeEditor?: boolean
  attributeType?: number
  placeholderStyle?: { color?: string, paddingLeft?: string }
}
const props = withDefaults(defineProps<IProps>(), { label: '', errors: () => [] as ErrorObject[], data: () => [] as any[], modelValue: null, valueProp: '', displayProp: '', secondaryDisplayProp: '', placeholder: '', filterable: false, multipleValues: false, multipleLimit: 0, clearable: false, disabled: false, required: false, sortList: true, showOne: false, loading: false, showNone: true })

const emit = defineEmits<{
  (e: 'update:modelValue', val: any): void
  (e: 'change', val: any): void
}>()

const { t } = useI18n()
const refSelect = ref()
const refDropdown = ref()
const isOpen = ref(false)
const filterText = ref('')
const refFilter = ref<HTMLInputElement>()
let popper: Instance

const hasError = computed(() => props.errors.length)

const hasDisplayProp = computed(() => props.displayProp.length > 0)

const hasSecondaryDisplayProp = computed(() => props.secondaryDisplayProp && props.secondaryDisplayProp.length > 0)

const hasValueKey = computed(() => props.valueKey != null && props.valueKey.length > 0)

const hasValueProp = computed(() => props.valueProp.length > 0)

const modelValues = computed(() => {
  if (props.multipleValues) {
    const multipleValues = (props.modelValue || []) as Array<any>
    return props.showOne ? multipleValues.slice(0, 1) : multipleValues
  }
  else {
    return []
  }
})

const hasValue = computed(() => props.multipleValues ? modelValues.value?.length > 0 : !isUndefined(props.modelValue) && !isNull(props.modelValue) && props.modelValue !== '')

const displayModelValue = computed(() => {
  if (hasValue.value && !props.multipleValues) {
    let found: any = null

    // Search in parents and their children
    const findItem = (itm: any) => {
      if (hasValueProp.value) {
        return itm[props.valueProp]?.toString().toLowerCase() === props.modelValue.toString().toLowerCase()
      }
      else if (hasValueKey.value) {
        return itm[props.valueKey!].toString().toLowerCase() === props.modelValue[props.valueKey!].toString().toLowerCase()
      }
      else {
        return itm.toString().toLowerCase() === props.modelValue.toString().toLowerCase()
      }
    }

    // Iterate over the data
    for (const parent of props.data) {
      // Check if the parent matches
      if (findItem(parent)) {
        found = parent
        break
      }
      // Check if any child matches
      if (parent.children) {
        for (const child of parent.children) {
          if (findItem(child)) {
            found = child
            break
          }
        }
      }
      if (found) { break }
    }

    if (!found) {
      if (props.required) {
        notifyUpdate('') // call notifyUpdate method to emit the change event to set the value as empty string
      }
      return ''
    }

    return hasDisplayProp.value ? found[props.displayProp] : found
  }
  return ''
})

const displayValues = computed(() => {
  if (props.multipleValues) {
    const addValues = (acc: Record<string, any>, item: any) => {
      const valueKey = hasValueProp.value ? item[props.valueProp] : item
      const displayValue = hasDisplayProp.value ? item[props.displayProp] : item
      acc[valueKey] = displayValue
    }

    return props.data.reduce((acc, parent) => {
      // Add parent value/display
      addValues(acc, parent)

      // Add child values/display if present
      if (parent.children) {
        parent.children.forEach((child: any) => {
          addValues(acc, child)
        })
      }

      return acc
    }, {} as Record<string, any>)
  }
  else {
    return {} as Record<string, any>
  }
})

const sortedData = computed(() => {
  if (props.sortList) {
    return [...props.data].sort((a, b) => {
      const aVal = hasDisplayProp.value ? a[props.displayProp]?.toString().trim().toLowerCase() : a?.toString().trim().toLowerCase()
      const bVal = hasDisplayProp.value ? b[props.displayProp]?.toString().trim().toLowerCase() : b?.toString().trim().toLowerCase()

      // If [Blank] value presnt show it as the first option
      if (aVal === '[blank]' && bVal !== '[blank]') {
        return -1
      }
      else if (aVal !== '[blank]' && bVal === '[blank]') {
        return 1
      }

      if (aVal < bVal) {
        return -1
      }
      else if (aVal > bVal) {
        return 1
      }
      else {
        return 0
      }
    })
  }
  else {
    return props.data
  }
})

const filteredData = computed(() => {
  if (filterText.value.length === 0) { return sortedData.value }
  const filterTextLowercase = filterText.value.toLowerCase()
  return sortedData.value.reduce((acc, itm) => {
    const isParentMatch = hasDisplayProp.value ? itm[props.displayProp].toString().toLowerCase().includes(filterTextLowercase) : itm.toString().toLowerCase().includes(filterTextLowercase)

    if (itm.children) {
      const children = itm.children?.filter((child) => {
        return hasDisplayProp.value ? child[props.displayProp].toString().toLowerCase().includes(filterTextLowercase) : child.toString().toLowerCase().includes(filterTextLowercase)
      })

      if ((children && children.length > 0)) {
        acc.push({ ...itm, children })
      }
    }
    else if (isParentMatch) {
      acc.push(itm)
    }

    return acc
  }, [])
})

function isSelected(val) {
  const v = hasValueProp.value ? val[props.valueProp] : val
  if (props.multipleValues) {
    return (props.modelValue || [] as Array<any>).includes(v)
  }
  else {
    if (hasValueKey.value && props.modelValue != null && val != null) {
      return props.modelValue[props.valueKey!] === val[props.valueKey!]
    }
    else {
      return props.modelValue === v
    }
  }
}

function doCloseDropdown() {
  isOpen.value = false
}

function doOpenDropdown() {
  if (!props.disabled) {
    isOpen.value = true
    popper.update()
    if (props.filterable) {
      nextTick(() => {
        refFilter.value?.focus()
      })
    }
  }
}

function doSelect(keyVal) {
  let newVal
  if (props.multipleValues) {
    const mv = [...(props.modelValue || []) as Array<any>]
    if (mv.includes(keyVal)) {
      newVal = mv.filter(itm => itm !== keyVal)
    }
    else {
      mv.push(keyVal)
      newVal = mv
    }
    if (props.multipleLimit > 0 && newVal.length > props.multipleLimit) {
      return
    }
  }
  else {
    newVal = keyVal
    isOpen.value = false
  }
  notifyUpdate(newVal)

  nextTick(() => {
    popper.update()
  })
}

function doSelectAll() {
  if (!props.multipleValues) { return }

  const mv = [...(props.modelValue || []) as Array<any>]
  let addedNew = false
  filteredData.value.forEach((itm) => {
    const itmVal = hasValueProp.value ? itm[props.valueProp] : itm
    if (!mv.includes(itmVal) && (props.multipleLimit <= 0 || mv.length < props.multipleLimit)) {
      mv.push(itmVal)
      addedNew = true
    }
  })
  if (addedNew) {
    notifyUpdate(mv)
    nextTick(() => {
      popper.update()
    })
  }
}

function doReset() {
  let newVal
  if (props.multipleValues) {
    newVal = []
  }
  else {
    newVal = undefined
  }
  nextTick(() => {
    popper.update()
  })
  notifyUpdate(newVal)
}

onMounted(() => {
  popper = createPopper(refSelect.value, refDropdown.value, {
    placement: 'bottom-start',
    modifiers: [
      sameWidthModifier,
      {
        name: 'offset',
        options: {
          offset: [0, 1],
        },
      },
    ],
    strategy: 'fixed',
  })

  onClickOutside(refDropdown.value, () => {
    doCloseDropdown()
  })
})

onUnmounted(() => {
  if (popper) {
    popper.destroy()
  }
})

function notifyUpdate(newVal: any) {
  emit('update:modelValue', newVal)
  emit('change', newVal)
}

function focus() {
  refSelect.value?.focus()
}

defineExpose({
  focus,
  openDropdown: doOpenDropdown,
})
</script>
