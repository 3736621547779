export default class NotificationModel {
  Id: number
  CatalogCode: number
  Status: number
  UserId: number
  CreatedDate: Date
  NotificationType: 'Request'
  Title?: string
  Description?: string
  TargetObject?: string
  Email?: string
  Read: boolean
  ReadOnDevice?: string
  ReadOn?: Date
  EmailedOn?: Date
  NotificationTab: 'Information' | 'Action'
  TargetObjectId?: number
  ReferenceArticleId?: number // Article ID
  ReferenceModelId?: number // Model ID
  UpdatedDate: Date
  ActionedOn?: Date | null
  ActionedBy?: number
  ActionedByUser?: string
  AvailableActions?: 'Approve/Reject' | 'Confirm' | null
  ActionPerformed?: 'Approve' | 'Reject' | 'Confirm' | null
  CatalogName?: string
  RequestType?: string

  constructor(obj: NotificationModel) {
    this.Id = obj.Id
    this.CatalogCode = obj.CatalogCode
    this.Status = obj.Status
    this.UserId = obj.UserId
    this.CreatedDate = new Date(obj.CreatedDate)
    this.NotificationType = 'Request'
    this.Title = obj.Title ? obj.Title : ''
    this.Description = obj.Description ? obj.Description : ''
    this.TargetObject = obj.TargetObject
    this.Email = obj.Email
    this.Read = obj.Read || false
    this.ReadOnDevice = obj.ReadOnDevice
    this.ReadOn = obj.ReadOn ? new Date(obj.ReadOn) : undefined
    this.EmailedOn = obj.EmailedOn ? new Date(obj.EmailedOn) : undefined
    this.CreatedDate = new Date(obj.CreatedDate)
    this.NotificationTab = obj.NotificationTab || 'Information'
    this.TargetObjectId = obj.TargetObjectId
    this.ReferenceArticleId = obj.ReferenceArticleId
    this.ReferenceModelId = obj.ReferenceModelId
    this.UpdatedDate = new Date(obj.UpdatedDate)
    this.ActionedOn = obj.ActionedOn ? new Date(obj.ActionedOn) : null
    this.ActionedBy = obj.ActionedBy ? obj.ActionedBy : undefined
    this.ActionedByUser = obj.ActionedByUser ? obj.ActionedByUser : undefined
    this.AvailableActions = obj.AvailableActions ? obj.AvailableActions : null
    this.ActionPerformed = obj.ActionPerformed ? obj.ActionPerformed : null
    this.CatalogName = obj.CatalogName ? obj.CatalogName : undefined
    this.RequestType = obj.RequestType ? obj.RequestType : ''
  }
}
