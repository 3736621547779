<template>
  <div :style="{ width }" class="box-border flex flex-col content-center justify-center py-2 text-sm" :title="valueTitle">
    <div v-if="field.type === AttributeType.TimeAgo">
      {{ value }}
    </div>
    <div v-if="isNull(visibleValue) || isUndefined(visibleValue)" class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap text-disabled">
      {{ t('general.blank') }}
    </div>
    <div
      v-else-if="field.type === AttributeType.Bool || field.type === AttributeType.ArticleStatus || field.type === AttributeType.Status"
      class="box-border flex flex-row justify-start my-auto whitespace-nowrap"
    >
      <div class="flex flex-row min-w-[50px] py-[3px] px-2 content-center items-center rounded-sm bg-grey-white">
        <div
          class="box-border w-2 h-2 mr-2 rounded-full whitespace-nowrap"
          :style="{ backgroundColor: (value === true || value === 1 || (typeof value === 'string' && value.toString().toUpperCase() === 'TRUE')) ? 'green' : (!utils.isDefined(value) || (value === false || value === 0 || value === 3 || (typeof value === 'string' && value.toString().toUpperCase() === 'FALSE'))) ? 'red' : '#0288d1' }"
        />
        <div class="box-border">
          {{ visibleValue }}
        </div>
      </div>
    </div>
    <div v-else-if="field.type === AttributeType.TimeAgo" class="w-full overflow-hidden whitespace-nowrap">
      <use-time-ago v-slot="{ timeAgo }" :time="visibleValue">
        {{ timeAgo }}
      </use-time-ago>
    </div>
    <div v-else class="w-full overflow-hidden overflow-ellipsis whitespace-nowrap">
      {{ visibleValue }}
    </div>
  </div>
</template>

<script lang="ts" setup>
import { isNull, isUndefined } from 'lodash-es'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import { UseTimeAgo } from '@vueuse/components'
import type { ITxDataTableColumn } from './TxDataTable.types'
import { AttributeType } from '@/models/catalogAttribute'
import utils from '@/services/utils'
import { useUserStore } from '@/store/userData'

const props = defineProps<{
  field: ITxDataTableColumn
  width: string
  value: any
  lookupData?: any
  columnData?: any
}>()

const userStore = useUserStore()
const { t } = useI18n()

const visibleValue = computed(() => {
  if (userStore.activeCatalog) {
    let fieldValue = props.value
    // TODO: due to time crunch choosing this approch
    if (props.field.isRequest && props.columnData && props.columnData._article) {
      fieldValue = props.columnData._article[props.field.property]
    }
    return utils.getAdminAttributeValue(props.field, fieldValue, props.lookupData, userStore.activeCatalog)
  }
  else {
    return ''
  }
})

const valueTitle = computed(() => {
  if (utils.isDefined(visibleValue.value)) {
    if (props.field.type === AttributeType.TimeAgo) {
      return utils.formatDateTime(visibleValue.value)
    }
  }
  return visibleValue.value
})
</script>
